<app-modal data-testid="thank-you-modal" #thankYouPurchaseModal (closed)="closeModal()">
  <ng-container header>
    <img
      class="header-img"
      data-testid="image"
      src="{{ modalData.src }}"
      alt="Thanks for purchase"
      [ngClass]="isGift || isClub ? 'header-img--big' : 'header-img--small'"
    >
    <h2
      class="modal-title"
      data-testid="title"
    >{{ modalData.title }}</h2>
  </ng-container>
  <ng-container
    body
    *ngIf="isClub && !isGift; then club; else series"
  ></ng-container>
</app-modal>

<ng-template #series>
  <ng-container *ngIf="isGift; then giftSeries; else justSeries"></ng-container>
</ng-template>

<ng-template #giftSeries>
  <div class="modal-description" data-testid="gift-series-description">
    <p class="modal-description__text">
      You have been sent a receipt and an email you can give to your friend whenever you want.
      They will have access to the streams once they log on to Kittch.</p>
  </div>
</ng-template>

<ng-template #justSeries>
  <div class="modal-description" data-testid="just-series-description">
    <p>
      The stream{{ isSeriesOrBundle ? 's' : '' }} will be added to your
      <a
        class="modal-description__link"
        data-testid="link"
        [routerLink]="myStuffLink"
      >My Stuff tab</a>,
      and an email receipt with the link{{ isSeriesOrBundle ? 's' : '' }} will be sent to you. Enjoy!
    </p>
  </div>
  <div class="form__item form__item--center">
    <div class="form-button">
      <app-button
        data-testid="button"
        [text]="isSeriesOrBundle ? 'View Streams' : 'View stream'"
        (clicked)="openStream()">
      </app-button>
    </div>
  </div>
</ng-template>

<ng-template #club>
  <div class="modal-description">
    <p class="modal-description__text">
      You now have access to exclusive streams, recipes, and more. You can always find your course in the
      <a
        class="modal-description__link modal-description__link--black"
        data-testid="link"
        [routerLink]="myStuffLink"
      >My Stuff</a> tab.
    </p>
  </div>
  <div class="form__item form__item--center">
    <div class="form-button">
      <app-button
        [text]="'start learning'"
        (clicked)="startLearning()">
      </app-button>
    </div>
  </div>
</ng-template>
