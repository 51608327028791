<button
  class="button button--save-my-spot"
  (click)="onSaveMySpotClick()"
>{{isSpotSaved ? 'Spot saved' : 'Save My Spot'}}</button>

<app-add-to-calendar-modal
  #addToCalendarModal
  *ngIf="isAddToCalendarModalShown"
  [stream]="stream"
  [isCreationSuccessModal]="false"
  [isShownIcon]="isShownIcon"
  [email]="email"
></app-add-to-calendar-modal>

<!-- EMAIL MODAL >> -->
<app-modal
  #emailModal
  *ngIf="isEmailModalShown"
>
  <ng-container header>
    <img
      class="top-flame-modal-icon"
      src="assets/ui/images/svg/kittch-flame-modal-top-icon.svg"
      alt="kittch-flame-modal-top-icon">
    <h3 class="modal-description email-form-title">To save your spot, enter your email:</h3>
  </ng-container>

  <ng-container body>
    <form [formGroup]="emailForm" (ngSubmit)="onEmailSubmit()">
      <ul>
        <li class="form__item">
          <div class="form__field">
            <app-animated-input
              formControlName="email"
              label='name@example.com'
              class="email-field"
              isSignupForm="true"
            ></app-animated-input>
            <app-errors-list formControlName="email"></app-errors-list>
          </div>
        </li>

        <li>
          <app-button
            class="button-register"
            [text]="'Submit email'"
            [type]="'submit'"
            [isDisabled]="emailForm.invalid"
          ></app-button>
        </li>
      </ul>
    </form>
  </ng-container>
</app-modal>
<!-- << EMAIL MODAL -->
