import { isPlatformBrowser } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  Input, NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { delay, tap } from 'rxjs/operators';
import { RtmMassageType } from '@kitch/data-access/constants';
import { RtmMessage, StreamStatus } from '@kitch/data-access/models';
import {
  StreamStartRecordingParams,
  TipSearchParams,
  UpdateRecordingLayoutParams,
} from '@kitch/data-access/models/search-params';
import {
  AudienceService,
  ChefTableService,
  LoggerService,
  ProfilesService,
  StreamsService,
  TipsService,
  TokenService,
} from '@kitch/data-access/services';
import { AgoraRTCTool } from '@kitch/util';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { SidebarService } from '@kitch/user/core/sidebar.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { MessageInvitedUsersModalComponent } from '@kitch/user/shared/components/modals';
import { DevicesFormValue, PipCameraPositions, TabId } from '@kitch/user/shared/models';
import { AgoraStreamService } from '@kitch/user/shared/services/agora-stream.service';
import { AgoraStreamComponent } from '../agora-stream/agora-stream.component';

@UntilDestroy()
@Component({
  selector: 'app-agora-chef-stream',
  templateUrl: './agora-chef-stream.component.html',
  styleUrls: ['./agora-chef-stream.component.scss', '../agora-stream/agora-stream.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgoraChefStreamComponent extends AgoraStreamComponent implements OnInit, OnDestroy {
  @ViewChild('messageInvitedUsersModal') private readonly messageInvitedUsersModal: MessageInvitedUsersModalComponent;

  @Input() streamDuration = 0;

  isLowVideoProfile: boolean;
  isCamerasSwitched = false;
  isLeftChatPresent = false;
  isReloadPageModalOpened = false;
  isRecordingErrorModalOpened = false;
  protected readonly tabId = TabId;

  private invitedUsersToPrepMode: string[] = [];
  private recordingStatusIntervalId: ReturnType<typeof setTimeout>;

  constructor(
    protected agoraStreamService: AgoraStreamService,
    protected cdr: ChangeDetectorRef,
    protected profilesService: ProfilesService,
    protected liveStreamStatusService: LiveStreamStatusService,
    protected tipsService: TipsService,
    protected tokenService: TokenService,
    protected audienceService: AudienceService,
    protected $gaService: GoogleAnalyticsService,
    protected userProfile: UserProfileService,
    protected streamsService: StreamsService,
    protected chefTableService: ChefTableService,
    protected liveReplayTabsService: LiveReplayTabsService,
    protected applicationRef: ApplicationRef,
    protected ngZone: NgZone,
    protected logger: LoggerService,
    private router: Router,
    private sidebarService: SidebarService,
    @Inject(PLATFORM_ID) protected platformId: Object,
  ) {
    super(
      agoraStreamService,
      cdr,
      profilesService,
      liveStreamStatusService,
      tipsService,
      tokenService,
      audienceService,
      $gaService,
      userProfile,
      streamsService,
      chefTableService,
      liveReplayTabsService,
      applicationRef,
      ngZone,
      logger,
      platformId,
    );
  }

  async ngOnInit(): Promise<void> {
    this.logger.info('#ngOnInit chef streamInfo: ', this.streamInfo);
    await super.ngOnInit();
    if (isPlatformBrowser(this.platformId)) {
      this.logger.debug('#ngOnInit subscribe to rtm channel');
      this.subscribeToRtmChannel();
      // disabled adjusting resolution in KB-955
      // this.subscribeToVideoProfileChanges();
      this.liveStreamStatusService.streamStatus$
        .pipe(
          untilDestroyed(this),
        )
        .subscribe(status => this.updateLeftChatPresence(status));
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.logger.debug('#ngOnDestroy destroy AgoraChefStreamComponent');
    await super.ngOnDestroy();

    this.sidebarService.changePossibilityToHideDesktopSidebar(false);
    this.sidebarService.changeDesktopState(true);
    clearInterval(this.recordingStatusIntervalId);
  }

  async onDevicesFormSubmit(formValue: DevicesFormValue): Promise<void> {
    this.isDevicesSubmitFormDisabled = true;
    this.devicesSet = formValue;
    this.logger.info('#onDevicesFormSubmit devises set ', this.devicesSet);

    this.logger.debug('#onDevicesFormSubmit start prepmode stream');
    await this.startPrepModeStream();

    this.liveStreamStatusService.updateStreamStatus({ isPrepMode: true });
    this.isDevicesSubmitFormDisabled = false;
    this.setDeviceModalStatus(false);
  }

  async startStream(): Promise<void> {
    this.logger.info('#startStream start method');
    this.isStreamFirstPrepMode = false;
    this.isStreamPrepMode = false;
    this.isStreamStarted = true;
    this.isStreamStarting = false;

    if (isPlatformBrowser(this.platformId)) {
      this.runRecordingStatusChecking();
    }

    this.liveStreamStatusService.updateStreamStatus({
      isLive: this.isStreamStarted,
      isPrepMode: false,
      isOwner: this.tokenService.getProfileId() === this.stream.channel.chefProfile.id,
    });
    this.logger.info('#startStream change stream status to live');
    this.streamStatusChange.emit(StreamStatus.LIVE);
    this.agoraRTMTool.streamStatusChanged(this.streamInfo.profileId, StreamStatus.LIVE);
    this.logger.debug('#startStream join recording channels');
    await this.joinRecordingChannels();

    // don't call API if stream was already LIVE
    if (this.stream.status !== StreamStatus.LIVE) {
      this.logger.debug('#startStream start recording');
      this.startRecording();
      this.saveFullTable();
    }
    this.isUserJoined = true;
    this.cdr.detectChanges();
  }

  stopStream(): void {
    this.logger.info('#stopStream change stream status to past');
    this.isStreamStopping = true;
    this.streamStatusChange.emit(StreamStatus.PAST);
    this.agoraRTMTool.streamStatusChanged(this.streamInfo.profileId, StreamStatus.PAST);
  }

  rescheduleStream(): void {
    this.logger.info('#rescheduleStream change stream status to scheduled');
    this.streamsService.update({ status: StreamStatus.SCHEDULED }, this.stream.id).subscribe(() => {
      this.router.navigate([`/streams/edit/${this.stream.id}`]);
    });
  }

  onRecordingErrorModalEvent(isContinueStream: boolean): void {
    this.isRecordingErrorModalOpened = false;
    if (isContinueStream) {
      localStorage.removeItem(this.liveStreamStatusService.retryRecordingCountKey);
    } else {
      this.setStopStreamModalStatus(true);
    }
  }

  onReloadPageModalEvent(needReloadPage: boolean): void {
    this.isReloadPageModalOpened = false;
    if (needReloadPage) {
      location.reload();
      localStorage.setItem(
        this.liveStreamStatusService.retryRecordingCountKey,
        String(this.liveStreamStatusService.recordingRetriesFailedFlag),
      );
    } else {
      clearInterval(this.recordingStatusIntervalId);
      localStorage.removeItem(this.liveStreamStatusService.retryRecordingCountKey);
    }
  }

  removeUserAsCoHost(uid: string): void {
    this.logger.info('#removeUserAsCoHost remove user from co-hosts uid ', uid);
    const coHost = this.liveStreamStatusService.coHosts.find(coHost => coHost.profileId === uid);
    const coHostParam: UpdateRecordingLayoutParams = {
      mainCameraUid: coHost.cameraId,
      profileId: coHost.profileId,
      removeCohost: true,
    };
    const updateCoHosts$ = this.isStreamStarted ?
      this.streamsService.updateRecordingLayout(this.stream.id, coHostParam) :
      this.streamsService.updateCoHostsList(this.stream.id, coHostParam);

    updateCoHosts$.subscribe(async () => {
      this.agoraRTMTool.userLeftAsCoHost(uid);
      await this.removeUserFromCoHostsTable(uid);
    });
  }

  toggleMuteEmoji(): void {
    this.isMuteEmogi = !this.isMuteEmogi;
    this.agoraRTMTool.sendStatusMuteEmogi(this.streamInfo.profileId, this.isMuteEmogi);
    this.logger.info('#toggleMuteEmoji is emoji muted ', this.isMuteEmogi);
  }

  muteUserSounds(): void {
    this.isMuteUsersSound = true;
    this.agoraRTMTool.sendStatusMuteAllUsers(this.streamInfo.profileId, this.isMuteUsersSound);
    this.logger.info('#muteUserSounds are users muted ', this.isMuteUsersSound);
  }

  async switchPipCamera(): Promise<void> {
    this.logger.info('#switchPipCamera start switching cameras');
    await this.toggleChefVideo();
    this.devicesSet = {
      ...this.devicesSet,
      camera: this.devicesSet.secondCamera,
      secondCamera: this.devicesSet.camera,
    };
    this.logger.debug('#switchPipCamera closing main and second cameras video track');
    this.localTracks.videoTrack?.close();
    this.secondCameraVideoTrack?.close();
    this.logger.debug('#switchPipCamera create main camera video track');
    await this.createCameraTrack();
    this.logger.debug('#switchPipCamera create second camera video track');
    await this.createSecondCameraTrack();

    this.logger.debug('#switchPipCamera play main/second camera video');
    this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    this.localTracks.videoTrack?.play(this.getChefPlayerId(this.streamInfo.profileId), { mirror: false, fit: 'cover' });

    this.logger.debug('#switchPipCamera host and publish main/second camera video tracks');

    try {
      await this.mainCameraClient.setClientRole('host').then(() => (this.currentUserRole = 'host'));
      await this.mainCameraClient.publish(this.localTracks.videoTrack);
    } catch (e) {
      this.logger.warn('#switchPipCamera publish main camera error', e);
    }

    try {
      await this.secondCameraClient.setClientRole('host').then(() => (this.currentUserRole = 'host'));
      await this.secondCameraClient.publish(this.secondCameraVideoTrack);
    } catch (e) {
      this.logger.warn('#switchPipCamera publish second camera error', e);
    }

    if (this.isStreamStarted) {
      // this.streamsService.switchPipCamera(this.stream.id, {
      //   mainCameraUid: this.isCamerasSwitched ? this.getMainCameraUid() : this.getSecondCameraUid(),
      //   secondCameraUid: this.isCamerasSwitched ? this.getSecondCameraUid() : this.getMainCameraUid(),
      // }).subscribe(() => this.isCamerasSwitched = !this.isCamerasSwitched);
      this.isCamerasSwitched = !this.isCamerasSwitched;
    }
  }

  pipCameraDropped(index: PipCameraPositions): void {
    this.logger.info('#pipCameraDropped new pip camera position: ', index);
    this.pipCameraPosition = index;
    this.agoraRTMTool.pipPositionChanged(this.streamInfo.profileId, index);

    setTimeout(() => {
      this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    }, 1);

    this.logger.debug('#pipCameraDropped update pip position on backend');
    this.streamsService.updatePipCameraPosition(this.stream.id, {
      mainCameraUid: this.getMainCameraUid(),
      secondCameraUid: this.getSecondCameraUid(),
      pipPosition: this.pipCameraPosition,
    }).subscribe();
  }

  async toggleChefAudio(): Promise<void> {
    const isEnabled = this.localTracks.audioTrack.enabled;

    this.logger.info('#toggleChefAudio is audio enabled ', isEnabled);

    try {
      await this.localTracks.audioTrack.setEnabled(!isEnabled);
    } catch (e) {
      this.logger.warn(`#toggleChefAudio error to set audio track to ${!isEnabled}`, e);
    }
    this.cdr.detectChanges();
  }

  async toggleChefVideo(): Promise<void> {
    this.logger.info('#toggleChefVideo start method');
    try {
      if (this.localTracks.videoTrack) {
        if (this.localTracks.videoTrack.enabled) {
          this.logger.info('#toggleChefVideo disable video track and un publish');
          await this.localTracks.videoTrack.setEnabled(false);
          await this.mainCameraClient.unpublish(this.localTracks.videoTrack);
        } else {
          this.logger.info('#toggleChefVideo enable video track and publish');
          await this.localTracks.videoTrack.setEnabled(true);
          await this.mainCameraClient.publish(this.localTracks.videoTrack);
        }
      }
    } catch (e) {
      this.logger.warn('#toggleChefVideo toggle main chef video track error: ', e);
    }

    await this.toggleChefSecondCamera();

    this.cdr.detectChanges();
  }

  private async toggleChefSecondCamera(): Promise<void> {
    this.logger.info('#toggleChefSecondCamera start method');
    try {
      if (this.devicesSet?.secondCamera && this.secondCameraVideoTrack) {
        if (this.secondCameraVideoTrack.enabled) {
          this.logger.info('#toggleChefSecondCamera disable video track and un publish');
          await this.secondCameraVideoTrack.setEnabled(false);
          await this.secondCameraClient.unpublish(this.secondCameraVideoTrack);
        } else {
          this.logger.info('#toggleChefSecondCamera enable video track and publish');
          await this.secondCameraVideoTrack.setEnabled(true);
          await this.secondCameraClient.publish(this.secondCameraVideoTrack);
        }
      }
    } catch (e) {
      this.logger.warn('#toggleChefSecondCamera toggle second chef video track error: ', e);
    }
  }

  private subscribeToRtmChannel() {
    this.rtmChannel.on('ChannelMessage', ({ text }: RtmTextMessage) => {
      this.logger.info('#subscribeToRtmChannel RTM message ', text);
      this.agoraRTMTool.sendStatusMuteEmogi(this.streamInfo.profileId, this.isMuteEmogi);
      this.handleChannelMassage(text);
    });
  }

  private handleChannelMassage(text: string): void {
    const message: RtmMessage = this.agoraRTMTool.parseMassage(text);

    switch (message.type) {
      case RtmMassageType.CO_HOST_WANT_TO_LEAVE:
        this.handleCoHostWantToLeave(message);
        break;
      case RtmMassageType.CO_HOST_ACCEPTED_INVITE:
        this.handleCoHostAcceptedInvite(message);
        break;
      case RtmMassageType.CO_HOST_DECLINED_INVITE:
        this.handleCoHostDeclineInvite(message);
        break;
      case RtmMassageType.PIP_POSITION_CHANGED:
        this.handlePipPositionChanged(message);
        break;
      case RtmMassageType.SWITCH_PIP_CAMERA:
        this.handlePipCameraSwitched();
        break;
      case RtmMassageType.USER_ACCEPTED_INVITE:
        this.handleUserAcceptedInvite(message);
        break;
      case RtmMassageType.USER_DECLINED_INVITE:
        this.handleUserDeclinedInvite(message);
        break;
    }
    this.cdr.detectChanges();
  }

  private handleCoHostWantToLeave(message: RtmMessage): void {
    this.logger.info('#handleCoHostWantToLeave handle co-host leave event for uid ', message.uid);
    this.removeUserAsCoHost(message.uid);
  }

  private handleCoHostAcceptedInvite(message: RtmMessage): void {
    this.logger.info('#handleCoHostAcceptedInvite handle co-host accept invite event for uid ', message.uid);
    this.setInviteWasSendModalStatus(false);
    this.invitedCoHosts = this.invitedCoHosts.filter(uid => message.uid !== uid);
    if (this.streamInfo.isStreamOwner &&
      this.liveStreamStatusService.coHosts.length < 4 &&
      !this.liveStreamStatusService.isCoHost(message.uid)
    ) {
      const coHostParam: UpdateRecordingLayoutParams = {
        mainCameraUid: message.payload.cameraId,
        profileId: message.uid,
      };
      const updateCoHosts$ = this.isStreamStarted ?
        this.streamsService.updateRecordingLayout(this.stream.id, coHostParam) :
        this.streamsService.updateCoHostsList(this.stream.id, coHostParam);

      updateCoHosts$.subscribe(async () => {
        this.logger.info('#handleCoHostAcceptedInvite add to co-hosts uid', message.uid);
        this.agoraRTMTool.userJoinedAsCoHost(message.uid);
        await this.addUserToCoHostsTable(message.uid, message.payload.cameraId);
      });
    }
  }

  private handleCoHostDeclineInvite(message: RtmMessage): void {
    this.logger.info('#handleCoHostDeclineInvite handle co-host decline invite event for uid ', message.uid);
    if (this.invitedCoHosts.some(uid => message.uid === uid)) {
      this.invitedCoHosts = this.invitedCoHosts.filter(uid => message.uid !== uid);
      const user = this.userInfoMap.get(message.uid);

      if (user) {
        this.logger.info('#handleCoHostDeclineInvite show decline invite modal for ', message.uid);
        this.setInviteWasSendModalStatus(false);
        this.messageInvitedUsersModal.openDeclineInviteModal(user.displayName);
      }
    }
  }

  private handlePipPositionChanged(message: RtmMessage): void {
    this.pipCameraPosition = message.payload.pipPosition;
    this.logger.info('#handlePipPositionChanged new pip position ', this.pipCameraPosition);

    setTimeout(() => {
      this.logger.info('#handlePipPositionChanged play second camera video');
      this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    }, 1);
    this.logger.info('#handlePipPositionChanged update pip position on backend');
    this.streamsService.updatePipCameraPosition(this.stream.id, {
      mainCameraUid: this.getMainCameraUid(),
      secondCameraUid: this.getSecondCameraUid(),
      pipPosition: this.pipCameraPosition,
    }).subscribe();
  }

  private handlePipCameraSwitched(): void {
    this.switchPipCamera();
  }

  private async joinRecordingChannels(): Promise<void> {
    this.logger.info(`#joinRecorderChannels with ${JSON.stringify(this.streamInfo.streamToken)}`);
    try {
      await this.ngZone.runOutsideAngular(() => {
        return Promise.all([
          this.compositeRecordingClient.join(
            this.streamInfo.streamToken.appId,
            this.stream.id,
            this.streamInfo.streamToken.compositeRecordingRtcToken,
            this.getCompositeRecordingStringUid(),
          ),
          this.compositeMobileRecordingClient.join(
            this.streamInfo.streamToken.appId,
            this.stream.id,
            this.streamInfo.streamToken.compositeMobileRecordingRtcToken,
            this.getCompositeMobileRecordingStringUid(),
          ),
          this.mainCameraRecordingClient.join(
            this.streamInfo.streamToken.appId,
            this.stream.id,
            this.streamInfo.streamToken.mainCameraRecordingRtcToken,
            this.getMainCameraRecordingStringUid(),
          ),
          this.secondCameraRecordingClient.join(
            this.streamInfo.streamToken.appId,
            this.stream.id,
            this.streamInfo.streamToken.secondCameraRecordingRtcToken,
            this.getSecondCameraRecordingStringUid(),
          ),
        ]);
      });
    } catch (e) {
      this.logger.warn('#joinRecorderChannels can\'t join recording clients ', e);
    }
  }

  private async startPrepModeStream(): Promise<void> {
    if (!this.isStreamStarted) {
      this.isStreamFirstPrepMode = false;
      this.isStreamPrepMode = true;
      this.isStreamStarted = false;
      this.isStreamStarting = false;
    }

    this.liveStreamStatusService.updateStreamStatus({
      isLive: this.isStreamStarted,
      isPrepMode: this.isStreamPrepMode,
      isOwner: true,
    });

    await this.ngZone.runOutsideAngular(async () => {
      this.logger.debug('#startPrepModeStream create audio/video tracks');
      await this.createAudioVideoTracks();
      const playerId = this.getChefPlayerId(this.streamInfo.profileId);

      this.logger.debug('#startPrepModeStream play main camera video track');
      this.localTracks.videoTrack?.play(playerId, { mirror: false, fit: 'cover' });

      if (new Date(this.streamInfo.streamToken.expiresIn) <= new Date()) {
        this.logger.debug('#ngOnInit update agora tokens');
        await this.getAgoraStreamInfo().toPromise();
      }

      this.logger.debug('#startPrepModeStream join main camera channel');
      await this.joinMainCameraChannel();
      if (this.isDualCamerasStream()) {
        this.logger.debug('#startPrepModeStream init second camera client');
        this.initSecondCameraAgoraClient();
        try {
          await this.secondCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
        } catch (e) {
          this.logger.warn('#startPrepModeStream can\'t set second client role to host', e);
        }

        this.logger.debug('#startPrepModeStream start play second camera video track');
        this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
        this.logger.debug('#startPrepModeStream join second camera channel');
        await this.joinSecondCameraChannel();
      }
      this.logger.debug('#startPrepModeStream starting audio/video');

      await this.startVideoAudio();
      if (!this.isStreamStarted) {
        this.logger.info('#startPrepModeStream stream is not live, start prepmode');
        this.streamStatusChange.emit(StreamStatus.PREPMODE);
        this.agoraRTMTool.streamStatusChanged(this.streamInfo.profileId, StreamStatus.PREPMODE);
      } else {
        this.logger.info('#startPrepModeStream stream is already live');
        await this.startStream();
      }
    });
    this.cdr.detectChanges();
    const params: TipSearchParams = {
      profileId: this.streamInfo.profileId,
      videoId: this.stream.id,
    };

    this.audienceService.getAll(params)
      .subscribe((response) => {
        this.logger.info(response);
        this.audienceUsers = response.results;
      });
  }

  private startRecording(): void {
    const videoConfig = this.agoraRTCTool.activeProfileConfig;
    const recordingParams: StreamStartRecordingParams = {
      videoId: this.stream.id,
      compositeRecordingUid: this.getCompositeRecordingUid(),
      compositeMobileRecordingUid: this.getCompositeMobileRecordingUid(),
      mainCameraRecordingUid: this.getMainCameraRecordingUid(),
      secondCameraRecordingUid: this.getSecondCameraRecordingUid(),
      mainCameraUid: this.getMainCameraUid(),
      secondCameraUid: this.getSecondCameraUid(),
      transcodingConfig: {
        height: videoConfig.height,
        width: videoConfig.width,
        bitrate: videoConfig.bitrateMax,
        fps: videoConfig.frameRate,
      },
    };

    this.logger.info('#startRecording start recording with config ', recordingParams);
    this.streamsService.startRecording(recordingParams)
      .pipe(
        (tap(resp => {
          this.stream.recordStartedAt = resp.recordStartedAt;
          if (resp.status === 'RECORDING') {
            clearInterval(this.recordingStatusIntervalId);
            localStorage.removeItem(this.liveStreamStatusService.retryRecordingCountKey);
          }
        })),
        // agora need some time to create recording entity
        delay(5000),
        untilDestroyed(this),
      )
      .subscribe(() => this.streamRecordStart.emit());
  }

  private runRecordingStatusChecking(): void {
    this.ngZone.runOutsideAngular(() => {
      this.recordingStatusIntervalId = setInterval(() => {
        const retryCount = Number(localStorage.getItem(this.liveStreamStatusService.retryRecordingCountKey) || 0);

        this.logger.info(`#runRecordingStatusChecking recoding retried ${retryCount} times`);

        if (retryCount === this.liveStreamStatusService.recordingRetriesFailedFlag) {
          this.isRecordingErrorModalOpened = true;

          return;
        }

        if (retryCount >= 3) {
          this.logger.info('#runRecordingStatusChecking recoding retries failed, show modal for a chef');
          clearInterval(this.recordingStatusIntervalId);
          this.isReloadPageModalOpened = true;
        } else {
          localStorage.setItem(this.liveStreamStatusService.retryRecordingCountKey, String(retryCount + 1));

          this.logger.info('#runRecordingStatusChecking recoding is not started, retry recording');
          this.startRecording();
        }
      }, 30 * 1000);
    });
  }

  private async createAudioVideoTracks(): Promise<void> {
    this.logger.debug('#createAudioVideoTracks create main camera video/audio');
    await this.createCameraMicrophoneTrack();

    if (this.devicesSet?.secondCamera) {
      try {
        this.logger.debug('#createAudioVideoTracks create second camera video');
        await this.createSecondCameraTrack();
      } catch (e) {
        this.logger.warn('#createAudioVideoTracks can\'t create second camera track ', e);
      }
    }
  }

  private async createSecondCameraTrack() {
    const config: CameraVideoTrackInitConfig = { encoderConfig: this.agoraRTCTool.activeProfileConfig };

    if (this.devicesSet?.secondCamera) {
      config.cameraId = this.devicesSet.secondCamera;
    }
    try {
      this.logger.info('#createSecondCameraTrack create second camera video track with config', config);
      this.secondCameraVideoTrack = await AgoraRTC.createCameraVideoTrack(config);
    } catch (e) {
      this.secondCameraVideoTrack = null;
      this.logger.warn('#createSecondCameraTrack create second camera video track error ', e);
    }
  }

  private getCompositeRecordingUid(): number {
    const stringUid = this.getCompositeRecordingStringUid();

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getCompositeMobileRecordingUid(): number {
    const stringUid = this.getCompositeMobileRecordingStringUid();

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getMainCameraRecordingUid(): number {
    const stringUid = this.getMainCameraRecordingStringUid();

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getSecondCameraRecordingUid(): number {
    const stringUid = this.getSecondCameraRecordingStringUid();

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getMainCameraUid(): number {
    const stringUid = this.streamInfo.profileId;

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getSecondCameraUid(): number {
    const stringUid = this.getSecondCameraStringUid();

    return this.getClientUIds().find((item) => item.stringUid === stringUid)?.uid;
  }

  private getSecondCameraStringUid(): string {
    return `${this.streamInfo.streamToken.secondCameraRtcUidPrefix}_${this.streamInfo.profileId}`;
  }

  private getSecondCameraRecordingStringUid(): string {
    return `${this.streamInfo.streamToken.secondCameraRecordingRtcUidPrefix}_${this.streamInfo.profileId}`;
  }

  private getMainCameraRecordingStringUid(): string {
    return `${this.streamInfo.streamToken.mainCameraRecordingRtcUidPrefix}_${this.streamInfo.profileId}`;
  }

  private getCompositeMobileRecordingStringUid(): string {
    return `${this.streamInfo.streamToken.compositeMobileRecordingRtcUidPrefix}_${this.streamInfo.profileId}`;
  }

  private getCompositeRecordingStringUid(): string {
    return `${this.streamInfo.streamToken.compositeRecordingRtcUidPrefix}_${this.streamInfo.profileId}`;
  }

  private getClientUIds(): { stringUid: string, uid: number }[] {
    return [
      {
        stringUid: this.compositeRecordingClient['_joinInfo']?.stringUid,
        uid: this.compositeRecordingClient['_joinInfo']?.uid,
      },
      {
        stringUid: this.compositeMobileRecordingClient['_joinInfo']?.stringUid,
        uid: this.compositeMobileRecordingClient['_joinInfo']?.uid,
      },
      {
        stringUid: this.mainCameraRecordingClient['_joinInfo']?.stringUid,
        uid: this.mainCameraRecordingClient['_joinInfo']?.uid,
      },
      {
        stringUid: this.secondCameraRecordingClient['_joinInfo']?.stringUid,
        uid: this.secondCameraRecordingClient['_joinInfo']?.uid,
      },
      {
        stringUid: this.mainCameraClient['_joinInfo']?.stringUid,
        uid: this.mainCameraClient['_joinInfo']?.uid,
      },
      {
        stringUid: this.secondCameraClient['_joinInfo']?.stringUid,
        uid: this.secondCameraClient['_joinInfo']?.uid,
      },
    ];
  }

  private saveFullTable(): void {
    this.logger.info('#saveFullTable persist existing table state on backend');
    this.userMap.forEach(user => {
      const uid = user.uid.toString();

      this.saveTableChanges('JOIN', uid);
      if (user.hasAudio) {
        this.saveTableChanges('UNMUTE', uid);
      }
    });
  }

  private subscribeToVideoProfileChanges(): void {
    this.agoraRTCTool.videoProfileChanging$
      .pipe(untilDestroyed(this))
      .subscribe((videoProfileId) => {
        if (AgoraRTCTool.getProfileIndexById(videoProfileId) < AgoraRTCTool.getProfileIndexById('720p')) {
          this.isLowVideoProfile = true;
          this.logger.info('debug', 'video profile became below 720p');
        }
      });
  }

  private updateLeftChatPresence(status) {
    this.isLeftChatPresent = this.isDesktopView &&
      (status.isPrepMode || status.isLive);

    this.sidebarService.changePossibilityToHideDesktopSidebar(this.isLeftChatPresent);
    this.sidebarService.changeDesktopState(!this.isLeftChatPresent);
  }

  inviteUsersToPrepMode(inviteUserIds: string[]): void {
    this.logger.info('#inviteUsersToPrepMode invite to user uids: ', inviteUserIds);

    this.agoraRTMTool.inviteUsersToPrepMode(this.streamInfo.profileId, inviteUserIds);
    this.setInviteWasSendModalStatus(true);
    this.invitedUsersToPrepMode = [...this.invitedUsersToPrepMode, ...inviteUserIds];
  }

  private handleUserAcceptedInvite(message: RtmMessage): void {
    this.logger.info('#handleUserAcceptedInvite handle user accept invite event for uid ', message.uid);
    if (this.invitedUsersToPrepMode.some(uid => message.uid === uid)) {
      this.setInviteWasSendModalStatus(false);
      this.messageInvitedUsersModal.openAcceptedInviteModal();
    }
  }

  private handleUserDeclinedInvite(message: RtmMessage): void {
    this.logger.info('#handleUserDeclineInvite handle user decline invite event for uid ', message.uid);
    if (this.invitedUsersToPrepMode.some(uid => message.uid === uid)) {
      this.setInviteWasSendModalStatus(false);
      this.messageInvitedUsersModal.openDeclineInviteModal(message.payload.userInfo.viewerName);
    }
  }
}
