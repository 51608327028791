import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  AfterContentChecked,
  Input,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, finalize } from 'rxjs/operators';
import { CreditCard, HardcodedSeriesEntry, TransactionCurrency } from '@kitch/data-access/models';
import { PurchaseData } from '@kitch/data-access/models/purchase';
import { SeriesType, Stream, StreamStatus } from '@kitch/data-access/models/stream';
import { ProfilesService, StreamsService } from '@kitch/data-access/services';
import { TokenService } from '@kitch/data-access/services/token.service';
import { sortByScheduledVideoDate } from '@kitch/util';
import { ModalComponent } from '@kitch/ui/components';
import { AfterLoginAction } from '@kitch/ui/directives/need-authorize.directive';
import { PurchaseService } from '@kitch/user/core/purchase.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { BuyClamsComponent } from '@kitch/user/shared/components';

@UntilDestroy()
@Component({
  selector: 'app-purchase-modal',
  templateUrl: './purchase-modal.component.html',
  styleUrls: ['./purchase-modal.component.scss', './../modals-styles.scss'],
})
export class PurchaseModalComponent implements OnInit, AfterContentChecked, OnChanges, OnDestroy {
  streamStatus = StreamStatus;
  profileId!: string;
  balance!: number;
  isGuest!: boolean;
  primaryCreditCard: CreditCard | undefined;
  buySeriesModalType!: TransactionCurrency;
  isConfirmPurchaseModalOpen!: boolean;
  isAddCreditCardModalOpen!: boolean;
  isBuySeriesModalOpen!: boolean;
  isBuyClubModalOpen!: boolean;
  purchaseClub: PurchaseData;
  purchaseStream: PurchaseData;
  purchaseBundle: PurchaseData;
  purchaseSeries: PurchaseData;
  bundle!: HardcodedSeriesEntry;
  isSeries!: boolean;
  isBundle!: boolean;
  isHardcodedSeries: boolean;
  isClub!: boolean;
  bundleStreams: Stream[] = [];
  bundleDummyVideo: Stream;
  currentStream: Stream;

  seriesStreams: Stream[] = [];
  seriesDummyVideo: Stream;

  showPurchaseModal: AfterLoginAction = 'showPurchaseModal';

  @Input() series!: HardcodedSeriesEntry;
  @Input() isOpen = false;
  @Input() stream!: Stream;

  @ViewChild('purchaseModal', { static: false }) purchaseModal!: ModalComponent;
  @ViewChild('description') description!: ElementRef;
  @ViewChild('descriptionContainer') descriptionContainer!: ElementRef;
  @ViewChild('buyClamsComponent', { static: false }) buyClamsComponent!: BuyClamsComponent;

  @Output() isPurchaseModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private profileService: ProfilesService,
    private tokenService: TokenService,
    private streamsService: StreamsService,
    private router: Router,
    private userProfile: UserProfileService,
    private purchaseService: PurchaseService,
  ) {}

  get isShowLinkToDetailStream(): boolean {
    return Boolean(this.purchaseData.description) && this.description?.nativeElement.scrollHeight > 48;
  }

  get purchaseData(): PurchaseData {
    if (this.isClub) {
      return this.purchaseClub;
    }

    if (this.isBundle && this.isHardcodedSeries) {
      return this.purchaseBundle;
    }

    if (this.isSeries) {
      return this.purchaseSeries;
    }

    return this.purchaseStream;
  }

  ngOnInit(): void {
    this.userProfile.userProfile$
      .pipe(untilDestroyed(this))
      .subscribe((res) => this.balance = res.balance);
    this.profileId = this.tokenService.getProfileId();
    this.profileService.getUserCards(this.profileId)
      .pipe(untilDestroyed(this))
      .subscribe((cards) => {
        this.primaryCreditCard = cards.find(({ primary }) => primary);
      });
    this.isGuest = this.tokenService.isGuest();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(ch: SimpleChanges): void {
    this.isClub = ch.purchaseType?.currentValue === 'club';
    this.stream = ch.stream?.currentValue;

    if (!this.isClub) {
      this.purchaseStream = this.purchaseService.getPurchaseStream(this.stream);
      this.series = ch.series?.currentValue;
      this.isSeries = this.series?.type === SeriesType.Series;
      this.isBundle = this.series?.type === SeriesType.Bundle;

      if (this.isBundle) {
        this.makeBundle();
      }

      if (this.isSeries) {
        this.makeSeries();
      }
    } else {
      this.purchaseClub = this.purchaseService.getPurchaseClub();
    }

    if (ch.isOpen.currentValue) {
      if (this.isClub) {
        // buy club modal open
        this.setBuyClubModalStatus(true);

        return;
      }

      this.isHardcodedSeries = false;
      this.purchaseModal.open();
    } else {
      this.purchaseModal?.close();
    }

    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {}

  setBuySeriesModalOpen(status: boolean): void {
    this.isBuySeriesModalOpen = status;
  }

  setAddCreditCardModalOpen(status: boolean): void {
    this.isAddCreditCardModalOpen = status;
  }

  openCreditCardModalAfterAddingCard(): void {
    this.profileService.getUserCards(this.profileId)
      .pipe(
        untilDestroyed(this),
        tap((cards) => {
          this.primaryCreditCard = cards.find(({ primary }) => primary);
        }),
        finalize(() => {
          setTimeout(() => this.setConfirmPurchaseModalStatus(true));
        }))
      .subscribe();
  }

  closeModal(): void {
    this.isPurchaseModalOpen.emit(false);
    this.purchaseModal.close();
  }

  setClosedModal(): void {
    this.isPurchaseModalOpen.emit(false);
  }

  setPurchaseModalOpen(): void {
    this.purchaseModal.close();
  }

  setConfirmPurchaseModalStatus(isOpen: boolean): void {
    this.isConfirmPurchaseModalOpen = isOpen;
  }

  setBuyClubModalStatus(value: boolean): void {
    this.isBuyClubModalOpen = value;
  }

  buyWithClams(): void {
    this.buySeriesModalType = 'CLAMS';
    if (this.isBundle && !this.isSeries) {
      this.setBuySeriesModalOpen(true);
    } else {
      this.balance >= this.purchaseData?.requiredSubscriptionPlan?.price ?
        this.setConfirmPurchaseModalStatus(true) :
        this.buyClamsComponent.open();
    }
  }

  buyCreditCard(): void {
    this.buySeriesModalType = 'USD';
    if (this.isBundle && !this.isSeries) {
      this.setBuySeriesModalOpen(true);
    } else {
      this.primaryCreditCard ?
        this.setConfirmPurchaseModalStatus(true) :
        this.setAddCreditCardModalOpen(true);
    }
  }

  joinClub(): void {
    this.buySeriesModalType = 'USD';
    this.primaryCreditCard ?
      this.setConfirmPurchaseModalStatus(true) :
      this.setAddCreditCardModalOpen(true);
  }

  openConfirmModal(status: boolean): void {
    if (status) {
      this.isHardcodedSeries = true;
      this.buyStream();
      this.purchaseModal?.close();
    } else {
      this.isHardcodedSeries = false;
      this.purchaseModal.open();
      this.buyStream();
    }
  }

  buyStream(): void {
    if (this.buySeriesModalType === 'CLAMS') {
      this.balance >= this.purchaseData?.requiredSubscriptionPlan?.price ?
        this.setConfirmPurchaseModalStatus(true) :
        this.buyClamsComponent.open();
    }

    if (this.buySeriesModalType === 'USD') {
      this.primaryCreditCard ?
        this.setConfirmPurchaseModalStatus(true) :
        this.setAddCreditCardModalOpen(true);
    }
  }

  private makeBundle(): void {
    this.streamsService.getHardcodedSeriesDetails()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.bundleStreams = res.hardcodedSeries.filter(seria => this.series.videoIds.includes(seria.id));
        this.bundleDummyVideo = res.hardcodedSeries.find(seria => seria.id === this.series.dummyVideo.id);
        this.purchaseBundle = this.purchaseService.getPurchaseBundle(this.stream, this.bundle, this.bundleDummyVideo);
      });
    this.streamsService.getHardcodedSeries()
      .pipe(untilDestroyed(this))
      .subscribe(({ hardcodedSeries }) => {
        this.bundle = hardcodedSeries.find(series => series.videoIds.includes(this.stream?.id));
      });
  }

  private makeSeries(): void {
    this.streamsService.getHardcodedSeriesDetails()
      .pipe(
        untilDestroyed(this),
        tap((res) => {
          this.seriesStreams = res.hardcodedSeries
            .filter(series => this.series.videoIds.includes(series.id))
            .sort(sortByScheduledVideoDate);
          this.seriesDummyVideo = res.hardcodedSeries.find(series => series.id === this.series.dummyVideo.id);
        }),
      )
      .subscribe(() => {
        this.purchaseSeries = this.purchaseService.getPurchaseSeries(this.stream, this.series, this.seriesDummyVideo);
      });
  }
}
