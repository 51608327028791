import { AddCreditCardModalComponent } from './add-credit-card-modal/add-credit-card-modal.component';
import { AddToCalendarModalComponent } from './add-to-calendar-modal/add-to-calendar-modal.component';
import { BuySeriesModalComponent } from './buy-series-modal/buy-series-modal.component';
import { ConfirmPurchaseModalComponent } from './confirm-purchase-modal/confirm-purchase-modal.component';
import {
  InviteUsersToPrepModeModalComponent,
} from './invite-users-to-prep-mode-modal/invite-users-to-prep-mode-modal.component';
import { JoinClubModalComponent } from './join-club-modal/join-club-modal.component';
import { MessageInvitedUsersModalComponent } from './message-invited-users-modal/message-invited-users-modal.component';
import { PurchaseModalComponent } from './purchase-modal/purchase-modal.component';
import { StreamInviteModalComponent } from './stream-invite-modal/stream-invite-modal.component';
import { ThankYouPurchaseModalComponent } from './thank-you-purchase-modal/thank-you-purchase-modal.component';

export * from './add-credit-card-modal/add-credit-card-modal.component';
export * from './add-to-calendar-modal/add-to-calendar-modal.component';
export * from './buy-series-modal/buy-series-modal.component';
export * from './purchase-modal/purchase-modal.component';
export * from './confirm-purchase-modal/confirm-purchase-modal.component';
export * from './thank-you-purchase-modal/thank-you-purchase-modal.component';
export * from './join-club-modal/join-club-modal.component';
export * from './invite-users-to-prep-mode-modal/invite-users-to-prep-mode-modal.component';
export * from './message-invited-users-modal/message-invited-users-modal.component';
export * from './stream-invite-modal/stream-invite-modal.component';

export const modalComponents = [
  PurchaseModalComponent,
  ConfirmPurchaseModalComponent,
  AddCreditCardModalComponent,
  AddToCalendarModalComponent,
  ThankYouPurchaseModalComponent,
  BuySeriesModalComponent,
  JoinClubModalComponent,
  InviteUsersToPrepModeModalComponent,
  MessageInvitedUsersModalComponent,
  StreamInviteModalComponent,
];
