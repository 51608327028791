import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GuestGuard } from '@kitch/data-access/guards';

import { PageNotFoundComponent } from '@kitch/ui/components';

const routes: Routes = [
  {
    path: 'login',
    redirectTo: '/',
  },
  {
    path: 'sign-up',
    redirectTo: '/',
  },
  {
    path: 'logout',
    loadComponent: () => import('@kitch/ui/components/logout-page/logout-page.component').then(
      m => m.LogoutPageComponent,
    ),
  },
  { path: 'not-found', component: PageNotFoundComponent },
  {
    path: '',
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./pages/user-panel/user-panel.module').then(
        (m) => m.UserPanelModule,
      ),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: CustomPreloading,
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class UserRoutingModule {}
