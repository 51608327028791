import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@kitch/data-access/services';
import { environment } from '@kitch/user/@env/environment';

type StripeErrorCode = 'incomplete_number' | 'incomplete_expiry' | 'incomplete_cvc';

interface CardFormError {
  code: StripeErrorCode;
  massage: string
}

let nextId = 0;

@Component({
  selector: 'app-credit-card-info',
  templateUrl: './credit-card-info.component.html',
  styleUrls: ['./credit-card-info.component.scss'],
})
export class CreditCardInfoComponent implements OnInit {
  @Input() profileId: string;
  @Input() isFormSubmitting = false;
  @Input() submitButtonTitle = 'CONTINUE';

  @Output() cardCreated: EventEmitter<string> = new EventEmitter<string>();

  billingInfoForm: UntypedFormGroup;
  elements;
  cardNumberElement;
  cardExpiryElement;
  cardCvcElement;
  zipCodeElement;
  cardFormError: CardFormError;

  isLoading = true;
  isActive = true;

  isCardNumberValid = false;
  isCardExpiryValid = false;
  isCardCvcValid = false;
  isCardZipcodeValid = false;

  isCardNumberTouched = false;
  isCardExpiryTouched = false;
  isCardCvcTouched = false;
  isCardZipcodeTouched = false;

  cardNumberInputId = `card-number-${nextId++}`;
  cardExpiryInputId = `card-date-${nextId++}`;
  cardCvcInputId = `card-cvc-${nextId++}`;
  cardZipcodeInputId = `card-zipcode-${nextId++}`;

  private stripe;
  private baseStyles = {
    fontFamily: 'Sofia Pro, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private logger: LoggerService,
  ) {}

  get isCardInfoValid(): boolean {
    return this.isCardNumberValid
      && this.isCardExpiryValid
      && this.isCardCvcValid
      && this.billingInfoForm.valid;
  }

  ngOnInit(): void {
    this.createBillingInfoForm();
    this.initStripe().then(() => this.isLoading = false);
  }

  submit(): void {
    if (!this.isCardInfoValid) {
      return;
    }

    this.cardFormError = null;
    this.isFormSubmitting = true;
    this.stripe.createToken(this.cardNumberElement).then((resp) => {
      this.logger.info(resp);
      if (resp.error) {
        this.isFormSubmitting = false;
        this.setCardError(resp.error.code);
      }

      if (resp.token) {
        this.cardCreated.emit(resp.token.id);
      }
    });
  }

  private async initStripe(): Promise<void> {
    const stripeJs = async () => await import('@stripe/stripe-js');
    const { loadStripe } = await stripeJs();

    this.stripe = await loadStripe(environment.stripePublishableKey);
    this.elements = this.stripe.elements({ locale: 'en' });
    this.zipCodeElement = this.elements.create('postalCode', {
      style: {
        base: this.baseStyles,
        invalid: { color: 'initial' },
      },
      classes: { invalid: 'input--is-invalid' },
      placeholder: 'Postal Code',
    });
    this.cardNumberElement = this.elements.create('cardNumber', {
      style: {
        base: this.baseStyles,
        invalid: { color: 'initial' },
      },
      classes: { invalid: 'input--is-invalid' },
      placeholder: 'XXXX XXXX XXXX XXXX',
    });
    this.cardExpiryElement = this.elements.create('cardExpiry', {
      style: {
        base: this.baseStyles,
        invalid: { color: 'initial' },
      },
      classes: { invalid: 'input--is-invalid' },
      placeholder: 'MM/YY',
    });
    this.cardCvcElement = this.elements.create('cardCvc', {
      style: {
        base: this.baseStyles,
        invalid: { color: 'initial' },
      },
      classes: { invalid: 'input--is-invalid' },
      placeholder: 'CVV',
    });

    this.cardNumberElement.mount(`#${this.cardNumberInputId}`);
    this.cardExpiryElement.mount(`#${this.cardExpiryInputId}`);
    this.cardCvcElement.mount(`#${this.cardCvcInputId}`);
    this.zipCodeElement.mount(`#${this.cardZipcodeInputId}`);

    this.cardNumberElement.on('change', (event) => {
      this.isCardNumberValid = !event.error;
      const displayError = document.getElementById('card-number-error');

      displayError.textContent = event.error ? 'Incorrect card number' : '';
    });

    this.cardExpiryElement.on('change', (event) => {
      this.isCardExpiryValid = !event.error;
      const displayError = document.getElementById('card-date-error');

      displayError.textContent = event.error ? 'Incorrect date' : '';
    });

    this.cardCvcElement.on('change', (event) => {
      this.isCardCvcValid = !event.error;
      const displayError = document.getElementById('card-cvc-error');

      displayError.textContent = event.error ? 'Incorrect code' : '';
    });

    this.zipCodeElement.on('change', (event) => {
      this.isCardZipcodeValid = !event.error;
      const displayError = document.getElementById('zip-code-error');

      displayError.textContent = event.error ? 'Incorrect code' : '';
    });

    this.cardNumberElement.on('blur', () => this.isCardNumberTouched = true);
    this.cardExpiryElement.on('blur', () => this.isCardExpiryTouched = true);
    this.cardCvcElement.on('blur', () => this.isCardCvcTouched = true);
    this.zipCodeElement.on('blur', () => this.isCardZipcodeTouched = true);
  }

  private createBillingInfoForm(): void {
    this.billingInfoForm = this.fb.group({
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      country: new UntypedFormControl(null, [Validators.required]),
    });
  }

  private setCardError(error: CardFormError): void {
    this.cardFormError = {
      code: error.code,
      massage: '',
    };
    switch (error.code) {
      case 'incomplete_number':
        this.cardFormError.massage = 'Incorrect card number';
        break;
      case 'incomplete_expiry':
        this.cardFormError.massage = 'Incorrect expiration date';
        break;
      case 'incomplete_cvc':
        this.cardFormError.massage = 'Incorrect CVV code';
        break;
      default:
        this.cardFormError.massage = error.massage || 'Something went wrong';
    }
  }
}
