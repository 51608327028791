import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { TypeformGAEvents } from '@kitch/data-access/constants';
import { Role, SHOPPING_CART_KEY } from '@kitch/data-access/models';
import { FullProfileInfo } from '@kitch/data-access/models/profile';
import { SettingsStatus } from '@kitch/data-access/models/settings';
import { SettingsService, TokenService } from '@kitch/data-access/services';
import { TypeformModalComponent } from '@kitch/ui/components/modals';
import { ModalService } from '@kitch/user/core/modal.service';
import { ProductsQuantityService } from '@kitch/user/core/products-quantity.service';
import { SidebarService } from '@kitch/user/core/sidebar.service';
import { UserProfile, UserProfileService } from '@kitch/user/core/user-profile.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  burgerMobileMenuIsOpen = false;
  burgerDesktopMenuIsOpen = false;
  isPossibleToHideDesktopSidebar = false;
  isInviteCodeModalOpened = false;
  isSearchOpened = false;
  isGuest: boolean;
  settingsStatus$: Observable<SettingsStatus>;
  userProfileInfo$: Observable<UserProfile>;
  profileId: string;
  isChefAccount: boolean;
  user: FullProfileInfo;
  userName: string;
  private isShoppingCardOpened = false;
  private shoppingProductsQuantity: number;

  constructor(
    private userProfile: UserProfileService,
    private tokenService: TokenService,
    private sidebarService: SidebarService,
    private router: Router,
    private productsQuantityService: ProductsQuantityService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.subscribeOnSidebarStatesChanges();
  }

  ngOnInit(): void {
    this.userProfileInfo$ = this.userProfile.userProfile$;
    this.settingsStatus$ = this.settingsService.settingStatus$;
    this.isChefAccount = this.tokenService.getRole() === Role.CHEF;
    this.profileId = this.tokenService.getProfileId();
    this.isGuest = this.tokenService.isGuest();
    this.userProfile.fullUserProfile$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const { chefName, displayName } = res.profile;

        this.user = res;
        this.userName = this.isChefAccount ? chefName : displayName;
      });

    this.initShoppingCart();
  }

  ngOnDestroy() {
  }

  get isShowShoppingCardButton(): boolean {
    return this.shoppingProductsQuantity > 0 || this.isShoppingCardOpened;
  }

  shoppingCardOpened(value: boolean): void {
    this.isShoppingCardOpened = value;
  }

  onMobileBurgerClick(): void {
    this.sidebarService.changeMobileState(!this.burgerMobileMenuIsOpen);
  }

  onDesktopBurgerClick(): void {
    this.sidebarService.changeDesktopState(!this.burgerDesktopMenuIsOpen);
  }

  setSearchStatus(status: boolean): void {
    this.isSearchOpened = status;
  }

  closeSideBar(): void {
    this.sidebarService.changeMobileState(false);
  }

  setIsOpenInviteModal(status: boolean): void {
    this.isInviteCodeModalOpened = status;
  }

  showTypeformModal(): void {
    this.modalService.open(TypeformModalComponent);
    this.$gaService.gtag('event', TypeformGAEvents.OPEN_GET_STARTED_UCPMODAL, {
      profile_id: this.tokenService.getProfileId(),
    });
  }

  private subscribeOnSidebarStatesChanges(): void {
    this.sidebarService.sidebarMobileState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => this.burgerMobileMenuIsOpen = state);

    this.sidebarService.sidebarDesktopState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => this.burgerDesktopMenuIsOpen = state);

    this.sidebarService.isPossibleToHideDesktopSidebarState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.isPossibleToHideDesktopSidebar = state;
      });
  }

  private initShoppingCart(): void {
    const cartData: string = localStorage.getItem(SHOPPING_CART_KEY);

    if (cartData) {
      this.productsQuantityService.updateProductsQuantity(JSON.parse(cartData));
    }

    this.productsQuantityService.productsQuantity$
      .pipe(untilDestroyed(this))
      .subscribe((quantity) => {
        this.shoppingProductsQuantity = quantity;
      });
  }
}
