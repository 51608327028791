import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CommonUserRole, REF_CODE_KEY } from '@kitch/data-access/models';
import { LoggerService, OpenwebService, TokenService } from '@kitch/data-access/services';

declare global {
  interface Window {
    SPOTIM: any;
  }
}

const CHAT_SELECTOR = '[data-spot-im-module-default-area="conversation"] div';
const LEFT_CHAT_CONTAINER_SELECTOR = '.left-chat-container';

@Component({
  selector: 'app-openweb-chat',
  templateUrl: './openweb-chat.component.html',
  styleUrls: ['./openweb-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OpenwebChatComponent implements OnInit, AfterViewChecked, OnDestroy {
  isLoginModalOpened = false;
  isRegisterModalOpened = false;
  userRole: CommonUserRole = CommonUserRole.USER;
  refCode: string;

  @Input() chatId: string;
  @Input() chefSlug?: string;
  @Input() isLeftChat? = false;
  @Input() title = 'ASK THE CHEF';

  isSSOInitialized = false;

  @ViewChild('openWebWrapper', { static: true })
  private readonly openWebWrapper: ElementRef;

  @ViewChild('commentCounterEl', { static: true })
  private readonly commentCounterEl: ElementRef;

  private isOpenWebRendered = false;
  private isHeaderHided = false;

  constructor(
    private tokenService: TokenService,
    private openwebService: OpenwebService,
    private renderer: Renderer2,
    private logger: LoggerService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.refCode = this.window.sessionStorage.getItem(REF_CODE_KEY);
  }

  ngAfterViewChecked() {
    this.onAfterViewChecked();
  }

  ngOnDestroy() {
    this.document.removeEventListener('spot-im-api-ready', this.startSSO);
    this.document.removeEventListener('spot-im-login-start', this.openLoginModal);
  }

  // Prior to initiating this function, ensure that the user
  // is actively logged into your site
  startSSO = (): void => {
    const callback = (codeA, completeSSOCallback) => {
      this.openwebService.getSSO(codeA).subscribe((res) => {
        if (res.codeB) {
          completeSSOCallback(res.codeB);
        }
      });
    };

    this.window.SPOTIM.startSSO({ callback: callback, userId: this.tokenService.getProfileId() })
      .then(() => {
        this.logger.info('\r\nUser logged-in OpenWeb');
      })
      .catch((reason) => {
        this.logger.error('SPOTIM.startSSO error reason: ', reason);
        this.modifyLoginButtons();
      })
      .finally(() => {
        if (this.isLeftChat) {
          this.hideHeader();
        }
      });
  };

  onLoginModalClosed(): void {
    this.isLoginModalOpened = false;
  }

  onRegisterModalClosed(): void {
    this.isRegisterModalOpened = false;
  }

  private onAfterViewChecked() {
    if (!this.isOpenWebRendered && this.chatId && this.openWebWrapper.nativeElement) {
      this.renderOpenWebContainers();
      this.renderOpenWebCommentCounter();
      this.isOpenWebRendered = true;
    }

    if (!this.isSSOInitialized) {
      if (this.window.SPOTIM && this.window.SPOTIM.startSSO) {
        this.startSSO();
      } else {
        this.document.addEventListener('spot-im-api-ready', this.startSSO, false);
      }

      this.document.addEventListener('spot-im-login-start', this.openLoginModal);
      this.isSSOInitialized = true;
    }

    if (this.isLeftChat && !this.isHeaderHided) {
      this.hideHeader();
    }
  }

  private renderOpenWebContainers(): void {
    const openWebWrapperEl = this.openWebWrapper.nativeElement;
    const chatContainer = this.makeChatContainer();

    this.renderer.appendChild(openWebWrapperEl, chatContainer);
  }

  private renderOpenWebCommentCounter(): void {
    const makeCommentCounter = () => {
      const node = document.createElement('div');

      node.setAttribute('class', 'spot-im-replies-count');
      node.setAttribute('data-post-id', this.chatId);
      node.setAttribute('real-time', 'true');

      return node;
    };
    const commentCounterEl = this.commentCounterEl.nativeElement;
    const commentCounter = makeCommentCounter();

    this.renderer.appendChild(commentCounterEl, commentCounter);
  }

  private makeChatContainer(): HTMLElement {
    const node = this.document.createElement('div');

    node.setAttribute('data-spotim-module', 'conversation');
    node.setAttribute('data-post-id', this.chatId);
    if (this.isLeftChat) {
      node.setAttribute('data-sort-by', 'newest');
    }

    return node;
  }

  private modifyLoginButtons(): void {
    const chatEl: HTMLElement = this.document.querySelector(CHAT_SELECTOR);

    if (!chatEl) {
      return;
    }
    const loginBtn: HTMLElement =
      chatEl.shadowRoot?.querySelector('.spcv_registration-buttons > div:last-child > button:first-child');
    const signUpBtn: HTMLElement =
      chatEl.shadowRoot?.querySelector('.spcv_registration-buttons > div:last-child > button:last-child');
    const splitEl: HTMLElement =
      chatEl.shadowRoot?.querySelector('.spcv_registration-buttons > div:last-child > div');

    loginBtn.innerHTML = 'Log in or Sign up';
    signUpBtn.style.display = 'none';
    splitEl.style.display = 'none';
  }

  private hideHeader() {
    const leftChat: HTMLElement = this.document.querySelector(`${LEFT_CHAT_CONTAINER_SELECTOR} ${CHAT_SELECTOR}`);

    if (!leftChat) {
      return;
    }

    const header: HTMLElement =
      leftChat.shadowRoot?.querySelector('.spcv_conversation-header');

    if (header) {
      header.style.display = 'none';
      this.isHeaderHided = true;
    }
  }

  private openLoginModal = (): void => {
    this.isLoginModalOpened = true;
  };
}
