export const COMING_UP_STREAM_CAROUSEL_DESKTOP_HEIGHT = 451;

export const COMING_UP_STREAM_CAROUSEL_TABLET_HEIGHT = 432;

export const COMING_UP_STREAM_CAROUSEL_MOBILE_HEIGHT = 413;

export const COMING_UP_STREAM_CAROUSEL_DESKTOP_WIDTH = 352;

export const COMING_UP_STREAM_CAROUSEL_TABLET_WIDTH = 348;

export const COMING_UP_STREAM_CAROUSEL_MOBILE_WIDTH = 343;
