<aside
  *ngIf="isDesktopSidebarShown"
  class="menu menu--desktop"
  [ngClass]="{'menu--desktop-toggling' : isPossibleToHideDesktopSidebar}"
>
  <ng-container *ngTemplateOutlet="sidebar"></ng-container>
</aside>

<aside
  data-testid="mobile-menu"
  class="menu menu--mobile"
  [class.menu--mobile-user]="isUser"
  [class.menu--is-open]="isOpen"
  [ngStyle]="isOpen ? { minHeight: '-webkit-calc(' + windowHeight + 'px - 72px)' } : ''"
>
  <ng-container *ngTemplateOutlet="sidebar"></ng-container>
</aside>

<ng-template #sidebar>
  <div
    *ngIf="isChef"
    (click)="closeSideBar()"
    class="go-live-btn"
  >
    <app-button
      *ngIf="!streamStatus?.isLive && !streamStatus?.isPrepMode || !streamStatus?.isOwner"
      (clicked)="openLiveStream()"
      [text]="'Go live'"
      [iconUrl]="'assets/ui/images/svg/camera.svg'"
    >
    </app-button>
    <app-button
      class="live-btn"
      *ngIf="streamStatus?.isLive && streamStatus?.isOwner || streamStatus?.isPrepMode && streamStatus?.isOwner"
      [text]="streamStatus?.isLive ? 'You’re live' : 'Prep Mode'"
      [isDisabled]="true"
      [iconUrl]="streamStatus?.isLive ? 'assets/ui/images/svg/red-circle.svg' : ''"
    >
    </app-button>
  </div>
<!--  <button class="toggle-menu-btn">-->
<!--    <div class="toggle-menu-btn__text"></div>-->
<!--  </button>-->
  <ul>
    <ng-container *ngIf="isChef">
      <div class="separator"></div>
      <div class="menu__sub-title">Chef Tools</div>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/profile/dashboard'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/dashboard-icon.svg"
            height="24"
            width="24"
            alt="dashboard-icon"
          />
          <span class="menu__text">Dashboard</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/streams/schedule'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/schedule.svg"
            height="24"
            width="24"
            alt="schedule-icon"
          />
          <span class="menu__text">Schedule & Promote</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/streams/upload'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/upload.svg"
            height="24"
            width="24"
            alt="upload-icon"
          />
          <span class="menu__text">Upload a show</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="(userProfileInfo$ | async).slug"
          (click)="closeSideBar()" tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/account.svg"
            height="24"
            width="24"
            alt="account-icon"
          />
          <span class="menu__text">My Channel</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/profile/notification'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/notification.svg"
            height="24"
            width="24"
            alt="notification-icon"
          />
          <span class="menu__text">Notifications</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/profile/my-streams'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/channels.svg"
            height="24"
            width="24"
            alt="channels-icon"
          />
          <span class="menu__text">My Streams</span>
        </a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/profile/recipe-manager'"
          (click)="closeSideBar()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/recipe.svg"
            height="24"
            width="24"
            alt="recipe-icon"
          />
          <span class="menu__text">Recipe Manager</span>
        </a>
      </li>
      <div class="separator"></div>
      <div class="menu__sub-title">Community</div>
    </ng-container>

    <li class="menu__item" routerLinkActive="active">
      <a
        data-testid="all-streams-link"
        class="menu__link"
        [routerLink]="'/all-streams'" (click)="closeSideBar(); sendGAEvent(gaEvents.allStreams)"
        tabindex="-1"
      >
        <img
          height="24"
          width="24"
          src="assets/ui/images/svg/stream.svg"
          alt="stream-icon"
        />
        <span class="menu__text">All Streams</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        [routerLink]="'/scheduled-streams'"
        (click)="closeSideBar(); sendGAEvent(gaEvents.upcoming)"
        tabindex="-1"
      >
        <img
          src="assets/ui/images/svg/scheduled.svg"
          height="24"
          width="24"
          alt="scheduled-icon"
        />
        <span class="menu__text">Coming Up</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        [routerLink]="'/channels'"
        (click)="closeSideBar(); sendGAEvent(gaEvents.channels)"
        tabindex="-1"
      >
        <img
          src="assets/ui/images/svg/channels.svg"
          height="24"
          width="24"
          alt="channel-icon"
        />
        <span class="menu__text">Channels</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        [routerLink]="'/my-stuff/upcoming/'"
        (click)="closeSideBar(); sendGAEvent(gaEvents.favorites)"
        tabindex="-1"
        rel="nofollow"
      >
        <img
          src="assets/ui/images/svg/black-bookmark.svg"
          height="29"
          width="20"
          alt="bookmark-icon"
        />
        <span class="menu__text">My Stuff</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        [routerLink]="'/recipes'"
        (click)="closeSideBar(); sendGAEvent(gaEvents.recipes)"
        tabindex="-1"
      >
        <img
          src="assets/ui/images/svg/recipes.svg"
          height="24"
          width="24"
          alt="recipes-icon"
        />
        <span class="menu__text">Recipes</span><br />
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        [routerLink]="'/marketplace'"
        (click)="closeSideBar(); sendGAEvent(gaEvents.marketplace)"
        tabindex="-1"
        rel="nofollow"
      >
        <img
          src="assets/ui/images/svg/cart-marketplace.svg"
          height="24"
          width="24"
          alt="marketplace-icon"
        />
        <span class="menu__text">Marketplace</span><br />
      </a>
    </li>
  </ul>

  <div class="separator separator--bottom"></div>

  <ul *ngIf="isOpen">
    <li
      *ngIf="!isGuest"
      class="menu__item"
      routerLinkActive="active"
    >
      <a class="menu__link"
        [routerLink]="isChef ? '/user-profile/' + profileId + '/notification' : '/user-profile/' + profileId + '/recipes'"
         data-testid="sidebar-my-account-link"
        (click)="closeSideBar()" tabindex="-1"
      >
        <img
          src="assets/ui/images/svg/account.svg"
          height="24"
          width="24"
          alt="account-icon"
        />
        <span class="menu__text">My Account</span>
      </a>
    </li>
    <li class="menu__item" routerLinkActive="active">
      <a
        class="menu__link"
        href="mailto:support@kittch.com"
        tabindex="-1"
        rel="nofollow"
      >
        <img
          src="assets/ui/images/svg/help-support.svg"
          height="24"
          width="24"
          alt="help-support"
        />
        <span class="menu__text">Help & Support</span>
      </a>
    </li>
    <ng-container *ngIf="!isGuest">
      <li class="menu__item" routerLinkActive="active">
        <button
          class="menu__link"
          (click)="setIsOpenInviteModal(true)"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/referral.svg"
            height="24"
            width="24"
            alt="referral-con"
          />
          <span class="menu__text">Invite a friend</span>
        </button>
      </li>
      <li class="menu__item" *ngIf="(settingsStatus$ | async).isTypeformModalCanBeShow">
        <button
          class="menu__link"
          (click)="showTypeformModal()"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/notepad.svg"
            height="24"
            width="24"
            alt="referral-con"
          />
          <span class="menu__text">Personalize your Experience</span>
        </button>
      </li>
      <li class="menu__item menu__item--last" routerLinkActive="active">
        <a
          class="menu__link"
          [routerLink]="'/logout'"
          tabindex="-1"
        >
          <img
            src="assets/ui/images/svg/logout.svg"
            height="16"
            width="16"
            alt="logout-icon"
          />
          <span class="menu__text">Log Out</span>
        </a>
      </li>
    </ng-container>
  </ul>

  <div
    class="separator separator--bottom"
    [ngClass]="isGuest ? 'separator--mta' : ''"
  ></div>

  <app-guest-buttons
    [source]="'sidebar'"
    *ngIf="isGuest"
    class="guest-buttons"
  ></app-guest-buttons>

  <div *ngIf="user && !isGuest" class="profile-info">
    <div class="avatar">
      <a
        (click)="closeSideBar()"
        [routerLink]="isChef ? '/profile/account/' : '/user-profile/' + profileId + '/recipes'"
      >
        <app-avatar
          [userName]="isChef ? user.profile.chefName : user.profile.displayName"
          [url]="user.profile.photo"
          [size]="'small'"
        ></app-avatar>
      </a>
      <a
        (click)="closeSideBar()"
        *ngIf="isChef" class="user-name"
        [routerLink]="'/profile/account/'"
      >
        {{ user.profile.chefName }}
      </a>
      <a
        (click)="closeSideBar()"
        *ngIf="!isChef" class="user-name"
        [routerLink]="'/user-profile/' + profileId + '/recipes'"
      >
        {{ user.profile.displayName }}
      </a>
    </div>
    <div class="clams">
      <img
        (click)="closeSideBar()"
        [routerLink]="isChef ? '/profile/rewards' : '/user-profile/' + profileId + '/clams'"
        class="clams__img"
        src="assets/ui/images/svg/clam.svg"
        alt="Clams"
        width="48"
        height="48"
      />
      <span class="clams__count">
        {{ (userProfileInfo$ | async).balance }}
      </span>
    </div>
  </div>
</ng-template>

<app-invite-code-modal
  *ngIf="(userProfileInfo$ | async).refCode"
  [refCode]="(userProfileInfo$ | async).refCode"
  [isOpen]="isInviteCodeModalOpened"
  (modalOpenChanged)="setIsOpenInviteModal($event)"
>
</app-invite-code-modal>
