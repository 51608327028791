import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-scroll-load',
  templateUrl: './scroll-load.component.html',
})
export class ScrollLoadComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() scrolled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;

  constructor(private host: ElementRef) { }

  ngOnInit() {
    const options = { root: this.isHostScrollable() ? this.host.nativeElement : null };

    this.observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolled.emit(true);
    }, options);
  }

  ngAfterViewInit() {
    this.observer.observe(this.anchor.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get element() {
    return this.host.nativeElement;
  }

  private isHostScrollable() {
    const style = window.getComputedStyle(this.element);

    return style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll';
  }
}
