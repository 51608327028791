<!-- USERS VIDEOS >> -->
<div class="users-video-wrap">
  <ng-container *ngFor="let user of userMap; index as index">
    <ng-container *appConst="userInfoMap.get(user?.uid) as userInfo">
      <div
        *ngIf="isAudienceUser(user)"
        id="{{ user.uid }}"
        class="users_video {{ 'users_video--' + videoSize }}"
        [class.users_video--no-image]="!userInfo?.avatar"
        [ngClass]="{ 'users_video--active': userInfo?.audioEnabled }"
      >
        <div *ngIf="!user.hasVideo">
          <ng-container *ngIf="userInfo?.avatar; then urlAvatarForUser; else nameUser"></ng-container>
          <ng-template #urlAvatarForUser>
            <picture class="users_video__avatar">
              <source srcset="{{ userInfo?.avatar }}"/>
              <img
                class="users_video__avatar"
                height="32"
                width="32"
                src="assets/ui/images/png/no-image.png"
                alt="avatar"
              />
            </picture>
          </ng-template>
          <ng-template #nameUser>
            <div class="users_video__avatar users_video__avatar-wrap">
              <div class="users_video__avatar">{{ userInfo?.displayName | shortName }}</div>
            </div>
          </ng-template>
        </div>
        <div class="users_video__gradient"></div>
        <div class="users_video__raised-hand" *ngIf="userInfo?.raisedHand">
          <img [src]="'assets/ui/images/svg/hand.svg'" alt="raised hand"/>
        </div>
        <div class="users_video__user-name users_video__user-name--hover">
          {{ isMe(user) ? 'You' : userInfo?.displayName }}
        </div>
        <div class="users_video__user-name users_video__user-name--speaking">
          {{ isMe(user) ? 'You' : userInfo?.displayName }}
        </div>
        <ng-container *ngIf="isMe(user) || isAdmin || streamInfo.isStreamOwner">
          <div class="users_video__context-menu" (click)="openContextMenu($event, user)">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="context-menu"
            [ngClass]="{
              'context-menu--active': isMe(user) && contextMenuUid === user.uid.toString(),
              'context-menu__remove--active': !isMe(user) && contextMenuUid === user.uid.toString()
            }">
            <div *ngIf="!isMe(user)" (click)="removeFromTable(user)">
              Remove from
              <span class="context-menu--active-mobile"> Chef's Table </span>
            </div>
            <div *ngIf="!isMe(user)" (click)="inviteCoHost(user)">
              Add as co-host
            </div>
            <div *ngIf="isMe(user)" (click)="leaveTable()">
              Leave Chef’s
              <span class="context-menu--active-mobile"> Table </span>
            </div>
          </div>
          <img
            *ngIf="!isMe(user) && userInfoMap.get(user.uid)?.audioEnabled"
            class="users_video__microphone users_video__microphone--chef"
            alt="microphone on"
            (click)="toggleUserAudio(user.uid, false)"
            [src]="'assets/ui/images/svg/microphone-on.svg'"
          />
          <img
            *ngIf="!isMe(user) && !userInfoMap.get(user.uid)?.audioEnabled"
            class="users_video__microphone users_video__microphone--chef"
            alt="microphone off"
            (click)="toggleUserAudio(user.uid, true)"
            [src]="'assets/ui/images/svg/microphone-off.svg'"
          />
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- << USERS VIDEOS -->

<!-- USER'S BLOCK >> -->
<ng-container *ngIf="!streamInfo.isStreamOwner">
  <!-- MIC/CAM/HAND BUTTONS >> -->
  <ng-container *ngIf="isUserJoined">
    <div class="control-buttons-wrap" *appConst="userInfoMap.get(streamInfo.profileId) as myUserInfo">
      <button class="control-button" (click)="myUserInfo?.audioEnabled ? turnOffMicrophone() : turnOnMicrophone()">
        <img
          alt="microphone"
          class="control-button-icon"
          [src]="getMicrophoneIconSrc(myUserInfo)"
        />
      </button>

      <button class="control-button" (click)="myUserInfo?.videoEnabled ? turnOffCamera() : turnOnCamera()">
        <img
          alt="camera"
          class="control-button-icon"
          [src]="getCameraIconSrc(myUserInfo)"
        />
      </button>

      <button class="control-button" (click)="raiseHand()">
        <img
          alt="rise hand"
          class="control-button-icon"
          [src]="getRiseHandIconSrc(myUserInfo)"
        />
      </button>
    </div>
  </ng-container>
  <!-- << MIC/CAM/HAND BUTTONS -->

  <!-- JOIN BUTTON >> -->
  <ng-container *ngIf="!isUserJoined && this.userMap.length < 8">
    <div class="join-wrap" [ngClass]="{ 'join-wrap--full-view': !isShortView }">
      <ng-container *ngIf="isJoinTextShown">
        <div class="join__title">JOIN<br *ngIf="isDesktopView"/> Chef’s Table</div>
        <div class="join__text">{{ joinText }}</div>
      </ng-container>
      <button
        appNeedAuthorize
        [source]="'join chefs table'"
        [afterLoginAction]="'showDevicesModal'"
        class="join__button"
        [disabled]="!canUserJoin"
        (click)="joinTable()"
      >{{ joinButtonText }}</button>
    </div>
  </ng-container>
  <!-- << JOIN BUTTON -->
</ng-container>
<!-- << USER'S BLOCK -->

<!-- CHEF'S BLOCK >> -->
<ng-container *ngIf="streamInfo.isStreamOwner && !userMap.length">
  <div class="empty-table">Encourage users to hop on the chef's table to ask questions.</div>
</ng-container>
<!-- << CHEF'S BLOCK -->
