import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, Observable, of } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import {
  CreditCard,
  DiscountType,
  Role,
  SimpleResponse,
  StreamStatus,
  TransactionCurrency,
} from '@kitch/data-access/models';
import { CheckoutInfo, CheckoutInfoPayload, ProductType } from '@kitch/data-access/models/checkout-info';
import { PurchaseData } from '@kitch/data-access/models/purchase';
import { BuySubscriptionPayload } from '@kitch/data-access/models/subscription';
import { DiscountsService, SubscriptionsService, TokenService } from '@kitch/data-access/services';
import { EmailValidator } from '@kitch/util/validators';
import { ModalComponent } from '@kitch/ui/components';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import {
  ThankYouPurchaseModalComponent,
} from '@kitch/user/shared/components/modals/thank-you-purchase-modal/thank-you-purchase-modal.component';
import { StripeService } from '@kitch/user/shared/services/stripe.service';

interface FormControls {
  discount: UntypedFormControl,
  isGift: UntypedFormControl,
  receiverEmail: UntypedFormControl,
  receiverFirstName: UntypedFormControl,
  receiverLastName: UntypedFormControl,
}

@UntilDestroy()
@Component({
  selector: 'app-confirm-purchase-modal',
  templateUrl: './confirm-purchase-modal.component.html',
  styleUrls: ['./confirm-purchase-modal.component.scss'],
})
export class ConfirmPurchaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() card!: CreditCard;
  @Input() isHardcodedSeries!: boolean;
  @Input() isBuyGift!: boolean;
  @Input() isClub!: boolean;
  @Input() purchaseCurrency!: TransactionCurrency;
  @Input() purchaseData!: PurchaseData;

  @ViewChild('confirmPurchaseModal') confirmPurchaseModal!: ModalComponent;
  @ViewChild('rootThankYouPurchaseModal') rootThankYouPurchaseModal!: ThankYouPurchaseModalComponent;

  @Output() purchaseModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmPurchaseModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  balance!: number;
  profileId!: string;
  discountForm!: UntypedFormGroup;
  giftForm!: UntypedFormGroup;
  streamStatus = StreamStatus;
  discountTypes = DiscountType;
  isChefAccount!: boolean;
  isDiscountInputShowed!: boolean;
  isDiscountApplying!: boolean;
  discountValue!: number;
  discountType!: DiscountType;
  discountId: string;
  buttonTitle: string;
  checkoutInfo: CheckoutInfo;
  isStripeError = false;
  isBuyProcessing = false;

  constructor(
    private discountsService: DiscountsService,
    private stripeService: StripeService,
    private tokenService: TokenService,
    private subscriptionService: SubscriptionsService,
    private userProfileService: UserProfileService,
    private router: Router,
    private fb: UntypedFormBuilder,
  ) {}

  get priceDiscount(): number {
    return Math.min(this.discountValue, this.purchaseData.requiredSubscriptionPlan.price);
  }

  get priceDiscountFromPercent(): number {
    return this.purchaseData.requiredSubscriptionPlan.price * this.discountValue / 100;
  }

  get subTotalPrice(): number {
    if (this.purchaseCurrency === 'USD') {
      return this.checkoutInfo?.amountSubtotal;
    } else {
      return this.purchaseData.requiredSubscriptionPlan.price;
    }
  }

  get totalPrice(): number {
    if (this.purchaseCurrency === 'USD') {
      return this.checkoutInfo?.amountTotal;
    }

    let totalPrice = this.purchaseData.requiredSubscriptionPlan.price;

    if (this.discountValue) {
      switch (this.discountType) {
        case DiscountType.FIXED:
          totalPrice -= this.priceDiscount;
          break;

        case DiscountType.PERCENT:
          totalPrice -= this.priceDiscountFromPercent;
      }
    }

    return totalPrice;
  }

  get controls(): FormControls {
    return {
      discount: this.discountForm.get('discount') as UntypedFormControl,
      isGift: this.giftForm.get('isGift') as UntypedFormControl,
      receiverEmail: this.giftForm.get('receiverEmail') as UntypedFormControl,
      receiverFirstName: this.giftForm.get('receiverFirstName') as UntypedFormControl,
      receiverLastName: this.giftForm.get('receiverLastName') as UntypedFormControl,
    };
  }

  get isBuyBtnDisabled(): boolean {
    return this.isBuyProcessing ||
      this.purchaseCurrency === 'USD' && !this.checkoutInfo ||
      this.giftForm.invalid && this.controls.isGift.value;
  }

  ngOnInit() {
    this.isChefAccount = this.tokenService.getRole() === Role.CHEF;

    this.userProfileService.userProfile$
      .pipe(
        untilDestroyed(this),
        tap(profile => {
          this.balance = profile.balance;
          this.profileId = profile.id;
        }),
        mergeMap(() => this.getCheckoutInfo()),
      )
      .subscribe(checkoutInfo => {
        if (checkoutInfo) {
          this.checkoutInfo = checkoutInfo;
        }
      },
      () => {
        this.handleStripeError();
      });
    this.createDiscountForm();
    this.createGiftForm();
    this.getButtonTitle();
  }

  ngAfterViewInit() {
    this.confirmPurchaseModal.open();
  }

  ngOnDestroy() {
    this.confirmPurchaseModal.close();
    this.rootThankYouPurchaseModal.thankYouPurchaseModal.close();
  }

  getButtonTitle(): void {
    if (this.isClub) {
      this.buttonTitle = 'enroll';
    } else {
      this.buttonTitle = 'Buy';
    }
  }

  closeAllModals(): void {
    this.closeModal();
    this.purchaseModalOpen.emit(false);
  }

  closeModal(): void {
    this.confirmPurchaseModalOpen.emit();
    this.confirmPurchaseModal.close();
  }

  buy(): void {
    this.isBuyProcessing = true;
    const buying$: Observable<SimpleResponse | CreditCard[]> = this.purchaseCurrency === 'USD' ?
      this.buyWithCreditCard() :
      this.buyWithClams();

    buying$
      .pipe(finalize(() => this.isBuyProcessing = false))
      .subscribe(() => {
        this.purchaseModalOpen.emit(false);
        this.confirmPurchaseModal.close();
        this.rootThankYouPurchaseModal.thankYouPurchaseModal.open();
      });
  }

  addDiscount(): void {
    this.isDiscountInputShowed = true;
  }

  applyDiscount(): void {
    const discountCode = this.controls.discount.value;

    if (discountCode.trim()) {
      this.isDiscountApplying = true;
      this.discountsService.getByCode(discountCode)
        .pipe(
          tap((discount) => {
            this.discountValue = discount.value;
            this.discountType = discount.type;
            this.discountId = discount.id;
            this.isDiscountInputShowed = false;
            // Make it "Calculating..."
            this.checkoutInfo.amountTax = null;
            this.checkoutInfo.amountTotal = null;
          }),
          mergeMap(() => this.getCheckoutInfo()),
          finalize(() => this.isDiscountApplying = false),
        )
        .subscribe((checkoutInfo) => {
          this.checkoutInfo = checkoutInfo;
        },
        err => {
          const { statusCode, error } = err;

          if (statusCode === 400 && error.includes('DIS')) {
            this.controls.discount.markAsTouched();
            this.controls.discount.setErrors({ serverError: error.message });
          } else {
            this.handleStripeError();
          }
        });
    }
  }

  private buyWithCreditCard(): Observable<SimpleResponse> {
    const buySubscriptionPayload = {
      cardId: this.card.id,
      discountCode: this.controls.discount.value || null,
      subscriptionPlanId: this.purchaseData.requiredSubscriptionPlan.id,
      sessionId: this.checkoutInfo.sessionId,
      isGift: this.controls.isGift.value,
      receiverEmail: this.controls.receiverEmail.value,
      receiverFirstName: this.controls.receiverFirstName.value,
      receiverLastName: this.controls.receiverLastName.value,
    };

    if (this.isClub) {
      return this.stripeService.subscribeToClubWithCard(buySubscriptionPayload);
    } else {
      return this.stripeService.subscribeToVideoWithCard(buySubscriptionPayload);
    }
  }

  private buyWithClams(): Observable<CreditCard[]> {
    const buySubscriptionPayload: BuySubscriptionPayload = {
      subscriptionPlanId: this.purchaseData.requiredSubscriptionPlan.id,
      discountCode: this.controls.discount.value || null,
    };

    if (this.balance >= this.purchaseData.requiredSubscriptionPlan.price) {
      return this.subscriptionService.subscribeToVideo(buySubscriptionPayload).pipe(
        tap(() => this.userProfileService.updateUserBalance()),
      );
    } else {
      return EMPTY;
    }
  }

  private createDiscountForm(): void {
    this.discountForm = this.fb.group({
      discount: new UntypedFormControl(''),
    });
  }

  private createGiftForm(): void {
    this.giftForm = this.fb.group({
      isGift: new UntypedFormControl(false),
      receiverEmail: new UntypedFormControl(null, [Validators.required, EmailValidator()]),
      receiverFirstName: new UntypedFormControl(null, [Validators.required]),
      receiverLastName: new UntypedFormControl(null),
    });
  }

  private getCheckoutInfo(): Observable<CheckoutInfo | null> {
    if (this.purchaseCurrency !== 'USD') {
      return of(null);
    }

    const checkoutInfoPayload: CheckoutInfoPayload = {
      cardId: this.card.id,
      productId: this.purchaseData.requiredSubscriptionPlan.id,
      productType: ProductType.videoContent,
      profileId: this.profileId,
      quantity: 1,
    };

    if (this.discountId) {
      checkoutInfoPayload.discount = this.discountId;
    }

    return this.stripeService.getCheckoutInfo(checkoutInfoPayload);
  }

  private handleStripeError() {
    this.isStripeError = true;
  }
}
