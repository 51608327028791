import {
  AfterViewInit,
  Component,
  EventEmitter, Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PurchaseData } from '@kitch/data-access/models/purchase';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-join-club-modal',
  templateUrl: './join-club-modal.component.html',
  styleUrls: ['./join-club-modal.component.scss'],
})
export class JoinClubModalComponent implements AfterViewInit, OnDestroy {
  @Input() purchaseClub!: PurchaseData;

  @Output() joinClub: EventEmitter<void> = new EventEmitter<void>();
  @Output() setBuyClubModalStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('buyClubModal') buyClubModal!: ModalComponent;

  ngAfterViewInit() {
    this.buyClubModal.open();
  }

  ngOnDestroy() {
    this.buyClubModal.close();
  }

  setClosedModal(): void {
    this.setBuyClubModalStatus.emit(false);
  }

  onJoinClub(): void {
    this.joinClub.emit();
  }
}
