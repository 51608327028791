import { NgModule } from '@angular/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '@kitch/data-access/env/environment';
import { AppComponent } from '@kitch/user/app.component';
import { UserModule } from '@kitch/user/user.module';

@NgModule({
  imports: [
    UserModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gaCode),
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],

})
export class AppBrowserModule {
}
