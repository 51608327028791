import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FiltersButtonService {
  filtersCount = 0;

  private buttonClickSub$: Subject<void> = new Subject<void>();

  get buttonClick$(): Observable<void> {
    return this.buttonClickSub$.asObservable();
  }

  emitButtonClicked(): void {
    this.buttonClickSub$.next();
  }
}
