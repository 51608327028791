import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  Output,
  ViewChild,
  AfterContentChecked,
  Input,
  SimpleChanges, OnInit,
} from '@angular/core';
import { PurchaseData } from '@kitch/data-access/models/purchase';
import { HardcodedSeriesEntry, Stream } from '@kitch/data-access/models/stream';
import { ModalComponent } from '@kitch/ui/components';

type BuySeriesModalType = 'CLAMS' | 'USD';

@Component({
  selector: 'app-buy-series-modal',
  templateUrl: './buy-series-modal.component.html',
  styleUrls: ['./buy-series-modal.component.scss', './../modals-styles.scss'],
})
export class BuySeriesModalComponent implements OnInit, AfterContentChecked, OnChanges {
  isClamsType!: boolean;

  @Input() isOpen = false;
  @Input() bundleStreams!: Stream[];
  @Input() type!: BuySeriesModalType;
  @Input() purchaseData!: PurchaseData;
  @Input() seriesInfo!: HardcodedSeriesEntry;

  @ViewChild('buySeriesModal', { static: false }) buySeriesModal!: ModalComponent;

  @Output() isBuySeriesModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openConfirmModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(ch: SimpleChanges): void {
    if (ch.isOpen?.currentValue) {
      setTimeout(() => this.buySeriesModal.open());
      this.isClamsType = this.type === 'CLAMS';
    } else {
      this.buySeriesModal?.close();
    }
    this.changeDetector.detectChanges();
  }

  closeModal(status: boolean): void {
    this.isBuySeriesModalOpen.emit(true);
    this.openConfirmModal.emit(status);
    if (!status) {
      this.buySeriesModal.close();
    }
  }

  setClosedModal(): void {
    this.isBuySeriesModalOpen.emit(false);
  }
}
