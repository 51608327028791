<div class="filter-btn-wrap">
  <app-button
    class="filter-btn"
    [iconUrl]="'assets/ui/images/svg/filter.svg'"
    [text]="''"
    [role]="'secondary'"
    [buttonClass]="'filter'"
    (click)="emitClickedEvent()"
  ></app-button>
  <span *ngIf="filtersCount" class="filter-indicator">{{ filtersCount }}</span>
</div>
