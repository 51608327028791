import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DiscountsService, OpenwebService, ProfilesService, UserService } from '@kitch/data-access/services';
import { MaterialModule } from '@kitch/material';
import { userSharedComponents } from '@kitch/user/shared/components';
import { RegisterService } from '@kitch/user/shared/services/register.service';
import { UiModule } from '@kitch/ui';
import { modalComponents } from './components/modals';
import { sharedDirectives } from './directives';

@NgModule({
  declarations: [
    ...userSharedComponents,
    ...sharedDirectives,
    ...modalComponents,
  ],
  providers: [DiscountsService, RegisterService, ProfilesService, OpenwebService, UserService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    UiModule,
    DragDropModule,
    FormsModule,
  ],
  exports: [
    ...userSharedComponents,
    ...sharedDirectives,
    ...modalComponents,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    UiModule,
  ],
})

export class UserSharedModule {}
