<div class="popups-wrap">
  <div *ngIf="isConnectionPopupOpened" class="popup popup--connection">
    <div class="popup__header">
      <h3 class="popup__header-title">Unstable connection</h3>
      <span (click)="closeConnectionPopup()" class="popup__header-close">
        <img src="assets/ui/images/svg/close.svg" alt="Close" />
      </span>
    </div>
    <span class="popup__text">Your Wifi is unstable and may result in a poor livestream experience.</span>
    <div class="popup__buttons">
      <app-button
        data-testid="proceed-btn"
        [text]="'Proceed'"
        [role]="'popup'"
        (clicked)="closeConnectionPopup()"
      ></app-button>
      <app-button
        data-testid='reschedule-btn'
        [text]="'Reschedule Stream'"
        [role]="'popup'"
        (clicked)="rescheduleStream()"
      ></app-button>
    </div>
  </div>

  <div *ngIf="isPrepModePopupOpened" class="popup popup--prep-mode">
    <div class="popup__header">
      <h3 class="popup__header-title">Prep Mode</h3>
      <span (click)="closePrepModePopup()" class="popup__header-close">
        <img src="assets/ui/images/svg/close.svg" alt="Close" />
      </span>
    </div>
    <span class="popup__text">You're not live yet! Test your cameras and audio and when you're ready to stream, click "Go Live".</span>
  </div>
</div>
