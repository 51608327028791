<article class="recipe-tab" *ngIf="!isLoading" [ngClass]="{'recipe-tab--no-access': isNoAccessToRecipe}">
  <img
    class="recipe-tab__image"
    src="{{ recipe.mealPhoto }}"
    alt="{{ recipe.recipeName }}"
    height="132"
    width="198"
  >

  <h2 class="recipe-tab__title">{{ recipe.recipeName }}</h2>

  <!-- DESCRIPTION >> -->
  <div
    class="recipe-tab__description"
    [ngClass]="{'recipe-tab__description--shown' : isFullDescriptionShown}"
    [innerHTML]="recipe.description"
    (click)="trackClick($event)"
  ></div>

  <button
    class="recipe-tab__show-btn"
    [ngClass]="{'recipe-tab__show-btn--shown' : isFullDescriptionShown}"
    (click)="toggleFullAppearance()"
  >
    {{isFullDescriptionShown ? 'Hide' : 'Show full'}}
    {{isDesktopView ? ' description' : ' recipe'}}
  </button>
  <!--<< DESCRIPTION -->

  <ng-container *ngIf="isFullRecipeShown">
    <!-- INGREDIENTS >> -->
    <h2 class="recipe-tab__sub-title">Ingredients</h2>

    <div
      class="recipe-tab__ingredients"
      (click)="trackClick($event)"
    >
      <p *ngFor="let ingredient of ingredients" [innerHTML]="ingredient"></p>
    </div>
    <!--<< INGREDIENTS -->

    <!-- PREPARATION >> -->
    <h2 class="recipe-tab__sub-title">Preparation</h2>

    <div class="recipe-tab__steps">
      <ng-container *ngFor="let step of recipe.listSteps">
        <div
          *ngIf="step.type === 'header'; else defaultStepTmpl"
          class="recipe-tab__step-title"
        >
          {{ step.value }}
        </div>

        <ng-template #defaultStepTmpl>
          <div
            [innerHTML]="step.value"
            (click)="trackClick($event)"
          ></div>
        </ng-template>
      </ng-container>
    </div>
    <!--<< PREPARATION -->
  </ng-container>

  <a
    [routerLink]="'/' + recipe.profile.chefSlug + '/recipes/' + recipe.slug"
    target="_blank"
    class="recipe-tab__view-recipe"
  >
    <img
      [src]="'assets/ui/images/svg/recipe-icon-' + (isDesktopView ? 'white' : 'black') + '.svg'"
      alt="recipes icon"
      width="22"
      height="20"
      class="recipe-tab__view-recipe-icon"
    />
    View Recipe
  </a>
</article>
