// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment as shared } from '@kitch/data-access/env/environment';

export const environment = {
  ...shared,
  // eslint-disable-next-line max-len
  stripePublishableKey:
    'pk_test_51IWUpsACT9ISAZwuTbSdVr0OitdHgDuCYMh30ArqtJkB1DplYaJOKjmhOkigMSZhrgs0t04CuU3cZ4JAtSFs5SL400b7WDi9Ap',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
