import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { CreditCard } from '@kitch/data-access/models/credit-card';
import { ProfilesService } from '@kitch/data-access/services/profiles.service';
import { TokenService } from '@kitch/data-access/services/token.service';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { StripeService } from '@kitch/user/shared/services/stripe.service';

@UntilDestroy()
@Component({
  selector: 'app-add-credit-card-modal',
  templateUrl: './add-credit-card-modal.component.html',
  styleUrls: ['./add-credit-card-modal.component.scss'],
})
export class AddCreditCardModalComponent implements OnInit, OnChanges {
  @Input() isOpen!: boolean;
  @Input() isClub = false;

  @Output() addCreditCardModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmPurchaseModalOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addCreditCardModal', { static: false }) addCreditCardModal!: ModalComponent;

  userClamsBalance = 0;
  primaryCreditCard?: CreditCard;
  isModalSubmitting = false;

  constructor(
    private stripeService: StripeService,
    private userProfileService: UserProfileService,
    private tokenService: TokenService,
    private profilesService: ProfilesService,
  ) { }

  ngOnInit(): void {
    this.userProfileService.userProfile$
      .pipe(untilDestroyed(this))
      .subscribe(({ balance }) => {
        this.userClamsBalance = balance;
      });
    this.getUserCards().subscribe();
  }

  ngOnChanges(ch: SimpleChanges): void {
    if (ch.isOpen?.currentValue) {
      this.addCreditCardModal.open();
    }
  }

  closeModal(): void {
    this.addCreditCardModalOpen.emit(false);
    this.addCreditCardModal.close();
  }

  setClosedModal(): void {
    this.addCreditCardModalOpen.emit(false);
  }

  private getUserCards(): Observable<CreditCard[]> {
    return this.profilesService.getUserCards(this.tokenService.getProfileId()).pipe(
      tap(cards => {
        this.primaryCreditCard = cards.find(({ primary }) => primary);
      }),
    );
  }

  creditCardCreatedHandler(token: string): void {
    this.isModalSubmitting = true;

    this.stripeService
      .addCreditCard(token)
      .pipe(
        concatMap(() => this.getUserCards()),
        finalize(() => {
          this.isModalSubmitting = false;
        }),
      )
      .subscribe(() => {
        this.confirmPurchaseModalOpen.emit(true);
        this.closeModal();
      });
  }
}
