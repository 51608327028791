<app-modal (closed)="setClosedModal()" #buySeriesModal>
  <ng-container header>
    <h2 class="modal-title" data-testid="title">Bundle up, Buy the Series.</h2>
    <div class="modal-subtitle" data-testid="subtitle" *ngIf="purchaseData">Save by purchasing the
      <span>{{ purchaseData?.chefProfile.chefName }}’s Series&nbsp;</span>for&nbsp;
      <span>{{ seriesInfo?.dummyVideo?.subscriptionPlan?.price | customCurrency: type }}.</span>
    </div>
  </ng-container>

  <ng-container body>
    <div *ngIf="purchaseData" class="modal-content-wrap" data-testid="content">
        <div class="content">
          <app-stream-top-card
            *ngFor="let stream of bundleStreams"
            [stream]="stream"
            [isNewTab]="true"
          ></app-stream-top-card>
        </div>
    </div>
    <div class="form__item form__item--center" data-testid="buttons">
      <div class="form-button form-button--close">
        <app-button
          [role]="isClamsType ? 'primary' : 'secondary'"
          class="modal-button"
          [text]="'Buy Series'"
          (clicked)="closeModal(true)"
          >
          <ng-container right-side>
            <div class="clams">
              <span
                class="clams__text"
                [ngClass]="{'price-text': isClamsType}"
              >
                <ng-container *ngIf="!isClamsType">$</ng-container>
                {{ seriesInfo?.dummyVideo?.subscriptionPlan?.price }}
              </span>
              <img
                *ngIf="isClamsType"
                src="assets/ui/images/svg/clam.svg"
                width="48"
                height="48"
                alt="clam"
              />
            </div>
          </ng-container>
        </app-button>
      </div>
      <span>or</span>
      <div class="form-button form-button--close">
        <app-button
          class="modal-button"
          role="'secondary'"
          [text]="'No thanks, continue'"
          (clicked)="closeModal(false)"
        ></app-button>
      </div>
    </div>
  </ng-container>
</app-modal>

