<div *ngIf="stream" class="top-stream" [class.top-stream--bg-scheduled]="isScheduledStream">
  <a
    class="top-stream__link-avatar"
    [class.top-stream__link-avatar-one-item]="isOneItem"
    [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug"
    (click)="sendGAEvent()"
  >
    <div class="top-stream__thumbnail" [class.top-stream__thumbnail-one-item]="isOneItem">
      <picture>
        <img
          class="stream-thumbnail"
          [ngSrc]="stream.thumbnailUrl"
          height="56"
          width="84"
          alt="thumbnail"
        />
      </picture>
      <div class="overlay"></div>

      <!-- STREAM BADGE >> -->
      <ng-container *ngIf="streamBadge">
        <div class="top-stream__badge thumbnail-earth">
          <img [src]="streamBadge" alt="badge"/>
        </div>
      </ng-container>

      <ng-container *ngIf="!streamBadge">
        <ng-container *ngIf="hasMushroomMonthChef && !stream.produced && stream.sponsored">
          <div class="top-stream__badge thumbnail-mushroom">
            <img src="assets/ui/images/png/mushroom-badge.png" alt="mushroom-month"/>
          </div>
        </ng-container>
        <ng-container *ngIf="hasEarthWeekChef">
          <div class="top-stream__badge thumbnail-earth">
            <img
              src="assets/ui/images/svg/earth-week.svg"
              width="70"
              height="52"
              alt="earth-week"
            />
          </div>
        </ng-container>
        <ng-container *ngIf="hasFireChef">
          <div class="top-stream__badge thumbnail-fire">
            <img
              src="assets/ui/images/svg/fire-badge.svg"
              width="198"
              height="168"
              alt="fire-badge"
            />
          </div>
        </ng-container>
      </ng-container>
      <!-- STREAM BADGE >> -->
    </div>
  </a>

  <div class="top-stream__content">
    <div>
      <div class="top-stream__top-row">
        <div *ngIf="stream.status === streamStatus.LIVE" class="stream-status">
          <app-views-counter></app-views-counter>
        </div>
        <div *ngIf="isScheduledStream" class="date-container">
          <div class="date-container__date">
            {{ stream.scheduledVideo.date | timezone }}
          </div>
        </div>
        <div *ngIf="stream.status === streamStatus.PAST" class="stream-status">
          <div class="duration">{{ streamDuration | durationVideo }}</div>
        </div>
        <div
          *ngIf="isPaidIndicatorShown"
          class="paid-indicator-container"
        >
          <img
            class="paid-indicator"
            [src]="'assets/ui/images/svg/paid-indicator.svg'"
            alt="paid-indicator"
            width="24"
            height="24"
          />
        </div>
        <app-access-indicator
          [isVideoPrivate]="stream.videoPrivate || stream.hidden"
          [isPurchased]="stream.subscribed || stream.subscribedToClub"
        ></app-access-indicator>
      </div>
      <a
        class="top-stream__description"
        [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug"
        (click)="sendGAEvent()"
      >{{ stream.title }}</a>
      <div class="top-stream__author">
        <a
          *ngIf="!stream.collaborators?.length"
          [routerLink]="'/' + stream.channel.chefProfile.chefSlug"
        >
          <app-avatar
            [url]="stream.channel.chefProfile.photo"
            [size]="'extra-small'"
            [name]="stream.channel.chefProfile.chefName"
            [title]="stream.channel.name"
            [isLive]="stream.channel.chefProfile.isLive"
            [isClub]="!!stream.requiredClubSubscriptionPlan"
          ></app-avatar>
        </a>
        <app-multiple-avatars
          *ngIf="stream.collaborators?.length"
          [chefs]="stream.collaborators"
          [backgroundColor]="
            stream.status === streamStatus.SCHEDULED || stream.status === streamStatus.PREPMODE ? 'green' : ''
          "
        >
        </app-multiple-avatars>
      </div>
    </div>

    <ng-container *ngIf="isUnpaidClub; else buyStreamBtnAndFreeSection">
      <app-club-button
        [stream]="stream"
        [isDisabled]="stream.subscribedToClub"
        [isJoinShown]="false"
      ></app-club-button>
    </ng-container>

    <ng-template #buyStreamBtnAndFreeSection>
      <ng-container *ngIf="isPaymentRequire && !stream.requiredClubSubscriptionPlan; else freeSection">
        <app-buy-stream-button [stream]="stream"></app-buy-stream-button>
      </ng-container>
    </ng-template>

    <ng-template #freeSection>
      <ng-container *ngIf="isScheduledStream">
        <div class="top-stream__action-buttons">
          <app-save-my-spot-button [stream]="stream"> </app-save-my-spot-button>
        </div>
      </ng-container>

      <ng-container *ngIf="stream.status === streamStatus.LIVE || stream.status === streamStatus.PAST">
        <a class="top-stream__action-button link"
          [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug">watch now
        </a>
      </ng-container>
    </ng-template>
  </div>
</div>
