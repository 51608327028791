import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { STREAMS_URL } from '@kitch/data-access/constants';
import { AgoraStreamToken, StreamUserRole } from '@kitch/data-access/models';
import { ApiService } from '@kitch/data-access/services';

import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class AgoraStreamService {
  private agoraClient: IAgoraRTCClient;

  constructor(private apiService: ApiService) {
    // this.agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
  }

  getStreamToken(streamId: string, role: StreamUserRole): Observable<AgoraStreamToken> {
    return this.apiService.get(makeUri(STREAMS_URL, streamId, 'token'), { role });
  }
}
