import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NOT_FOUND, NOT_FOUND_RECIPE_ERROR } from '@kitch/data-access/constants';
import { Recipe, ServerError } from '@kitch/data-access/models';
import { RecipesService } from '@kitch/data-access/services';
import { getIngredientsWithFractions } from '@kitch/util';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { TabId } from '@kitch/user/shared/models';
import { GoogleTrackerService } from '@kitch/user/shared/services/google-tracker.service';

@Component({
  selector: 'app-recipe-live-tab',
  templateUrl: './recipe-live-tab.component.html',
  styleUrls: ['./recipe-live-tab.component.scss'],
  encapsulation: ViewEncapsulation.None, // for styles in innerHtml
})
export class RecipeLiveTabComponent implements OnInit, OnChanges {
  @Input() recipeId: string;
  @Input() isDesktopView: boolean;
  @Input() tabId: TabId;

  isLoading = true;
  isNoAccessToRecipe: boolean;
  recipe: Recipe;
  isFullDescriptionShown = false;
  isFullRecipeShown: boolean;
  isNewStyleRecipe: boolean;
  ingredients: string[] = [];

  constructor(
    private recipesService: RecipesService,
    private liveReplayTabsService: LiveReplayTabsService,
    private googleTrackerService: GoogleTrackerService,
  ) {}

  ngOnInit(): void {
    if (this.recipeId) {
      this.setRecipe();
    }
    this.isFullRecipeShown = this.isDesktopView;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recipeId?.currentValue) {
      this.setRecipe();
    }
  }

  toggleFullAppearance(): void {
    this.isFullDescriptionShown = !this.isFullDescriptionShown;

    if (!this.isDesktopView) {
      this.isFullRecipeShown = !this.isFullRecipeShown;
    }
  }

  trackClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      this.googleTrackerService.trackViewProductLink(event.target.text, event.target.href, 'stream_page');
    }
  }

  private setRecipe(): void {
    this.recipesService.getById(this.recipeId)
      .subscribe({ next: (response) => {
        this.recipe = response;
        this.isNewStyleRecipe = !!response.listIngredients?.length;
        this.setIngredients();
        this.isNoAccessToRecipe = (!!response.requiredSubscriptionPlan && !response.subscribed) ||
          (!!response.requiredClubSubscriptionPlan && !response.subscribedToClub);
        this.isLoading = false;
      },
      error: (error: ServerError) => {
        if (error.statusCode === NOT_FOUND && error.message.includes(NOT_FOUND_RECIPE_ERROR)) {
          this.liveReplayTabsService.removeTab(this.tabId);
        }
      } });
  }

  private setIngredients(): void {
    if (this.isNewStyleRecipe) {
      this.ingredients = getIngredientsWithFractions(this.recipe.listIngredients);
    } else {
      this.ingredients = [...this.recipe.ingredients];
    }
  }
}
