<app-button
  appShowPurchaseModal
  class="buy-stream-button"
  [stream]="stream"
  [text]="buttonTitle"
  [role]="'secondary'">
  <ng-container right-side>
    <div class="buy-stream-button__price-block">
      <div class="buy-stream-button__price-icon">
        <img
          src="assets/ui/images/svg/clam.svg"
          width="48"
          height="48"
          alt="clam"
        />
      </div>
    </div>
  </ng-container>
</app-button>
