import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-emoji-button',
  templateUrl: './emoji-button.component.html',
  styleUrls: ['./emoji-button.component.scss'],
})
export class EmojiButtonComponent implements OnInit, OnDestroy {
  @ViewChild('button', { static: true }) button: ElementRef;

  @Input() icon: string;

  @Output() sendReaction: EventEmitter<number> = new EventEmitter<number>();

  iconAlt: string;

  ngOnInit(): void {
    const iconFile = this.icon.split('/').reverse()[0];

    this.iconAlt = iconFile.split('.')[0];
    this.subscribeOnsButtonClick();
  }

  ngOnDestroy() {
  }

  private subscribeOnsButtonClick(): void {
    let count = 0;

    fromEvent(this.button.nativeElement, 'click')
      .pipe(
        tap(() => count++),
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.sendReaction.emit(count);
        count = 0;
      });
  }
}
