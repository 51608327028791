<ng-container *ngIf="users.length">
  <div class="users-wrap">
    <div class="user" *ngFor="let user of users; trackBy: trackById">
      <div class="user-avatar-wrap">
        <div
          class="user-avatar user-avatar--name"
          [ngClass]="{ 'user-avatar--un-muted': !user.isMuted }"
        >
          <div class="avatar__name">{{ user.displayName | shortName }}</div>
        </div>
        <img
          *ngIf="user.photo"
          class="user-avatar"
          alt="User avatar"
          [ngClass]="{ 'user-avatar--un-muted': !user.isMuted }"
          [src]="user.photo"
          (error)="onBrokenPhotoUrl(user)"
        />
      </div>
      <div class="user-name">{{ user.displayName }}</div>
    </div>
  </div>

  <div class="description">
    {{ users.length === 1 ? users[0].displayName : 'These participants' }}
    joined the real-time Chef's Table during the livestream.
  </div>
</ng-container>

<div *ngIf="!users.length" class="description description--empty-table">
  No active participants at this time. User will appear here as they join the Chef’s Table.
</div>
