<!-- USER VIDEO SECTION >> -->
<ng-container *appConst="userInfoMap.get(streamInfo.profileId) as myUserInfo">
  <div class="stream-container">
    <div class="stream-wrap">
      <div class="stream-container__video-wrap">
        <div class="player-wrap">
          <ng-container *ngIf="!coHosts.length">
            <div id="chef-player" class="player"></div>
            <app-pip-camera-area
              [index]="pipCameraPosition"
              [isDndDisabled]="true"
              [isSwitchDisabled]="true"
              (cameraDragStarted)="pipCameraDragStarted($event)"
              (cameraDropped)="pipCameraDropped($event)"
              (cameraSwitched)="switchPipCamera()"
            ></app-pip-camera-area>
          </ng-container>
          <ng-container *ngIf="coHosts.length">
            <div class="co-hosts-wrap" [ngClass]="coHosts.length === 2 ? 'co-hosts-wrap--dual' : ''">
              <div *ngFor="let coHost of coHosts" id="co-host-{{ coHost.profileId }}" class="player--co-host">
                <span *ngIf="coHost.profileId !== stream.channel.chefProfile.id && (coHost.profileId === streamInfo.profileId || isAdmin)"
                  class="remove-co-host-btn">
                  <img (click)="leaveCoHostsTable(coHost.profileId)"
                    src="assets/ui/images/svg/close.svg" alt="close" />
                </span>
                <div class="users_video__raised-hand users_video__raised-hand--co-host"
                  *ngIf="userInfoMap.get(coHost.profileId)?.raisedHand">
                  <img [src]="'assets/ui/images/svg/hand.svg'" alt="hand"/>
                </div>
                <div *ngIf="coHost.profileId === stream.channel.chefProfile.id"
                  id="second-camera-player" class="player--pip player--pip-{{ pipCameraPosition }}">
                </div>

                <div class="co-host-name">{{ userInfoMap.get(coHost.profileId)?.displayName }}</div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="isStreamStarted" class="viewers-count">
            <app-views-counter></app-views-counter>
          </div>

          <div class="popup-audio"
            [class.popup-audio--active]="isSpeakerPopup"
            [class.popup-audio]="!isSpeakerPopup">
            <span class="icon-audio">
              <img
                height="24"
                width="24"
                src="assets/ui/images/svg/speaker.svg"
                alt="Clams"
              />
            </span>
                  <span class="text">Click here if you don’t hear the stream audio</span>
                  <span class="close">
              <img
                height="24"
                width="24"
                src="assets/ui/images/svg/close.svg"
                alt="Clams"
              />
            </span>
          </div>

          <div (click)="scrollEl()" class="popup-ask"
            [hidden]="!isAskPopup"
            [class.popup-ask--active]="isAskPopup">
            <span class="icon-message">
              <img
                height="24"
                width="24"
                src="assets/ui/images/svg/message.svg"
                alt="message"
              />
            </span>
            <span class="text">Click here to ask the chef a question</span>
            <span class="close">
              <img
                height="24"
                width="24"
                (click)="closePopupAskChef()"
                src="assets/ui/images/svg/close.svg"
                alt="Clams"
              />
            </span>
          </div>

          <div class="clams">
            <span class="clams__count" [class.count-translate-bottom]="clamsTranslate">
              {{ streamInfo.tipsBalance }}
            </span>
            <div class="clams__img">
              <img
                src="assets/ui/images/svg/clam.svg"
                width="48"
                height="48"
                alt="Clams"
              />
            </div>
          </div>
        </div>

        <div class="users_video-wrap">
          <ng-container *ngTemplateOutlet="tabs"></ng-container>
        </div>
      </div>
      <!-- << USER VIDEO SECTION -->

      <!-- USER BOTTOM PANEL >> -->
      <div class="bottom-panel bottom-panel--user">
        <div class="bottom-panel__button--wrap">
          <div class="bottom-panel__label bottom-panel__label--reaction">Reactions</div>
          <div
            appNeedAuthorize
            [source]="'emoji'"
            *ngFor="let icon of emojiIcons"
            class="bottom-panel__control">
            <app-emoji-button
              [icon]="icon"
              (sendReaction)="sendReaction($event, icon)"
            ></app-emoji-button>
          </div>
        </div>
        <div class="bottom-panel__button--wrap">
          <div class="bottom-panel__button bottom-panel__button--tip">
            <app-tips-buttons
              #tipsButtons
              [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
            ></app-tips-buttons>
            <app-tips-success-message
              @opacityAnimation
              *ngIf="isTipsSuccessMessageShown"
              [tipsChefCount]="tipsChefCount"
            ></app-tips-success-message>
          </div>
        </div>
      </div>
      <!-- << USER BOTTOM PANEL -->

      <div class="bottom-tabs-wrap">
        <ng-container *ngTemplateOutlet="tabs"></ng-container>
      </div>
    </div>
  </div>


  <!-- USER HELP MESSAGES >> -->
  <ng-template #tooltip let-control="control">
    <div *ngIf="control === 'camera' && isUserJoined && myUserInfo?.videoEnabled"
      class="bottom-panel__tooltip">
      Turn camera off
    </div>
    <div *ngIf="(control === 'micro' || control === 'raise-hand') && isUserJoined && !myUserInfo?.audioEnabled"
      class="bottom-panel__tooltip">
      Raise hand to speak
    </div>
    <div *ngIf="!isUserJoined && streamInfo.canUserJoinTable" class="bottom-panel__tooltip">
      Join Chef’s Table
      <span> to participate </span>
    </div>
    <div *ngIf="!isUserJoined && !streamInfo.canUserJoinTable" class="bottom-panel__tooltip">
      Join Cooking Club
      <span> to participate </span>
    </div>
  </ng-template>
  <!-- << USER HELP MESSAGES -->
</ng-container>


<app-select-devices-modal
  #selectDevicesModal
  [isOpen]="isDevicesFormModalOpened"
  [isSubmitDisabled]="isDevicesSubmitFormDisabled"
  [isStreamOwner]="streamInfo.isStreamOwner"
  [microphones]="microphones"
  [cameras]="cameras"
  (formSubmit)="onDevicesFormSubmit($event)"
  (modalOpenChanged)="setDeviceModalStatus($event)"
></app-select-devices-modal>

<app-stream-invite-modal
  [isOpen]="isCoHostInviteModalOpened"
  [chefName]="stream.channel.chefProfile.chefName"
  (inviteAccepted)="onInviteAccept($event)"
></app-stream-invite-modal>

<app-co-host-invite-was-send-modal
  [isOpen]="isInviteWasSendModalOpened"
  (modalOpenChanged)="setInviteWasSendModalStatus($event)"
></app-co-host-invite-was-send-modal>

<app-full-stream-table-modal
  [isOpen]="isFullTableModalOpened"
  (isFullTableModalOpened)="setFullTableModalStatus($event)"
></app-full-stream-table-modal>

<app-message-invited-users-modal
  #messageInvitedUsersModal
></app-message-invited-users-modal>

<ng-template #tabs>
  <app-tabs
    (switchedTab)="onTabSwitched()"
  >
    <app-stream-info
      tab-0
      *ngIf="!isDesktopView"
      [isDesktopView]="isDesktopView"
      [stream]="stream"
      [isChefOrAdmin]="isChefOrAdmin"
      [isAskChefLoaded]="isAskChefLoaded"
      [isChefStreamOwner]="isChefStreamOwner"
      [walmartRecipeId]="walmartRecipeId"
      [walmartRecipePortions]="walmartRecipePortions"
      [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
      [tipsChefCount] ="tipsChefCount"
      (openSubscribeModal)="onClubBtnClick($event)"
      (sendTranslation)="onSubmitTranslate($event)"
    ></app-stream-info>

    <app-openweb-chat
      tab-1
      *ngIf="!isDesktopView && isAskChefLoaded"
      [chatId]="chatId"
    ></app-openweb-chat>

    <app-chef-table
      tab-2
      [userMap]="userMap"
      [userInfoMap]="userInfoMap"
      [canUserJoin]="canUserJoin"
      [isDesktopView]="isDesktopView"
      (componentViewInit)="subscribeToChefTableVideo()"
      (removedFromTable)="removeFromTable($event)"
      (invitedCoHost)="inviteCoHost($event)"
      (joinedTable)="showSelectDeviceFormForUser()"
      (leftTable)="leaveTable()"
      (turnedOnCamera)="turnOnCamera()"
      (turnedOffCamera)="turnOffCamera()"
      (turnedOnMicrophone)="turnOnMicrophone()"
      (turnedOffMicrophone)="turnOffMicrophone()"
      (toggledUserAudio)="toggleUserAudio($event)"
      (raisedHand)="raiseHand()"
    ></app-chef-table>

    <app-recipe-live-tab
      tab-3
      *ngIf="recipes[0]"
      [recipeId]="recipes[0].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_FIRST"
    ></app-recipe-live-tab>

    <app-recipe-live-tab
      tab-4
      *ngIf="recipes[1]"
      [recipeId]="recipes[1].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_SECOND"
    ></app-recipe-live-tab>

    <app-recipe-live-tab
      tab-5
      *ngIf="recipes[2]"
      [recipeId]="recipes[2].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_THIRD"
    ></app-recipe-live-tab>

    <app-shop-live-tab
      tab-6
      *ngIf="stream.products.length || walmartRecipe"
      [products]="stream.products"
      [walmartRecipe]="walmartRecipe"
    ></app-shop-live-tab>
  </app-tabs>
</ng-template>
