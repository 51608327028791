<app-button
  *ngIf="isJoinShown; else buyButton"
  appShowPurchaseModal
  [purchaseType]="'club'"
  [stream]="stream"
  [text]="joinCourseBtnTitle"
  [iconUrl]="'assets/ui/images/svg/club-member.svg'"
  [role]="role"
  [type]="'button'"
  [isDisabled]="isDisabled"
  [isSmallOnTablet]="isSmallOnTablet"
  [buttonClass]="'join-club'"
  (clicked)="onJoinClub()"
></app-button>

<ng-template #buyButton>
  <app-button
    class="buy-club-button"
    appShowPurchaseModal
    [purchaseType]="'club'"
    [stream]="stream"
    [isDisabled]="isDisabled"
    [isSmallOnTablet]="isSmallOnTablet"
    [ngClass]="{'small-on-tablet': isSmallOnTablet}"
    [text]="'Buy course'"
    [role]="role"
    (clicked)="onBuyCourse()"
  >
    <ng-container right-side>
      <div class="buy-club-button__price-block">
        <div class="buy-club-button__price-icon" [ngClass]="{'small-icon-on-tablet': isSmallOnTablet}">
          <img
            src="assets/ui/images/svg/clam.svg"
            width="48"
            height="48"
            alt="clam"
          />
        </div>
      </div>
    </ng-container>
  </app-button>
</ng-template>
