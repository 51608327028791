import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { concatMap, finalize, startWith, tap } from 'rxjs/operators';
import {
  MAX_ITEMS_PER_PAGE,
  CardGAEvents,
  CarouselGAEvents,
  DEFAULT_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
} from '@kitch/data-access/constants';
import {
  AppPagesItem,
  SocketEventMessage,
  SocketEventType,
  Stream,
  StreamStatus,
} from '@kitch/data-access/models';
import { StreamsSearchParams } from '@kitch/data-access/models/search-params';
import { ChatService, ProfilesService, StreamsService, TokenService } from '@kitch/data-access/services';
import { META_DESCRIPTION_ALL_STREAMS } from '@kitch/util';
import { SeoService } from '@kitch/ui/services';
import { FiltersButtonService } from '@kitch/user/shared/components/filters-button/filters-button.service';
import { AppliedFilters, StreamsSortBy } from '@kitch/user/shared/models';

@UntilDestroy()
@Component({
  selector: 'app-all-streams',
  templateUrl: './all-streams.component.html',
  styleUrls: ['./all-streams.component.scss'],
  providers: [FiltersButtonService],
})
export class AllStreamsComponent implements OnInit, OnDestroy {
  isLoading = false;
  isNextStreamsPageLoading = false;
  streamId: string;

  allStreams: Stream[];
  cardGAEvents = CardGAEvents;

  profileId: string;

  titleForSpecificChef: string;
  pageTitle: string;

  total: number;
  totalPage: number;

  private readonly pastStreamsParams: StreamsSearchParams = {
    page: DEFAULT_PAGE,
    itemsPerPage: MAX_ITEMS_PER_PAGE,
    status: StreamStatus.PAST,
  };

  private readonly allStreamsParams$ = new BehaviorSubject<StreamsSearchParams>(
    {
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      page: 1,
      orderDirection: 'desc',
      orderField: 'stoppedAt',
    },
  );

  private readonly updateAllStreamPageList$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private streamsService: StreamsService,
    private chatService: ChatService,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private profilesService: ProfilesService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.profileId = this.route.snapshot.data.channelId;
    this.initLoadStreams();
  }

  ngOnDestroy(): void {
    this.chatService.disconnect();
    this.seoService.setTagsToDefault();
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CarouselGAEvents.seeAllPast, { profile_id: this.tokenService.getProfileId() });
  }

  onPageChange(): void {
    if (this.allStreamsParams.page < this.totalPage) {
      const page = this.allStreamsParams.page + 1;

      this.isNextStreamsPageLoading = true;
      this.updateAllStreamsParams({ page });
    }
  }

  identify(index: number, stream: Stream): string {
    return stream.id;
  }

  appliedFilters(filters: AppliedFilters): void {
    const { cuisinesIds, sortBy, tagIds } = filters;
    const streamCuisineIds = cuisinesIds.join(',');
    const orderField = this.getOrderField(sortBy as StreamsSortBy);

    this.updateAllStreamsParams({
      streamCuisineIds,
      tagIds: tagIds.join(','),
      orderField,
      orderDirection: sortBy === 'titleAsc' ? 'asc' : 'desc',
      page: 1,
    });
  }

  private get allStreamsParams(): StreamsSearchParams {
    return this.allStreamsParams$.value;
  }

  private getMainInfo(): Observable<AppPagesItem<Stream>> {
    return combineLatest([
      this.allStreamsParams$,
      this.updateAllStreamPageList$.pipe(startWith(undefined as void)),
    ]).pipe(
      tap(() => {
        if (!this.isNextStreamsPageLoading) {
          this.isLoading = true;
        }
      }),
      concatMap(() => this.getStreamInfo(this.allStreamsParams)),
      tap((allStreams: AppPagesItem<Stream>) => {
        this.total = allStreams.total;
        this.totalPage = allStreams.pageCount;
        if (this.allStreamsParams.page > 1) {
          this.allStreams = [...this.allStreams, ...allStreams.results];
        } else {
          this.allStreams = allStreams.results;
        }
      }),
      tap(() => {
        this.isNextStreamsPageLoading = false;
        this.setPageTitle();
        this.isLoading = false;
        this.updateSeoTags();
      }),
    );
  }

  private getStreamInfo(params: StreamsSearchParams): Observable<AppPagesItem<Stream>> {
    return this.streamsService.getAllStreams(params);
  }

  private subscribeToSocketEvents(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.chatService.messages$
        .pipe(untilDestroyed(this))
        .subscribe((massage: SocketEventMessage) => {
          this.handleSocketMessage(massage);
        });
    }
  }

  private handleSocketMessage(massage: SocketEventMessage): void {
    switch (massage.type) {
      case SocketEventType.UPDATE_ALL_STREAMS_PAGE_LIST:
        this.updateAllStreamPageList$.next();
        break;
      default:
        break;
    }
  }

  private initLoadStreams(): void {
    if (this.profileId) {
      this.loadPastStreams();
    } else {
      this.loadAllStreams();
      this.tagPageView();
    }
  }

  private loadAllStreams(): void {
    this.getMainInfo().pipe(untilDestroyed(this)).subscribe();
    this.subscribeToSocketEvents();
  }

  private loadPastStreams(): void {
    this.isLoading = true;
    this.streamsService.getAll({ ...this.pastStreamsParams, profileId: this.profileId }).pipe(
      tap((stream) => {
        this.allStreams = stream.results;
        this.titleForSpecificChef = `${stream.results[0]?.channel?.name}'s library`;
      }),
      concatMap(() => this.profilesService.getUserProfileFullDetail(this.profileId)),
      tap(res => this.titleForSpecificChef = `${res.profile.channel.name}'s library`),
      finalize(() => {
        this.setPageTitle();
        this.isLoading = false;
        this.updateSeoTags();
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  private tagPageView(): void {
    this.$gaService.pageView(
      '/all-streams',
      'All streams',
      undefined,
      { profile_id: this.tokenService.getProfileId() },
    );
  }

  private updateAllStreamsParams(params: StreamsSearchParams): void {
    const allParams = {
      ...this.allStreamsParams,
      ...params,
    };

    this.allStreamsParams$.next(allParams);
  }

  private getOrderField(sortBy: StreamsSortBy): string {
    switch (sortBy) {
      case 'titleAsc':
      case 'titleDesc':
        return 'title';
      default:
        return sortBy;
    }
  }

  private setPageTitle(): void {
    this.pageTitle = this.profileId ? this.titleForSpecificChef : 'All streams';
  }

  private updateSeoTags() {
    this.seoService.updateTags({
      title: this.pageTitle,
      description: META_DESCRIPTION_ALL_STREAMS,
    });
  }
}
