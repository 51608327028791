import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CardGAEvents, GA_CATEGORY_UPCOMING_EVENT, GA_EVENT_ADD_TO_CALENDAR_CLICK } from '@kitch/data-access/constants';
import { Stream, StreamStatus } from '@kitch/data-access/models';
import {
  getAllCollaborators,
  getEarthWeekChef,
  getFireChef,
  getIsPaymentRequire,
  getMushroomMonthChef,
  TokenService,
} from '@kitch/data-access/services';
import { getSecondsFromDate } from '@kitch/util';

@Component({
  selector: 'app-main-stage-stream',
  templateUrl: './main-stage-stream.component.html',
  styleUrls: ['./main-stage-stream.component.scss'],
})
export class MainStageStreamComponent implements OnInit {
  addToCalendarUrl: string;
  streamDuration: number;
  @Input() isActiveSlider = false;
  @Input() stream: Stream;
  @Input() useBgColorAvatar = true;
  @Input() isOneItem = false;
  @HostBinding('class.carousel-cell') private carouselCell = false;

  streamStatus = StreamStatus;
  isScheduledStream = false;

  hasMushroomMonthChef = false;
  hasEarthWeekChef = false;
  hasFireChef = false;
  streamBadge: string;
  profileId: string;

  isUnpaidClub: boolean;
  isPaymentRequire: boolean;
  isPaidIndicatorShown: boolean;

  constructor(protected $gaService: GoogleAnalyticsService, private tokenService: TokenService) {}

  ngOnInit(): void {
    this.profileId = this.tokenService.getProfileId();
    this.carouselCell = this.isActiveSlider;
    this.isScheduledStream =
      this.stream?.status === StreamStatus.SCHEDULED || this.stream?.status === StreamStatus.PREPMODE;
    if (this.stream?.collaborators?.length) {
      this.streamBadge = this.stream?.collaborators.map(collab => collab.badgeImageUrl)[0];
      this.stream.collaborators = getAllCollaborators(this.stream.channel.chefProfile, this.stream.collaborators);
      this.hasMushroomMonthChef = getMushroomMonthChef(this.stream);
      this.hasEarthWeekChef = getEarthWeekChef(this.stream);
      this.hasFireChef = getFireChef(this.stream);
    }
    this.streamDuration =
      getSecondsFromDate(new Date(this.stream?.stoppedAt)) - getSecondsFromDate(new Date(this.stream?.startedAt));

    this.isPaymentRequire = getIsPaymentRequire(
      this.stream,
      this.profileId,
      this.tokenService.isAdmin());
    this.isUnpaidClub = !this.stream.subscribedToClub && !!this.stream.requiredClubSubscriptionPlan;
    this.isPaidIndicatorShown = this.stream.paid && !this.stream.subscribed && !this.stream.subscribedToClub
      || this.isUnpaidClub;
  }

  addToCalendar(): void {
    this.$gaService.event(GA_EVENT_ADD_TO_CALENDAR_CLICK, GA_CATEGORY_UPCOMING_EVENT, '');
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.mainStageStream, {
      profile_id: this.profileId,
      name: this.stream?.title,
    });
  }
}
