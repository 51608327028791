import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { VideoTimestamp } from '@kitch/ui/models/video-timestamp.form';
import { StreamReplayService } from '@kitch/user/core/stream-replay.service';

@UntilDestroy()
@Component({
  selector: 'app-video-chapters',
  templateUrl: './video-chapters.component.html',
  styleUrls: ['./video-chapters.component.scss'],
})
export class VideoChaptersComponent implements OnInit, OnDestroy {
  @Input() timestamps: VideoTimestamp[];

  videoDuration: number;

  constructor(private streamReplayService: StreamReplayService) {}

  ngOnInit() {
    this.timestamps.sort((a: VideoTimestamp, b: VideoTimestamp) => +a.streamSecond - +b.streamSecond);
    this.streamReplayService.videoDuration$
      .pipe(
        tap((minutes) => this.videoDuration = minutes),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  onShowVideoChapter(minutes: number): void {
    this.streamReplayService.currentVideoTimestamp = minutes * 60;
  }
}
