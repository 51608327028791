import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CardGAEvents } from '@kitch/data-access/constants';
import { StagedChannel, Stream, StreamChefProfile } from '@kitch/data-access/models';
import {
  getEarthWeekChef,
  getFireChef,
  getMushroomMonthChef,
  getIsPaymentRequire,
  TokenService,
  getAllCollaborators,
} from '@kitch/data-access/services';

@Component({
  selector: 'app-stream-schedule-card',
  templateUrl: './stream-schedule-card.component.html',
  styleUrls: ['./stream-schedule-card.component.scss'],
})
export class StreamScheduleCardComponent implements OnInit {
  hasMushroomMonthChef = false;
  hasEarthWeekChef = false;
  hasFireChef = false;

  streamBadge: string;
  chefProfile: StreamChefProfile | StagedChannel;
  profileId: string;

  isPaid: boolean;
  isUnpaidClub: boolean;
  isPaymentRequire: boolean;
  isPaidIndicatorShown: boolean;

  @Input() isActiveSlider = false;
  @Input() isVisibleIndicator = false;
  @Input() stream: Stream;
  @HostBinding('class.carousel-cell') private carouselCell = false;

  constructor(
    protected $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
    this.profileId = this.tokenService.getProfileId();
    this.chefProfile = this.stream?.profile || this.stream?.channel.chefProfile;
    if (this.stream?.collaborators?.length) {
      this.streamBadge = this.stream?.collaborators.map(collab => collab.badgeImageUrl)[0];

      if (!this.stream?.collaborators.includes(this.chefProfile)) {
        this.stream.collaborators = getAllCollaborators(this.chefProfile, this.stream.collaborators);
      }
      this.hasMushroomMonthChef = getMushroomMonthChef(this.stream);
      this.hasEarthWeekChef = getEarthWeekChef(this.stream);
      this.hasFireChef = getFireChef(this.stream);
    }

    this.isPaid = this.stream?.paid;
    this.isPaymentRequire = getIsPaymentRequire(
      this.stream,
      this.profileId,
      this.tokenService.isAdmin());
    this.isUnpaidClub = !this.stream.subscribedToClub && !!this.stream.requiredClubSubscriptionPlan;
    this.isPaidIndicatorShown = this.stream.paid && !this.stream.subscribed && !this.stream.subscribedToClub
      || this.isUnpaidClub;
  }

  goToLink(): void {
    const streamLink = `/${this.chefProfile.chefSlug}/streams/${this.stream.slug}`;

    this.router.navigateByUrl(streamLink).then(() => this.sendGAEvent());
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.upcoming, {
      profile_id: this.profileId,
      name: this.stream.title,
    });
  }
}
