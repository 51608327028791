import { MyRecipe, Recipe, Stream, StreamStatus } from '@kitch/data-access/models';
import { Days } from '@kitch/data-access/services';
import { removeHTMLTags } from '@kitch/util/string.tool';
import { TimezonePipe } from '@kitch/ui/pipes';

export const META_DESCRIPTION_COMING_UP =
  'Hop on live to watch how to make great dishes on Kittch! ' +
  'Ask questions and learn new techniques from the comfort of your kitchen.';

export const META_DESCRIPTION_RECIPES_CHANNELS =
  'Learn new recipes and new techniques from chefs like Joe Sasto, ' +
  'Joy The Baker, and Matthew Mancuso from  the comfort of your kitchen.';

export const META_DESCRIPTION_ALL_STREAMS =
  'Watch great chefs like Joe Sasto, Joy The Baker, and Marcus Samuelsson show you how to make ' +
  'great dishes on Kittch! Learn new recipes and new techniques from the comfort of your kitchen.';

export const getStreamMetaDescription = (stream: Stream): string => {
  const { status, title } = stream;
  const chefName = stream.channel.chefProfile.chefName;
  const description = removeHTMLTags(stream.description);

  if (status === StreamStatus.PAST) {
    return `Watch ${chefName}’s ${title} on Kittch. ${description}`;
  }

  const date = new TimezonePipe().transform(stream.scheduledVideo.date);
  const day = Days[(new Date(stream.scheduledVideo.date)).getDay().toString()];

  return `Watch ${chefName}’s ${title} LIVE on ${day} ${date} on Kittch! ${description}`;
};

export const getRecipeMetaDescription = (recipe: MyRecipe | Recipe, ingredients: string[]): string => {
  return `Try ${
    recipe.profile.chefName
  }’s ${
    recipe.recipeName
  }. ${
    removeHTMLTags(recipe.description)
  }. Ingredients: ${
    removeHTMLTags(ingredients.join())
  }`;
};
