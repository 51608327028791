import { Component, Input, OnInit } from '@angular/core';
import { Stream } from '@kitch/data-access/models';

@Component({
  selector: 'app-buy-stream-button',
  templateUrl: './buy-stream-button.component.html',
  styleUrls: ['./buy-stream-button.component.scss'],
})
export class BuyStreamButtonComponent implements OnInit {
  @Input() stream: Stream;

  buttonTitle: string;

  ngOnInit() {
    this.updateButtonTitle();
  }

  updateButtonTitle(): void {
    if (this.stream.isSeries) {
      this.buttonTitle = 'BUY SERIES';
    } else {
      this.buttonTitle = 'BUY STREAM';
    }
  }
}
