<div class="filters">
  <app-filters-button class="filters-btn"></app-filters-button>

  <!-- CHIPS FILTERS >> -->
  <app-button
    *ngFor="let filter of chipsFilters"
    [text]="filter.name"
    [role]="'secondary'"
    [activeClass]="filter.isApplied"
    [buttonClass]="'tag'"
    (click)="toggleChipsFilter(filter)"
  ></app-button>
  <!-- << CHIPS FILTERS -->

  <button
    *ngIf="filtersCount"
    class="clear-filters-btn"
    (click)="clearFilters()"
  >
    <img
      src="assets/ui/images/svg/close-black.svg"
      alt="cross"
      width="24"
      height="24"
    >
    <span>Clear all</span>
  </button>
</div>

<!-- FILTERS MODAL >> -->
<app-modal class="filters-modal" #filtersModal>
  <ng-container header>
    <h2 class="modal-title">Filters</h2>
  </ng-container>

  <ng-container body>
    <!-- CUISINES FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllCuisines,
        itemType: FilterSectionName.cuisines,
        items: cuisines,
        maxFiltersCount: maxCuisinesFiltersCount
      }"
    ></ng-container>
    <!-- << CUISINES FILTERS -->

    <!-- MEAL CATEGORY FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllTagsMealCategory,
        itemType: FilterSectionName.mealCategory,
        items: tagsMealCategory,
        maxFiltersCount: maxFilterCountLargeSection
      }"
    ></ng-container>
    <!-- << MEAL CATEGORY FILTERS -->

    <!-- DIET FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllTagsDiet,
        itemType: FilterSectionName.diet,
        items: tagsDiet,
        maxFiltersCount: maxFilterCountLargeSection
      }"
    ></ng-container>
    <!-- << DIET FILTERS -->

    <!-- LIFESTYLE FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllTagsLifestyle,
        itemType: FilterSectionName.lifestyle,
        items: tagsLifestyle,
        maxFiltersCount: maxFilterCountSmallSection
      }"
    ></ng-container>
    <!-- << LIFESTYLE FILTERS -->

    <!-- MEAL TYPE FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllTagsMealType,
        itemType: FilterSectionName.mealType,
        items: tagsMealType,
        maxFiltersCount: maxFilterCountSmallSection
      }"
    ></ng-container>
    <!-- << MEAL TYPE FILTERS -->

    <!-- SUB-CUISINES FILTERS >>-->
    <ng-container
      *ngTemplateOutlet="filtersGroup; context: {
        isShownAll: isShownAllSubCuisines,
        itemType: FilterSectionName.subCuisines,
        items: subCuisines,
        maxFiltersCount: maxSubCuisinesFiltersCount
      }"
    ></ng-container>
    <!-- << SUB-CUISINES FILTERS -->

    <!-- SORT BY >> -->
    <div class="filters-group">
      <h6 class="filters-group__title">sort by</h6>

      <div class="filters-group__filters filters-group__filters--sort-by">
        <ng-container *ngFor="let sortOption of sortOptions">
          <input
            type="radio"
            class="filter-radio-button__input"
            id="{{ sortOption.key }}"
            name="sortBy"
            [value]="sortOption.key"
            [(ngModel)]="sortBy"
          />
          <label
            class="filter-radio-button__label"
            for="{{ sortOption.key }}"
          >{{ sortOption.label }}</label>
        </ng-container>
      </div>
    </div>
    <!-- << SORT BY -->

    <!-- FILTER BUTTONS >> -->
    <div class="buttons-group">
      <app-button
        [text]="'Clear All'"
        [role]="'secondary'"
        [buttonClass]="'filters-modal'"
        (clicked)="clearFilters()"
      ></app-button>
      <app-button
        [text]="'Show Results'"
        [role]="'primary'"
        [buttonClass]="'filters-modal'"
        (clicked)="applyFilters()"
      ></app-button>
    </div>
    <!-- << FILTER BUTTONS -->

  </ng-container>
</app-modal>
<!-- << FILTERS MODAL -->

<!-- TEMPLATE FILTERS-GROUP >>-->
<ng-template
  #filtersGroup
  let-items="items"
  let-isShownAll="isShownAll"
  let-itemType="itemType"
  let-maxFiltersCount="maxFiltersCount">
  <section *ngIf="items.length" class="filters-group">
    <h6 class="filters-group__title">{{ itemType }}</h6>

    <div class="filters-group__filters">
      <ng-container *ngFor="let item of items; index as index">
        <div
          *ngIf="isShownAll || index < maxFiltersCount"
          class="filters-group__checkbox"
        >
          <label class="checkmark">
            <input
              type="checkbox"
              class="checkmark__input"
              [(ngModel)]="item.isSelected"
              (change)="selectFilter(item)"
            />
            <span class="checkmark__label"></span>
          </label>

          <span class="filter-checkbox__label">{{ item.name }}</span>
        </div>
      </ng-container>
    </div>

    <button
      *ngIf="items.length > maxFiltersCount"
      class="filters-group--show-more"
      (click)="toggleFiltersGroup(itemType)"
    >
      <ng-container
        *ngTemplateOutlet="showMore; context: { isShownAll: isShownAll, itemType: itemType }"
      ></ng-container>
    </button>
  </section>
</ng-template>
<!-- << TEMPLATE FILTERS-GROUP -->

<!-- TEMPLATE SHOW ALL BTN >> -->
<ng-template #showMore let-isShownAll="isShownAll" let-itemType="itemType">
  <ng-container *ngIf="!isShownAll">
    <img
      src="assets/ui/images/svg/plus-filters.svg"
      height="12"
      width="13"
      alt="plus"
    />
    <span>Show all {{ itemType }}</span>
  </ng-container>
  <ng-container *ngIf="isShownAll">
    <img
      src="assets/ui/images/svg/minus-filters.svg"
      height="2"
      width="13"
      alt="minus"
    />
    <span>Show fewer {{ itemType }}</span>
  </ng-container>
</ng-template>
<!-- << TEMPLATE SHOW ALL BTN -->
