<app-modal (closed)="setClosedModal()" #addCreditCardModal>
  <ng-container header>
    <h2 *ngIf="!primaryCreditCard" class="modal-title">
      Purchase {{ isClub ? 'COURSE' : 'stream' }}
    </h2>
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="!primaryCreditCard">
      <h3 class="card-form-title">Pay with credit card</h3>

      <app-credit-card-info
        [isFormSubmitting]="isModalSubmitting"
        (cardCreated)="creditCardCreatedHandler($event)"
      ></app-credit-card-info>
    </ng-container>
  </ng-container>
</app-modal>

