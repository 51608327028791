<div
  class="shop-card"
  [ngClass]="{
    'shop-card--tile': isTileCard,
    'shop-card--upcoming-stream': source === 'upcoming_stream_shop'
  }"
>
  <div class="shop-card__content-row">
    <img
      *ngIf="product.image"
      class="shop-card__image"
      data-testid="image"
      [src]="product.image"
      [alt]="product.label"
      height="58"
      width="58"
    >
    <div class="shop-card__wrapper">
      <h3 class="shop-card__title" data-testid="label">{{ product.label }}</h3>
      <p
        *ngIf="product.price"
        class="shop-card__price-text"
        data-testid="price"
      >${{ product.price }}</p>
    </div>
  </div>
  <div class="shop-card__buttons-wrapper">
    <button
      class="shop-card__view-details-button"
      data-testid="button"
      (click)="onViewDetails()"
    >
      View details
      <svg class="shop-card__button-icon">
        <use href="assets/ui/images/svg/sprites.svg#view-icon"></use>
      </svg>
    </button>

    <app-share-buttons
      *ngIf="isShareBtn; else favoriteButton"
      class="shop-card__button shop-card__button--share"
      [title]="product.label"
      [description]="''"
      [image]="''"
      [url]="product.url"
      [isProduct]="true"
      [role]="'secondary'"
    ></app-share-buttons>

    <ng-template #favoriteButton>
      <app-button
        class="shop-card__button shop-card__button--favorite"
        appNeedAuthorize
        [source]="'like recipe'"
        [text]="''"
        [iconUrl]="iconUrl"
        [role]="'secondary'"
        (clicked)="onFavoriteButtonClick()"
        [activeClass]="favoriteStatus"
        [buttonClass]="'liked'"
        [isDisabled]="isDisabled"
        [iconHeight]="29"
        [iconWidth]="20"
      ></app-button>
    </ng-template>
  </div>
  <a
    class="shop-card__link"
    data-testid="link"
    (click)="onViewDetails()"
  ></a>
</div>

<div *ngIf="!isTileCard" class="shop-card__separator"></div>
