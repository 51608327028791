import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StreamReplayService {
  private readonly currentVideoTimeSub: Subject<number> = new Subject<number>();
  private readonly currentVideoTimestampSub: Subject<number> = new Subject<number>();
  private readonly videoDurationSub: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get currentVideoTime$(): Observable<number> {
    return this.currentVideoTimeSub.asObservable();
  }

  get videoDuration$(): Observable<number> {
    return this.videoDurationSub.asObservable();
  }

  get currentVideoTimestamp$(): Observable<number> {
    return this.currentVideoTimestampSub.asObservable();
  }

  set currentVideoTimestamp(second: number) {
    this.currentVideoTimestampSub.next(second);
  }

  set currentVideoTime(second: number) {
    this.currentVideoTimeSub.next(second);
  }

  set videoDuration(minutes: number) {
    this.videoDurationSub.next(minutes);
  }
}
