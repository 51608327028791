import { Component } from '@angular/core';
import { CanonicalLinkService } from '@kitch/data-access/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  constructor(
    private readonly linkService: CanonicalLinkService,
  ) {
    linkService.startRouteListener();
  }
}
