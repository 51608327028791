<div *ngIf="stream" class="upcoming-stream__wrap">
  <div class="upcoming-stream__thumbnail">
    <div class="overlay"></div>

    <div class="upcoming-stream__container-date">
      <div class="upcoming-stream__date">
        {{ stream.scheduledVideo.date | timezone }}
      </div>
    </div>

    <div class="paid-indicator-container paid-indicator-container--card">
      <img
        *ngIf="isPaidIndicatorShown"
        class="paid-indicator"
        [src]="'assets/ui/images/svg/paid-indicator.svg'"
        alt="paid-indicator"
        width="24"
        height="24"
      >
    </div>

    <!-- STREAM BADGE >> -->
    <ng-container *ngIf="streamBadge">
      <div class="upcoming-stream__badge badge">
        <img
          [src]="streamBadge"
          height="50"
          width="107"
          alt="badge"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="!streamBadge">
      <ng-container *ngIf="hasMushroomMonthChef && stream.sponsored">
        <div class="upcoming-stream__badge mushroom-badge">
          <img src="assets/ui/images/png/mushroom-badge.png" alt="mushroom-month" />
        </div>
      </ng-container>
      <ng-container *ngIf="hasEarthWeekChef">
        <div class="upcoming-stream__badge earth-badge">
          <img
            src="assets/ui/images/svg/earth-week.svg"
            width="70"
            height="52"
            alt="earth-week"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="hasFireChef">
        <div class="upcoming-stream__badge fire-badge">
          <img
            src="assets/ui/images/svg/fire-badge.svg"
            width="198"
            height="168"
            alt="fire-badge"
          />
        </div>
      </ng-container>
    </ng-container>
    <!-- STREAM BADGE >> -->

    <picture class="carousel-img">
      <img
        class="avatar carousel-img"
        [ngSrc]="stream.thumbnailUrl"
        width="352"
        height="200"
        alt="{{ stream.title }}"
      />
    </picture>
  </div>

  <a
    class="upcoming-stream__link"
    [routerLink]="'/' + chefProfile.chefSlug + '/streams/' + stream.slug"
    (click)="sendGAEvent()"
  ></a>

  <div class="upcoming-stream__content">
    <div class="content-container">
      <app-access-indicator
        [isVideoPrivate]="stream.videoPrivate || stream.hidden"
        [isPurchased]="(stream.subscribed || stream.subscribedToClub) && !isVisibleIndicator"
      ></app-access-indicator>
      <div class="content__title">{{ stream.title }}</div>
      <div class="content__author">
        <a *ngIf="!stream.collaborators?.length" [routerLink]="'/' + chefProfile.chefSlug">
          <app-avatar
            [url]="chefProfile.photo"
            [size]="'scheduled'"
            [name]="chefProfile.chefName"
            [title]="stream.profile ? stream.profile.channel.name : stream.channel.name"
            [isLive]="chefProfile.isLive"
            [isClub]="!!stream.requiredClubSubscriptionPlan"
            [avatarInputTextColor]="'green'"
          ></app-avatar>
        </a>
        <app-multiple-avatars
          *ngIf="stream.collaborators?.length"
          [chefs]="stream.collaborators"
          [backgroundColor]="'green'"
        ></app-multiple-avatars>
      </div>
    </div>
    <div class="content__action-button">
      <app-learn-more-button
        *ngIf="isPaymentRequire && !stream.requiredClubSubscriptionPlan || isUnpaidClub; else addToCalendarButton"
        class="content__learn-more-button"
        (goToLink)="goToLink()"
      ></app-learn-more-button>

      <ng-template #addToCalendarButton>
        <app-add-to-calendar-button
          *ngIf="stream.subscribed || stream.subscribedToClub; else saveMySpotButton"
          [stream]="stream"
        ></app-add-to-calendar-button>
      </ng-template>

      <ng-template #saveMySpotButton>
        <app-save-my-spot-button [stream]="stream"></app-save-my-spot-button>
      </ng-template>
    </div>
  </div>
</div>
