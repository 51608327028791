import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  GA_EVENT_USER_FAVORITE_RECIPE_CLICK,
  GA_EVENT_USER_VIEW_RECIPE_CLICK,
} from '@kitch/data-access/constants';
import { TABS_TABLET_WIDTH } from '@kitch/data-access/constants/media-queries';
import { Recipe, ShortFavoriteRecipeInfo, SimpleResponse } from '@kitch/data-access/models';
import { FavoritesService, TokenService } from '@kitch/data-access/services';

interface DataGAEvent {
  device_type?: 'desktop' | 'mobile',
  profile_id: string;
  recipe_id: string;
  recipe_name: string;
  recipe_slug: string;
  recipe_source?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-shop-walmart-card',
  templateUrl: './shop-walmart-card.component.html',
  styleUrls: ['./shop-walmart-card.component.scss'],
})
export class ShopWalmartCardComponent implements OnInit, OnDestroy {
  @Input() walmartRecipe: Recipe;
  @Input() source?: 'upcoming_stream_shop';
  @Input() isDesktopView: boolean;

  private isTablet: boolean;
  iconUrl: string;
  walmartRecipeUrl: string;
  favoriteStatus: boolean;
  isDisabled: boolean;

  constructor(
    private tokenService: TokenService,
    private $gaService: GoogleAnalyticsService,
    private favoritesService: FavoritesService,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.walmartRecipeUrl = `/${this.walmartRecipe.profile.chefSlug}/recipes/${this.walmartRecipe.slug}`;
    this.favoriteStatus = this.walmartRecipe.favorited;

    this.responsive.observe([`(min-width: ${TABS_TABLET_WIDTH}px)`])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        this.isTablet = result.matches;
        this.getFavoriteButtonImg();
      });
  }

  ngOnDestroy() {}

  private get dataGAEvent(): DataGAEvent {
    const dataGAEvent: DataGAEvent = {
      recipe_id: this.walmartRecipe.id,
      recipe_name: this.walmartRecipe.recipeName,
      recipe_slug: this.walmartRecipe.slug,
      profile_id: this.tokenService.getProfileId(),
    };

    if (this.source) {
      dataGAEvent.recipe_source = this.source;
    }

    if (this.source === 'upcoming_stream_shop') {
      dataGAEvent.device_type = this.isDesktopView ? 'desktop' : 'mobile';
    }

    return dataGAEvent;
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', GA_EVENT_USER_VIEW_RECIPE_CLICK, this.dataGAEvent);
  }

  onFavoriteButtonClick(): void {
    this.favoriteStatus = !this.favoriteStatus;
    this.getFavoriteButtonImg();
    this.isDisabled = true;

    if (this.favoriteStatus) {
      this.$gaService.gtag('event', GA_EVENT_USER_FAVORITE_RECIPE_CLICK, this.dataGAEvent);
      this.toggleFavoriteRecipe(this.favoritesService.addToFavorites(this.walmartRecipe.id));
    } else {
      this.toggleFavoriteRecipe(this.favoritesService.deleteFromFavorites(this.walmartRecipe.id));
    }
  }

  private toggleFavoriteRecipe(source$: Observable<ShortFavoriteRecipeInfo | SimpleResponse>) {
    source$
      .pipe(
        untilDestroyed(this),
        finalize(() => this.isDisabled = false),
      )
      .subscribe();
  }

  private getFavoriteButtonImg(): void {
    const iconSrc = 'assets/ui/images/svg/';

    switch (true) {
      case this.favoriteStatus:
        this.iconUrl = `${iconSrc}bookmark--checked.svg`;
        break;

      case this.isTablet:
        this.iconUrl = `${iconSrc}white-bookmark.svg`;
        break;

      default:
        this.iconUrl = `${iconSrc}black-bookmark.svg`;
    }
  }
}
