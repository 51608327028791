import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseData } from '@kitch/data-access/models/purchase';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-thank-you-purchase-modal',
  templateUrl: './thank-you-purchase-modal.component.html',
  styleUrls: ['./thank-you-purchase-modal.component.scss'],
})
export class ThankYouPurchaseModalComponent {
  @ViewChild('thankYouPurchaseModal') thankYouPurchaseModal: ModalComponent;

  @Input() purchaseData: PurchaseData;
  @Input() isHardcodedSeries: boolean;
  @Input() isGift: boolean;
  @Input() isClub: boolean;

  myStuffLink = '/my-stuff/upcoming/';

  constructor(
    private router: Router,
  ) {}

  get isSeriesOrBundle(): boolean {
    return this.purchaseData?.isBundle && this.isHardcodedSeries || this.purchaseData?.isSeries;
  }

  get modalData(): { [key: string]: string } {
    if (this.isClub && !this.isGift) {
      return {
        title: 'Welcome!',
        src: this.purchaseData.clubData.clubPhoto,
      };
    }

    if (this.isClub && this.isGift) {
      return {
        title: 'thanks for your purchase',
        src: this.purchaseData.clubData.clubPhoto,
      };
    }

    if (this.isGift) {
      return {
        title: 'thanks for your purchase!',
        src: this.purchaseData.thumbnailUrl,
      };
    }

    return {
      title: 'Thank you for your purchase',
      src: 'assets/ui/images/png/thanks-for-purchase.png',
    };
  }

  startLearning(): void {
    this.router.navigate([`/${this.purchaseData.clubData.clubChefSlug}`])
      .then(() => this.closeModal());
  }

  closeModal(): void {
    this.openStreamPage(false);
  }

  openStream(): void {
    if (this.isSeriesOrBundle) {
      this.router.navigate([this.myStuffLink]).then();
    } else {
      this.openStreamPage(true);
    }
  }

  private openStreamPage(status: boolean): void {
    const streamPath = `/${this.purchaseData.chefProfile.chefSlug}/streams/${this.purchaseData.slug}`;
    const isCurrentPage = streamPath === window.location.pathname;

    if (isCurrentPage || !status) {
      location.reload();
    } else {
      this.router.navigate([streamPath]).then();
    }
  }
}
