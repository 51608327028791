import { Component } from '@angular/core';
import { FiltersButtonService } from '@kitch/user/shared/components/filters-button/filters-button.service';

@Component({
  selector: 'app-filters-button',
  templateUrl: './filters-button.component.html',
  styleUrls: ['./filters-button.component.scss'],
})
export class FiltersButtonComponent {
  get filtersCount(): number {
    return this.filtersButtonService.filtersCount;
  }

  constructor(
    private filtersButtonService: FiltersButtonService,
  ) {}

  emitClickedEvent(): void {
    this.filtersButtonService.emitButtonClicked();
  }
}
