import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { TypeformGAEvents } from '@kitch/data-access/constants';
import { Role } from '@kitch/data-access/models';
import { SettingsStatus } from '@kitch/data-access/models/settings';
import { SettingsService, TokenService } from '@kitch/data-access/services';
import { TypeformModalComponent } from '@kitch/ui/components/modals';
import { ModalService } from '@kitch/user/core/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isAdmin: boolean;
  isChef: boolean;
  settingsStatus$: Observable<SettingsStatus>;

  constructor(
    private tokenService: TokenService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private $gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.tokenService.isAdmin();
    this.isChef = this.tokenService.getRole() === Role.CHEF;
    this.settingsStatus$ = this.settingsService.settingStatus$;
  }

  showTypeformModal(): void {
    this.modalService.open(TypeformModalComponent);
    this.$gaService.gtag('event', TypeformGAEvents.OPEN_GET_STARTED_UCPMODAL, {
      profile_id: this.tokenService.getProfileId(),
    });
  }
}
