<div class="stream-info" *ngIf="stream">
  <div class="stream-page">
    <div style="display: flex; justify-content: space-between">
      <div class="stream-page__channel-info"
           [class.stream-page__channel-info--scheduled]="stream.status === streamStatuses.SCHEDULED"
           [class.stream-page__channel-info--live]="stream.status === streamStatuses.LIVE"
           [class.stream-page__channel-info--past]="stream.status === streamStatuses.PAST"
      >

        <app-access-indicator
          [isVideoPrivate]="(stream.videoPrivate || stream.hidden) && stream.status !== streamStatuses.SCHEDULED"
          [isPurchased]="stream.subscribed && stream.status !== streamStatuses.SCHEDULED"
        ></app-access-indicator>

        <!--  TITLE, AVATARS, DESCRIPTION MOBILE, BUTTONS  >> -->
        <div
          class="stream-page__stream-info"
          [class.stream-page__stream-info--past]="stream.status === streamStatuses.PAST"
        >
          <div *ngIf="isProfileShown" class="stream-page__profile">
            <div
              class="profile__stream-info"
              [ngClass]="{ 'profile__stream-info--collaborators': stream.collaborators?.length }"
            >
              <h1 class="profile__title">{{stream.title}}</h1>

              <!--  AVATARS >> -->
              <div class="profile__avatar">
                <a
                  class="profile__avatar-link"
                  target="_blank"
                  *ngIf="!stream.collaborators?.length"
                  [routerLink]="'/' + stream.channel.chefProfile.chefSlug"
                >
                  <app-avatar
                    [url]="stream.channel.chefProfile.photo"
                    [size]="'small'"
                    [name]="stream.channel.chefProfile.chefName"
                    [title]="stream.channel.name"
                    [isLive]="stream.channel.chefProfile.isLive"
                    [isClub]="!!stream.requiredClubSubscriptionPlan"
                    [avatarInputTextColor]="'green'"
                  ></app-avatar>
                </a>
                <app-multiple-avatars
                  *ngIf="stream.collaborators?.length"
                  [chefs]="stream.collaborators"
                ></app-multiple-avatars>
              </div>
              <!-- << AVATARS -->
            </div>
          </div>

          <!-- DESCRIPTION -->
          <ng-container *ngIf="stream.status !== streamStatuses.SCHEDULED && !isDesktopView">
            <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
          </ng-container>
          <!-- DESCRIPTION -->

          <div
            *ngIf="!isChefStreamOwner || isStreamOwnerContentPresent"
            class="separator separator-bottom"
          ></div>

          <!-- SHARE_BTN_OWNER_STREAM -->
          <div *ngIf="isStreamOwnerContentPresent" class="action-buttons">
            <app-share-buttons
              data-testid="stream-owner-share-btn"
              [buttonTitle]="'share stream link'"
              [title]="stream.title"
              [description]="stream.description"
              [image]="stream.thumbnailUrl"
              [role]="'secondary-big'"
            ></app-share-buttons>
          </div>
          <!-- SHARE_BTN_OWNER_STREAM -->

          <!-- ACTION_BUTTONS_USERS -->
          <div
            *ngIf="!isChefStreamOwner"
            class="action-buttons"
            [class.action-buttons--scheduled]="stream.status === streamStatuses.SCHEDULED"
            [class.action-buttons--live]="stream.status === streamStatuses.LIVE"
            [class.action-buttons--past]="stream.status === streamStatuses.PAST"
          >
            <div class="favorite-follow">
              <!-- LIKE_BUTTON_USERS -->
              <app-button
                appNeedAuthorize
                data-testid="like-btn"
                [source]="'like stream'"
                [text]="''"
                [iconUrl]="iconUrl"
                [iconWidth]="20"
                [iconHeight]="29"
                [role]="'secondary'"
                (click)="toggleLikeUnlike()"
                [activeClass]="favoriteStatus"
                [buttonClass]="'liked'"
              ></app-button>
              <!-- LIKE_BUTTON_USERS -->

              <!-- SHARE_BUTTON_USERS -->
              <app-share-buttons
                data-testid="share-btn"
                [title]="stream.title"
                [description]="stream.description"
                [image]="stream.thumbnailUrl"
                [role]="'secondary'"
              ></app-share-buttons>
              <!-- SHARE_BUTTON_USERS -->

              <!-- FOLLOW_BUTTON_USERS -->
              <app-button
                appNeedAuthorize
                data-testid="follow-btn"
                class="follow"
                [source]="'follow'"
                [text]="isFollowing ? 'Following' : 'Follow'"
                [type]="'button'"
                [role]="'secondary'"
                [iconHeight]="16"
                [iconWidth]="16"
                (clicked)="toggleFollowUnfollow(stream.channel.chefProfile.id)"
                [iconUrl]="isFollowing ? 'assets/ui/images/svg/check-mark.svg' : 'assets/ui/images/svg/plus-channel.svg'"
                [buttonClass]="'favorite'"
                [activeClass]="isFollowing"
              ></app-button>
              <!-- FOLLOW_BUTTON_USERS -->
            </div>

            <div
              class="join-tip"
              [class.join-tip--live]="stream.status === streamStatuses.LIVE"
            >
              <!-- JOIN_CLUB_BUTTON_USERS -->
              <app-button
                class="join-club"
                [text]="stream.channel.subscribed ? 'Club Member' : 'Join club'"
                [iconHeight]="16"
                [iconWidth]="16"
                [iconUrl]="stream.channel.subscribed ? 'assets/ui/images/svg/club-member.svg' : 'assets/ui/images/svg/black-star.svg'"
                [role]="'secondary'"
                [isDisabled]="true"
                (clicked)="onClubBtnClick(false)"
                [activeClass]="stream.channel.subscribed"
                [buttonClass]="'join-club'"
              ></app-button>
              <!-- JOIN_CLUB_BUTTON_USERS -->

              <!-- TIP_BUTTON_USERS -->
              <div
                *ngIf="stream.status === streamStatuses.SCHEDULED || stream.status === streamStatuses.PAST"
                class="action-buttons__tip"
              >
                <app-tips-buttons
                  #tipsButtons
                  data-testid="tips-btn"
                  [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
                ></app-tips-buttons>
                <app-tips-success-message
                  @opacityAnimation
                  *ngIf="isTipsSuccessMessageShown"
                  [tipsChefCount]="tipsChefCount"
                ></app-tips-success-message>
              </div>
              <!-- TIP_BUTTON_USERS -->
            </div>
          </div>
          <!-- ACTION_BUTTONS_USERS -->
        </div>
        <!-- << TITLE, AVATARS, DESCRIPTION MOBILE, BUTTONS -->

        <!-- DESCRIPTION DESKTOP -->
        <ng-container *ngIf="isDesktopView || stream.status === streamStatuses.SCHEDULED">
          <div
            class="description-desktop"
            [class.description-desktop--scheduled]="stream.status === streamStatuses.SCHEDULED"
            [class.description-desktop--live]="stream.status === streamStatuses.LIVE"
            [class.description-desktop--past]="stream.status === streamStatuses.PAST"
          >
            <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
          </div>
        </ng-container>
        <!-- DESCRIPTION DESKTOP -->
      </div>
    </div>

    <ng-container *ngIf="stream.buttons?.length || walmartRecipeId">
      <div class="separator separator-mobile"></div>

      <div class="stream-page__container">
        <div class="action-buttons action-buttons--custom-buttons">
          <a
            class="custom-button-link"
            [ngClass]="{'custom-button-link': !button.isSocial}"
            *ngFor="let button of stream.buttons"
            href="{{ button.url }}"
            target="_blank"
            rel="noopener"
          >
            <app-button
              [buttonClass]="button.isSocial ? '' : 'custom-button'"
              [text]="button.isSocial ? '' : button.label"
              [iconUrl]="button.isSocial ? button.label : ''"
              [role]="'secondary'"
              [ngClass]="{'social-button': button.isSocial}"
              (click)="trackCustomButtonClick(button)"
            ></app-button>
          </a>
          <app-walmart-button
            *ngIf="walmartRecipeId"
            [title]="'Shop Ingredients'"
            [walmartRecipeId]="walmartRecipeId"
            [walmartRecipePortions]="walmartRecipePortions"
            [pageTypeButton]="'STREAM'"
          ></app-walmart-button>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="pastStreamsInSeries?.length || upcomingStreamsInSeries?.length"
      class="stream-page__streams-series"
    >
      <!-- UPCOMING STREAMS IN SERIES >> -->
      <div
        *ngIf="upcomingStreamsInSeries?.length"
        class="stream-page__container stream-page__container--streams-slider"
      >
        <h2>UPCOMING STREAMS IN THE SERIES</h2>

        <div
          class="home-page__upcoming-streams"
          #upcomingStreamsContainer
        >
          <carousel
            [cellWidth]="widthCarouselCell"
            [containerWidth]="upcomingStreamsContainer.offsetWidth"
            [objectFit]="'cover'"
            [margin]="24"
            [dots]="true"
            [height]="heightCarouselCell"
            [arrows]="true"
          >
            <app-stream-schedule-card
              *ngFor="let stream of upcomingStreamsInSeries"
              [stream]="stream"
              [isActiveSlider]="true"
            ></app-stream-schedule-card>
          </carousel>
        </div>
      </div>
      <!-- << UPCOMING STREAMS IN SERIES -->

      <!-- PAST STREAMS IN SERIES >> -->
      <div
        class="stream-page__container stream-page__container--streams-slider"
        *ngIf="pastStreamsInSeries?.length"
      >
        <h2>PREVIOUS STREAMS IN THE SERIES</h2>

        <div
          class="home-page__upcoming-streams"
          #pastStreamsContainer
        >
          <carousel
            [cellWidth]="352"
            [containerWidth]="pastStreamsContainer.offsetWidth"
            [objectFit]="'cover'"
            [margin]="24"
            [dots]="true"
            [height]="351"
            [arrows]="true"
          >
            <app-stream-top-card
              *ngFor="let stream of pastStreamsInSeries"
              [stream]="stream"
              [isActiveSlider]="true"
            ></app-stream-top-card>
          </carousel>
        </div>
      </div>
      <!-- << PAST STREAMS IN SERIES -->
    </div>

    <div class="separator separator-top"></div>

    <ng-container *ngIf="isChatShown">
      <div
        class="stream-page__container"
        #askChef
      >
        <app-openweb-chat ngSkipHydration [chatId]="chatId"></app-openweb-chat>
      </div>
    </ng-container>
  </div>
</div>

<!-- DESCRIPTION TEMPLATE >> -->
<ng-template #descriptionTemplate>
  <div
    class="description-container"
    [ngClass]="{'description-no-display': !stream.description}"
    #descriptionContainer
  >
    <div class="description-inner" #descriptionInner>
      <div
        class="description"
        [innerHTML]="stream.description"
        #description
        [ngClass]="{'description-hide': !isFullDescription}"
        (click)="onStreamDescriptionClick($event)"
      ></div>
      <app-video-chapters
        *ngIf="isVideoChapters && isFullDescription"
        [timestamps]="stream.timestamps"
      ></app-video-chapters>
    </div>

    <div
      class="description__button-container"
      [ngClass]="{'description__button-hide': !stream.description || !isVideoChapters && description.scrollHeight < 25}"
      (click)="toggleDisplayDescription()"
    >
      <div class="description__button">
        <img
          *ngIf="!isDesktopView"
          [ngClass]="{'description__button-mobile-icon--full' : isFullDescription}"
          class="description__button-mobile-icon"
          src="assets/ui/images/svg/icon-chevron-down.svg"
          alt="switch-description"
        />
        <p>{{ isFullDescription ? 'Hide description' : 'Show full description'}}</p>
        <img
          *ngIf="isDesktopView"
          [src]="isFullDescription ? 'assets/ui/images/svg/hide-description.svg' : 'assets/ui/images/svg/show-description.svg'"
          class="description__button-desktop-icon"
          alt="switch-description"
          width="16"
          height="16"
        />
      </div>
    </div>
  </div>
</ng-template>
<!-- << DESCRIPTION TEMPLATE -->
