import { Injectable } from '@angular/core';
import { HardcodedSeriesEntry, MyRecipe, Recipe, Stream } from '@kitch/data-access/models';
import { ProfileChannel } from '@kitch/data-access/models/profile';
import { PurchaseData } from '@kitch/data-access/models/purchase';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  series: HardcodedSeriesEntry;
  purchaseClub: PurchaseData;

  setPurchaseClub(
    stream?: Stream,
    profile?: ProfileChannel,
    recipe?: MyRecipe | Recipe,
  ): void {
    if (stream) {
      this.purchaseClub = this.getDataStreamClub(stream);
    }
    if (profile) {
      this.purchaseClub = this.getDataChannelClub(profile);
    }
    if (recipe) {
      this.purchaseClub = this.getDataRecipeClub(recipe);
    }
  }

  getPurchaseClub(): PurchaseData {
    return this.purchaseClub;
  }

  getPurchaseSeries(stream: Stream, series: HardcodedSeriesEntry, seriesDummyVideo: Stream): PurchaseData {
    return {
      channelName: stream.channel.name,
      chefProfile: stream.channel.chefProfile,
      collaborators: stream.collaborators,
      description: stream.description,
      id: stream.id,
      photo: stream.channel.chefProfile.photo,
      requiredSubscriptionPlan: {
        id: series.dummyVideo.subscriptionPlan.id,
        price: series.dummyVideo.subscriptionPlan.price,
      },
      scheduledVideo: stream.scheduledVideo,
      slug: stream.slug,
      status: stream.status,
      thumbnailUrl: stream.thumbnailUrl,
      title: seriesDummyVideo.title,
      isBundle: stream.isBundle,
      isSeries: stream.isSeries,
    };
  }

  getPurchaseStream(stream: Stream): PurchaseData {
    return {
      channelName: stream.channel.name,
      chefProfile: stream.channel.chefProfile,
      collaborators: stream.collaborators,
      description: stream.description,
      id: stream.id,
      photo: stream.channel.chefProfile.photo,
      requiredSubscriptionPlan: stream.requiredSubscriptionPlan,
      scheduledVideo: stream.scheduledVideo,
      slug: stream.slug,
      status: stream.status,
      thumbnailUrl: stream.thumbnailUrl,
      title: stream.title,
      isBundle: stream.isBundle,
      isSeries: stream.isSeries,
    };
  }

  getPurchaseBundle(stream: Stream, bundle: HardcodedSeriesEntry, bundleDummyVideo: Stream): PurchaseData {
    return {
      channelName: stream.channel.name,
      chefProfile: stream.channel.chefProfile,
      collaborators: stream.collaborators,
      description: stream.description,
      id: stream.id,
      photo: stream.channel.chefProfile.photo,
      requiredSubscriptionPlan: {
        id: bundle?.dummyVideo?.subscriptionPlan.id,
        price: bundle?.dummyVideo?.subscriptionPlan.price,
      },
      scheduledVideo: stream.scheduledVideo,
      slug: stream.slug,
      status: stream.status,
      thumbnailUrl: stream.thumbnailUrl,
      title: bundleDummyVideo.title,
      isBundle: stream.isBundle,
      isSeries: stream.isSeries,
    };
  }

  private getDataStreamClub(stream: Stream): PurchaseData {
    return {
      channelName: stream.channel.name,
      chefProfile: stream.channel.chefProfile,
      bannerImage: stream.channel.bannerImage,
      requiredSubscriptionPlan: stream.requiredClubSubscriptionPlan,
      slug: stream.channel.chefProfile.chefSlug,
      status: stream.status,
      thumbnailUrl: stream.thumbnailUrl,
      title: stream.clubData.clubChefName,
      clubData: stream.clubData,
    };
  }

  private getDataRecipeClub(recipe: MyRecipe | Recipe): PurchaseData {
    return {
      channelName: recipe.profile.channel.name,
      bannerImage: recipe.profile.photo,
      chefProfile: {
        chefName: recipe.profile.chefName,
        photo: recipe.profile.photo,
      },
      requiredSubscriptionPlan: recipe.requiredClubSubscriptionPlan,
      slug: recipe.profile.chefSlug,
      title: recipe.clubData.clubChefName,
      clubData: recipe.clubData,
    };
  }

  private getDataChannelClub(profile: ProfileChannel): PurchaseData {
    return {
      channelName: profile.channel.name,
      clubData: {
        clubBannerImage: profile.channel.bannerImage,
        clubChefName: profile.chefName,
        clubChefSlug: profile.chefSlug,
        clubPhoto: profile.photo,
        clubTileImage: profile.channel.tileImage,
      },
      chefProfile: {
        chefName: profile.chefName,
        photo: profile.photo,
      },
      requiredSubscriptionPlan: profile.channel.requiredClubSubscriptionPlan,
      slug: profile.chefSlug,
      title: profile.fullName,
    };
  }
}
