<div class="payment-methods">
  <div class="sub-title-wrap">
    <h2 class="sub-title">Payment methods</h2>
    <button
      class="add-credit-card-btn"
      (click)="newCardModal.open()"
      data-testid="add-credit-card-btn"
    >
      <img
        src="assets/ui/images/svg/plus.svg"
        height="24"
        width="24"
        alt="add card"
      />
    </button>
  </div>

  <div class="credit-card" *ngFor="let card of cards">
    <app-credit-card
      [card]="card"
      [selectedCardId]="selectedCardId"
      [type]="'wallet'"
      (cardSelect)="onCardSelected($event)"
      (cardDelete)="onCardDeleted($event)"
    ></app-credit-card>
  </div>
</div>

<div class="payment-methods">
  <div class="sub-title-wrap">
    <h2 class="sub-title">Activity</h2>
  </div>
  <div class="separator separator--activity"></div>
  <div class="transaction" *ngFor="let transaction of transactions">
    <app-credit-card-transaction [transaction]="transaction"></app-credit-card-transaction>
  </div>
</div>


<app-modal #deleteCardModal>
  <ng-container header>
    <h2 class="modal-title">Delete payment method</h2>
  </ng-container>

  <ng-container body>
    <p class="modal-description">
      Are you sure you want to delete payment method {{ cardForDeletion?.brand?.toUpperCase() }} ending in {{ cardForDeletion?.last4 }}?
    </p>
    <div class="form-button-wrap">
      <div class="form-button form-button--cancel">
        <app-button
          [isDisabled]="isModalSubmitting"
          [text]="'Delete'"
          data-testid="modal-credit-card-delete-btn"
          (clicked)="deleteCreditCard()"
        ></app-button>
      </div>
      <div class="form-button form-button--cancel">
        <app-button
          [text]="'Cancel'"
          [role]="'secondary'"
          (clicked)="deleteCardModal.close()"
        ></app-button>
      </div>
    </div>
  </ng-container>
</app-modal>

<app-modal #newCardModal>
  <ng-container header>
    <h2 class="modal-title">Add new payment method</h2>
  </ng-container>

  <ng-container body>
    <p class="modal-description">
      Fill the form to add a new payment method
    </p>
    <div class="separator"></div>
    <app-credit-card-info
      *ngIf="newCardModal.isActive"
      [submitButtonTitle]="'Submit'"
      [isFormSubmitting]="isModalSubmitting"
      (cardCreated)="onCardCreated($event)"
    ></app-credit-card-info>
  </ng-container>
</app-modal>
