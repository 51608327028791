import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { tap } from 'rxjs/operators';
import { PurchaseGAEvents } from '@kitch/data-access/constants';
import { HardcodedSeriesEntry, Stream } from '@kitch/data-access/models';
import { StreamsService, TokenService } from '@kitch/data-access/services';
import { PurchaseModalComponent } from '../components/modals';

@UntilDestroy()
@Directive({
  selector: '[appShowPurchaseModal]',
})
export class ShowPurchaseModalDirective implements OnInit, OnDestroy {
  componentRef: ComponentRef<PurchaseModalComponent>;
  isFirstClick = true;
  isOwner: boolean;
  series: HardcodedSeriesEntry;

  @Input() stream?: Stream;
  @Input() isDisabled = true;
  @Input() isOpen: boolean;
  @Input() purchaseType?: 'club';

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private tokenService: TokenService,
    private streamsService: StreamsService,
    private $gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit() {
    this.isOwner = this.stream?.channel?.chefProfile.id === this.tokenService.getProfileId();

    if ((!this.isOwner && this.stream?.paid || this.purchaseType === 'club' && !this.isDisabled) &&
      (this.tokenService.isGuest() || !this.stream?.subscribed)) {
      this.interceptClickEvents();
    }
  }

  ngOnDestroy() {}

  private interceptClickEvents(): void {
    this.element.nativeElement.addEventListener('click', (event) => {
      this.streamsService.getHardcodedSeries()
        .pipe(
          untilDestroyed(this),
          tap(({ hardcodedSeries }) => {
            this.series = hardcodedSeries.find(series => (series.dummyVideo.id === this.stream?.id
            || series.videoIds.includes(this.stream?.id)));
          }))
        .subscribe(() => {
          this.initPurchaseModal();

          this.isFirstClick = false;
          const changes = {
            isOpen: new SimpleChange(this.isOpen, true, this.isFirstClick),
            stream: new SimpleChange(
              {},
              this.stream,
              this.isFirstClick,
            ),
            series: new SimpleChange(
              {},
              this.series,
              this.isFirstClick,
            ),
            purchaseType: new SimpleChange(
              {},
              this.purchaseType,
              true,
            ),
          };

          setTimeout(() => {
            this.componentRef.instance.ngOnChanges(changes);
          });
        });

      this.sendGaEvent();

      if (this.purchaseType !== 'club') {
        event.stopPropagation();
      }

      event.preventDefault();
    }, true);
  }

  private initPurchaseModal(): void {
    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(PurchaseModalComponent);

    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    document.body.appendChild(this.componentRef.location.nativeElement);
  }

  private sendGaEvent(): void {
    if (this.stream) {
      const action = this.purchaseType === 'club' ?
        PurchaseGAEvents.BUY_COURSE_BUTTON_CLICK :
        PurchaseGAEvents.BUY_STREAM_BUTTON_CLICK;

      this.$gaService.gtag('event', action, {
        profile_id: this.tokenService.getProfileId(),
        name: this.stream.title,
      });
    }
  }
}
