import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  GA_EVENT_USER_FAVORITE_PRODUCT_CLICK,
  GA_EVENT_VIEW_PRODUCT_CLICK,
} from '@kitch/data-access/constants';
import { TABS_TABLET_WIDTH } from '@kitch/data-access/constants/media-queries';
import { FavoriteProduct, ShortFavoriteProductsInfo } from '@kitch/data-access/models/favorite-products';
import { TokenService } from '@kitch/data-access/services';
import { FavoriteProductsService } from '@kitch/data-access/services/favorite-products.service';
import { Product } from '@kitch/ui/models';

interface DataGAEvent {
  device_type?: 'desktop' | 'mobile',
  product_label: string,
  product_source: ProductCardSource,
  product_url: string,
  profile_id: string
}
type ProductCardSource = 'channel_shop' | 'stream_shop' | 'my_stuff' | 'marketplace_shop' | 'upcoming_stream_shop';

@UntilDestroy()
@Component({
  selector: 'app-shop-product-card',
  templateUrl: './shop-product-card.component.html',
  styleUrls: ['./shop-product-card.component.scss'],
})
export class ShopProductCardComponent implements OnInit, OnDestroy {
  @Input() product: Product | FavoriteProduct;
  @Input() isTileCard = false;
  @Input() isShareBtn = false;
  @Input() source: ProductCardSource;
  @Input() isDesktopView: boolean;

  private isTablet: boolean;
  favoriteStatus: boolean;
  isDisabled: boolean;
  iconUrl: string;

  constructor(
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private responsive: BreakpointObserver,
    private favoriteProductsService: FavoriteProductsService,
  ) {}

  ngOnInit() {
    this.favoriteStatus = this.product.favorited;
    this.responsive.observe([`(min-width: ${TABS_TABLET_WIDTH}px)`])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        this.isTablet = result.matches;
        this.getFavoriteButtonImg();
      });
  }

  ngOnDestroy() {}

  onViewDetails(): void {
    this.$gaService.gtag('event', GA_EVENT_VIEW_PRODUCT_CLICK, this.dataGAEvent);
    window.open(this.product.url, '_blank');
  }

  onFavoriteButtonClick(): void {
    this.favoriteStatus = !this.favoriteStatus;
    this.getFavoriteButtonImg();
    this.isDisabled = true;

    if (this.favoriteStatus) {
      this.$gaService.gtag('event', GA_EVENT_USER_FAVORITE_PRODUCT_CLICK, this.dataGAEvent);
      this.toggleFavoriteProduct(this.favoriteProductsService.addToFavoriteProduct(this.product.id));
    } else {
      this.toggleFavoriteProduct(this.favoriteProductsService.deleteFromFavoriteProduct(this.product.id));
    }
  }

  private get dataGAEvent(): DataGAEvent {
    const dataGAEvent: DataGAEvent = {
      profile_id: this.tokenService.getProfileId(),
      product_label: this.product.label,
      product_source: this.source,
      product_url: this.product.url,
    };

    if (this.source === 'upcoming_stream_shop') {
      dataGAEvent.device_type = this.isDesktopView ? 'desktop' : 'mobile';
    }

    return dataGAEvent;
  }

  private toggleFavoriteProduct(source$: Observable<ShortFavoriteProductsInfo>) {
    source$
      .pipe(
        untilDestroyed(this),
        finalize(() => this.isDisabled = false),
      )
      .subscribe();
  }

  private getFavoriteButtonImg(): void {
    const iconSrc = 'assets/ui/images/svg/';

    switch (true) {
      case this.favoriteStatus:
        this.iconUrl = `${iconSrc}bookmark--checked.svg`;
        break;

      case (this.isTablet && this.source === 'stream_shop') || this.source === 'upcoming_stream_shop':
        this.iconUrl = `${iconSrc}white-bookmark.svg`;
        break;

      default:
        this.iconUrl = `${iconSrc}black-bookmark.svg`;
    }
  }
}
