<h4 class="card-title">Billing Information</h4>
<form [formGroup]="billingInfoForm">
  <div class="form-line">
    <div class="form-line__item">
      <label class="card-label card-label--number">
        <span>First Name</span>
        <input formControlName="firstName"
               placeholder="First Name"
               appWasInvolved
               class="form-input"
               data-testid="credit-card-info-first-name"
        >
        <app-errors-list formControlName="firstName"></app-errors-list>
      </label>
    </div>
    <div class="form-line__item">
      <label class="card-label card-label--number">
        <span>Last Name</span>
        <input formControlName="lastName"
               placeholder="Last Name"
               appWasInvolved
               class="form-input"
               data-testid="credit-card-info-last-name"
        >
        <app-errors-list formControlName="lastName"></app-errors-list>
      </label>
    </div>
  </div>

  <div class="form-line">
    <div class="form-line__item">
      <label class="card-label card-label--number">
        <span>Country</span>
        <input formControlName="country"
               placeholder="Country"
               appWasInvolved
               class="form-input"
               data-testid="credit-card-info-country"
        >
        <app-errors-list formControlName="country"></app-errors-list>
      </label>
    </div>
    <div class="form-line__item">
      <label class="card-label card-label--number">
        <span>Postal Code</span>
        <div
          id="{{cardZipcodeInputId}}"
          class="form-input"
          data-testid="credit-card-info-postal-code"
          [ngClass]="{ 'form-input--touched': isCardZipcodeTouched }"
        ></div>
        <div class="form-error__text form-error__text--required">This field is required</div>
        <div id="zip-code-error" class="form-error__text"></div>
        <div *ngIf="cardFormError?.code === 'incomplete_number'" class="form-error__text">
          {{ cardFormError?.massage }}
        </div>
      </label>
    </div>
  </div>
</form>

<h4 class="card-title">Credit Card Information</h4>
<div class="form-line">
  <div class="form-line__item">
    <label class="card-label card-label--number">
      <span>Credit Card Number</span>
      <div
        id="{{cardNumberInputId}}"
        class="form-input"
        [ngClass]="{ 'form-input--touched': isCardNumberTouched }"
        data-testid="credit-card-info-card-number"
      ></div>
      <div class="form-error__text form-error__text--required">This field is required</div>
      <div id="card-number-error" class="form-error__text"></div>
      <div *ngIf="cardFormError?.code === 'incomplete_number'" class="form-error__text">
        {{ cardFormError?.massage }}
      </div>
    </label>
  </div>

  <div class="form-line__item form-line__item--multiple">
    <label class="card-label card-label--date">
      <span>Expiration Date</span>
      <div
        id="{{cardExpiryInputId}}"
        class="form-input"
        [ngClass]="{ 'form-input--touched': isCardExpiryTouched }"
        data-testid="credit-card-info-card-expiration"
      ></div>
      <div class="form-error__text form-error__text--required">This field is required</div>
      <div id="card-date-error" class="form-error__text"></div>
      <div *ngIf="cardFormError?.code === 'incomplete_expiry'" class="form-error__text">
        {{ cardFormError?.massage }}
      </div>
    </label>
    <label class="card-label card-label--cvc">
      <span>CVV</span>
      <div
        id="{{cardCvcInputId}}"
        class="form-input"
        [ngClass]="{ 'form-input--touched': isCardCvcTouched }"
        data-testid="credit-card-info-card-cvc"
      ></div>
      <div class="form-error__text form-error__text--required">CVV is required</div>
      <div id="card-cvc-error" class="form-error__text"></div>
      <div *ngIf="cardFormError?.code === 'incomplete_cvc'" class="form-error__text">
        {{ cardFormError?.massage }}
      </div>
    </label>
  </div>
</div>

<div class="form-button">
  <app-button
    data-testid="credit-card-info-submit-btn"
    [text]="submitButtonTitle"
    [isDisabled]="!isCardInfoValid || isFormSubmitting"
    (clicked)="submit()"
  ></app-button>
</div>
