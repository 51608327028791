<div
  *ngIf="stream"
  class="main-stage"
  [class.main-stage--bg-scheduled]="isScheduledStream"
>
  <div class="main-stage__video-container">

    <!-- LEFT STREAM THUMBNAIL PICTURE >> -->
    <ng-container *ngIf="isLiveOrScheduled; else video">

      <a
        class="main-stage__thumbnail-link"
        [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug"
        (click)="sendGAEvent()"
      ></a>

      <div class="main-stage__thumbnail main-stage__thumbnail-one-item">
        <picture>
          <img
            class="stream-thumbnail"
            [ngSrc]="stream.thumbnailUrl"
            [priority]="true"
            alt="thumbnail"
            height="500"
            width="500"
          />
        </picture>
        <div class="overlay"></div>

        <!-- STREAM BADGE >> -->
        <ng-container *ngIf="streamBadge">
          <div class="main-stage__badge thumbnail-earth">
            <img
              [src]="streamBadge"
              height="69"
              width="69"
              alt="badge"
            />
          </div>
        </ng-container>

        <ng-container *ngIf="!streamBadge">
          <ng-container *ngIf="hasMushroomMonthChef && !stream.produced && stream.sponsored">
            <div class="main-stage__badge thumbnail-mushroom">
              <img src="assets/ui/images/png/mushroom-badge.png" alt="mushroom-month" />
            </div>
          </ng-container>
          <ng-container *ngIf="hasEarthWeekChef">
            <div class="main-stage__badge thumbnail-earth">
              <img
                src="assets/ui/images/svg/earth-week.svg"
                width="70"
                height="52"
                alt="earth-week"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="hasFireChef">
            <div class="main-stage__badge thumbnail-fire">
              <img
                src="assets/ui/images/svg/fire-badge.svg"
                width="198"
                height="168"
                alt="fire-badge"
              />
            </div>
          </ng-container>
        </ng-container>
        <!-- STREAM BADGE >> -->
      </div>
    </ng-container>
    <!-- << LEFT STREAM THUMBNAIL PICTURE -->

    <ng-template #video>
      <app-stream-video [stream]="stream" [isMainStage]="true"></app-stream-video>
    </ng-template>
  </div>

  <div class="main-stage__content">
    <div>
      <div class="main-stage__top-row">
        <div *ngIf="stream.status === streamStatus.LIVE" class="stream-status">
          <app-views-counter></app-views-counter>
        </div>
        <div *ngIf="isScheduledStream" class="date-container">
          <div class="date-container__date">
            {{ stream.scheduledVideo.date | timezone }}
          </div>
        </div>
        <div *ngIf="stream.status === streamStatus.PAST" class="stream-status">
          <div class="duration">{{ streamDuration | durationVideo }}</div>
        </div>
      </div>

      <div
        #descriptionWrapper
        class="main-stage__description"
        appAutoscalingText
        [textWrapper]="descriptionWrapper"
        [textElement]="descriptionLink"
        [textLength]="stream.title.length"
      >
        <a
          #descriptionLink
          class="main-stage__title-link"
          [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug"
          (click)="sendGAEvent()"
        >{{ stream.title }}</a>
      </div>
      <div class="main-stage__author">
        <a
          *ngIf="!stream.collaborators?.length"
          [routerLink]="'/' + stream.channel.chefProfile.chefSlug"
          class="main-stage__author-link"
        >
          <app-avatar
            [url]="stream.channel.chefProfile.photo"
            [size]="'extra-small'"
            [name]="stream.channel.chefProfile.chefName"
            [title]="stream.channel.name"
            [isLive]="stream.channel.chefProfile.isLive"
            [isClub]="!!stream.requiredClubSubscriptionPlan"
          ></app-avatar>
        </a>
        <app-multiple-avatars
          *ngIf="stream.collaborators?.length"
          [chefs]="stream.collaborators!"
          [backgroundColor]="
            stream.status === streamStatus.SCHEDULED || stream.status === streamStatus.PREPMODE ? 'green' : ''
          "
        ></app-multiple-avatars>
      </div>
    </div>
  </div>
</div>
