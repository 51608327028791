<section class="shop-tab">
  <app-shop-walmart-card
    *ngIf="walmartRecipe"
    [walmartRecipe]="walmartRecipe"
  ></app-shop-walmart-card>

  <app-shop-product-card
    *ngFor="let product of products;  trackBy: trackByFn"
    [product]="product"
    [source]="'stream_shop'"
  ></app-shop-product-card>
</section>
