import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit, PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Hls from 'hls.js';
import isbot from 'isbot';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { EMPTY, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { CardGAEvents, GA_EVENT_VIDEO_START_CLICK, GA_EVENT_VIDEO_STOP_CLICK } from '@kitch/data-access/constants';
import { SimpleResponse, Stream, WatchReplayUserInfo } from '@kitch/data-access/models';
import { AudienceService, LoggerService, TokenService } from '@kitch/data-access/services';
import { initVideoPlayer } from '@kitch/util';
import { StreamReplayService } from '@kitch/user/core/stream-replay.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

@UntilDestroy()
@Component({
  selector: 'app-stream-video',
  templateUrl: './stream-video.component.html',
  styleUrls: ['./stream-video.component.scss'],
})
export class StreamVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() stream: Stream;
  @Input() isMainStage = false;

  video: HTMLMediaElement;
  hls: Hls;
  videoUrl: string;
  profileId: string;
  displayName: string;
  photo: string;
  viewing = false;

  currentTime: number;
  startWatchedAt: number;

  private readonly viewingStatus$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userProfileService: UserProfileService,
    private tokenService: TokenService,
    private audienceService: AudienceService,
    private streamReplayService: StreamReplayService,
    private $gaService: GoogleAnalyticsService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private logger: LoggerService,
  ) {}

  ngOnInit(): void {
    this.profileId = this.tokenService.getProfileId();

    this.userProfileService.userProfile$
      .pipe(untilDestroyed(this))
      .subscribe((profile) => {
        this.displayName = profile.name;
        this.photo = profile.avatar;
      });

    if (!this.isMainStage) {
      this.subscribeToVideoStatus();
    }

    this.setVideoUrl();
    if (isPlatformBrowser(this.platformId)) {
      this.subscribeToWindowResize();
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.profileId && this.viewing && !this.isMainStage) {
      this.onStopWatching().subscribe();
    }
    if (this.video) {
      this.video.onloadedmetadata = null;
    }
  }

  ngAfterViewInit() {
    this.video = this.document.getElementById('video-player') as HTMLMediaElement;

    this.initHlsPlayer();
    this.streamReplayService.currentVideoTimestamp$
      .pipe(
        tap((seconds) => this.showVideoChapter(seconds)),
        untilDestroyed(this),
      )
      .subscribe();
    this.video.onloadedmetadata = () => this.streamReplayService.videoDuration = this.video.duration / 60;

    if (this.route.snapshot.queryParams.t) {
      this.video.currentTime = this.route.snapshot.queryParams.t;
    }
  }

  setViewingStatus(isViewing: boolean): void {
    this.viewingStatus$.next(isViewing);
  }

  setCurrentTime(event: Event): void {
    const videoPlayer = event.target as HTMLVideoElement;

    this.currentTime = videoPlayer.currentTime;
    this.streamReplayService.currentVideoTime = this.currentTime;
  }

  sendMainStageGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.mainStageStream, {
      profile_id: this.profileId,
      name: this.stream.title,
    });
  }

  private subscribeToVideoStatus(): void {
    this.viewingStatus$.pipe(
      debounceTime(1000),
      mergeMap((status) => {
        this.logger.info('#subscribeToVideoStatus current viewing status ', status);
        if (status && !this.viewing) {
          this.onStartPlay();

          return EMPTY;
        }
        if (!status && this.viewing) {
          return this.onStopWatching();
        }

        return EMPTY;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  private onStartPlay(): void {
    this.viewing = true;
    this.startWatchedAt = Date.now();
    this.$gaService.gtag('event', GA_EVENT_VIDEO_START_CLICK, {
      profile_id: this.tokenService.getProfileId(),
      video_id: this.stream.id,
      start_viewing_on_second: this.currentTime,
    });
  }

  private onStopWatching(): Observable<SimpleResponse> {
    this.viewing = false;
    this.$gaService.gtag('event', GA_EVENT_VIDEO_STOP_CLICK, {
      profile_id: this.tokenService.getProfileId(),
      video_id: this.stream.id,
      stop_viewing_on_second: this.currentTime,
    });
    const duration = Math.round((Date.now() - this.startWatchedAt) / 1000);
    const userInfo: WatchReplayUserInfo = {
      profileId: this.profileId,
      seconds: duration,
      videoId: this.stream.id,
      viewerName: this.displayName,
      viewerPhoto: this.photo,
    };

    return this.audienceService.sendViewedSeconds(userInfo);
  }

  private subscribeToWindowResize(): void {
    let isMobileView = this.isMobileView();

    fromEvent(window, 'resize').pipe(
      map(() => this.isMobileView()),
      distinctUntilChanged(),
      untilDestroyed(this),
    )
      .subscribe((isNewMobileView) => {
        if (isMobileView !== isNewMobileView) {
          isMobileView = isNewMobileView;
          this.setVideoUrl();
          const currentTime = this.currentTime;

          this.hls.destroy();
          this.hls.detachMedia();
          this.initHlsPlayer();
          const video: HTMLMediaElement = this.document.getElementById('video-player') as HTMLMediaElement;

          video.onloadedmetadata = (event) => {
            (event.target as HTMLMediaElement).currentTime = currentTime;
          };
        }
      });
  }

  private initHlsPlayer(): void {
    if (isPlatformBrowser(this.platformId) && !isbot(navigator.userAgent)) {
      this.ngZone.runOutsideAngular(() => {
        this.hls = initVideoPlayer('video-player', this.videoUrl, this.isMainStage) as Hls;
      });
    }
  }

  private setVideoUrl(): void {
    if (this.isMobileView()) {
      this.videoUrl = this.stream.videoUrlMobile || this.stream.videoUrl;
    } else {
      this.videoUrl = this.stream.videoUrl;
    }
  }

  private isMobileView(): boolean {
    return window.innerWidth < 600;
  }

  private showVideoChapter(seconds: number): void {
    const video: HTMLMediaElement = this.document.getElementById('video-player') as HTMLMediaElement;

    video.pause();
    video.currentTime = seconds;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      video.play().then();
    }, 10);

    this.setTimeParams(seconds);
  }

  setTimeParams(seconds: number): void {
    const timeParams = new URLSearchParams(window.location.search);

    timeParams.set('t', `${seconds}`);
    const url = `${window.location.pathname}?${timeParams.toString()}`;

    window.history.pushState({ path: url }, '', url);
  }
}
