<div class="success-message" [ngClass]="messageClass">
  <img
    class="success-message__icon"
    [src]="'assets/ui/images/svg/share-modal-icon.svg'"
    alt="clams"
    width="116"
    height="84"
  />
  <div class="success-message__wrapper">
    <h3 class="success-message__title">{{ messageTitle }}</h3>
    <p class="success-message__text">{{ messageText }}</p>
  </div>
  <button
    *ngIf="isChef"
    class="modal-close-btn success-message__modal-close-btn"
    (click)="onSuccessMessageClose()"
  >
    <div class="modal-close-btn__cross modal-close-btn__cross--left"></div>
    <div class="modal-close-btn__cross modal-close-btn__cross--right"></div>
  </button>
</div>


