import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { PipCameraPositions } from '@kitch/user/shared/models';

@Component({
  selector: 'app-pip-camera-area',
  templateUrl: './pip-camera-area.component.html',
  styleUrls: ['./pip-camera-area.component.scss'],
})
export class PipCameraAreaComponent implements OnInit, OnChanges {
  @Input() index: PipCameraPositions;

  @Input() showHelperMessage: boolean;

  @Input() isDndDisabled: boolean;

  @Input() isSwitchDisabled: boolean;

  @Output() cameraDragStarted: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

  @Output() cameraDropped: EventEmitter<PipCameraPositions> = new EventEmitter<PipCameraPositions>();

  @Output() cameraSwitched: EventEmitter<void> = new EventEmitter<void>();

  positions = PipCameraPositions;

  firstPipAreaList = [];
  secondPipAreaList = [];
  thirdPipAreaList = [];
  fourthPipAreaList = [];

  constructor() { }

  ngOnInit(): void {
    this.setCameraPosition(this.index);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setCameraPosition(changes.index?.currentValue);
    if (changes.showHelperMessage?.currentValue) {
      setTimeout(() => this.showHelperMessage = false, 5000);
    }
  }

  async dragStarted(source: CdkDragStart): Promise<void> {
    this.showHelperMessage = false;
    const player: HTMLElement = document.querySelector('.cdk-drag-preview div');

    this.cameraDragStarted.emit(player);
  }

  dropped(event: CdkDragDrop<any, any>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    const index = parseInt(event.container.id.replace(/^\D+/, ''), 10);

    this.cameraDropped.emit(index);
  }

  switchCamera(): void {
    this.cameraSwitched.emit();
  }

  private setCameraPosition(index: PipCameraPositions): void {
    switch (index) {
      case PipCameraPositions.TOP_LEFT:
        this.firstPipAreaList = ['secondCamera'];
        this.secondPipAreaList = [];
        this.thirdPipAreaList = [];
        this.fourthPipAreaList = [];
        break;
      case PipCameraPositions.TOP_RIGHT:
        this.firstPipAreaList = [];
        this.secondPipAreaList = ['secondCamera'];
        this.thirdPipAreaList = [];
        this.fourthPipAreaList = [];
        break;
      case PipCameraPositions.BOTTOM_LEFT:
        this.firstPipAreaList = [];
        this.secondPipAreaList = [];
        this.thirdPipAreaList = ['secondCamera'];
        this.fourthPipAreaList = [];
        break;
      case PipCameraPositions.BOTTOM_RIGHT:
        this.firstPipAreaList = [];
        this.secondPipAreaList = [];
        this.thirdPipAreaList = [];
        this.fourthPipAreaList = ['secondCamera'];
        break;
    }
  }
}
