import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, tap } from 'rxjs/operators';
import {
  GA_EVENT_JOIN_CHEF_TABLE_CLICK,
  GA_EVENT_USER_RAISE_HAND_CLICK,
  GA_EVENT_USER_SEND_EMOJI_CLICK,
  RtmMassageType,
} from '@kitch/data-access/constants';
import { ACTION_AFTER_LOGIN_KEY, RtmMessage } from '@kitch/data-access/models';
import {
  AudienceService,
  ChefTableService, LoggerService,
  ProfilesService,
  StreamsService,
  TipsService,
  TokenService,
} from '@kitch/data-access/services';
import { SelectDevicesModalComponent } from '@kitch/ui/components/modals';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { MessageInvitedUsersModalComponent } from '@kitch/user/shared/components/modals';
import { TipsButtonsComponent } from '@kitch/user/shared/components/tips-buttons/tips-buttons.component';
import { DevicesFormValue, PipCameraPositions, TabId } from '@kitch/user/shared/models';
import { AgoraStreamService } from '@kitch/user/shared/services/agora-stream.service';
import { AgoraStreamComponent } from '../agora-stream/agora-stream.component';

@Component({
  selector: 'app-agora-user-stream',
  templateUrl: './agora-user-stream.component.html',
  styleUrls: ['../agora-stream/agora-stream.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgoraUserStreamComponent extends AgoraStreamComponent implements OnInit, AfterViewInit {
  @ViewChild('selectDevicesModal', { static: true }) selectDevicesModal: SelectDevicesModalComponent;
  @ViewChild('messageInvitedUsersModal') private readonly messageInvitedUsersModal: MessageInvitedUsersModalComponent;
  @ViewChild('tipsButtons') private readonly tipsButtons: TipsButtonsComponent;

  tipsChefCount = 0;
  isCoHostInviteModalOpened = false;
  isFullTableModalOpened = false;
  protected readonly tabId = TabId;

  constructor(
    protected agoraStreamService: AgoraStreamService,
    protected cdr: ChangeDetectorRef,
    protected profilesService: ProfilesService,
    protected liveStreamStatusService: LiveStreamStatusService,
    protected tipsService: TipsService,
    protected tokenService: TokenService,
    protected audienceService: AudienceService,
    protected $gaService: GoogleAnalyticsService,
    protected userProfile: UserProfileService,
    protected streamsService: StreamsService,
    protected chefTableService: ChefTableService,
    protected liveReplayTabsService: LiveReplayTabsService,
    protected applicationRef: ApplicationRef,
    protected ngZone: NgZone,
    protected logger: LoggerService,
    @Inject(PLATFORM_ID) protected platformId: Object,
  ) {
    super(
      agoraStreamService,
      cdr,
      profilesService,
      liveStreamStatusService,
      tipsService,
      tokenService,
      audienceService,
      $gaService,
      userProfile,
      streamsService,
      chefTableService,
      liveReplayTabsService,
      applicationRef,
      ngZone,
      logger,
      platformId,
    );
  }

  get canUserJoin(): boolean {
    return (
      this.currentConnectionState === 'CONNECTED' &&
      this.currentUserRole === 'audience' &&
      this.streamInfo.isLoggedInRtm
    );
  }

  async ngOnInit(): Promise<void> {
    this.logger.info('#ngOnInit user streamInfo: ', this.streamInfo);
    await super.ngOnInit();
    if (isPlatformBrowser(this.platformId)) {
      this.subscribeToRtmChannel();
      this.autoJoinLoggedInGuest();
    }
  }

  ngAfterViewInit() {
    this.logger.debug('#ngAfterViewInit subscribe to tips button');
    this.subscribeOnTipsButtonsClick();
  }

  scrollEl(): void {
    if (this.isAskPopup) {
      this.askChef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.isAskPopup = false;
    }
  }

  closePopupAskChef(): void {
    this.isAskPopup = false;
  }

  async onDevicesFormSubmit(formValue: DevicesFormValue): Promise<void> {
    this.isDevicesSubmitFormDisabled = true;
    this.devicesSet = formValue;
    this.logger.info('#onDevicesFormSubmit selected devices ', this.devicesSet);

    if (this.isCoHostInviteForUser) {
      this.logger.debug('#onDevicesFormSubmit auto turn on camera/microphone for invited user');
      this.logger.debug('#onDevicesFormSubmit turn on camera');
      await this.turnOnCamera();
      this.logger.debug('#onDevicesFormSubmit turn on microphone');
      await this.turnOnMicrophone();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.agoraRTMTool.coHostAcceptedInvite(this.streamInfo.profileId, this.mainCameraClient._joinInfo?.uid);

      return;
    }

    if (this.isUserJoined) {
      this.logger.debug('#onDevicesFormSubmit start video/audio for already joined user');
      try {
        this.logger.debug('#onDevicesFormSubmit create camera/microphone tracks');
        await this.createCameraMicrophoneTrack();
        this.logger.debug('#onDevicesFormSubmit set main camera to host role');
        await this.mainCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
        this.logger.debug('#onDevicesFormSubmit start video/audio');
        await this.startVideoAudio();
      } catch (e) {
        this.logger.warn('#onDevicesFormSubmit can\'t start video/audio ', e);
      }
    } else {
      this.logger.debug('#onDevicesFormSubmit join stream for a new user');
      await this.joinStream();
    }
    this.liveStreamStatusService.updateStreamStatus({ isPrepMode: true });
    this.isDevicesSubmitFormDisabled = false;
    this.setDeviceModalStatus(false);
  }

  showSelectDeviceFormForUser(): void {
    if (this.isGuest) return;

    if (this.streamInfo.canUserJoinTable) {
      this.setDeviceModalStatus(true);
    }

    this.$gaService.gtag('event', GA_EVENT_JOIN_CHEF_TABLE_CLICK, { profile_id: this.tokenService.getProfileId() });
  }

  setFullTableModalStatus(status: boolean): void {
    this.isFullTableModalOpened = status;
  }

  leaveCoHostsTable(uid: string): void {
    this.agoraRTMTool.coHostWantToLeave(uid);
  }

  onInviteAccept(isAccepted: boolean): void {
    this.isCoHostInviteModalOpened = false;
    isAccepted ? this.acceptInvite() : this.declineInvite();
  }

  private async acceptInvite(): Promise<void> {
    this.isCoHostInviteForUser = true;
    if (!this.localTracks.videoTrack || !this.localTracks.audioTrack) {
      this.logger.info('#acceptInvite show devices modal for empty video/audio tracks');
      this.setDeviceModalStatus(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.agoraRTMTool.coHostAcceptedInvite(this.streamInfo.profileId, this.mainCameraClient._joinInfo?.uid);
    }
  }

  private declineInvite(): void {
    this.agoraRTMTool.coHostDeclinedInvite(this.streamInfo.profileId);
  }

  sendReaction(amount: number, emojiSrc: string): void {
    if (this.isGuest) return;

    this.animateEmoji(emojiSrc, amount);
    this.agoraRTMTool.sendEmoji(this.streamInfo.profileId, amount, emojiSrc);
    this.soundEmoji(emojiSrc);

    this.$gaService.gtag('event', GA_EVENT_USER_SEND_EMOJI_CLICK, { profile_id: this.tokenService.getProfileId() });
  }

  raiseHand(): void {
    const user = this.userInfoMap.get(this.streamInfo.profileId);

    user.raisedHand = !user.raisedHand;
    this.sendUserRaisedHand(user.raisedHand);

    this.$gaService.gtag('event', GA_EVENT_USER_RAISE_HAND_CLICK, { profile_id: this.tokenService.getProfileId() });
  }

  private sendUserRaisedHand(status: boolean): void {
    this.agoraRTMTool.userRaisedHand(this.streamInfo.profileId, status);
    if (status) {
      this.handSound.volume = 0.3;
      this.handSound.play();
    }
  }

  switchPipCamera(): void {
    this.agoraRTMTool.switchPipCamera(this.streamInfo.profileId);
  }

  pipCameraDropped(index: PipCameraPositions): void {
    this.logger.info('#pipCameraDropped new pip camera position ', index);
    this.pipCameraPosition = index;
    this.agoraRTMTool.pipPositionChanged(this.streamInfo.profileId, index);

    setTimeout(() => {
      this.logger.info('#pipCameraDropped play second camera video');
      this.chefSecondCamera?.videoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    }, 1);
  }

  private subscribeToRtmChannel() {
    this.rtmChannel.on('ChannelMessage', ({ text }: RtmTextMessage) => {
      this.handleChannelMassage(text);
    });
  }

  private handleChannelMassage(text: string): void {
    const message: RtmMessage = this.agoraRTMTool.parseMassage(text);

    this.logger.info('#handleChannelMassage: handle rtm message ', message);
    switch (message.type) {
      case RtmMassageType.CHEF_REMOVED_USER:
        this.handleChefRemoveUser(message);
        break;
      case RtmMassageType.CHEF_CHANGED_USER_AUDIO:
        this.handleChefToggleUserAudio(message);
        break;
      case RtmMassageType.CHEF_INVITED_CO_HOST:
        this.handleChefInvitedCoHost(message);
        break;
      case RtmMassageType.CO_HOST_ACCEPTED_INVITE:
        this.handleCoHostAcceptedInvite();
        break;
      case RtmMassageType.CO_HOST_DECLINED_INVITE:
        this.handleCoHostDeclineInvite(message);
        break;
      case RtmMassageType.MUTE_EMOGI:
        this.handleMuteEmogi(message);
        break;
      case RtmMassageType.MUTE_ALL_USERS:
        this.handleMuteAllUsers(message);
        break;
      case RtmMassageType.PIP_POSITION_CHANGED:
        this.handlePipPositionChanged(message);
        break;
    }
    this.cdr.detectChanges();
  }

  private handleChefToggleUserAudio(message: RtmMessage): void {
    this.logger.info('#handleChefToggleUserAudio: toggle audio for uid ', message.payload.uid);
    const user = this.userInfoMap.get(message.payload.uid);

    if (message.payload.uid === this.streamInfo.profileId) {
      user.audioEnabled = message.payload.status;
      if (message.payload.status) {
        this.selectDevicesModal.devicesForm.get('microphone').setValidators(Validators.required);
        this.selectDevicesModal.devicesForm.get('microphone').updateValueAndValidity();
        this.logger.debug('#handleChefToggleUserAudio turn on microphone');
        this.turnOnMicrophone();
      } else {
        this.logger.debug('#handleChefToggleUserAudio turn off microphone');
        this.turnOffMicrophone();
      }
    }
    this.logger.info('#handleChefToggleUserAudio updated userInfoMap ', this.userInfoMap);
  }

  private handleChefRemoveUser(message: RtmMessage): void {
    this.logger.info('#handleChefRemoveUser: chef kicked from the table uid ', message.uid);
    if (message.uid === this.streamInfo.profileId) {
      this.leaveTable();
    }
  }

  private handleChefInvitedCoHost(message: RtmMessage): void {
    this.logger.info('#handleChefInvitedCoHost: chef invited to co-hosts uid ', message.uid);
    if (message.uid === this.streamInfo.profileId) {
      this.isCoHostInviteModalOpened = true;
    }
  }

  private handleCoHostAcceptedInvite(): void {
    this.setInviteWasSendModalStatus(false);
  }

  private handleCoHostDeclineInvite(message: RtmMessage): void {
    this.logger.info('#handleCoHostDeclineInvite: user declined invite to co-hosts ', message.uid);
    if (this.invitedCoHosts.some(uid => message.uid === uid)) {
      this.invitedCoHosts = this.invitedCoHosts.filter(uid => message.uid !== uid);
      const user = this.userInfoMap.get(message.uid);

      if (user) {
        this.setInviteWasSendModalStatus(false);
        this.messageInvitedUsersModal.openDeclineInviteModal(user.displayName);
      }
    }
  }

  private handleMuteEmogi(message: RtmMessage): void {
    this.isMuteEmogi = message.payload.status;
    this.logger.info('#handleMuteEmogi: is emoji muted', this.isMuteEmogi);
  }

  private handleMuteAllUsers(message: RtmMessage): void {
    this.isMuteUsersSound = message.payload.status;
    this.logger.info('#handleMuteAllUsers: is users muted', this.isMuteUsersSound);
    if (
      (!this.liveStreamStatusService.isCoHost(this.streamInfo.profileId) && !this.streamInfo.isStreamOwner)
      && this.isMuteUsersSound
    ) {
      this.logger.info('#handleMuteAllUsers turn off microphone');
      this.turnOffMicrophone();
    }
  }

  private handlePipPositionChanged(message: RtmMessage): void {
    this.pipCameraPosition = message.payload.pipPosition;
    this.logger.info('#handlePipPositionChanged new pip camera position ', this.pipCameraPosition);
    setTimeout(() => {
      this.logger.info('#handlePipPositionChanged play second camera video');
      this.chefSecondCamera?.videoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    }, 1);
  }

  private async joinStream(): Promise<void> {
    this.logger.info('#joinStream start method');
    if (this.hasTableAvailableSeats()) {
      try {
        this.logger.debug('#joinStream create camera/microphone tracks');
        await this.createCameraMicrophoneTrack();
        this.logger.debug('#joinStream set main camera role to host');
        await this.mainCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
        this.logger.debug('#joinStream start video/audio');
        await this.startVideoAudio();
        this.logger.debug('#joinStream turn off microphone for new joined user');
        await this.turnOffMicrophone();
        this.isUserJoined = true;
        this.logger.debug('#joinStream add self to chef table');
        await this.addLocalUserVideo();
      } catch (e) {
        this.logger.warn('#joinStream can\'t join stream error ', e);
      }
      this.cdr.detectChanges();
    } else {
      this.setFullTableModalStatus(true);
    }
  }

  private autoJoinLoggedInGuest(): void {
    if (window.sessionStorage.getItem(ACTION_AFTER_LOGIN_KEY) === 'showDevicesModal') {
      this.showSelectDeviceFormForUser();
      window.sessionStorage.removeItem(ACTION_AFTER_LOGIN_KEY);
    }
  }

  private subscribeOnTipsButtonsClick(): void {
    let count = 0;

    this.tipsButtons.tipToChef
      .pipe(
        tap((amount: number) => {
          count += amount;
          this.tipsChefCount = count;
        }),
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tip(count);
        count = 0;
      });
  }

  private tip(amount: number): void {
    const callback = () => {
      this.sendTips(amount);
      this.sendReaction(amount, 'assets/ui/images/svg/clam.svg');
      this.getTips();

      if (amount >= 5) {
        this.tipsButtons.isShownDropdown = false;
        this.tipsSuccessMessageToggle.emit(true);
        setTimeout(() => {
          this.tipsSuccessMessageToggle.emit(false);
        }, 4000);
      }
    };
    this.tipToChef.emit({ amount, callback });
  }

  private sendTips(amount: number): void {
    const viewerName = this.userInfoMap.get(this.streamInfo.profileId).displayName;

    this.agoraRTMTool.sendTips(
      this.streamInfo.profileId, this.stream.channel.chefProfile.id, amount, viewerName,
    );
    this.tipsSound.volume = 0.25;
    if (!this.isMuteEmogi) {
      this.tipsSound.play();
    }
  }
}
