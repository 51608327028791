import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PurchaseGAEvents } from '@kitch/data-access/constants';
import { MyRecipe, Recipe, Stream } from '@kitch/data-access/models';
import { ProfileChannel } from '@kitch/data-access/models/profile';
import { TokenService } from '@kitch/data-access/services';
import { ButtonRole } from '@kitch/ui/models/custom-button-form';
import { PurchaseService } from '@kitch/user/core/purchase.service';

@Component({
  selector: 'app-club-button',
  templateUrl: './club-button.component.html',
  styleUrls: ['./club-button.component.scss'],
})
export class ClubButtonComponent {
  @Input() isDisabled = true;
  @Input() isJoinShown = true;
  @Input() isSmallOnTablet = false;
  @Input() stream?: Stream;
  @Input() profile?: ProfileChannel;
  @Input() recipe?: MyRecipe | Recipe;
  @Input() role?: ButtonRole = 'secondary-white-bg';
  @Input() joinCourseBtnTitle? = 'Join Course';

  constructor(
    private purchaseService: PurchaseService,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  onJoinClub(): void {
    this.setPurchaseData();
    this.sendGaEvent();
  }

  onBuyCourse(): void {
    this.setPurchaseData();
  }

  private setPurchaseData(): void {
    this.purchaseService.setPurchaseClub(this.stream, this.profile, this.recipe);
  }

  private sendGaEvent(): void {
    const name = this.profile?.fullName || this.recipe?.recipeName;

    if (name) {
      this.$gaService.gtag('event', PurchaseGAEvents.JOIN_COURSE_BUTTON_CLICK, {
        profile_id: this.tokenService.getProfileId(),
        name: name,
      });
    }
  }
}
