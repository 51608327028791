<app-modal #addToCalendarModal>
  <ng-container header>
      <img
        class="success-modal__icon"
        src="assets/ui/images/svg/success-icon.svg"
        alt="success modal icon"
      />
      <h2 class="modal-title">
        {{isCreationSuccessModal ? 'YOU\'RE GOOD TO GO!' : 'Yey, your spot is saved!'}}
      </h2>

      <div class="success-modal__subtitle">
        <ng-container *ngIf="isCreationSuccessModal; else saveSpotSuccessSubTitle">
          Be sure to share with your
          community and friends. You can edit your streams at
          any time on
          <button
            class="success-modal__button-accent"
            type="button"
            (click)="addToCalendarModal.close()"
          >My Streams</button>.
        </ng-container>
        <ng-template #saveSpotSuccessSubTitle>
          You’ll get an email reminder before the livestream. See you in the kittch’n!
        </ng-template>
      </div>
  </ng-container>

  <ng-container body>
    <div *ngIf="stream" class="stream-card">
      <img
        class="stream-card__thumb"
        src={{stream.thumbnailUrl}}
        alt={{stream.title}}
      />
      <div>
        <div *ngIf="stream.status === streamStatuses.SCHEDULED" class="stream-card__date-container">
          <div class="stream-card__date">{{ stream.scheduledVideo.date | timezone }}</div>
        </div>
        <div class="stream-card__title">{{ stream.title }}</div>
        <div class="stream-card__description">{{ stream.description | clearHtml }}</div>
      </div>
    </div>

    <div class="success-modal__action-buttons">
      <!-- ADD TO CALENDAR BUTTON >> -->
      <app-add-to-calendar-button
        [isCreationSuccessModal]="isCreationSuccessModal"
        [stream]="stream"
        [email]="email"
        (updateAddToCalendarModalData)="updateAddToCalendarModalData($event)"
      ></app-add-to-calendar-button>
      <!-- << ADD TO CALENDAR BUTTON -->

      <!-- COPY STREAM LINK BUTTON >> -->
      <app-button
        [text]="copiedButtonContent.text"
        [iconUrl]="'assets/ui/images/svg/' + copiedButtonContent.icon"
        [role]="'primary'"
        (click)="onCopyStreamLink()"
      ></app-button>
      <!-- << COPY STREAM LINK BUTTON -->
    </div>
  </ng-container>
</app-modal>
