<div *ngIf="!videoUrl" class="video-processing">
  <div class="video-processing__title">
    Your livestream is  currently being processed
  </div>
  <div class="video-processing__text">
    Your livestream is currently  being processed, come back later to watch your livestream as a video. The process will take up to an hour.
  </div>
</div>

<ng-container *ngIf="videoUrl">
  <video id="video-player"
    data-testid="video-player"
    class="video-player"
    crossorigin="anonymous"
    controls
    autoplay
    playsinline
    controlsList="nodownload"
    [src]="stream.videoUrl"
    [poster]="stream.thumbnailUrl"
    (play)="setViewingStatus(true)"
    (playing)="setViewingStatus(true)"
    (pause)="setViewingStatus(false)"
    (waiting)="setViewingStatus(false)"
    (timeupdate)="setCurrentTime($event)">
  </video>

  <a
    *ngIf="isMainStage"
    class="video-stream-link"
    [routerLink]="'/' + stream.channel.chefProfile.chefSlug + '/streams/' + stream.slug"
    (click)="sendMainStageGAEvent()"
  ></a>
</ng-container>
