import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FAVORITE_PRODUCT_URL } from '@kitch/data-access/constants';
import {
  FavoriteProduct,
  FavoriteProductsList,
  ShortFavoriteProductsInfo,
} from '@kitch/data-access/models/favorite-products';
import { ApiService } from '@kitch/data-access/services/api.service';
import { makeUri } from '@kitch/util';

@Injectable({ providedIn: 'root' })
export class FavoriteProductsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<FavoriteProductsList<FavoriteProduct>> {
    return this.apiService.get(makeUri(FAVORITE_PRODUCT_URL));
  }

  addToFavoriteProduct(productId: string): Observable<ShortFavoriteProductsInfo> {
    return this.apiService.post(FAVORITE_PRODUCT_URL, { productId });
  }

  deleteFromFavoriteProduct(productId: string): Observable<ShortFavoriteProductsInfo> {
    return this.apiService.delete(makeUri(FAVORITE_PRODUCT_URL, productId));
  }
}
