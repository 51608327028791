<app-modal
  (closed)="setClosedModal()"
  #buyClubModal
>
  <ng-container header>
    <ng-container *ngIf="purchaseClub">
      <h2 class="modal-title">Join US!</h2>
      <p class="text modal-subtitle">
        For <span>${{ purchaseClub.requiredSubscriptionPlan.price }}</span>
        , you'll have access to exclusive live streams, videos, recipes, a private community, and more!
      </p>
    </ng-container>
  </ng-container>

  <ng-container body>
    <div *ngIf="purchaseClub" class="modal-content-wrap">
      <img
        class="stream-image"
        [src]="purchaseClub.clubData.clubTileImage"
        [alt]="purchaseClub.channelName"
      />
      <div class="modal-content-inner">
        <div class="stream-title">{{ purchaseClub.clubData.clubChefName }}</div>
        <app-avatar
          [url]="purchaseClub.chefProfile.photo"
          [size]="'scheduled'"
          [name]="purchaseClub.chefProfile.chefName"
        ></app-avatar>
        <a
          class="text club-link"
          [routerLink]="'/' + purchaseClub.clubData.clubChefSlug"
          (click)="setClosedModal()"
        >
          <img
            class="club-link__icon"
            [src]="'assets/ui/images/svg/club-member.svg'"
            width="24"
            height="24"
            alt="join course"
          />
          View details
        </a>
      </div>
    </div>

    <div class="form__item form__item--center">
      <app-button
        appNeedAuthorize
        class="modal-button"
        [text]="'ENROLL'"
        (clicked)="onJoinClub()"
      ></app-button>
    </div>
  </ng-container>
</app-modal>
