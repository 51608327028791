import { HttpClient } from '@angular/common/http';
import { InjectionToken, NgModule, NgZone, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';

import { Router } from '@angular/router';
import { AuthGuard, ChefGuard, GuestGuard, OldRouteGuard } from '@kitch/data-access/guards';
import { httpInterceptorProviders } from '@kitch/data-access/interceptors';
import { CommonUserRole } from '@kitch/data-access/models';
import {
  ApiService,
  AuthService,
  FetchApiService,
  LoggerService,
  TokenService,
  WebsocketService,
} from '@kitch/data-access/services';

import { AlertService, PreviousRouteService, SeoService } from '@kitch/ui/services';

import { FirstTimeUserSignupService } from '@kitch/user/core/first-time-user-signup.service';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { SidebarService } from '@kitch/user/core/sidebar.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';

export const USER_ROLE: InjectionToken<CommonUserRole> = new InjectionToken(
  'UserRole',
  { factory: () => CommonUserRole.USER },
);

export const LOGIN_PAGE_URL: InjectionToken<string> = new InjectionToken(
  'LoginPageUrl',
  { factory: () => '' },
);

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AuthGuard,
    ChefGuard,
    GuestGuard,
    OldRouteGuard,
    ApiService,
    AlertService,
    FetchApiService,
    PreviousRouteService,
    UserProfileService,
    LiveStreamStatusService,
    SidebarService,
    FirstTimeUserSignupService,
    SeoService,
    WebsocketService,
    LoggerService,
    {
      provide: AuthService,
      useClass: AuthService,
      deps: [
        LOGIN_PAGE_URL,
        USER_ROLE,
        ApiService,
        HttpClient,
        Router,
        TokenService,
        WebsocketService,
        NgZone,
        PLATFORM_ID,
      ],
    },
    {
      provide: TokenService,
      useClass: TokenService,
      deps: [USER_ROLE],
    },
    ...httpInterceptorProviders,
  ],
})
export class UserCoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: UserCoreModule,
    private previousRouteService: PreviousRouteService,
  ) {
    if (parentModule) {
      throw new Error(
        'Core module is already loaded. Import it in the root AppModule only',
      );
    }
  }
}
