import { Component, Input, ViewChild } from '@angular/core';
import { Stream, StreamStatus } from '@kitch/data-access/models';
import { ModalComponent } from '@kitch/ui/components';
import { AddToCalendarData } from '@kitch/user/shared/models/add-to-calendar-data';

@Component({
  selector: 'app-add-to-calendar-modal',
  templateUrl: './add-to-calendar-modal.component.html',
  styleUrls: ['./add-to-calendar-modal.component.scss'],
})
export class AddToCalendarModalComponent {
  @ViewChild('addToCalendarModal') addToCalendarModal: ModalComponent;

  @Input() isCreationSuccessModal: boolean;
  @Input() stream: Stream;
  @Input() email: string;
  @Input() isShownIcon = false;

  streamStatuses = StreamStatus;

  private chefSlug: string;
  private refCode: string;
  private isStatusCopied = false;

  get copiedButtonContent(): { [key: string]: string } {
    return {
      text: this.isStatusCopied ? 'Copied!' : 'copy stream link',
      icon: this.isStatusCopied ? 'checked-black.svg' : 'copy.svg',
    };
  }

  onCopyStreamLink(): void {
    this.isStatusCopied = !this.isStatusCopied;
    this.copyToClipboard();
  }

  updateAddToCalendarModalData(addToCalendarModalData: AddToCalendarData): void {
    this.stream = addToCalendarModalData.stream;
    this.chefSlug = addToCalendarModalData.chefSlug;
    this.refCode = addToCalendarModalData.refCode;

    if (addToCalendarModalData.isAddToCalendarModalOpen) {
      this.open();
    }
  }

  open(): void {
    this.addToCalendarModal.open();
  }

  private copyToClipboard(): void {
    let streamUrl = `${window.location.origin}/${this.chefSlug}/streams/${this.stream.slug}`;

    if (this.refCode) {
      streamUrl = `${streamUrl}?refCode=${this.refCode}`;
    }

    window.navigator.clipboard.writeText(streamUrl)
      .then(() => {
        setTimeout(() => {
          this.isStatusCopied = !this.isStatusCopied;
        }, 3000);
      });
  }
}
