<app-button
  *ngIf="!isDisplayProductsShop"
  class="stream-shop-card__button"
  text="Shop"
  role="stream-shop-card"
  type="button"
  (clicked)="displayProductsShop()"
>
  <svg class="stream-shop-card__button-icon" left-side height="24" width="24">
    <use href="assets/ui/images/svg/sprites.svg#stream-shop-cart"></use>
  </svg>
</app-button>

<section class="stream-shop-card__products" *ngIf="isDisplayProductsShop">
  <div class="stream-shop-card__products-head">
    <h2 class="stream-shop-card__products-header">Shop</h2>

    <img
      class="stream-shop-card__products-close"
      (click)="closeProductsShop()"
      src="assets/ui/images/svg/close-white.svg"
      alt="close"
      width="24"
      height="24"
    />
  </div>

  <section class="stream-shop-card__products-container" appHorizontalScroll>
    <app-shop-walmart-card
      *ngIf="walmartRecipe"
      [walmartRecipe]="walmartRecipe"
      [isDesktopView]="isDesktopView"
      source="upcoming_stream_shop"
    ></app-shop-walmart-card>

    <app-shop-product-card
      *ngFor="let product of products; trackBy: trackByFn"
      [product]="product"
      [isDesktopView]="isDesktopView"
      source="upcoming_stream_shop"
    ></app-shop-product-card>
  </section>
</section>
