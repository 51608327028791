<button class="invite-users-button" (click)="onInviteUsers()">
  <svg class="invite-users-button__icon" height="20" width="30">
    <use href="assets/ui/images/svg/sprites.svg#invite-users-icon"></use>
  </svg>
</button>

<app-invite-users-to-prep-mode-modal
  #inviteUsersToPrepModeModal
  (inviteUsersToPrepMode)="inviteUsersToPrepMode.emit($event)"
></app-invite-users-to-prep-mode-modal>
