import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonUserRole, REF_CODE_KEY, Role } from '@kitch/data-access/models';
import { TokenService } from '@kitch/data-access/services';
import { FirstTimeUserSignupService } from '@kitch/user/core/first-time-user-signup.service';

@UntilDestroy()
@Component({
  selector: 'app-first-time-user-signup',
  templateUrl: './first-time-user-signup.component.html',
})
export class FirstTimeUserSignupComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoginModalOpened = false;
  isRegisterModalOpened = false;
  userRole: CommonUserRole = CommonUserRole.USER;
  refCode: string;

  @HostListener('document:mouseout', ['$event'])
  mouseout(event: MouseEvent): void {
    if (
      this.firstTimeUserSignupService.streamStatusIsLive &&
      !event.relatedTarget &&
      event.clientY < 10
    ) {
      this.firstTimeUserSignupService.showModal();
    }
  }

  constructor(
    private firstTimeUserSignupService: FirstTimeUserSignupService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit() {
    this.refCode = this.window.sessionStorage.getItem(REF_CODE_KEY);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const refCode = this.route.snapshot.queryParams.refCode;
      const profileId = this.tokenService.getRole();

      if (refCode && profileId === Role.GUEST) {
        setTimeout(() => {
          this.isRegisterModalOpened = true;
        });
      }

      this.subscribeOnFirstTimeUserSignupChanges();
    }
  }

  openLoginModal(): void {
    this.isLoginModalOpened = true;
  }

  openRegisterModal(): void {
    this.isRegisterModalOpened = true;
  }

  onLoginModalClosed(): void {
    this.isLoginModalOpened = false;
  }

  onRegisterModalClosed(): void {
    this.isRegisterModalOpened = false;
    // commented for ticket KB-1487
    // this.walmartModalService.isFirstTimeVisitModalActive = false;
  }

  private subscribeOnFirstTimeUserSignupChanges(): void {
    this.firstTimeUserSignupService.signupCanBeShow$
      .pipe(untilDestroyed(this))
      .subscribe((canBeShow) => {
        if (canBeShow) {
          this.openRegisterModal();
          this.firstTimeUserSignupService.signupCanBeShow = false;
        }
      });
  }
}
