<app-modal #modal class="modal-invite" [showCloseIcon]="false">
  <ng-container header>
    <h3 class="modal-invite-title">
      {{ chefName }} invited you to {{ isInviteToPrepMode ? '' : 'co-host ' }}a stream!
    </h3>
  </ng-container>

  <ng-container body>
    <div class="modal-invite-content">
      <p class="modal-invite-content__text">
        Would you like to join the stream{{ isInviteToPrepMode ? '' : ' as a Co-Host' }}?
      </p>
      <div class="modal-invite-content__buttons">
        <app-button
          class="success-btn"
          [text]="isInviteToPrepMode ? 'accept' : 'i’m in'"
          [type]="'button'"
          (clicked)="emitInviteAccepted(true)"
        ></app-button>

        <app-button
          class="reject-btn"
          [text]="isInviteToPrepMode ? 'decline' : 'no thanks'"
          [type]="'button'"
          [role]="'secondary'"
          (clicked)="emitInviteAccepted(false)"
        ></app-button>
      </div>
    </div>
  </ng-container>
</app-modal>

