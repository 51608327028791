<button
  *ngIf="stream"
  class="addeventatc"
  data-testid="modal-add-to-calendar-btn"
  title="Add to Calendar"
  (click)="sendAddToCalendarGAEvent($event)"
>
  <div class="addeventatc__wrapper">
    <img
      class="addeventatc__icon"
      src="assets/ui/images/svg/schedule.svg"
      alt="schedule icon"
      height="24"
      width="24"
    />
    <span>Add to Calendar</span>
  </div>
  <span class="content-wrap">
    <span class="start">{{ startEventDate }}</span>
    <span class="end">{{ endEventDate }}</span>
    <span class="title">{{ stream.title }}</span>
    <span class="description">
      <ng-container *ngIf="stream.collaborators?.length">
        <p>
          <ng-container *ngFor="let collaborator of stream.collaborators; last as last">
            {{ collaborator.chefName }}
            <ng-container *ngIf="!last"> + </ng-container>
          </ng-container>
        </p>
      </ng-container>

      <ng-container *ngIf="!stream.collaborators?.length">
        <p>{{ chefAndChannelNames }}</p>
      </ng-container>

      <p>{{ stream.title }}</p>
      <p>{{ url }}</p>
    </span>
  </span>
</button>
