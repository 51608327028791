import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Stream } from '@kitch/data-access/models';

@Component({
  selector: 'app-stream-paywall',
  templateUrl: './stream-paywall.component.html',
  styleUrls: ['./stream-paywall.component.scss'],
})
export class StreamPaywallComponent implements OnInit {
  @Input() stream: Stream;

  @Output() joinClub: EventEmitter<void> = new EventEmitter<void>();
  @Output() buyAccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() hidePaywall: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onJoinClub(): void {
    this.joinClub.emit();
  }

  onBuyAccess(): void {
    this.buyAccess.emit();
  }

  onHidePaywall(): void {
    this.hidePaywall.emit();
  }
}
