import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoHostInfo, Stream } from '@kitch/data-access/models';
import { LoggerService } from '@kitch/data-access/services';
import { AgoraStreamInfo } from '@kitch/user/shared/models';

export interface StreamStatus {
  isLive: boolean;
  isOwner: boolean;
  isPrepMode: boolean;
  isScheduled: boolean;
}

@Injectable({ providedIn: 'root' })
export class LiveStreamStatusService {
  coHosts: CoHostInfo[] = [];
  stream: Stream;
  streamInfo: AgoraStreamInfo;
  isUserJoined = false;
  readonly recordingRetriesFailedFlag = -1;

  private defaultStatus: StreamStatus = {
    isLive: false,
    isOwner: false,
    isPrepMode: false,
    isScheduled: true,
  };

  private streamStatus: BehaviorSubject<StreamStatus> = new BehaviorSubject<StreamStatus>(this.defaultStatus);

  constructor(private logger: LoggerService) {
  }

  get streamStatus$(): Observable<StreamStatus> {
    return this.streamStatus.asObservable();
  }

  get retryRecordingCountKey(): string {
    return `record_retry_${this.stream.id}`;
  }

  updateStreamStatus(streamStatus: Partial<StreamStatus>): void {
    this.logger.info('#updateStreamStatus streamStatus ', streamStatus);
    this.streamStatus.next({ ...this.streamStatus.value, ...streamStatus });
  }

  isAudienceUser(user: IAgoraRTCRemoteUser): boolean {
    return user && !this.isCoHost(user.uid.toString());
  }

  isOwner(uid: string): boolean {
    return this.stream.channel.chefProfile.id === uid;
  }

  addToCoHosts(coHost: CoHostInfo): void {
    if (this.isOwner(coHost.profileId)) {
      this.coHosts = [coHost, ...this.coHosts];
    } else {
      this.coHosts.push(coHost);
    }
  }

  removeCoHost(uid: string): void {
    this.coHosts = this.coHosts.filter(coHost => coHost.profileId !== uid);
  }

  isCoHost(uid: string): boolean {
    return this.coHosts.map(coHost => coHost.profileId).includes(uid);
  }
}
