import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GA_EVENT_ADD_TO_CALENDAR_CLICK } from '@kitch/data-access/constants';
import { CREATED_STREAM_ID, StagedChannel, Stream, StreamChefProfile } from '@kitch/data-access/models';
import { ProfilesService, StreamsService, TokenService } from '@kitch/data-access/services';
import { convertDateToCalendarAppFormat } from '@kitch/util';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { AddToCalendarData } from '@kitch/user/shared/models/add-to-calendar-data';

declare global {
  interface Window {
    addeventatc: {
      refresh: () => void;
    };
  }
}

@UntilDestroy()
@Component({
  selector: 'app-add-to-calendar-button',
  templateUrl: './add-to-calendar-button.component.html',
  styleUrls: ['./add-to-calendar-button.component.scss'],
})
export class AddToCalendarButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isCreationSuccessModal = false;
  @Input() stream: Stream;
  @Input() email: string;

  @Output() updateAddToCalendarModalData: EventEmitter<AddToCalendarData> = new EventEmitter<AddToCalendarData>();

  startEventDate: string;
  endEventDate: string;
  url: string;
  chefAndChannelNames: string;

  private chefSlug: string;
  private refCode: string;

  constructor(
    private tokenService: TokenService,
    private userProfileService: UserProfileService,
    private profilesService: ProfilesService,
    private $gaService: GoogleAnalyticsService,
    private streamService: StreamsService,
    @Inject(WINDOW) private window: Window,
  ) {}

  ngOnInit(): void {
    if (this.isCreationSuccessModal) {
      this.proceedWithCreatedStream();
    } else {
      // save my spot logic otherwise
      const chefProfile = this.getChefProfile(this.stream);

      this.setStartEndDates();
      this.url = this.getURL(this.stream);
      this.chefSlug = chefProfile.chefSlug;
      this.setRefCode();
      this.chefAndChannelNames = this.getChefAndChannelNames();
    }
  }

  ngAfterViewInit() {
    this.window.addeventatc.refresh();
  }

  ngOnDestroy() {}

  sendAddToCalendarGAEvent(event: Event): void {
    event.stopPropagation();
    const params = {
      email: this.email,
      guest: this.tokenService.isGuest(),
      profile_id: this.tokenService.getProfileId(),
      stream_name: this.stream.title,
    };

    this.$gaService.gtag('event', GA_EVENT_ADD_TO_CALENDAR_CLICK, params);
  }

  private getChefAndChannelNames(): string {
    if (this.stream.profile) {
      return `${this.stream.profile.chefName} - ${this.stream.profile.channel.name}`;
    } else {
      return `${this.stream.channel.chefProfile.chefName} - ${this.stream.channel.name}`;
    }
  }

  private getCreatedStreamId(): string {
    const streamId = this.window.sessionStorage.getItem(CREATED_STREAM_ID);

    this.window.sessionStorage.removeItem(CREATED_STREAM_ID);

    return streamId;
  }

  private proceedWithCreatedStream(): void {
    const streamId = this.getCreatedStreamId();

    if (streamId) {
      this.getCreatedStream(streamId).subscribe((createdStream) => {
        this.stream = createdStream;
        this.setStartEndDates();
        this.url = this.getURL(this.stream);
        this.chefAndChannelNames = this.getChefAndChannelNames();
        setTimeout(() => {
          this.setAddToCalendarModalData(true);
          this.window.addeventatc.refresh();
        });
      });
    }
  }

  private setAddToCalendarModalData(isAddToCalendarModalOpen: boolean) {
    const addToCalendarModalData = {
      stream: this.stream,
      chefSlug: this.chefSlug,
      refCode: this.refCode,
      isAddToCalendarModalOpen,
    };

    this.updateAddToCalendarModalData.emit(addToCalendarModalData);
  }

  private setRefCode(): void {
    this.profilesService.getUserProfile(this.tokenService.getProfileId())
      .pipe(
        map((profiles) => profiles.results[0]),
        filter((profile) => !!profile),
        untilDestroyed(this),
      )
      .subscribe((profile) => {
        this.refCode = profile.refCode;
        this.setAddToCalendarModalData(false);
      });
  }

  private setStartEndDates(): void {
    this.startEventDate = convertDateToCalendarAppFormat(this.stream.scheduledVideo.date);
    this.endEventDate = convertDateToCalendarAppFormat(this.stream.scheduledVideo.date);
  }

  private getCreatedStream(streamId: string): Observable<Stream> {
    return this.userProfileService.userProfile$.pipe(
      tap((result) => {
        this.chefSlug = result.slug;
        this.refCode = result.refCode;
      }),
      switchMap(result => {
        return this.streamService.getById(streamId, result.id);
      }),
      untilDestroyed(this),
    );
  }

  private getChefProfile(stream: Stream): StagedChannel | StreamChefProfile {
    return stream.profile || stream.channel.chefProfile;
  }

  private getURL(stream: Stream): string {
    const chefProfile = this.getChefProfile(this.stream);

    return `${this.window.location.origin}/${chefProfile.chefSlug}/streams/${stream.slug}`;
  }
}
