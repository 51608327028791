<footer class="footer">
  <div class="logo">
    <img
      class="logo-img"
      src="assets/ui/images/svg/logo-footer.svg"
      width="207"
      height="67"
      alt="Kittch"
    />
  </div>
  <nav class="footer__menu">
    <ul class="menu">
      <li class="menu__item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="menu__link" [routerLink]="'/'">Home</a>
      </li>
      <li class="menu__item">
        <a class="menu__link" href="http://kittch.workable.com/" target="_blank" rel="nofollow">CAREERS</a>
      </li>
      <li class="menu__item">
        <a class="menu__link" href="mailto:support@kittch.com" rel="nofollow">SUPPORT</a>
      </li>
    </ul>
    <ul class="menu">
      <li class="menu__item" *ngIf="(settingsStatus$ | async).isTypeformModalCanBeShow">
        <button
          class="menu__link menu__link--button"
          (click)="showTypeformModal()"
        >Personalize your Experience</button>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a class="menu__link" [routerLink]="'/privacy-policy'" rel="nofollow">Privacy statement</a>
      </li>
      <li class="menu__item" routerLinkActive="active">
        <a class="menu__link" [routerLink]="'/terms-of-use'" rel="nofollow">Terms of use</a>
      </li>
      <li *ngIf="isAdmin || isChef" class="menu__item" routerLinkActive="active">
        <a class="menu__link" [routerLink]="'/creators-terms-of-use'" rel="nofollow">Creators terms of use</a>
      </li>
    </ul>
  </nav>
</footer>
