import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CardGAEvents } from '@kitch/data-access/constants';
import { StagedChannel, Stream, StreamChefProfile, StreamStatus } from '@kitch/data-access/models';
import {
  getEarthWeekChef,
  getFireChef,
  getMushroomMonthChef,
  getIsPaymentRequire,
  TokenService,
  getAllCollaborators,
} from '@kitch/data-access/services';
import { getSecondsFromDate } from '@kitch/util';

@Component({
  selector: 'app-stream-top-card',
  templateUrl: './stream-top-card.component.html',
  styleUrls: ['./stream-top-card.component.scss'],
})
export class StreamTopCardComponent implements OnInit {
  @Input() isActiveSlider = false;
  @Input() isAllStreamsPage = false;
  @Input() stream: Stream;
  @Input() useBgColorAvatar = true;
  @Input() gaEvent: CardGAEvents;
  @Input() isNewTab = false;
  @Input() isVisibleIndicator = false;
  @HostBinding('class.carousel-cell') private carouselCell = false;

  streamStatus = StreamStatus;
  streamDuration: number;

  hasMushroomMonthChef = false;
  hasEarthWeekChef = false;
  hasFireChef = false;

  profileId: string;

  isUnpaidClub: boolean;
  isPaymentRequire: boolean;
  isPaidIndicatorShown: boolean;
  isLearnMoreButton: boolean;

  streamBadge: string;
  chefProfile: StreamChefProfile | StagedChannel;

  constructor(
    protected $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.carouselCell = this.isActiveSlider;
    this.chefProfile = this.stream?.profile || this.stream?.channel.chefProfile;
    this.profileId = this.tokenService.getProfileId();
    this.setGaEvent();
    if (this.stream?.collaborators?.length) {
      this.streamBadge = this.stream?.collaborators.map(collab => collab.badgeImageUrl)[0];

      if (!this.stream?.collaborators.includes(this.chefProfile)) {
        this.stream.collaborators = getAllCollaborators(this.chefProfile, this.stream.collaborators);
      }
      this.hasMushroomMonthChef = getMushroomMonthChef(this.stream);
      this.hasEarthWeekChef = getEarthWeekChef(this.stream);
      this.hasFireChef = getFireChef(this.stream);
    }
    this.streamDuration =
      getSecondsFromDate(new Date(this.stream?.stoppedAt)) - getSecondsFromDate(new Date(this.stream?.startedAt));

    this.isPaymentRequire = getIsPaymentRequire(this.stream, this.profileId, this.tokenService.isAdmin());
    this.isUnpaidClub = !this.stream.subscribedToClub && !!this.stream.requiredClubSubscriptionPlan;
    this.isPaidIndicatorShown = this.stream.paid && !this.stream.subscribed && !this.stream.subscribedToClub
      || this.isUnpaidClub;
    this.isLearnMoreButton = this.isAllStreamsPage &&
      (this.isPaymentRequire && !this.stream.requiredClubSubscriptionPlan || this.isUnpaidClub);
  }

  goToLink(): void {
    const streamLink = `/${this.chefProfile.chefSlug}/streams/${this.stream.slug}`;

    this.router.navigateByUrl(streamLink).then(() => this.sendGAEvent());
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', this.gaEvent, {
      profile_id: this.profileId,
      name: this.stream?.title,
    });
  }

  private setGaEvent(): void {
    if (this.gaEvent) {
      return;
    } else {
      switch (this.stream?.status) {
        case StreamStatus.LIVE:
          this.gaEvent = CardGAEvents.live;
          break;
        case StreamStatus.PAST:
          this.gaEvent = CardGAEvents.past;
          break;
        case StreamStatus.SCHEDULED:
          this.gaEvent = CardGAEvents.upcoming;
          break;
      }
    }
  }
}
