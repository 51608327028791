import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TokenService } from '@kitch/data-access/services';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { AgoraStreamInfo, ToggleUserAudioEvent, UserInfo } from '@kitch/user/shared/models';

type VideoSize = 'small' | 'mid' | 'big';

@Component({
  selector: 'app-chef-table',
  templateUrl: './chef-table.component.html',
  styleUrls: ['./chef-table.component.scss'],
})
export class ChefTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() userMap: IAgoraRTCRemoteUser[] = [];

  @Input() userInfoMap: Map<string, UserInfo>;

  @Input() canUserJoin: boolean;

  @Input() isDesktopView: boolean;

  @Output() removedFromTable: EventEmitter<IAgoraRTCRemoteUser> = new EventEmitter<IAgoraRTCRemoteUser>();

  @Output() invitedCoHost: EventEmitter<IAgoraRTCRemoteUser> = new EventEmitter<IAgoraRTCRemoteUser>();

  @Output() leftTable: EventEmitter<void> = new EventEmitter<void>();

  @Output() turnedOnCamera: EventEmitter<void> = new EventEmitter<void>();

  @Output() turnedOffCamera: EventEmitter<void> = new EventEmitter<void>();

  @Output() turnedOnMicrophone: EventEmitter<void> = new EventEmitter<void>();

  @Output() turnedOffMicrophone: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggledUserAudio: EventEmitter<ToggleUserAudioEvent> = new EventEmitter<ToggleUserAudioEvent>();

  @Output() joinedTable: EventEmitter<void> = new EventEmitter<void>();

  @Output() raisedHand: EventEmitter<void> = new EventEmitter<void>();

  @Output() componentViewInit: EventEmitter<void> = new EventEmitter<void>();

  myUser: IAgoraRTCRemoteUser;
  profileId: string;

  isAdmin: boolean;
  contextMenuUid: string;

  @HostListener('document:click')
  documentClick(): void {
    this.contextMenuUid = null;
  }

  get isJoinTextShown(): boolean {
    return !this.isDesktopView || (this.isDesktopView && !this.isShortView);
  }

  get joinText(): string {
    return this.isDesktopView ?
      'Join the Chef’s Table to interact with the chef live! Ask questions or just say hi!' :
      'Interact with the chef live!';
  }

  get joinButtonText(): string {
    if (this.isDesktopView && this.isShortView) {
      return 'Join Chef’s Table';
    } else {
      return 'Join Now';
    }
  }

  get isShortView(): boolean {
    return this.userMap.length === 2 || this.userMap.length > 4;
  }

  get streamInfo(): AgoraStreamInfo {
    return this.liveStreamStatusService.streamInfo;
  }

  get isUserJoined(): boolean {
    return this.liveStreamStatusService.isUserJoined;
  }

  get videoSize(): VideoSize {
    switch (this.userMap.length) {
      case 1:
      case 2:
        return 'big';
      case 3:
        return 'mid';
      default:
        return 'small';
    }
  }

  constructor(
    private liveStreamStatusService: LiveStreamStatusService,
    private tokenService: TokenService,
  ) { }

  ngOnInit(): void {
    this.profileId = this.tokenService.getProfileId();
    this.isAdmin = this.tokenService.isAdmin();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userMap?.currentValue) {
      this.myUser = this.userMap.find(user => user.uid === this.profileId);
    }
  }

  ngAfterViewInit() {
    this.componentViewInit.emit();
  }

  isAudienceUser(user: IAgoraRTCRemoteUser): boolean {
    return this.liveStreamStatusService.isAudienceUser(user);
  }

  isMe(user: IAgoraRTCRemoteUser): boolean {
    return user.uid === this.profileId;
  }

  openContextMenu(event: Event, user: IAgoraRTCRemoteUser): void {
    event.stopPropagation();
    const uid = user.uid.toString();

    if (this.contextMenuUid === uid) {
      // close opened context menu by second click
      this.contextMenuUid = null;
    } else {
      this.contextMenuUid = uid;
    }
  }

  removeFromTable(user: IAgoraRTCRemoteUser): void {
    this.removedFromTable.emit(user);
  }

  inviteCoHost(user: IAgoraRTCRemoteUser): void {
    this.invitedCoHost.emit(user);
  }

  leaveTable(): void {
    this.leftTable.emit();
  }

  turnOnCamera(): void {
    console.log('turnOnCamera');
    this.turnedOnCamera.emit();
  }

  turnOffCamera(): void {
    console.log('turnOffCamera');
    this.turnedOffCamera.emit();
  }

  toggleUserAudio(uid: string, status: boolean): void {
    this.toggledUserAudio.emit({ uid, status });
  }

  joinTable(): void {
    this.joinedTable.emit();
  }

  turnOffMicrophone(): void {
    this.turnedOffMicrophone.emit();
  }

  turnOnMicrophone(): void {
    this.turnedOnMicrophone.emit();
  }

  raiseHand(): void {
    this.raisedHand.emit();
  }

  getMicrophoneIconSrc(userInfo: UserInfo): string {
    return userInfo?.audioEnabled ?
      this.isDesktopView ?
        'assets/ui/images/svg/mic-on.svg' : // desktop on
        'assets/ui/images/svg/mic-on-gray.svg' // mobile on
      : 'assets/ui/images/svg/mic-off.svg'; // both off
  }

  getCameraIconSrc(userInfo: UserInfo): string {
    return userInfo?.videoEnabled ?
      this.isDesktopView ?
        'assets/ui/images/svg/camera-on1.svg' : // desktop on
        'assets/ui/images/svg/camera-on1-gray.svg' // mobile on
      : 'assets/ui/images/svg/camera-off2.svg'; // both off
  }

  getRiseHandIconSrc(userInfo: UserInfo): string {
    return userInfo?.raisedHand ?
      'assets/ui/images/svg/hand-up.svg' : // desktop & mobile up
      this.isDesktopView ?
        'assets/ui/images/svg/hand-down.svg' : // desktop down
        'assets/ui/images/svg/hand-down-gray.svg'; // mobile down
  }
}
