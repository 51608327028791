<div class="stream-page">
  <div class="stream-page__container stream-page__container-top">
    <div class="header-wrap">
      <h1 class="stream-page__page-title">{{ pageTitle }}</h1>
      <app-filters-button *ngIf="!profileId" class="filters-btn"></app-filters-button>
    </div>
    <app-filters
      *ngIf="!profileId"
      class="filters"
      (appliedFilters)="appliedFilters($event)"
      filtersPageType="streams"
    ></app-filters>
    <ng-container *ngIf="!isLoading">
      <app-scroll-load class="stream-page__featured-streams" (scrolled)="onPageChange()">
        <app-stream-top-card
          *ngFor="let stream of allStreams;trackBy:identify"
          class="stream-page__stream-top-card"
          [stream]="stream"
          [isAllStreamsPage]="true"
        >
        </app-stream-top-card>
      </app-scroll-load>
      <div *ngIf="!allStreams?.length" class="empty-list">
        <div class="empty-list__text empty-list__text--streaming-now">
          There aren't any livestreams happening right now, but check out some of our replays!
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="isLoading || isNextStreamsPageLoading" class="loader-container">
  <app-preloader></app-preloader>
</div>
