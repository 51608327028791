import { ChannelsSortBy, SortOptions, StreamsSortBy } from '@kitch/user/shared/models';

export const CHANNEL_SORT_OPTIONS: SortOptions<ChannelsSortBy>[] = [
  { key: 'newest', label: 'Most Recent' },
  { key: 'popular', label: 'Most Popular' },
  { key: 'chefName', label: 'A–Z' },
  { key: 'chefNameDesc', label: 'Z-A' },
];

export const STREAMS_SORT_OPTIONS: SortOptions<StreamsSortBy>[] = [
  { key: 'stoppedAt', label: 'Most Recent' },
  { key: 'popularity', label: 'Most Popular' },
  { key: 'titleAsc', label: 'A–Z' },
  { key: 'titleDesc', label: 'Z-A' },
];
