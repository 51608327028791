import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ChefTableVisitor, Recipe, Stream } from '@kitch/data-access/models';
import { ChefTableService } from '@kitch/data-access/services';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { TabId, TipChefEvent } from '@kitch/user/shared/models';

@Component({
  selector: 'app-stream-replay',
  templateUrl: './stream-replay.component.html',
  styleUrls: ['./stream-replay.component.scss'],
})
export class StreamReplayComponent implements OnInit {
  @Input() stream: Stream;
  @Input() recipes: Recipe[];
  @Input() isDesktopView: boolean;
  @Input() isChefOrAdmin: boolean;
  @Input() isAskChefLoaded: boolean;
  @Input() isChefStreamOwner: boolean;
  @Input() walmartRecipeId: number;
  @Input() walmartRecipePortions: number;
  @Input() tipsChefCount: number;
  @Input() isTipsSuccessMessageShown: boolean;
  @Input() chatId: string;

  @Input() videoStubTmplRef: TemplateRef<ElementRef>;
  @Input() isShowVideoStub: boolean;

  @Output() openSubscribeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendTranslation: EventEmitter<string> = new EventEmitter<string>();
  @Output() tipToChef: EventEmitter<TipChefEvent> = new EventEmitter<TipChefEvent>();

  chefTableVisitors: ChefTableVisitor[] = [];
  walmartRecipe: Recipe;

  protected readonly tabId = TabId;

  get isTabsPresent(): boolean {
    return Boolean(this.liveReplayTabsService.tabsMenu.tabs.length);
  }

  constructor(
    private chefTableService: ChefTableService,
    private liveReplayTabsService: LiveReplayTabsService,
  ) { }

  ngOnInit(): void {
    this.getChefTableVisitors();
    this.updateWalmartRecipe();
  }

  onClubBtnClick(joinStream: boolean): void {
    this.openSubscribeModal.emit(joinStream);
  }

  onSubmitTranslate($event: string): void {
    this.sendTranslation.emit($event);
  }

  onTabSwitched(): void {
    if (this.liveReplayTabsService.isTabActive(TabId.CHAT)) {
      this.isAskChefLoaded = true;
    }
  }

  emitTipToChef($event: TipChefEvent): void {
    this.tipToChef.emit($event);
  }

  private getChefTableVisitors(): void {
    this.chefTableService.getVisitors(this.stream.id)
      .subscribe(visitors => {
        this.chefTableVisitors = visitors || [];
        this.updateTabsMenu();
      });
  }

  private updateWalmartRecipe(): void {
    this.recipes.forEach((recipe: Recipe) => {
      if (recipe.walmartRecipeId === this.walmartRecipeId) {
        this.walmartRecipe = recipe;
      }
    });
  }

  private updateTabsMenu() {
    const isChefTableTabHidden = this.stream.produced || !this.chefTableVisitors.length;

    if (isChefTableTabHidden) {
      this.liveReplayTabsService.removeTab(TabId.CHEFS_TABLE);
    }

    if (!this.stream.products.length && !this.walmartRecipeId) {
      this.liveReplayTabsService.removeTab(TabId.SHOP);
    } else {
      this.liveReplayTabsService.addTab(TabId.SHOP);
    }
  }
}
