<app-button
  class="learn-more-button"
  [text]="'LEARN MORE'"
  [role]="'secondary'"
  (clicked)="onGoToLink()"
>
  <ng-container right-side>
    <div class="learn-more-button__price-icon">
      <img
        src="assets/ui/images/svg/clam.svg"
        width="48"
        height="48"
        alt="clam"
      />
    </div>
  </ng-container>
</app-button>
