import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CardGAEvents } from '@kitch/data-access/constants';
import { StagedStream, StreamStatus } from '@kitch/data-access/models';
import {
  getAllCollaborators,
  getEarthWeekChef,
  getFireChef,
  getMushroomMonthChef,
  TokenService,
} from '@kitch/data-access/services';
import { getSecondsFromDate } from '@kitch/util';

@Component({
  selector: 'app-main-stage-video',
  templateUrl: './main-stage-video.component.html',
  styleUrls: ['./main-stage-video.component.scss'],
})
export class MainStageVideoComponent implements OnInit {
  @Input() stream: StagedStream;

  streamDuration: number;
  isScheduledStream = false;
  hasMushroomMonthChef = false;
  hasEarthWeekChef = false;
  hasFireChef = false;
  isLiveOrScheduled: boolean;
  streamBadge: string;
  profileId: string;
  streamStatus = StreamStatus;

  constructor(
    protected $gaService: GoogleAnalyticsService,
    private elem: ElementRef,
    private tokenService: TokenService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stream?.currentValue) {
      this.setStreamStatus();
      this.setupBasedOnStreamCollaborators();
      this.setStreamDuration();
    }
  }

  ngOnInit(): void {
    this.profileId = this.tokenService.getProfileId();
  }

  sendGAEvent(): void {
    this.$gaService.gtag('event', CardGAEvents.mainStageStream, {
      profile_id: this.profileId,
      name: this.stream.title,
    });
  }

  private setStreamStatus(): void {
    const { status } = this.stream;

    this.isScheduledStream = status === StreamStatus.SCHEDULED || status === StreamStatus.PREPMODE;
    this.isLiveOrScheduled = status === StreamStatus.LIVE || this.isScheduledStream;
  }

  private setupBasedOnStreamCollaborators(): void {
    const hasCollaborators = !!this.stream.collaborators?.length;

    this.streamBadge = hasCollaborators ? this.stream.collaborators.map(collab => collab.badgeImageUrl)[0] : '';
    this.hasMushroomMonthChef = hasCollaborators ? getMushroomMonthChef(this.stream) : false;
    this.hasEarthWeekChef = hasCollaborators ? getEarthWeekChef(this.stream) : false;
    this.hasFireChef = hasCollaborators ? getFireChef(this.stream) : false;

    if (hasCollaborators) {
      this.stream.collaborators = getAllCollaborators(this.stream.channel.chefProfile, this.stream.collaborators);
    }
  }

  private setStreamDuration(): void {
    const { stoppedAt, startedAt } = this.stream;

    this.streamDuration = getSecondsFromDate(new Date(stoppedAt)) - getSecondsFromDate(new Date(startedAt));
  }
}
