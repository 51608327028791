import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

@Injectable()
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data.reuseRoute !== undefined) {
      return future.data.reuseRoute;
    } else {
      return future.routeConfig === curr.routeConfig;
    }
  }
}
