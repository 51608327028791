import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stream-popups',
  templateUrl: './stream-popups.component.html',
  styleUrls: ['./stream-popups.component.scss'],
})
export class StreamPopupsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isConnectionPopupOpened: boolean;

  @Input() isPrepModePopupOpened: boolean;

  @Output() streamRescheduled: EventEmitter<void> = new EventEmitter<void>();

  private prepModePopupTimeoutId: ReturnType<typeof setTimeout>;
  private connectionPopupTimeoutId: ReturnType<typeof setTimeout>;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isConnectionPopupOpened && changes.isConnectionPopupOpened.currentValue) {
      this.openConnectionPopup();
    }
    if (changes.isPrepModePopupOpened && changes.isPrepModePopupOpened.currentValue) {
      this.openPrepModePopup();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.prepModePopupTimeoutId);
    clearTimeout(this.connectionPopupTimeoutId);
  }

  closePrepModePopup(): void {
    this.isPrepModePopupOpened = false;
  }

  closeConnectionPopup(): void {
    this.isConnectionPopupOpened = false;
  }

  rescheduleStream(): void {
    this.streamRescheduled.emit();
  }

  private openPrepModePopup(): void {
    this.isPrepModePopupOpened = true;
    this.prepModePopupTimeoutId = setTimeout(() => this.isPrepModePopupOpened = false, 10000);
  }

  private openConnectionPopup(): void {
    this.isConnectionPopupOpened = true;
    this.connectionPopupTimeoutId = setTimeout(() => this.isConnectionPopupOpened = false, 15000);
  }
}
