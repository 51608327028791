import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GA_EVENT_USER_TIP_CLAM_CLICK } from '@kitch/data-access/constants';
import { TokenService } from '@kitch/data-access/services';
import { opacityAnimation } from '@kitch/ui/animations/opacity-animation';
import { ButtonRole } from '@kitch/ui/models/custom-button-form';

@Component({
  selector: 'app-tips-buttons',
  templateUrl: './tips-buttons.component.html',
  styleUrls: ['./tips-buttons.component.scss'],
  animations: [opacityAnimation],
})
export class TipsButtonsComponent {
  @Input() isTipsSuccessMessageShown = false;
  @Input() role?: ButtonRole = 'primary';
  @Input() isSmallOnTablet = false;
  @Input() tipBtnTitle? = 'Tip chef';

  @Output() tipToChef: EventEmitter<number> = new EventEmitter<number>();

  isShownDropdown = false;

  @HostListener('document:click', ['$event'])
  documentClick(event: Event): void {
    if (this.isShownDropdown && !this.elem.nativeElement.contains(event.target)) {
      this.isShownDropdown = false;
    }
  }

  constructor(
    private readonly elem: ElementRef,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
  ) {}

  onToggleDropdown(): void {
    this.isShownDropdown = !this.isShownDropdown;
  }

  onTipToChef(amount: number): void {
    this.tipToChef.emit(amount);
    if (this.isShownDropdown && amount <= 4) {
      this.isShownDropdown = false;
    }

    this.$gaService.gtag('event', GA_EVENT_USER_TIP_CLAM_CLICK, {
      profile_id: this.tokenService.getProfileId(),
      amount,
    });
  }
}
