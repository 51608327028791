import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { MenuGAEvents, TypeformGAEvents } from '@kitch/data-access/constants';
import { Role } from '@kitch/data-access/models';
import { FullProfileInfo } from '@kitch/data-access/models/profile';
import { SettingsStatus } from '@kitch/data-access/models/settings';
import { SettingsService, TokenService } from '@kitch/data-access/services';
import { TypeformModalComponent } from '@kitch/ui/components/modals';
import { LiveStreamStatusService, StreamStatus } from '@kitch/user/core/live-stream-status.service';
import { ModalService } from '@kitch/user/core/modal.service';
import { SidebarService } from '@kitch/user/core/sidebar.service';
import { UserProfile, UserProfileService } from '@kitch/user/core/user-profile.service';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  isOpen = false;
  isUser = false;
  isChef = false;
  isAdmin = false;
  isGuest: boolean;
  isInviteCodeModalOpened = false;
  streamStatus: StreamStatus;
  settingsStatus$: Observable<SettingsStatus>;
  userProfileInfo$: Observable<UserProfile>;
  profileId: string;
  user: FullProfileInfo;
  gaEvents = MenuGAEvents;
  windowHeight: number = window.innerHeight;
  isDesktopSidebarShown: boolean;
  isPossibleToHideDesktopSidebar = false;

  constructor(
    private router: Router,
    private liveStreamStatusService: LiveStreamStatusService,
    private tokenService: TokenService,
    private sidebarService: SidebarService,
    private userProfile: UserProfileService,
    private $gaService: GoogleAnalyticsService,
    private modalService: ModalService,
    private settingsService: SettingsService,
  ) {
    this.userProfileInfo$ = this.userProfile.userProfile$;
  }

  ngOnInit(): void {
    this.liveStreamStatusService.streamStatus$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.streamStatus = status;
      });
    this.subscribeOnSidebarStatesChanges();

    const role = this.tokenService.getRole();

    this.isUser = role === Role.USER;
    this.isChef = role === Role.CHEF;
    this.isAdmin = this.tokenService.isAdmin();
    this.isGuest = this.tokenService.isGuest();
    this.profileId = this.tokenService.getProfileId();
    this.settingsStatus$ = this.settingsService.settingStatus$;
    this.userProfile.fullUserProfile$
      .pipe(untilDestroyed(this))
      .subscribe(res => this.user = res);
  }

  ngOnDestroy(): void {}

  openLiveStream(): void {
    this.router.navigate(['/streams/live']);
  }

  closeSideBar(): void {
    this.sidebarService.changeMobileState(false);
  }

  setIsOpenInviteModal(status: boolean): void {
    this.isInviteCodeModalOpened = status;
  }

  sendGAEvent(event: MenuGAEvents): void {
    this.$gaService.gtag('event', event, { profile_id: this.profileId });
  }

  showTypeformModal(): void {
    this.modalService.open(TypeformModalComponent);
    this.$gaService.gtag('event', TypeformGAEvents.OPEN_GET_STARTED_UCPMODAL, {
      profile_id: this.tokenService.getProfileId(),
    });
  }

  private subscribeOnSidebarStatesChanges(): void {
    this.sidebarService.sidebarMobileState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.isOpen = state;
      });

    this.sidebarService.sidebarDesktopState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.isDesktopSidebarShown = state;
      });

    this.sidebarService.isPossibleToHideDesktopSidebarState$
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.isPossibleToHideDesktopSidebar = state;
      });
  }
}
