import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipsInfo } from '@kitch/data-access/models';

@Component({
  selector: 'app-tips-success-message',
  templateUrl: './tips-success-message.component.html',
  styleUrls: ['./tips-success-message.component.scss'],
})
export class TipsSuccessMessageComponent implements OnInit {
  @Input() tipsChefCount = 5;
  @Input() isChef = false;
  @Input() tipsInfo?: TipsInfo;
  @Input() messageClass?: string;

  @Output() tipsSuccessMessageClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  messageTitle: string;

  ngOnInit(): void {
    this.messageTitle = this.isChef ? 'CONGRATULATIONS!' : 'THANK YOU!';
  }

  get targetMeals(): string {
    if (this.tipsInfo.tipsAmount < 25) {
      return 'a drink';
    }

    if (this.tipsInfo.tipsAmount < 100) {
      return 'a dinner';
    }

    if (this.tipsInfo.tipsAmount === 100) {
      return 'a day off';
    }
  }

  get messageText(): string {
    return this.isChef ?
      `${this.tipsInfo.viewerName} just bought you ${this.targetMeals}!` :
      `Your tip of ${this.tipsChefCount} clams is super generous.`;
  }

  onSuccessMessageClose(): void {
    this.tipsSuccessMessageClose.emit(false);
  }
}
