import { HttpBackend } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { INGXLoggerMetadata, NGXLoggerServerService } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { UserProfile, UserProfileService } from '@kitch/user/core/user-profile.service';

@Injectable()
export class MyLoggerHttpService extends NGXLoggerServerService {
  public constructor(
    httpBackend: HttpBackend,
    ngZone: NgZone,
    private userProfileService: UserProfileService,
  ) {
    super(httpBackend, ngZone);
  }

  private profile: UserProfile;

  customiseRequestBody(metadata: INGXLoggerMetadata): any {
    this.userProfileService.userProfile$
      .pipe(tap((profile: UserProfile) => this.profile = profile))
      .subscribe();

    const userUrl = window.location.href;
    const message = metadata.message ?? 'no error message';

    return {
      name: 'Logger Error',
      message: `${message}, page url:${userUrl}, user name: ${this.profile.name}, user profile id: ${this.profile.id}`,
      stack: metadata.fileName,
    };
  }
}
