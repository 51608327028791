import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppPagesItem, Role } from '@kitch/data-access/models';
import { UsersSearchParams } from '@kitch/data-access/models/search-params';
import { ModalComponent } from '@kitch/ui/components';
import { SelectDropdownWithAvatarComponent } from '@kitch/ui/components/ng-select';
import { GuestUser, UsersListItem } from '@kitch/admin/shared/models/user';
import { UsersService } from '@kitch/admin/shared/services/users.service';

@UntilDestroy()
@Component({
  selector: 'app-invite-users-to-prep-mode-modal',
  templateUrl: './invite-users-to-prep-mode-modal.component.html',
  styleUrls: ['./invite-users-to-prep-mode-modal.component.scss'],
})
export class InviteUsersToPrepModeModalComponent implements OnDestroy {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('selectDropdownWithAvatar') selectDropdownWithAvatar: SelectDropdownWithAvatarComponent;

  totalPagePrivateUsers: number;
  guestsPage = 1;
  allUsers: GuestUser[] = [];
  inviteUserIds: string[] = [];

  @Output() inviteUsersToPrepMode: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private usersService: UsersService) {}

  ngOnDestroy(): void {}

  onInviteUsers(status: boolean): void {
    if (status) {
      this.inviteUsersToPrepMode.emit(this.inviteUserIds);
    }
    this.inviteUserIds = [];
    this.allUsers = [];
    this.selectDropdownWithAvatar.countItems = 0;
    this.selectDropdownWithAvatar.renderer.setAttribute(
      this.selectDropdownWithAvatar.inputElement, 'placeholder', this.selectDropdownWithAvatar.placeholder,
    );
    this.modal.close();
  }

  openModal(): void {
    this.getGuests().subscribe(() => {
      this.modal.open();
    });
  }

  getGuests(query?: string): Observable<AppPagesItem<UsersListItem>> {
    const params: UsersSearchParams = {
      approved: true,
      page: this.guestsPage,
      itemsPerPage: 25,
      role: [Role.USER, Role.CHEF],
    };

    if (query) {
      params.query = query;
    }

    return this.usersService.getUsers(params).pipe(
      tap((users) => {
        const privateUsers = users.results.map((user) => {
          return {
            id: user.profile.id,
            photo: user.profile.photo,
            name: user.profile.fullName,
          };
        });

        this.totalPagePrivateUsers = users.pageCount;

        if (this.guestsPage > 1) {
          this.allUsers = [...this.allUsers, ...privateUsers];
        } else {
          this.allUsers = privateUsers;
        }

        setTimeout(() => {
          this.inviteUserIds.forEach((id) => {
            this.selectDropdownWithAvatar.checkSelectedItem(id);
          });
        });
      }),
      untilDestroyed(this),
    );
  }

  setSelectedPrivateUsers(userIds: string[]): void {
    this.inviteUserIds = userIds;
  }

  setSearchTerm(term: string): void {
    this.guestsPage = 1;
    this.getGuests(term).subscribe();
  }

  setPagePrivateUsers(page: number): void {
    if (page < this.totalPagePrivateUsers) {
      this.guestsPage = page + 1;

      this.getGuests().subscribe();
    }
  }
}
