import { MOBILE_WIDTH, TABLET_WIDTH } from '@kitch/data-access/constants/media-queries';
import {
  COMING_UP_STREAM_CAROUSEL_DESKTOP_HEIGHT,
  COMING_UP_STREAM_CAROUSEL_TABLET_HEIGHT,
  COMING_UP_STREAM_CAROUSEL_MOBILE_HEIGHT,
  COMING_UP_STREAM_CAROUSEL_DESKTOP_WIDTH,
  COMING_UP_STREAM_CAROUSEL_TABLET_WIDTH,
  COMING_UP_STREAM_CAROUSEL_MOBILE_WIDTH,
} from '@kitch/user/shared/constants/upcoming-streams-carousels-size';

export class UpcomingSteamsCarouselSizeTool {
  static calculateWidth(innerWidth: number): number {
    switch (true) {
      case UpcomingSteamsCarouselSizeTool.isTablet(innerWidth):
        return COMING_UP_STREAM_CAROUSEL_TABLET_WIDTH;
      case UpcomingSteamsCarouselSizeTool.isMobile(innerWidth):
        return COMING_UP_STREAM_CAROUSEL_MOBILE_WIDTH;
      default:
        return COMING_UP_STREAM_CAROUSEL_DESKTOP_WIDTH;
    }
  }

  static calculateHeight(innerWidth: number): number {
    switch (true) {
      case UpcomingSteamsCarouselSizeTool.isTablet(innerWidth):
        return COMING_UP_STREAM_CAROUSEL_TABLET_HEIGHT;
      case UpcomingSteamsCarouselSizeTool.isMobile(innerWidth):
        return COMING_UP_STREAM_CAROUSEL_MOBILE_HEIGHT;
      default:
        return COMING_UP_STREAM_CAROUSEL_DESKTOP_HEIGHT;
    }
  }

  protected static isTablet(innerWidth: number): boolean {
    return innerWidth <= TABLET_WIDTH && innerWidth > MOBILE_WIDTH;
  }

  protected static isMobile(innerWidth: number): boolean {
    return innerWidth <= MOBILE_WIDTH;
  }
}
