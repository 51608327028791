import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InviteUsersToPrepModeModalComponent } from '@kitch/user/shared/components/modals';

@Component({
  selector: 'app-invite-users-to-prep-mode-button',
  templateUrl: './invite-users-to-prep-mode-button.component.html',
  styleUrls: ['./invite-users-to-prep-mode-button.component.scss'],
})
export class InviteUsersToPrepModeButtonComponent {
  @ViewChild('inviteUsersToPrepModeModal')
  private readonly inviteUsersToPrepModeModal: InviteUsersToPrepModeModalComponent;

  @Output() inviteUsersToPrepMode: EventEmitter<string[]> = new EventEmitter<string[]>();

  onInviteUsers(): void {
    this.inviteUsersToPrepModeModal.openModal();
  }
}
