import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Recipe, Stream } from '@kitch/data-access/models';
import { StreamsService, TokenService } from '@kitch/data-access/services';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { TabId, TipChefEvent } from '@kitch/user/shared/models';

@Component({
  selector: 'app-stream-schedule-banner',
  templateUrl: './stream-schedule-banner.component.html',
  styleUrls: ['./stream-schedule-banner.component.scss'],
})
export class StreamScheduleBannerComponent implements OnInit, OnDestroy {
  @Input() stream: Stream;
  @Input() recipes: Recipe[];
  @Input() isShowChefTools = false;
  @Input() isChefStreamOwner = false;
  @Input() isPaymentRequire: boolean;
  @Input() isDesktopView: boolean;
  @Input() isAskChefLoaded: boolean;
  @Input() isChefOrAdmin: boolean;
  @Input() walmartRecipePortions: number;
  @Input() walmartRecipeId: number;
  @Input() tipsChefCount: number;
  @Input() isTipsSuccessMessageShown: boolean;
  @Input() chatId: string;
  @Output() tipToChef: EventEmitter<TipChefEvent> = new EventEmitter<TipChefEvent>();
  @Output() openSubscribeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  isPaidIndicatorShown: boolean;
  isMobileScreen: boolean;
  isFullDate: boolean;
  isUnpaidClub: boolean;
  walmartRecipe: Recipe;

  protected readonly tabId = TabId;

  constructor(
    private liveStreamStatusService: LiveStreamStatusService,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private streamsService: StreamsService,
    private router: Router,
    private liveReplayTabsService: LiveReplayTabsService,
  ) {}

  ngOnInit(): void {
    this.isMobileScreen = window.innerWidth <= 480;
    this.isFullDate = this.stream.scheduledVideo.date.slice(14, 16) === '00';
    this.isUnpaidClub = !this.stream.subscribedToClub && !!this.stream.requiredClubSubscriptionPlan;
    this.isPaidIndicatorShown = this.stream.paid && !this.stream.subscribed && !this.stream.subscribedToClub
      || this.isUnpaidClub;

    this.$gaService.pageView(
      '/upcoming-details',
      'Upcoming details',
      undefined,
      { profile_id: this.tokenService.getProfileId() },
    );

    this.setWalmartRecipe();
    this.removeChefTableTab();
  }

  ngOnDestroy() {
    this.addChefTableTab();
  }

  onEditStream(): void {
    this.router.navigate([`/streams/edit/${this.stream.id}`]);
  }

  onEnterPrepMode(): void {
    this.liveStreamStatusService.updateStreamStatus({ isScheduled: false });
  }

  onTabSwitched(): void {
    if (this.liveReplayTabsService.isTabActive(TabId.CHAT)) {
      this.isAskChefLoaded = true;
    }
  }

  private setWalmartRecipe(): void {
    this.walmartRecipe = this.recipes.find(({ walmartRecipeId }) => walmartRecipeId === this.walmartRecipeId);
  }

  private removeChefTableTab(): void {
    this.liveReplayTabsService.removeTab(TabId.CHEFS_TABLE);
  }

  private addChefTableTab(): void {
    this.liveReplayTabsService.addTab(TabId.CHEFS_TABLE);
  }
}
