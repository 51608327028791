import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@sentry/angular-ivy';

import { AppBrowserModule } from '@kitch/user/app.browser.module';
import { environment } from './environments/environment';

if (environment.enableSentryLogs) {
  init({
    dsn: environment.sentryDns,
    environment: environment.name,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));
