<div
  cdkDropList
  #firstPipArea="cdkDropList"
  [cdkDropListData]="firstPipAreaList"
  [cdkDropListConnectedTo]="[secondPipArea, thirdPipArea, fourthPipArea]"
  class="pip-area pip-area--{{ positions.TOP_LEFT }}"
  id="pip-area-{{ positions.TOP_LEFT }}"
  (cdkDropListDropped)="dropped($event)">
  <div *ngFor="let i of firstPipAreaList" cdkDrag id="second-camera-player" class="player--pip"
    [ngClass]="{ 'dnd-disabled' : isDndDisabled, 'switch-disabled' : isSwitchDisabled }"
    [cdkDragDisabled]="isDndDisabled"
    (cdkDragStarted)="dragStarted($event)">
    <ng-container *ngTemplateOutlet="switchCameraButton"></ng-container>
  </div>
</div>

<div
  cdkDropList
  #secondPipArea="cdkDropList"
  [cdkDropListData]="secondPipAreaList"
  [cdkDropListConnectedTo]="[firstPipArea, thirdPipArea, fourthPipArea]"
  class="pip-area pip-area--{{ positions.TOP_RIGHT }}"
  id="pip-area-{{ positions.TOP_RIGHT }}"
  (cdkDropListDropped)="dropped($event)">
  <div *ngFor="let i of secondPipAreaList" cdkDrag id="second-camera-player" class="player--pip"
    [ngClass]="{ 'dnd-disabled' : isDndDisabled, 'switch-disabled' : isSwitchDisabled }"
    [cdkDragDisabled]="isDndDisabled"
    (cdkDragStarted)="dragStarted($event)">
    <ng-container *ngTemplateOutlet="switchCameraButton"></ng-container>
  </div>
</div>

<div
  cdkDropList
  #thirdPipArea="cdkDropList"
  [cdkDropListData]="thirdPipAreaList"
  [cdkDropListConnectedTo]="[firstPipArea, secondPipArea, fourthPipArea]"
  class="pip-area pip-area--{{ positions.BOTTOM_LEFT }}"
  id="pip-area-{{ positions.BOTTOM_LEFT }}"
  (cdkDropListDropped)="dropped($event)">
  <div *ngFor="let i of thirdPipAreaList" cdkDrag id="second-camera-player" class="player--pip"
    [ngClass]="{ 'dnd-disabled' : isDndDisabled, 'switch-disabled' : isSwitchDisabled }"
    [cdkDragDisabled]="isDndDisabled"
    (cdkDragStarted)="dragStarted($event)">
    <ng-container *ngTemplateOutlet="switchCameraButton"></ng-container>
  </div>
</div>

<div
  cdkDropList
  #fourthPipArea="cdkDropList"
  [cdkDropListData]="fourthPipAreaList"
  [cdkDropListConnectedTo]="[firstPipArea, secondPipArea, thirdPipArea]"
  class="pip-area pip-area--{{ positions.BOTTOM_RIGHT }}"
  id="pip-area-{{ positions.BOTTOM_RIGHT }}"
  (cdkDropListDropped)="dropped($event)">
  <div *ngFor="let i of fourthPipAreaList" cdkDrag id="second-camera-player" class="player--pip"
    [ngClass]="{ 'dnd-disabled' : isDndDisabled, 'switch-disabled' : isSwitchDisabled }"
    [cdkDragDisabled]="isDndDisabled"
    (cdkDragStarted)="dragStarted($event)">
    <span *ngIf="showHelperMessage && !isDndDisabled" class="helper-message">Click and drag to reposition</span>
    <ng-container *ngTemplateOutlet="switchCameraButton"></ng-container>
  </div>
</div>


<ng-template #switchCameraButton>
  <button class="switch-camera-btn" (click)="switchCamera()">
    <img
      height="24"
      width="24"
      src="assets/ui/images/svg/switch-camera.svg"
      alt="switch camera">
  </button>
  <span *ngIf="index === positions.BOTTOM_RIGHT" class="switch-camera-hint">Switch cameras</span>
</ng-template>
