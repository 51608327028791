<ng-container>
  <app-modal #clamsSelectModal>
    <ng-container header>
      <h2 class="modal-title">Buy clams</h2>

      <p class="modal-description">Yes, clams are real money! 1 clam = $1</p>

      <p class="modal-description modal-description--clams">

        <span class="clams-count">You have <b>{{ userClamsBalance }} Clams</b></span>

        <img
          class="clams-icon"
          src="{{ 'assets/ui/images/svg/clam.svg' }}"
          width="48"
          height="48"
          alt="clams"
        />
      </p>

      <p class="modal-description modal-description--clams-bottom">
        Tip Chefs and Creators and buy premium content with Clams. Save more when you buy more!
      </p>
    </ng-container>

    <ng-container body>
      <div *ngFor="let clamsPlan of clamsPlans; index as index" class="clams-plan">
        <app-clams-plan
          [clamsPlan]="clamsPlan"
          [index]="index + 2"
          (clamsPlanSelect)="clamsPlanSelectHandler($event)"
        ></app-clams-plan>
      </div>
    </ng-container>
  </app-modal>

  <app-modal #clamsPurchaseModal data-testid="buy-clams-modal">
    <ng-container header>
      <ng-container *ngIf="primaryCreditCard">
        <h2 class="modal-title">Confirm purchase</h2>

        <div class="purchase-summary">
          <div>Purchase Summary</div>
        </div>

        <div *ngIf="selectedClamsPlan" class="clams-plan clams-plan--selected">
          <app-clams-plan
            [clamsPlan]="selectedClamsPlan"
            [index]="selectedClamsPlanIndex"
            [isSelected]="true"
            [checkoutInfo]="checkoutInfo"
            (clamsPlanChange)="clamsPlanChangeHandler()"
          ></app-clams-plan>
        </div>

        <app-credit-card *ngIf="primaryCreditCard" [card]="primaryCreditCard"></app-credit-card>

        <div class="form-button-wrap">
          <div class="form-button form-button--buy">
            <app-button
              data-testid="buy-clams-btn"
              text="Buy"
              [isDisabled]="isModalSubmitting"
              (clicked)="buttonBuyClamsClickHandler()"
            ></app-button>
          </div>

          <div class="form-button form-button--cancel">
            <app-button role="secondary" text="Cancel" (clicked)="clamsPurchaseModal.close()"></app-button>
          </div>
        </div>
      </ng-container>

      <h2 *ngIf="!primaryCreditCard" class="modal-title">Buy clams</h2>
    </ng-container>

    <ng-container body>
      <ng-container *ngIf="!primaryCreditCard">
        <h3 class="card-form-title">Pay with credit card</h3>

        <app-credit-card-info
          [isFormSubmitting]="isModalSubmitting"
          (cardCreated)="creditCardCreatedHandler($event)"
        ></app-credit-card-info>
      </ng-container>
    </ng-container>
  </app-modal>

  <app-modal #successfulClamsPurchaseModal>
    <ng-container header>
      <h2 class="modal-title">Thanks for your purchase!</h2>

      <p *ngIf="selectedClamsPlan" class="modal-description">
        {{ selectedClamsPlan.clams + (selectedClamsPlan.freeClams || 0) }} clams have been added
        to your wallet and are available to use.
      </p>

      <div class="form-button-wrap">
        <div class="form-button form-button--close">
          <app-button text="Close" (clicked)="closeSuccessfulClamsPurchaseModal()"></app-button>
        </div>
      </div>
    </ng-container>
  </app-modal>
</ng-container>
