import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

import { AppPagesItem, CardTransaction, CreditCard } from '@kitch/data-access/models';
import { ProfilesService, TokenService } from '@kitch/data-access/services';

import { ModalComponent } from '@kitch/ui/components';

import { StripeService } from '@kitch/user/shared/services/stripe.service';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.scss', '../../../pages/user-panel/user-profile/user-profile-shared.scss'],
})
export class UserWalletComponent implements OnInit {
  @ViewChild('deleteCardModal', { static: false }) deleteCardModal: ModalComponent;
  @ViewChild('newCardModal', { static: false }) newCardModal: ModalComponent;

  isLoading = false;
  isModalSubmitting = false;
  cards: CreditCard[];
  selectedCardId: string;
  cardForDeletion: CreditCard;
  transactions: CardTransaction[];

  constructor(
    private profilesService: ProfilesService,
    private stripeService: StripeService,
    private tokenService: TokenService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getTransactions().subscribe();
    this.getCreditCards().subscribe();
  }

  onCardSelected(cardId: string): void {
    this.selectedCardId = cardId;
    this.stripeService.markCardPrimary(cardId)
      .pipe((concatMap(() => this.getCreditCards())))
      .subscribe();
  }

  onCardCreated(token: string): void {
    this.isModalSubmitting = true;
    this.stripeService.addCreditCard(token)
      .pipe(
        tap(() => this.newCardModal.close()),
        concatMap(() => this.getCreditCards()),
        finalize(() => this.isModalSubmitting = false),
      )
      .subscribe();
  }

  onCardDeleted(card: CreditCard): void {
    this.cardForDeletion = card;
    this.deleteCardModal.open();
  }

  deleteCreditCard(): void {
    this.isModalSubmitting = true;
    this.stripeService.deleteCreditCard(this.cardForDeletion.id)
      .pipe(
        concatMap(() => {
          if (this.cardForDeletion.primary) {
            const foundCard = this.cards.find((card) => !card.primary);

            if (foundCard) {
              return this.stripeService.markCardPrimary(foundCard.id);
            }
          }

          return of({});
        }),
        tap(() => this.deleteCardModal.close()),
        concatMap(() => this.getCreditCards()),
        finalize(() => this.isModalSubmitting = false),
      )
      .subscribe();
  }

  private getCreditCards(): Observable<CreditCard[]> {
    return this.profilesService.getUserCards(this.tokenService.getProfileId())
      .pipe(tap(
        (cards) => {
          this.cards = cards.sort((a, b) => (a.primary === b.primary) ? 0 : a.primary ? -1 : 1);
          this.selectedCardId = cards.find((card) => card.primary)?.id;
        },
      ));
  }

  private getTransactions(): Observable<AppPagesItem<CardTransaction>> {
    return this.profilesService.getUserCardTransactions(this.tokenService.getProfileId())
      .pipe(
        tap((transactions) => {
          this.transactions = transactions.results.sort((a, b) => {
            return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          });
        }),
      );
  }
}
