<header class="header">
  <!-- BURGER MENU >> -->
  <div
    *ngIf="isPossibleToHideDesktopSidebar"
    class="burger-menu burger-menu--desktop"
    (click)="onDesktopBurgerClick()"
    [ngClass]="{'active': burgerDesktopMenuIsOpen}"
  >
    <span class="burger-menu__line"></span>
    <span class="burger-menu__line"></span>
    <span class="burger-menu__line"></span>
  </div>
  <!-- << BURGER MENU -->

  <a (click)="closeSideBar()" [routerLink]="'/'">
    <div class="logo">
      <img
        class="logo-img"
        src="assets/ui/images/svg/logo-1.svg"
        alt="Kittch"
        width="152"
        height="49"
      >
    </div>
  </a>

  <app-search-input
    [isOpen]="isSearchOpened"
    (searchOpenChanged)="setSearchStatus($event)"
  ></app-search-input>

  <div class="right-side">
    <div class="search" (click)="setSearchStatus(true)">
      <img
        class="search-icon"
        src="assets/ui/images/svg/search.svg"
        width="22"
        height="22"
        alt="Search"
      />
    </div>

    <!-- PROFILE INFO >> -->
    <div (click)="closeSideBar()" class="profile-info" *ngIf="!isGuest">
      <!-- AVATAR AND NAME >> -->
      <button mat-button [matMenuTriggerFor]="menu">
        <div *ngIf="user" class="profile-info__link">
          <a>
            <app-avatar
              [userName]="userName"
              [url]="(this.userProfileInfo$ | async).avatar"
              [size]="'header'"
              data-testid="header-user-avatar"
            ></app-avatar>
          </a>
          <a class="user-name">
            {{ userName }}
          </a>
        </div>
      </button>
      <!-- << AVATAR AND NAME -->
      <!-- DROPDOWN MENU >> -->
      <div>
        <mat-menu class="menu-header-dropdown" #menu="matMenu">
          <button
            class="menu-header-dropdown__item"
            data-testid="menu-header-dropdown-my-account-btn"
            [routerLink]="isChefAccount ? '/user-profile/' + profileId + '/notification' : '/user-profile/' + profileId + '/subscriptions'"
            mat-menu-item>
              My Account
            </button>
          <button class="menu-header-dropdown__item" mat-menu-item>
            <a href="mailto:support@kittch.com">Help & Support</a>
          </button>
          <button class="menu-header-dropdown__item" (click)="setIsOpenInviteModal(true)" mat-menu-item>
            Invite a friend
          </button>
          <button
            *ngIf="(settingsStatus$ | async).isTypeformModalCanBeShow"
            class="menu-header-dropdown__item"
            mat-menu-item
            (click)="showTypeformModal()"
          >
            Personalize your Experience
          </button>
          <button class="menu-header-dropdown__item" mat-menu-item>
            <a [routerLink]="'/logout'">Log Out</a>
          </button>
        </mat-menu>
      </div>
      <!-- << DROPDOWN MENU -->
      <!-- CLAMS >> -->
      <a
        class="clams"
        [routerLink]="isChefAccount ? '/profile/rewards' : '/user-profile/' + profileId + '/clams'"
        data-testid="menu-header-clams-btn"
      >
        <img
          class="clams__img"
          src="assets/ui/images/svg/clam.svg"
          width="48"
          height="48"
          alt="Clams"
        />
        <span
          class="clams__count"
          data-testid="menu-header-clams-count"
        >
          {{  (userProfileInfo$ | async).balance }}
        </span>
      </a>
      <!-- << CLAMS -->
    </div>
    <!-- << PROFILE INFO -->

    <app-shopping-cart-button
      *ngIf="isShowShoppingCardButton"
      class="shopping-cart-button"
      [ngClass]="{'shopping-cart-button--guest' : isGuest}"
      (shoppingCardOpened)="shoppingCardOpened($event)"
    >
    </app-shopping-cart-button>

    <app-guest-buttons [source]="'header'" *ngIf="isGuest" class="guest-buttons"></app-guest-buttons>

    <!-- BURGER MENU >> -->
    <div
      data-testid="mobile-burger-menu"
      class="burger-menu"
      (click)="onMobileBurgerClick()"
      [ngClass]="{'active': burgerMobileMenuIsOpen}"
    >
      <span class="burger-menu__line"></span>
      <span class="burger-menu__line"></span>
      <span class="burger-menu__line"></span>
    </div>
    <!-- << BURGER MENU -->
  </div>
</header>

<app-invite-code-modal
  *ngIf="(userProfileInfo$ | async).refCode"
  [refCode]="(userProfileInfo$ | async).refCode"
  [isOpen]="isInviteCodeModalOpened"
  (modalOpenChanged)="setIsOpenInviteModal($event)"
>
</app-invite-code-modal>
