import { NgZone } from '@angular/core';
import { RtmMassageType } from '@kitch/data-access/constants';
import { AudienceViewerUser, RtmMessage, StreamStatus, UserInfo } from '@kitch/data-access/models';
import { LoggerService } from '@kitch/data-access/services';

export class AgoraRTMTool {
  constructor(
    private rtmChannel: RtmChannel,
    private ngZone: NgZone,
    private logger: LoggerService,
  ) {
  }

  streamStatusChanged(initiatorUid: string, status: StreamStatus): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.CHEF_CHANGED_STREAM_STATUS,
      payload: { streamStatus: status },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  toggleUserAudio(initiatorUid: string, targetUid: string, status: boolean): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.CHEF_CHANGED_USER_AUDIO,
      payload: {
        uid: targetUid,
        status: status,
      },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  removeUserFromTable(targetUid: string): void {
    const massage: RtmMessage = {
      uid: targetUid,
      type: RtmMassageType.CHEF_REMOVED_USER,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  inviteAsCoHost(targetUid: string): void {
    const massage: RtmMessage = {
      uid: targetUid,
      type: RtmMassageType.CHEF_INVITED_CO_HOST,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userRaisedHand(initiatorUid: string, status: boolean): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_RAISED_HAND,
      payload: { status: status },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  sendEmoji(initiatorUid: string, emojiAmount: number, emojiSrc: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.SEND_EMOJI,
      payload: { emojiAmount, emojiSrc },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  sendStatusMuteEmogi(initiatorUid: string, status: boolean): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.MUTE_EMOGI,
      payload: { status: status },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  sendStatusMuteAllUsers(initiatorUid: string, status: boolean): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.MUTE_ALL_USERS,
      payload: { status: status },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  coHostDeclinedInvite(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.CO_HOST_DECLINED_INVITE,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  coHostAcceptedInvite(initiatorUid: string, cameraId: number): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.CO_HOST_ACCEPTED_INVITE,
      payload: { cameraId },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userJoinedStream(initiatorUid: string, videoId: string, user: AudienceViewerUser): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_JOINED_STREAM,
      payload: {
        videoId: videoId,
        userInfo: {
          profileId: user.profileId,
          viewerName: user.viewerName,
          avatar: user.viewerPhoto,
        },
      },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userLeftStream(initiatorUid: string, videoId: string, user: AudienceViewerUser): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_LEFT_STREAM,
      payload: {
        videoId,
        userInfo: {
          profileId: user.profileId,
          viewerName: user.viewerName,
          avatar: user.viewerPhoto,
        },
      },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  sendTips(initiatorUid: string, targetUid: string, amount: number, name: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.SEND_TIP,
      payload: { uid: targetUid, tipsAmount: amount, userInfo: { viewerName: name, profileId: initiatorUid } },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userJoinedAsCoHost(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_JOINED_AS_CO_HOST,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userLeftAsCoHost(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_LEFT_AS_CO_HOST,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  coHostWantToLeave(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.CO_HOST_WANT_TO_LEAVE,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  pipPositionChanged(initiatorUid: string, position: number): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.PIP_POSITION_CHANGED,
      payload: {
        pipPosition: position,
      },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  switchPipCamera(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.SWITCH_PIP_CAMERA,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  inviteUsersToPrepMode(initiatorUid: string, inviteUserIds: string[]): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.INVITE_USERS_TO_PREP_MODE,
      payload: { inviteUserIds },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userAcceptedInvite(initiatorUid: string): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_ACCEPTED_INVITE,
      payload: {},
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  userDeclinedInvite(initiatorUid: string, userInfo: UserInfo): void {
    const massage: RtmMessage = {
      uid: initiatorUid,
      type: RtmMassageType.USER_DECLINED_INVITE,
      payload: { userInfo },
    };

    this.sendRTMMessage(this.stringifyMassage(massage));
  }

  stringifyMassage(massage: RtmMessage): string {
    return JSON.stringify(massage);
  }

  parseMassage(massage: string): RtmMessage {
    return JSON.parse(massage);
  }

  private sendRTMMessage(massage: string): void {
    this.ngZone.runOutsideAngular(() => {
      this.rtmChannel
        .sendMessage({ text: massage })
        .then(() => {})
        .catch((error) => {
          this.logger.error(`AgoraRTM sendMessage didnt send massage ${massage}`, new Error('AgoraRTM error'));
          this.logger.error('AgoraRTM sendMessage failure', error);
        });
    });
  }
}
