import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FilterGAEvents } from '@kitch/data-access/constants';
import { MostPopularFilters } from '@kitch/data-access/models';
import {
  ProfilesService,
  StreamsService,
  TokenService,
} from '@kitch/data-access/services';
import { mapMostPopularToFilterOption } from '@kitch/util';
import { ModalComponent } from '@kitch/ui/components';
import { FiltersButtonService } from '@kitch/user/shared/components/filters-button/filters-button.service';
import {
  CHANNEL_SORT_OPTIONS,
  STREAMS_SORT_OPTIONS,
} from '@kitch/user/shared/components/filters/constants/filters-sort-options';
import {
  AppliedFilters,
  FilterOption,
  FilterSectionName,
  FilterType,
  SortBy,
  SortOptions,
} from '@kitch/user/shared/models';

const FILTERS_COLUMNS_BREAKPOINT = 550;
const FILTERS_COUNT_EXTRA_BIG = 24;
const FILTERS_COUNT_BIG_DESKTOP = 9;
const FILTERS_COUNT_BIG_MOBILE = 10;
const FILTERS_COUNT_SMALL_DESKTOP = 3;
const FILTERS_COUNT_SMALL_MOBILE = 4;
const MAX_CHANNEL_CUISINES_CHIPS_TAGS = 5;
const MAX_STREAM_CUISINES_CHIPS_TAGS = 2;
const MAX_STREAM_LIFESTYLE_CHIPS_TAGS = 1;
const MAX_STREAM_MEAL_CHIPS_TAGS = 1;

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() filtersPageType: 'channels' | 'streams' = 'channels';

  @ViewChild('filtersModal') filtersModal!: ModalComponent;

  @Output() appliedFilters: EventEmitter<AppliedFilters> = new EventEmitter<AppliedFilters>();

  readonly FilterSectionName = FilterSectionName;

  chipsFilters: FilterOption[] = [];
  cuisines: FilterOption[] = [];
  subCuisines: FilterOption[] = [];
  tagsDiet: FilterOption[] = [];
  tagsLifestyle: FilterOption[] = [];
  tagsMealCategory: FilterOption[] = [];
  tagsMealType: FilterOption[] = [];
  sortBy: SortBy;
  sortOptions: SortOptions[];
  isShownAllCuisines = false;
  isShownAllSubCuisines = false;
  isShownAllTagsDiet = false;
  isShownAllTagsLifestyle = false;
  isShownAllTagsMealCategory = false;
  isShownAllTagsMealType = false;
  maxCuisinesFiltersCount: number;
  maxFilterCountLargeSection: number;
  maxFilterCountSmallSection: number;
  maxSubCuisinesFiltersCount: number;
  private appliedSortBy: SortBy;

  @HostListener('window:resize', ['$event'])
  setMaxFiltersCount(): void {
    if (this.isChannelFiltersPageType) {
      this.maxCuisinesFiltersCount = FILTERS_COUNT_EXTRA_BIG;
      this.maxSubCuisinesFiltersCount =
        window.innerWidth > FILTERS_COLUMNS_BREAKPOINT ?
          FILTERS_COUNT_BIG_DESKTOP : FILTERS_COUNT_BIG_MOBILE;

      return;
    }

    // streams page
    if (window.innerWidth > FILTERS_COLUMNS_BREAKPOINT) {
      this.maxCuisinesFiltersCount = FILTERS_COUNT_BIG_DESKTOP;
      this.maxFilterCountLargeSection = FILTERS_COUNT_BIG_DESKTOP;
      this.maxSubCuisinesFiltersCount = FILTERS_COUNT_SMALL_DESKTOP;
      this.maxFilterCountSmallSection = FILTERS_COUNT_SMALL_DESKTOP;
    } else {
      this.maxCuisinesFiltersCount = FILTERS_COUNT_BIG_MOBILE;
      this.maxFilterCountLargeSection = FILTERS_COUNT_BIG_MOBILE;
      this.maxSubCuisinesFiltersCount = FILTERS_COUNT_SMALL_MOBILE;
      this.maxFilterCountSmallSection = FILTERS_COUNT_SMALL_MOBILE;
    }
  }

  get filtersCount(): number {
    return this.filtersButtonService.filtersCount;
  }

  constructor(
    private filtersButtonService: FiltersButtonService,
    private $gaService: GoogleAnalyticsService,
    private tokenService: TokenService,
    private profilesService: ProfilesService,
    private streamService: StreamsService,
  ) {}

  ngOnInit(): void {
    this.setMaxFiltersCount();
    this.setSortOptions();
    this.getFilters();
    this.filtersButtonService.buttonClick$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openModal());
    this.setDefaultSortBy();
  }

  ngAfterViewInit() {
    this.subscribeToCloseModal();
  }

  ngOnDestroy(): void {
  }

  get isChannelFiltersPageType(): boolean {
    return this.filtersPageType === 'channels';
  }

  openModal(): void {
    this.filtersModal.open();
    this.appliedSortBy = this.sortBy;
    const action = this.isChannelFiltersPageType ?
      FilterGAEvents.CHANNEL_FILTER_BUTTON_CLICK :
      FilterGAEvents.STREAM_FILTER_BUTTON_CLICK;

    this.$gaService.gtag('event', action, {
      profile_id: this.tokenService.getProfileId(),
    });
  }

  toggleChipsFilter(filter: FilterOption): void {
    filter.isApplied = !filter.isApplied;
    filter.isSelected = filter.isApplied;
    this.appliedFilters.emit(this.getAppliedFilters());
    this.calculateFiltersCount();

    if (filter.isApplied) {
      this.gtagForFilterSelection(filter);
    }
  }

  clearFilters(): void {
    this.filtersButtonService.filtersCount = 0;
    this.setDefaultSortBy();
    this.allFilters.forEach((filter) => {
      filter.isApplied = false;
      filter.isSelected = false;
    });
    this.filtersModal.close();
    this.appliedFilters.emit(this.getAppliedFilters());
  }

  applyFilters(): void {
    this.allFilters.forEach(filter => filter.isApplied = filter.isSelected);
    this.appliedSortBy = this.sortBy;
    this.calculateFiltersCount();
    this.appliedFilters.emit(this.getAppliedFilters());
    this.filtersModal.close();
  }

  toggleFiltersGroup(filterType: FilterSectionName): void {
    switch (filterType) {
      case FilterSectionName.cuisines:
        this.isShownAllCuisines = !this.isShownAllCuisines;
        break;
      case FilterSectionName.subCuisines:
        this.isShownAllSubCuisines = !this.isShownAllSubCuisines;
        break;
      case FilterSectionName.diet:
        this.isShownAllTagsDiet = !this.isShownAllTagsDiet;
        break;
      case FilterSectionName.mealType:
        this.isShownAllTagsMealType = !this.isShownAllTagsMealType;
        break;
      case FilterSectionName.mealCategory:
        this.isShownAllTagsMealCategory = !this.isShownAllTagsMealCategory;
        break;
      case FilterSectionName.lifestyle:
        this.isShownAllTagsLifestyle = !this.isShownAllTagsLifestyle;
        break;
    }
  }

  selectFilter(filter: FilterOption): void {
    if (filter.isSelected) {
      this.gtagForFilterSelection(filter);
    }
  }

  private get defaultSortBy(): SortBy {
    return this.isChannelFiltersPageType ? 'popular' : 'stoppedAt';
  }

  private get allFilters(): FilterOption[] {
    return this.cuisines.concat(this.subCuisines, this.tags);
  }

  private get tags(): FilterOption[] {
    return this.tagsDiet.concat(this.tagsLifestyle, this.tagsMealCategory, this.tagsMealType);
  }

  private calculateFiltersCount(): void {
    const getAppliedLength = (filters: FilterOption[]) => filters.filter(filter => filter.isApplied).length;

    this.filtersButtonService.filtersCount = [this.cuisines, this.subCuisines, this.tags]
      .map((filters) => getAppliedLength(filters))
      .reduce((a, b) => a + b, 0);
  }

  private getFilters(): void {
    const filters$ = this.isChannelFiltersPageType ?
      this.profilesService.getFilters() :
      this.streamService.getFilters();

    filters$
      .pipe(untilDestroyed(this))
      .subscribe(filters => this.setFilters(filters));
  }

  private setFilters(filters: MostPopularFilters): void {
    this.cuisines = filters.cuisines.map(cuisine => mapMostPopularToFilterOption(cuisine, FilterType.cuisine));
    this.subCuisines =
      filters.subCuisines.map(subCuisine => mapMostPopularToFilterOption(subCuisine, FilterType.cuisine));

    if (this.isChannelFiltersPageType) {
      this.chipsFilters = this.getChannelChipsFilters();
    } else {
      this.tagsDiet = filters.tags.diet.map(tag => mapMostPopularToFilterOption(tag, FilterType.tag));
      this.tagsLifestyle = filters.tags.lifestyle.map(tag => mapMostPopularToFilterOption(tag, FilterType.tag));
      this.tagsMealCategory =
        filters.tags.mealCategory.map(tag => mapMostPopularToFilterOption(tag, FilterType.tag));
      this.tagsMealType = filters.tags.mealType.map(tag => mapMostPopularToFilterOption(tag, FilterType.tag));
      this.chipsFilters = this.getStreamChipsFilters();
    }
  }

  private getAppliedFilters(): AppliedFilters {
    const appliedFilters = this.allFilters.filter(filter => filter.isApplied);

    return {
      sortBy: this.sortBy,
      cuisinesIds: appliedFilters.filter(filter => filter.type === FilterType.cuisine).map(cuisine => cuisine.id),
      tagIds: appliedFilters.filter(filter => filter.type === FilterType.tag).map(cuisine => cuisine.id),
    };
  }

  private setSortOptions(): void {
    this.sortOptions = this.isChannelFiltersPageType ? CHANNEL_SORT_OPTIONS : STREAMS_SORT_OPTIONS;
  }

  private gtagForFilterSelection(filter: FilterOption): void {
    const action = this.isChannelFiltersPageType ? FilterGAEvents.CHANNEL_TAG_CLICK : FilterGAEvents.STREAM_TAG_CLICK;

    this.$gaService.gtag('event', action, {
      profile_id: this.tokenService.getProfileId(),
      tag: filter.name,
    });
  }

  private subscribeToCloseModal(): void {
    this.filtersModal.closed
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFilterSelectionToApplied();
        this.resetSortByToApplied();
      });
  }

  private resetFilterSelectionToApplied(): void {
    this.allFilters.forEach(filter => {
      const { isSelected, isApplied } = filter;

      if (isApplied !== isSelected) {
        filter.isSelected = isApplied;
      }
    });
  }

  private resetSortByToApplied(): void {
    this.sortBy = this.appliedSortBy;
  }

  private setDefaultSortBy(): void {
    this.sortBy = this.defaultSortBy;
    this.appliedSortBy = this.sortBy;
  }

  private getStreamChipsFilters(): FilterOption[] {
    const veganTag = this.tagsDiet.find(tag => tag.name === 'Vegan');

    const chipsFilters = this.getTopItems(this.cuisines, MAX_STREAM_CUISINES_CHIPS_TAGS);

    if (veganTag) {
      chipsFilters.push(veganTag);
    }

    // top 2 cuisines, Vegan if any, top 1 lifestyle/occasion and top 1 meal category.
    return [
      ...chipsFilters,
      ...this.getTopItems(this.tagsLifestyle, MAX_STREAM_LIFESTYLE_CHIPS_TAGS),
      ...this.getTopItems(this.tagsMealCategory, MAX_STREAM_MEAL_CHIPS_TAGS),
    ];
  }

  private getChannelChipsFilters(): FilterOption[] {
    return [...this.getTopItems(this.cuisines, MAX_CHANNEL_CUISINES_CHIPS_TAGS)];
  }

  private getTopItems(items: FilterOption[], count: number): FilterOption[] {
    return [...items]
      .sort((a, b) => b.count - a.count)
      .filter(item => !/global|other/.test(item.name.toLowerCase()))
      .slice(0, count);
  }
}
