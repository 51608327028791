<div *ngIf="stream" class="top-stream__wrap">
  <div class="top-stream__thumbnail">
    <div class="overlay"></div>
    <div class="paid-indicator-container paid-indicator-container--card">
      <img
        *ngIf="isPaidIndicatorShown"
        class="paid-indicator"
        [ngSrc]="'assets/ui/images/svg/paid-indicator.svg'"
        alt="paid-indicator"
        width="24"
        height="24"
      />
    </div>

    <!-- STREAM BADGE >> -->
    <ng-container *ngIf="streamBadge">
      <div class="stream-status__badge badge">
        <img
          height="50"
          width="50"
          [ngSrc]="streamBadge"
          alt="badge"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="!streamBadge">
      <ng-container *ngIf="hasMushroomMonthChef && !stream.produced && stream.sponsored">
        <div class="stream-status__badge mushroom">
          <img [ngSrc]="'assets/ui/images/png/mushroom-badge.png'" alt="mushroom-month"/>
        </div>
      </ng-container>
      <ng-container *ngIf="hasEarthWeekChef">
        <div class="stream-status__badge earth-badge">
          <img
            [ngSrc]="'assets/ui/images/svg/earth-week.svg'"
            width="70"
            height="52"
            alt="earth-week"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="hasFireChef">
        <div class="stream-status__badge fire-badge">
          <img
            [ngSrc]="'assets/ui/images/svg/fire-badge.svg'"
            width="198"
            height="168"
            alt="fire-badge"
          />
        </div>
      </ng-container>
    </ng-container>
    <!-- << STREAM BADGE -->

    <div class="stream-status">
      <ng-container *ngIf="stream.status === streamStatus.LIVE">
        <app-views-counter></app-views-counter>
      </ng-container>

      <ng-container *ngIf="stream.status === streamStatus.PAST">
        <ng-container *ngIf="stream.produced">
          <div class="stream-status__past">
            <img
              [ngSrc]="'assets/ui/images/svg/show-indicator.svg'"
              width="42"
              height="28"
              alt="show-indicator" />
          </div>
        </ng-container>
        <ng-container *ngIf="!stream.produced">
          <div class="stream-status__date">{{ streamDuration | durationVideo }}</div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="stream.status === streamStatus.SCHEDULED">
      <div class="container-date">
        <div class="date">{{ stream.scheduledVideo.date | timezone }}</div>
      </div>
    </ng-container>

    <picture class="carousel-img">
      <img
        class="avatar carousel-img"
        [ngSrc]="stream.thumbnailUrl"
        height="198"
        width="352"
        alt="{{ stream.title }}"
      />
    </picture>
  </div>
  <app-access-indicator
    [isVideoPrivate]="stream.videoPrivate || stream.hidden"
    [isPurchased]="(stream.subscribed || stream.subscribedToClub) && !isVisibleIndicator"
  ></app-access-indicator>
  <div data-testid="stream-title" class="top-stream__title">{{ stream.title }}</div>
  <a
    class="top-stream__link"
    data-testid="stream-link"
    [target]="isNewTab ? '_blank' : '_self'"
    [routerLink]="'/' + chefProfile.chefSlug + '/streams/' + stream.slug"
    (click)="sendGAEvent()"
  ></a>
  <div class="top-stream__author">
    <a
      data-testid="channel-link"
      [routerLink]="'/' + chefProfile.chefSlug" *ngIf="!stream.collaborators?.length"
    >
      <app-avatar
        [url]="chefProfile.photo"
        [size]="'extra-small'"
        [name]="chefProfile.chefName"
        [title]="stream.profile ? stream.profile.channel.name : stream.channel.name"
        [isLive]="chefProfile.isLive"
        [isClub]="!!stream.requiredClubSubscriptionPlan"
        [avatarInputTextColor]="'green'"
      ></app-avatar>
    </a>
    <app-multiple-avatars *ngIf="stream.collaborators?.length" [chefs]="stream.collaborators"></app-multiple-avatars>
  </div>
  <app-learn-more-button
    *ngIf="isLearnMoreButton"
    class="top-stream__buy-button"
    (goToLink)="goToLink()"
  ></app-learn-more-button>
</div>
