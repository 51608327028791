import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild, ViewEncapsulation,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  GA_EVENT_CUSTOM_BUTTON_CLICK,
  GA_EVENT_FOLLOW_BUTTON_CLICK,
  GA_EVENT_HEART_BUTTON_CLICK,
  MAX_ITEMS_PER_PAGE,
} from '@kitch/data-access/constants';
import {
  AppPagesItemSeries,
  HardcodedSeriesEntry,
  Stream,
  StreamStatus,
} from '@kitch/data-access/models';
import { ChatService, FollowsService, StreamsService, TokenService } from '@kitch/data-access/services';
import { sortByScheduledVideoDate, sortByStartedAtDate } from '@kitch/util';
import { CustomButton } from '@kitch/ui/models/custom-button-form';
import { VideoTimestamp } from '@kitch/ui/models/video-timestamp.form';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { StreamReplayService } from '@kitch/user/core/stream-replay.service';
import { TipsButtonsComponent } from '@kitch/user/shared/components/tips-buttons/tips-buttons.component';
import {
  COMING_UP_STREAM_CAROUSEL_DESKTOP_HEIGHT,
  COMING_UP_STREAM_CAROUSEL_DESKTOP_WIDTH,
} from '@kitch/user/shared/constants/upcoming-streams-carousels-size';
import { TipChefEvent } from '@kitch/user/shared/models';
import { GoogleTrackerService } from '@kitch/user/shared/services/google-tracker.service';
import { UpcomingSteamsCarouselSizeTool } from '@kitch/user/shared/util/upcoming-steams-carousel-size.tool';

@UntilDestroy()
@Component({
  selector: 'app-stream-info',
  templateUrl: './stream-info.component.html',
  styleUrls: ['./stream-info.component.scss'],
  encapsulation: ViewEncapsulation.None, // for styles in innerHtml
})
export class StreamInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stream: Stream;
  @Input() isChefOrAdmin: boolean;
  @Input() isDesktopView: boolean;
  @Input() isAskChefLoaded: boolean;
  @Input() isChefStreamOwner: boolean;
  @Input() isScheduled: boolean;
  @Input() walmartRecipeId: number;
  @Input() walmartRecipePortions: number;
  @Input() isTipsSuccessMessageShown: boolean;
  @Input() tipsChefCount: number;
  @Input() chatId: string;

  @Output() sendTranslation: EventEmitter<string> = new EventEmitter<string>();
  @Output() openSubscribeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tipsSuccessMessageToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() tipToChef: EventEmitter<TipChefEvent> = new EventEmitter<TipChefEvent>();

  @ViewChild('descriptionInner') private readonly descriptionInner: ElementRef;
  @ViewChild('descriptionContainer') private readonly descriptionContainer: ElementRef;
  @ViewChild('tipsButtons') private readonly tipsButtons: TipsButtonsComponent;

  private tipsSound = isPlatformBrowser(this.platformId) ? new Audio('assets/ui/sounds/tips.mp3') : null;

  streamStatuses = StreamStatus;
  buttons: CustomButton[];
  isFullDescription = false;
  isVideoChapters = false;
  isFollowing = false;
  isChatShown: boolean;
  favoriteStatus: boolean;
  iconUrl: string;
  profileId: string;
  upcomingStreamsInSeries: Stream[] | undefined;
  pastStreamsInSeries: Stream[] | undefined;
  widthCarouselCell = COMING_UP_STREAM_CAROUSEL_DESKTOP_WIDTH;
  heightCarouselCell = COMING_UP_STREAM_CAROUSEL_DESKTOP_HEIGHT;

  constructor(
    private chatService: ChatService,
    private followsService: FollowsService,
    private liveStreamStatusService: LiveStreamStatusService,
    private tokenService: TokenService,
    private streamsService: StreamsService,
    private $gaService: GoogleAnalyticsService,
    private googleTrackerService: GoogleTrackerService,
    private streamReplayService: StreamReplayService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window,
  ) {}

  get isTranslateFormAvailable(): boolean {
    return this.isChefOrAdmin &&
      (this.stream.status === this.streamStatuses.PREPMODE || this.stream.status === this.streamStatuses.LIVE);
  }

  get isStreamOwnerContentPresent(): boolean {
    return this.isChefStreamOwner && (this.stream.status === this.streamStatuses.PAST ||
      this.stream.status === this.streamStatuses.LIVE || !this.isScheduled);
  }

  get isProfileShown(): boolean {
    return this.isStreamOwnerContentPresent ||
      (this.stream.status !== this.streamStatuses.SCHEDULED && !this.isChefStreamOwner);
  }

  ngOnInit(): void {
    this.favoriteStatus = this.stream.liked;
    this.profileId = this.tokenService.getProfileId();
    this.getFavoriteButtonImg(this.favoriteStatus);
    this.updateFollowingStatus();
    setTimeout(() => {
      if (this.tipsButtons) {
        this.subscribeOnTipsButtonsClick();
      }
    }, 100);
    this.checkStreamToBelongsToSeries();
    if (isPlatformBrowser(this.platformId)) {
      this.changeWidthAndHeightUpcomingStreamCards(this.window.innerWidth);
      this.subscribeToWindowResize();
    }
    this.streamReplayService.videoDuration$
      .pipe(
        tap((minutes) => {
          this.isVideoChapters = this.stream.timestamps
            .some((value: VideoTimestamp): boolean => minutes > +value.streamSecond);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.isAskChefLoaded || change.isScheduled || change.isDesktopView) {
      this.setIsChatShown();
    }
  }

  ngOnDestroy() {}

  onSubmitTranslate($event: string): void {
    this.sendTranslation.emit($event);
  }

  toggleDisplayDescription(): void {
    const heightOfDescription = this.descriptionInner.nativeElement.getBoundingClientRect().height;

    if (heightOfDescription <= 24) {
      this.isFullDescription = true;
    } else if (heightOfDescription > 24) {
      this.isFullDescription = false;
    }
  }

  toggleLikeUnlike(): void {
    if (!this.favoriteStatus) {
      this.streamsService.like(this.stream.id).subscribe((res) => {
        if (!res.success) return;

        this.favoriteStatus = true;
        this.getFavoriteButtonImg(this.favoriteStatus);

        this.$gaService.gtag('event', GA_EVENT_HEART_BUTTON_CLICK, { profile_id: this.profileId });
      });
    } else {
      this.streamsService.unlike(this.stream.id).subscribe((res) => {
        if (!res.success) return;

        this.favoriteStatus = false;
        this.getFavoriteButtonImg(this.favoriteStatus);
      });
    }
  }

  getFavoriteButtonImg(favoriteStatus: boolean): void {
    this.iconUrl = favoriteStatus ?
      'assets/ui/images/svg/bookmark--checked.svg' :
      'assets/ui/images/svg/black-bookmark.svg';
  }

  trackCustomButtonClick(button: CustomButton): void {
    this.$gaService.gtag('event', GA_EVENT_CUSTOM_BUTTON_CLICK, {
      profile_id: this.profileId,
      url: button.url,
    });
  }

  toggleFollowUnfollow(id: string): void {
    if (this.isFollowing) {
      this.followsService.unfollow(id).pipe(
        tap((resp) => {
          if (resp.success) {
            this.isFollowing = false;
          }
        }),
      ).subscribe();
    } else {
      this.followsService.follow(id).pipe(
        tap((resp) => {
          if (resp.success) {
            this.isFollowing = true;
            this.$gaService.gtag('event', GA_EVENT_FOLLOW_BUTTON_CLICK, { profile_id: this.profileId });
          }
        }),
      ).subscribe();
    }
  }

  onClubBtnClick(joinStream: boolean): void {
    this.openSubscribeModal.emit(joinStream);
  }

  onStreamDescriptionClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      this.googleTrackerService.trackViewProductLink(event.target.text, event.target.href, 'stream_page');
    }
  }

  private updateFollowingStatus(): void {
    this.followsService.getFollowing({
      page: 1,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      profileId: this.profileId,
    }).pipe(
      map((res) => res.results.filter((res) => res.id === this.stream.channel.chefProfile.id)),
      tap((res) => this.isFollowing = !!res.length),
    ).subscribe();
  }

  private subscribeOnTipsButtonsClick(): void {
    let amount = 0;
    const callback = (value) => {
      if (value >= 5) {
        this.tipsButtons.isShownDropdown = false;
        this.tipsSuccessMessageToggle.emit(true);
        setTimeout(() => {
          this.tipsSuccessMessageToggle.emit(false);
        }, 4000);
      }
      this.tipsSound.volume = 0.5;
      this.tipsSound.play();
    };

    this.tipsButtons.tipToChef
      .pipe(
        tap((value: number) => {
          amount += value;
          this.tipsChefCount = amount;
        }),
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.tipToChef.emit({ amount, callback });
        amount = 0;
      });
  }

  private checkStreamToBelongsToSeries(): void {
    this.streamsService.getHardcodedSeries()
      .pipe(untilDestroyed(this))
      .subscribe(({ hardcodedSeries }) => {
        const streamSeries = hardcodedSeries.find(({ dummyVideo, videoIds }) => {
          const streamId = this.stream.id;

          return streamId === dummyVideo.id || videoIds.includes(streamId);
        });

        if (streamSeries) {
          this.setStreamsInSeries(streamSeries);
        }
      });
  }

  private setStreamsInSeries(hardcodedSeries: HardcodedSeriesEntry): void {
    this.streamsService.getHardcodedSeriesDetails()
      .pipe(
        map((itemSeries: AppPagesItemSeries<Stream>) => {
          const { videoIds, dummyVideo } = hardcodedSeries;
          const dummyVideoInfo = itemSeries.hardcodedSeries.find(({ id }) => id === dummyVideo.id);
          const dummyVideoIsPublic = !(dummyVideoInfo.videoPrivate || dummyVideoInfo.hidden);

          return itemSeries.hardcodedSeries
            .filter(({ id }) => {
              if (id === dummyVideo.id) {
                return dummyVideoIsPublic;
              }

              return videoIds.includes(id);
            })
            .filter(({ id }) => id !== this.stream.id);
        }),
        untilDestroyed(this),
      )
      .subscribe((streams: Stream[]) => {
        const getStreamsByStatus = (status: StreamStatus) => streams.filter((stream) => stream.status === status);

        this.upcomingStreamsInSeries = getStreamsByStatus(StreamStatus.SCHEDULED).sort(sortByScheduledVideoDate);
        this.pastStreamsInSeries = getStreamsByStatus(StreamStatus.PAST).sort(sortByStartedAtDate);
      });
  }

  private subscribeToWindowResize(): void {
    fromEvent(this.window, 'resize')
      .pipe(
        map(() => this.window.innerWidth),
        distinctUntilChanged(),
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe((width) => {
        this.changeWidthAndHeightUpcomingStreamCards(width);
      });
  }

  private changeWidthAndHeightUpcomingStreamCards(width: number): void {
    this.widthCarouselCell = UpcomingSteamsCarouselSizeTool.calculateWidth(width);
    this.heightCarouselCell = UpcomingSteamsCarouselSizeTool.calculateHeight(width);
  }

  private setIsChatShown(): void {
    this.isChatShown = this.isAskChefLoaded && this.isDesktopView;
  }
}
