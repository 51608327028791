import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { SaveSpotGAEvent } from '@kitch/data-access/constants';
import { SimpleResponse, StagedChannel, Stream, StreamChefProfile } from '@kitch/data-access/models';
import { SaveSpotParams, TokenService, UserService } from '@kitch/data-access/services';
import { SaveSpotService } from '@kitch/data-access/services/save-spot.service';
import { EmailValidator } from '@kitch/util/validators';
import { ModalComponent } from '@kitch/ui/components';

interface EmailForm {
  email: FormControl<string|null>;
}

interface SaveSpotGAParams {
  email: string,
  guest: boolean,
  profile_id: string,
  stream_name: string,
}

@UntilDestroy()
@Component({
  selector: 'app-save-my-spot-button',
  templateUrl: './save-my-spot-button.component.html',
  styleUrls: ['./save-my-spot-button.component.scss'],
})
export class SaveMySpotButtonComponent implements OnInit, OnDestroy {
  // for binding to element which is not exist in the template(ngIf=false)
  @ViewChild('addToCalendarModal') set calendarModalContent(calendarModalContent: ModalComponent) {
    if (calendarModalContent) {
      this.addToCalendarModal = calendarModalContent;
    }
  }

  @ViewChild('emailModal') set content(content: ModalComponent) {
    if (content) {
      this.emailModal = content;
    }
  }

  @Input() stream: Stream;
  @Input() isShownIcon: boolean;
  @Input() showAddToCalendarBtnByDefault = false;

  chefProfile: StreamChefProfile | StagedChannel;
  isAddToCalendarModalShown: boolean;
  isEmailModalShown: boolean;
  isGuest: boolean;
  isSpotSaved: boolean;
  emailForm: FormGroup<EmailForm>;
  email: string;

  private addToCalendarModal: ModalComponent;
  private emailModal: ModalComponent;

  constructor(
    private saveSpotService: SaveSpotService,
    private tokenService: TokenService,
    private userService: UserService,
    private $gaService: GoogleAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.isGuest = this.tokenService.isGuest();
    this.isSpotSaved = this.saveSpotService.isSpotSavedLocally(this.stream.id);
  }

  ngOnDestroy(): void {}

  get userTimezoneOffset(): number {
    return (new Date()).getTimezoneOffset();
  }

  onSaveMySpotClick(): void {
    if (this.isSpotSaved) {
      this.showAddToCalendarModal();

      return;
    }

    if (this.isGuest) {
      this.isEmailModalShown = true;
      this.initEmailForm();
      setTimeout(() => this.emailModal.open());
      this.sendGAEvent(SaveSpotGAEvent.GA_EVENT_SAVE_SPOT_BUTTON_CLICK);
    } else {
      this.saveSpotForRegisteredUser();
    }
  }

  onEmailSubmit(): void {
    this.email = this.emailForm.get('email').value;
    this.saveSpotOnBackend()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response) => {
        if (response.success) {
          this.isEmailModalShown = false;
          this.showAddToCalendarModal();

          this.saveSpotService.saveSpotLocally(this.stream.id);
          this.isSpotSaved = true;
          this.sendGAEvent(SaveSpotGAEvent.GA_EVENT_SAVE_SPOT_EMAIL_SUBMIT);
        }
      });
  }

  private initEmailForm(): void {
    this.emailForm = new FormGroup<EmailForm>({
      email: new FormControl(null, [Validators.required, EmailValidator()]),
    });
  }

  private saveSpotForRegisteredUser(): void {
    const userId = this.tokenService.getIdFromToken();

    this.userService.getUserById(userId).pipe(
      tap((user) => {
        this.email = user.email;
      }),
      concatMap(() => this.saveSpotOnBackend()),
      untilDestroyed(this),
    )
      .subscribe((resp) => {
        if (resp.success) {
          this.showAddToCalendarModal();
          this.saveSpotService.saveSpotLocally(this.stream.id);
          this.isSpotSaved = true;
          this.sendGAEvent(SaveSpotGAEvent.GA_EVENT_SAVE_SPOT_BUTTON_CLICK);
        }
      });
  }

  private saveSpotOnBackend(): Observable<SimpleResponse> {
    const params: SaveSpotParams = {
      email: this.email,
      timezoneOffset: this.userTimezoneOffset,
      videoId: this.stream.id,
    };

    return this.saveSpotService.saveSpot(params);
  }

  private sendGAEvent(eventName: SaveSpotGAEvent): void {
    const params = this.getGAParams();

    this.$gaService.gtag('event', eventName, params);
  }

  private getGAParams(): SaveSpotGAParams {
    return {
      email: this.email,
      guest: this.isGuest,
      profile_id: this.tokenService.getProfileId(),
      stream_name: this.stream.title,
    };
  }

  private showAddToCalendarModal(): void {
    this.isAddToCalendarModalShown = true;
    setTimeout(() => this.addToCalendarModal.open());
  }
}

