<section class="video-chapters">
  <h3 class="video-chapters__title">Chapters</h3>
  <div *ngFor="let timestamp of timestamps">
    <button
      *ngIf="videoDuration > timestamp.streamSecond"
      class="video-chapters__button"
      (click)="onShowVideoChapter(timestamp.streamSecond)"
    >
      <span class="video-chapters__button-time">{{ timestamp.streamSecond | convertMinutesToTime }}</span>
      <h4 class="video-chapters__button-title">{{ timestamp.label }}</h4>
    </button>
  </div>
</section>
