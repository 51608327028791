<div
  class="shop-card"
  [ngClass]="{'shop-card--upcoming-stream': source === 'upcoming_stream_shop'}"
>
  <p class="shop-card__subtitle">Get cooking</p>
  <p class="shop-card__title">Buy this stream’s ingredients</p>
  <div class="shop-card__wrapper">
    <app-walmart-button
      class="shop-card__walmart-button"
      [title]="'Add to cart'"
      [walmartRecipeId]="walmartRecipe.walmartRecipeId"
      [walmartRecipePortions]="walmartRecipe.portions"
      [pageTypeButton]="'streamshoptab'"
    ></app-walmart-button>
    <app-button
      class="shop-card__favorite-button"
      appNeedAuthorize
      [source]="'like recipe'"
      [text]="''"
      [iconUrl]="iconUrl"
      [role]="'secondary'"
      (clicked)="onFavoriteButtonClick()"
      [activeClass]="favoriteStatus"
      [buttonClass]="'liked'"
      [isDisabled]="isDisabled"
      [iconHeight]="29"
      [iconWidth]="20"
    ></app-button>
  </div>
  <a
    class="shop-card__link"
    target="_blank"
    [routerLink]="walmartRecipeUrl"
    (click)="sendGAEvent()"
  ></a>
</div>

<div class="shop-card__separator"></div>
