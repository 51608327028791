import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { LoggerModule, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { environment } from '@kitch/data-access/env/environment';
import { SentryErrorHandler } from '@kitch/data-access/interceptors/sentry-error-handler.service';
import { MyLoggerHttpService } from '@kitch/user/core/logger-http.service';
import { CustomRouteReuseStrategy } from '@kitch/user/shared/route-strategies/custom-route-reuse.strategy';
import { UserSharedModule } from '@kitch/user/shared/user-shared.module';
import { AppComponent } from './app.component';
import { UserCoreModule } from './core/user-core.module';
import { UserRoutingModule } from './user-routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    UserRoutingModule,
    UserCoreModule,
    UserSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot(
      environment.logging,
      {
        serverProvider: {
          provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: MyLoggerHttpService,
        },
      },
    ),
    SocialLoginModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.ssoMetaClientId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    // Date pipe provide for ngx-logger
    {
      provide: DatePipe,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    provideClientHydration(),
  ],
})
export class UserModule {}
