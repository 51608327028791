<div class="wrap" [ngClass]="{ 'wrap--for-stub': isShowVideoStub && !isTabsPresent }">
  <ng-container *ngIf="!isShowVideoStub; else videoStubTmpl">
    <app-stream-video [stream]="stream"></app-stream-video>
  </ng-container>

  <div
    class="replay-tabs"
    *ngIf="isTabsPresent"
  >
    <app-tabs
      ngSkipHydration
      (switchedTab)="onTabSwitched()"
    >
      <app-stream-info
        tab-0
        *ngIf="!isDesktopView"
        [isDesktopView]="isDesktopView"
        [stream]="stream"
        [isChefOrAdmin]="isChefOrAdmin"
        [isAskChefLoaded]="isAskChefLoaded"
        [isChefStreamOwner]="isChefStreamOwner"
        [walmartRecipeId]="walmartRecipeId"
        [walmartRecipePortions]="walmartRecipePortions"
        [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
        [tipsChefCount] ="tipsChefCount"
        (tipToChef)="emitTipToChef($event)"
        (openSubscribeModal)="onClubBtnClick($event)"
        (sendTranslation)="onSubmitTranslate($event)"
      ></app-stream-info>

      <app-openweb-chat
        tab-1
        *ngIf="!isDesktopView && isAskChefLoaded"
        [chatId]="chatId"
      ></app-openweb-chat>

      <app-replay-chef-table
        tab-2
        *ngIf="chefTableVisitors.length"
        [chefTableVisitors]="chefTableVisitors"
      ></app-replay-chef-table>

      <app-recipe-live-tab
        tab-3
        *ngIf="recipes[0]"
        [recipeId]="recipes[0].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_FIRST"
      ></app-recipe-live-tab>

      <app-recipe-live-tab
        tab-4
        *ngIf="recipes[1]"
        [recipeId]="recipes[1].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_SECOND"
      ></app-recipe-live-tab>

      <app-recipe-live-tab
        tab-5
        *ngIf="recipes[2]"
        [recipeId]="recipes[2].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_THIRD"
      ></app-recipe-live-tab>

      <app-shop-live-tab
        tab-6
        *ngIf="stream.products.length || walmartRecipe"
        [products]="stream.products"
        [walmartRecipe]="walmartRecipe"
      ></app-shop-live-tab>
    </app-tabs>
  </div>
</div>

<ng-template #videoStubTmpl>
  <ng-container [ngTemplateOutlet]="videoStubTmplRef" [ngTemplateOutletContext]="{ isTabsPresent }"></ng-container>
</ng-template>
