<app-modal data-testid="confirm-purchase-modal" [showCloseIcon]="false" #confirmPurchaseModal>
  <ng-container header>
    <h2 class="modal-title">Confirm purchase</h2>
  </ng-container>

  <ng-container *ngIf="purchaseData" body>
    <div class="modal-content-wrap">
      <div class="content">
        <div class="confirm-purchase-details confirm-purchase-details--stream-title">
          <span class="confirm-purchase-details__label">
            {{ purchaseData.title }}
            <ng-container *ngIf="!isClub">
              {{ purchaseData.status === streamStatus.PAST ? 'show' : 'livestream' }}
            </ng-container>
          </span>
          <span class="confirm-purchase-details__value">
            <ng-container *ngTemplateOutlet="price; context: { price: purchaseData.requiredSubscriptionPlan.price }"></ng-container>
          </span>
        </div>
        <div class="confirm-purchase-details confirm-purchase-details--stream-info">
          <span class="confirm-purchase-details__label">{{ purchaseData.chefProfile.chefName }}</span>
          <span class="confirm-purchase-details__value">One-time charge</span>
        </div>

        <ng-container *ngIf="!isStripeError; else stripeError">
          <div class="confirm-purchase-details">
            <span class="confirm-purchase-details__label">Subtotal</span>
            <span class="confirm-purchase-details__value">
              <ng-container *ngTemplateOutlet="price; context: { price: subTotalPrice }"></ng-container>
            </span>
          </div>

          <!-- DISCOUNT CALCULATED VALUE >> -->
          <div *ngIf="discountValue" class="confirm-purchase-details confirm-purchase-details--discount">
            <ng-container [ngSwitch]="discountType">
              <ng-container *ngSwitchCase="discountTypes.PERCENT">
                <span class="confirm-purchase-details__label">
                  Discount Code Applied ({{ discountValue }}% off)
                </span>
                <span class="confirm-purchase-details__value">
                  -<ng-container *ngTemplateOutlet="price; context: { price: priceDiscountFromPercent }"></ng-container>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="discountTypes.FIXED">
                <span class="confirm-purchase-details__label">
                  Discount Code Applied
                </span>
                <span class="confirm-purchase-details__value">
                  -<ng-container *ngTemplateOutlet="price; context: { price: priceDiscount }"></ng-container>
                </span>
              </ng-container>
            </ng-container>
          </div>
          <!--<< DISCOUNT CALCULATED VALUE-->

          <!-- TAX >> -->
          <div *ngIf="purchaseCurrency === 'USD'" class="confirm-purchase-details">
            <span class="confirm-purchase-details__label">Sales Tax</span>
            <span class="confirm-purchase-details__value">
              <ng-container *ngTemplateOutlet="price; context:{ price: checkoutInfo?.amountTax }"></ng-container>
            </span>
          </div>
          <!--<< TAX-->

          <div class="confirm-purchase-details confirm-purchase-details--total">
            <span class="confirm-purchase-details__label">Total</span>
            <span class="confirm-purchase-details__value">
              <ng-container *ngTemplateOutlet="price; context:{ price: totalPrice }"></ng-container>
            </span>
          </div>

          <!-- DISCOUNT INPUT >>-->
          <button *ngIf="!isDiscountInputShowed && !discountValue" class="add-discount-btn" (click)="addDiscount()">
            Enter Discount Code
          </button>

          <div *ngIf="isDiscountInputShowed">
            <form class="discount-form" [formGroup]="discountForm" (submit)="applyDiscount()">
              <div>
                <input formControlName="discount"
                  placeholder="Discount Code"
                  appWasInvolved
                  class="form-input">
                <button
                  type="submit"
                  [disabled]="isDiscountApplying"
                  class="apply-discount-btn">
                  Apply
                </button>
                <app-errors-list formControlName="discount"></app-errors-list>
              </div>
            </form>
          </div>
          <!--<< DISCOUNT INPUT -->
        </ng-container>

        <ng-container *ngIf="purchaseCurrency === 'USD'; else clamsPaymentMethod">
          <app-credit-card *ngIf="card" [card]="card"></app-credit-card>
        </ng-container>
      </div>

      <!-- GIFT SERIES AND CLUB >>-->
        <form
          *ngIf="isBuyGift || isClub"
          class="gift-form"
          [formGroup]="giftForm"
        >
          <label class="checkmark gift-form__checkmark">
            <input
              type="checkbox"
              class="checkmark__input"
              formControlName="isGift"
            />
            <span class="checkmark__label"></span>
            <span class="text checkmark__text">This purchase is a gift</span>
          </label>

          <ng-container *ngIf="controls.isGift.value">
            <h2 class="gift-form__title">Who’s the lucky recipient?</h2>
            <div class="gift-form__field-wrapper">
              <div class="form__field form__field--left">
                <app-animated-input
                  formControlName="receiverFirstName"
                  label='First Name*'
                ></app-animated-input>
                <app-errors-list formControlName="receiverFirstName"></app-errors-list>
              </div>
              <div class="form__field">
                <app-animated-input
                  formControlName="receiverLastName"
                  label='Last Name'
                ></app-animated-input>
              </div>
            </div>
            <div class="form__field">
              <app-animated-input
                formControlName="receiverEmail"
                label='Email Address*'
              ></app-animated-input>
              <app-errors-list formControlName="receiverEmail"></app-errors-list>
            </div>
            <span class="text gift-form__info-text">
              Don't worry, we're only sending the confirmation email to you, so you can gift when you want.
            </span>
          </ng-container>
        </form>
      <!--<< GIFT SERIES AND CLUB -->
    </div>

    <!--BUY CANCEL BUTTONS >>-->
    <div class="form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          class="modal-button buy-clams-button"
          data-testid="buy-btn"
          [text]="buttonTitle"
          (clicked)="buy()"
          [isDisabled]="isBuyBtnDisabled"
        ></app-button>
      </div>
      <div class="form-button form-button--close">
        <app-button
          role="'secondary'"
          class="modal-button"
          [text]="'cancel'"
          (clicked)="closeModal()"
        ></app-button>
      </div>
    </div>
    <!--<< BUY CANCEL BUTTONS -->

    <!-- FOOTER >>-->
    <div class="footer-text">
      For questions or refund requests, please email <a class="contact-link" href="mailto:support@kittch.com">support@kittch.com</a>.
      <div>
        <ng-container *ngIf="purchaseData.status === streamStatus.PAST; else otherStreams">
          Refunds requested prior to 14 days after purchase are available at the discretion of Kittch.
        </ng-container>
        <ng-template #otherStreams>
          Refunds are available at the discretion of the creator before the start of the livestream.
        </ng-template>
      </div>
    </div>
    <!--<< FOOTER -->
  </ng-container>
</app-modal>

<ng-template #price let-price="price">
  <ng-container *ngIf="purchaseCurrency === 'USD'; else clamsPrice">
    <ng-container *ngIf="price || price === 0; else calculating">
      {{ price | customCurrency: 'USD' }}
    </ng-container>
  </ng-container>

  <ng-template #clamsPrice>
    <span class="clams">
      <span class="clams__text">{{ price | customCurrency: 'CLAMS': false }}</span>
      <img
        src="assets/ui/images/svg/clam.svg"
        width="48"
        height="48"
        alt="clam"
      />
    </span>
  </ng-template>
</ng-template>

<ng-template #clamsPaymentMethod>
  <div class="clams-payment-method">
    <div class="clams-payment-method__title">Payment Method</div>
    <div class="clams-payment-method__details">
      <img src="assets/ui/images/svg/clams-4.svg" alt="clams">
      <div>
        <div class="clams-method-name">Clams</div>
        <div class="clams-method-description">Deducted from your Clams balance</div>
      </div>
      <a class="clams-balance-link"
        [routerLink]="isChefAccount ? '/profile/rewards' : '/user-profile/' + profileId + '/clams'"
        (click)="closeAllModals()"
      >View</a>
    </div>
  </div>
</ng-template>

<ng-template #calculating>
  <span class="animated--fade-in">
    Calculating...
  </span>
</ng-template>

<ng-template #stripeError>
  <p class="confirm-purchase-details confirm-purchase-details--stripe-error">
    We have some issues with checkout, try again after reloading or use another card.
  </p>
</ng-template>

<app-thank-you-purchase-modal #rootThankYouPurchaseModal
  [isGift]="controls.isGift.value"
  [isClub]="isClub"
  [isHardcodedSeries]="isHardcodedSeries"
  [purchaseData]="purchaseData"
></app-thank-you-purchase-modal>
