import { Stream } from '@kitch/data-access/models';
import { sortByJSONDate } from '@kitch/util/date.tool';

export const sortByScheduledVideoDate = (firstStream: Stream, secondStream: Stream): number => {
  return sortByJSONDate(firstStream.scheduledVideo.date, secondStream.scheduledVideo.date);
};

export const sortByStartedAtDate = (firstStream: Stream, secondStream: Stream): number => {
  return sortByJSONDate(firstStream.startedAt, secondStream.startedAt);
};
