import { isPlatformBrowser } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { MAX_ITEMS_PER_PAGE, RtmMassageType } from '@kitch/data-access/constants';
import {
  AgoraStreamToken,
  AppPagesItem,
  AudienceViewerUser,
  ChefTableVisitorInfo,
  CoHostInfo,
  Recipe,
  RtmMessage,
  Stream,
  StreamStatus,
  StreamUserRole,
  TipsInfo,
  VisitorAction,
} from '@kitch/data-access/models';
import { TipSearchParams } from '@kitch/data-access/models/search-params';
import {
  AudienceService,
  ChefTableService,
  LoggerService,
  ProfilesService,
  StreamsService,
  TipsService,
  TokenService,
} from '@kitch/data-access/services';
import { AgoraRTCTool, AgoraRTMTool } from '@kitch/util';
import { opacityAnimation } from '@kitch/ui/animations/opacity-animation';
import { LiveReplayTabsService } from '@kitch/user/core/live-replay-tabs.service';
import { LiveStreamStatusService } from '@kitch/user/core/live-stream-status.service';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import {
  AgoraStreamInfo,
  DevicesFormValue,
  PipCameraPositions,
  StreamTracks,
  TabId,
  TipChefEvent,
  ToggleUserAudioEvent,
  UserInfo,
} from '@kitch/user/shared/models';
import { AgoraStreamService } from '@kitch/user/shared/services/agora-stream.service';

@UntilDestroy()
@Component({
  selector: 'app-agora-stream',
  templateUrl: './agora-stream.component.html',
  styleUrls: ['./agora-stream.component.scss'],
  animations: [opacityAnimation],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgoraStreamComponent implements OnInit, OnDestroy {
  @Input() stream: Stream;
  @Input() streamInfo: AgoraStreamInfo;
  @Input() rtmChannel: RtmChannel;
  @Input() askChef: ElementRef;
  @Input() isAskPopup: boolean;
  @Input() isSpeakerPopup: boolean;
  @Input() isTipsSuccessMessageShown: boolean;
  @Input() isDesktopView: boolean;
  @Input() isChefOrAdmin: boolean;
  @Input() isAskChefLoaded: boolean;
  @Input() isChefStreamOwner: boolean;
  @Input() walmartRecipeId: number;
  @Input() walmartRecipePortions: number;
  @Input() tipsChefCount: number;
  @Input() recipes: Recipe[];
  @Input() chatId: string;

  @Output() streamStatusChange: EventEmitter<StreamStatus> = new EventEmitter<StreamStatus>();
  @Output() streamViewCountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() userJoin: EventEmitter<void> = new EventEmitter<void>();
  @Output() tipToChef: EventEmitter<TipChefEvent> = new EventEmitter<TipChefEvent>();
  @Output() updateBalance: EventEmitter<void> = new EventEmitter<void>();
  @Output() statusPopup: EventEmitter<boolean> = new EventEmitter<any>();
  @Output() tipsSuccessMessageToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() streamRecordStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSubscribeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendTranslation: EventEmitter<string> = new EventEmitter<string>();

  walmartRecipe: Recipe;
  isCoHostsMode = false;

  private VIDEO = 'video';
  private AUDIO = 'audio';

  private MAX_TABLE_SEATS = 8;

  protected clapSound = isPlatformBrowser(this.platformId) ? new Audio('assets/ui/sounds/clap.mp3') : null;
  protected handSound = isPlatformBrowser(this.platformId) ? new Audio('assets/ui/sounds/hand.mp3') : null;
  protected tipsSound = isPlatformBrowser(this.platformId) ? new Audio('assets/ui/sounds/tips.mp3') : null;

  localTracks: StreamTracks = {
    videoTrack: null,
    audioTrack: null,
  };

  emojiIcons = [
    'assets/ui/images/png/emoji/cheers.png',
    'assets/ui/images/png/emoji/chef-kiss.png',
    'assets/ui/images/png/emoji/clap.png',
    'assets/ui/images/png/emoji/heart-eyes.png',
    'assets/ui/images/png/emoji/mmm.png',
  ];

  secondCameraVideoTrack: ICameraVideoTrack = null;

  audienceUsers: AudienceViewerUser[];

  agoraRTMTool: AgoraRTMTool;
  tipsInfo: TipsInfo;

  protected readonly agoraRTCTool = new AgoraRTCTool({
    initialVideoProfileId: '720p',
  });

  microphones: MediaDeviceInfo[] = [];
  cameras: MediaDeviceInfo[] = [];

  userMap: IAgoraRTCRemoteUser[] = [];

  allJoinedUsersMap: IAgoraRTCRemoteUser[] = [];
  invitedCoHosts: string[] = [];
  userInfoMap: Map<string, UserInfo> = new Map<string, UserInfo>();
  chefUser: IAgoraRTCRemoteUser;
  chefSecondCamera: IAgoraRTCRemoteUser;

  compositeRecordingClient: IAgoraRTCClient;
  compositeMobileRecordingClient: IAgoraRTCClient;
  mainCameraRecordingClient: IAgoraRTCClient;
  secondCameraRecordingClient: IAgoraRTCClient;

  mainCameraClient: IAgoraRTCClient;
  secondCameraClient: IAgoraRTCClient;

  isStreamFirstPrepMode = false;
  isStreamPrepMode = false;
  isStreamStarted = false;
  isStreamStarting = false;
  isStreamStopping = false;
  currentConnectionState: ConnectionState;
  currentUserRole: ClientRole = 'audience';

  currentEmojiCount = 0;
  readonly MAX_EMOJI_AMOUNT = 40;
  pipCameraPosition = PipCameraPositions.BOTTOM_RIGHT;

  viewersCount = 0;
  viewersCountIntervalId;

  clamsTranslate: boolean;

  indexSelectedTab = 0;

  isAdmin = false;
  isGuest = false;

  isCoHostInviteForUser = false;
  isInviteWasSendModalOpened = false;
  isStopStreamModalOpened = false;

  isMuteEmogi = false;
  isMuteUsersSound = true;

  protected devicesSet: DevicesFormValue;
  isDevicesFormModalOpened = false;
  isDevicesSubmitFormDisabled = false;

  @HostListener('document:click')
  documentClick(): void {
    this.isSpeakerPopup = false;
  }

  constructor(
    protected agoraStreamService: AgoraStreamService,
    protected cdr: ChangeDetectorRef,
    protected profilesService: ProfilesService,
    protected liveStreamStatusService: LiveStreamStatusService,
    protected tipsService: TipsService,
    protected tokenService: TokenService,
    protected audienceService: AudienceService,
    protected $gaService: GoogleAnalyticsService,
    protected userProfile: UserProfileService,
    protected streamsService: StreamsService,
    protected chefTableService: ChefTableService,
    protected liveReplayTabsService: LiveReplayTabsService,
    protected applicationRef: ApplicationRef,
    protected ngZone: NgZone,
    protected logger: LoggerService,
    @Inject(PLATFORM_ID) protected platformId: Object,
  ) {}

  get isUserJoined(): boolean {
    return this.liveStreamStatusService.isUserJoined;
  }

  set isUserJoined(status: boolean) {
    this.liveStreamStatusService.isUserJoined = status;
  }

  get coHosts(): CoHostInfo[] {
    return this.liveStreamStatusService.coHosts;
  }

  async ngOnInit(): Promise<void> {
    this.logger.info('#ngOnInit streamInfo ', this.streamInfo);
    this.agoraRTMTool = new AgoraRTMTool(this.rtmChannel, this.ngZone, this.logger);
    this.isAdmin = this.tokenService.isAdmin();
    this.isGuest = this.tokenService.isGuest();
    this.liveStreamStatusService.streamInfo = this.streamInfo;
    if (this.stream.cohosts?.length) {
      this.liveStreamStatusService.coHosts = [
        { profileId: this.stream.channel.chefProfile.id, cameraId: 0 },
        ...this.stream.cohosts,
      ];
      this.isCoHostsMode = this.liveStreamStatusService.coHosts.length > 1;
      this.logger.debug('#ngOnInit isCoHostsMode ', this.isCoHostsMode);
    }
    if (this.isAdmin || this.streamInfo.isStreamOwner) {
      this.isStreamPrepMode = false;
      this.isStreamFirstPrepMode = true;
    } else {
      this.isStreamPrepMode = this.stream.status === StreamStatus.PREPMODE;
      this.isStreamFirstPrepMode = this.stream.status === StreamStatus.SCHEDULED;
    }
    this.logger.debug('#ngOnInit isStreamPrepMode ', this.isStreamPrepMode);
    this.logger.debug('#ngOnInit isStreamFirstPrepMode ', this.isStreamFirstPrepMode);

    this.getTips();

    this.isStreamStarted = this.stream.status === StreamStatus.LIVE;
    this.pipCameraPosition = this.stream.secondCameraPosition || PipCameraPositions.BOTTOM_RIGHT;

    this.logger.debug('#ngOnInit isStreamStarted ', this.isStreamStarted);
    this.logger.debug('#ngOnInit pipCameraPosition ', this.pipCameraPosition);

    this.liveStreamStatusService.updateStreamStatus({
      isLive: this.isStreamStarted,
      isPrepMode: this.isStreamFirstPrepMode,
      isOwner: this.tokenService.getProfileId() === this.stream.channel.chefProfile.id,
    });

    this.liveStreamStatusService.streamStatus$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (this.isAdmin || this.streamInfo.isStreamOwner) {
          this.isStreamPrepMode = status.isPrepMode;
          if (this.isStreamPrepMode) {
            this.isStreamFirstPrepMode = false;
          }
        }
        this.isStreamStarted = status.isLive;
        if (this.isStreamStarted && this.stream) {
          this.stream.startedAt = this.stream.startedAt || new Date().toString();
          if (!this.isStreamFirstPrepMode) {
            this.isStreamPrepMode = false;
          }
        }
        this.logger.debug('#ngOnInit isStreamStarted ', this.isStreamStarted);
        this.logger.debug('#ngOnInit isStreamPrepMode ', this.isStreamPrepMode);
        this.logger.debug('#ngOnInit isStreamFirstPrepMode ', this.isStreamFirstPrepMode);
      });
    this.userInfoMap.set(this.streamInfo.profileId, {
      avatar: '',
      displayName: '',
      raisedHand: false,
      audioEnabled: false,
      videoEnabled: false,
    });
    if (!this.streamInfo.isStreamOwner) {
      this.userProfile.userProfile$
        .subscribe(profile => this.updateUserInfo(profile.id, profile.avatar, profile.name));
    }

    if (isPlatformBrowser(this.platformId)) {
      await this.ngZone.runOutsideAngular(async () => {
        this.logger.debug('#ngOnInit before initAgoraClient');
        await this.initAgoraClient();
        this.logger.debug('#ngOnInit before initSecondCameraAgoraClient');
        this.initSecondCameraAgoraClient();
        this.logger.debug('#ngOnInit before initRecordingAgoraClients');
        await this.initRecordingAgoraClients();
      });
      this.logger.debug('#ngOnInit before initRTM');
      this.initRTM();
      if (!this.streamInfo.isStreamOwner) {
        if (new Date(this.streamInfo.streamToken.expiresIn) <= new Date()) {
          this.logger.debug('#ngOnInit update agora tokens');
          await this.getAgoraStreamInfo().toPromise();
        }
        this.logger.debug('#ngOnInit before joinChannel');
        await this.joinMainCameraChannel();
        this.logger.debug('#ngOnInit before joinSecondCameraChannel');
        await this.joinSecondCameraChannel();
      }

      if (this.streamInfo.isStreamOwner || this.liveStreamStatusService.isCoHost(this.streamInfo.profileId)) {
        this.logger.debug('#ngOnInit display devices form modal on page load for owner or co-host');
        this.setDeviceModalStatus(true);
      }

      if (this.isStreamStarted) {
        this.getAllAudience().subscribe();
        await this.updateViewersCount();
      }
      this.applicationRef.isStable
        .pipe(untilDestroyed(this))
        .subscribe((isStable) => {
          if (isStable && !this.viewersCountIntervalId) {
            this.viewersCountIntervalId = setInterval(() => {
              if (!this.isStreamPrepMode && !this.isStreamFirstPrepMode) {
                this.updateViewersCount();
              }
            }, 5 * 1000);
          }
        });
    }

    this.updateWalmartRecipe();
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.logger.debug('#ngOnDestroy started');

    if (this.isStreamStarted && this.streamInfo.isStreamOwner) {
      this.liveStreamStatusService.updateStreamStatus({ isLive: false });
    }

    this.leaveCall();
    clearInterval(this.viewersCountIntervalId);
    this.logger.info('ngOnDestroy ended');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tabChanged(tabChangeEvent): void {
    this.indexSelectedTab = tabChangeEvent.index;
  }

  setDeviceModalStatus(status: boolean): void {
    this.isDevicesFormModalOpened = status;
    this.cdr.detectChanges();
  }

  protected onClubBtnClick(joinStream: boolean): void {
    this.openSubscribeModal.emit(joinStream);
  }

  protected onSubmitTranslate($event: string): void {
    this.sendTranslation.emit($event);
  }

  protected async startVideoAudio(): Promise<void> {
    this.logger.info('#startVideoAudio start method');
    try {
      await this.publishAudioVideoToChannel();
    } catch (e) {
      this.logger.warn('#startVideoAudio can\'t publish audio and video error ', e);
    }
  }

  pipCameraDragStarted(player: HTMLElement): void {
    const videoTrack = this.streamInfo.isStreamOwner ? this.secondCameraVideoTrack : this.chefSecondCamera?.videoTrack;

    setTimeout(() => {
      this.logger.info('pipCameraDragStarted play second camera video');
      videoTrack?.play(player, { mirror: false, fit: 'cover' });
    }, 1);
  }

  // INIT AGORA START ===>>>
  private async initAgoraClient() {
    AgoraRTC.enableLogUpload();
    AgoraRTC.setLogLevel(0);

    this.mainCameraClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    if (this.streamInfo.isStreamOwner) {
      try {
        await this.mainCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
      } catch (e) {
        this.logger.warn('#initAgoraClient: can\'t set mainCameraClient to a host role', e);
      }
    }

    this.mainCameraClient.on('user-published', this.handleUserPublished);
    // unpublished is called when users mute. Best not to remove them from UI completely
    this.mainCameraClient.on('user-unpublished', this.handleUserUnpublished);
    // handle user-join event to get user info if user joined without video/audio
    this.mainCameraClient.on('user-joined', this.handleUserJoined);
    this.mainCameraClient.on('user-left', this.handleUserLeft);
    this.mainCameraClient.on('connection-state-change', this.handleConnectionStateChange);
    this.mainCameraClient.on('token-privilege-will-expire', () => {
      this.logger.debug(' mainCameraClient token-privilege-will-expire event fired');
      this.getAgoraStreamInfo().subscribe(() => this.renewAgoraClientsTokens());
    });
    this.mainCameraClient.on('token-privilege-did-expire', () => {
      this.logger.debug(' mainCameraClient token-privilege-did-expire event fired');
      this.getAgoraStreamInfo().subscribe(() => this.rejoinAgoraClients());
    });
  }

  private renewAgoraClientsTokens(): void {
    this.mainCameraClient?.renewToken(this.streamInfo.streamToken.rtcToken);
    if (this.isDualCamerasStream()) {
      this.secondCameraClient?.renewToken(this.streamInfo.streamToken.secondCameraRtcToken);
    }
  }

  private rejoinAgoraClients(): void {
    if (this.mainCameraClient) {
      this.joinMainCameraChannel();
    }
    if (this.secondCameraClient) {
      this.joinSecondCameraChannel();
    }
  }

  protected initSecondCameraAgoraClient(): void {
    this.secondCameraClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    this.secondCameraClient.on('user-published', this.handleChefPublishedSecondCamera);
    this.secondCameraClient.on('user-unpublished', this.handleChefUnPublishedSecondCamera);
  }

  private async initRecordingAgoraClients() {
    this.compositeRecordingClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    this.compositeMobileRecordingClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    this.mainCameraRecordingClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    this.secondCameraRecordingClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

    if (this.streamInfo.isStreamOwner) {
      try {
        await this.secondCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
      } catch (e) {
        this.logger.warn('#initRecordingAgoraClients: can\'t set secondCameraClient to a host role', e);
      }
    }
  }
  // <<<=== INIT AGORA END

  // JOIN AGORA CHANNELS START ===>>>
  protected async joinMainCameraChannel(): Promise<void> {
    this.logger.info(`#joinMainCameraChannel with ${JSON.stringify(this.streamInfo.streamToken)}`);
    try {
      await this.ngZone.runOutsideAngular(() => {
        return this.mainCameraClient.join(
          this.streamInfo.streamToken.appId,
          this.stream.id,
          this.streamInfo.streamToken.rtcToken,
          this.streamInfo.profileId,
        );
      });
    } catch (e) {
      this.logger.warn(
        `#joinMainCameraChannel: user ${this.streamInfo.profileId} can't join stream ${this.stream.id}`, e,
      );
    }
  }

  protected async joinSecondCameraChannel(): Promise<void> {
    this.logger.info(`#joinSecondCameraChannel with ${JSON.stringify(this.streamInfo.streamToken)}`);
    try {
      await this.ngZone.runOutsideAngular(() => {
        return this.secondCameraClient.join(
          this.streamInfo.streamToken.appId,
          this.stream.id,
          this.streamInfo.streamToken.secondCameraRtcToken,
          `${this.streamInfo.streamToken.secondCameraRtcUidPrefix}_${this.streamInfo.profileId}`,
        );
      });
    } catch (e) {
      this.logger.warn(
        `#joinSecondCameraChannel: user ${this.streamInfo.profileId} can't join stream ${this.stream.id}`, e,
      );
    }
  }
  // <<<=== JOIN AGORA CHANNELS END

  // AGORA EVENTS HANDLERS START ===>>>
  private handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    if (!user) return;

    this.logger.info(`#handleUserPublished start publishing ${mediaType} for user ${user}`);

    const uid = user.uid.toString();
    const isChef = uid === this.stream.channel.chefProfile.id;
    const isCoHost = this.liveStreamStatusService.isCoHost(uid);
    const isChefsTableTabActive = this.liveReplayTabsService.isTabActive(TabId.CHEFS_TABLE);

    if (this.isSecondChannelUser(uid)) {
      return;
    }

    if (isChef) {
      this.chefUser = user;
    } else {
      if (!isCoHost) {
        this.logger.info(`#handleUserPublished adding user ${user} to chef table`);
        this.addUserVideoToTable(user);
        if (mediaType === 'audio') {
          this.saveTableChanges('UNMUTE', uid);
        }
      }
      this.saveAgoraUserInfo(user);
      this.setUserInfo(user);
    }
    this.logger.info('handleUserPublished ', this.userMap);
    this.cdr.detectChanges();

    if (isChef || isCoHost || isChefsTableTabActive || mediaType === this.AUDIO) {
      this.subscribeToRemoteUser(user, mediaType);
    }
  };

  private handleChefPublishedSecondCamera = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    if (!this.isSecondChannelUser(user.uid.toString())) {
      return;
    }
    this.logger.info(`#handleChefPublishedSecondCamera start publishing ${mediaType} for user ${user}`);
    if (!this.streamInfo.isStreamOwner) {
      await this.secondCameraClient
        .subscribe(user, mediaType)
        .then(async () => {
          this.logger.info('#handleChefPublishedSecondCamera subscribing user ', user);
          if (mediaType === this.VIDEO) {
            this.chefSecondCamera = user;
            setTimeout(() => {
              this.logger.info('#handleChefPublishedSecondCamera play second camera for uid ', user.uid);
              user.videoTrack.play('second-camera-player', { mirror: false, fit: 'cover' });
            }, 3000);
            // allow stream to fall back to audio only when congested
            await this.secondCameraClient
              .setStreamFallbackOption(user.uid, AgoraRTC.RemoteStreamFallbackType.LOW_STREAM);
            await this.secondCameraClient
              .setRemoteVideoStreamType(user.uid, AgoraRTC.RemoteStreamType.HIGH_STREAM);
          }
        })
        .catch((e) => {
          this.logger.warn('#handleChefPublishedSecondCamera second camera client subscribe error: ', e);
        });
    }
  };

  private handleUserLeft = (user: IAgoraRTCRemoteUser) => {
    this.logger.info('#handleUserLeft user left ', user);
    const uid = user.uid.toString();

    if (this.isSecondChannelUser(uid)) {
      return;
    }
    if (!this.liveStreamStatusService.isCoHost(uid)) {
      this.saveTableChanges('DISCONNECT', uid);
    }
    this.logger.debug('#handleUserLeft remove user video from table');
    this.removeUserVideoFromTable(uid);
    this.logger.debug('#handleUserLeft remove agora user info');
    this.removeAgoraUserInfo(uid);
    if (this.userInfoMap.has(uid)) {
      this.userInfoMap.get(uid).raisedHand = false;
    }
    this.cdr.detectChanges();
  };

  private handleUserUnpublished = (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    this.logger.info(`#handleUserUnpublished start un-publishing ${mediaType} for user ${user}`);
    const uid = user.uid.toString();

    if (this.isSecondChannelUser(uid)) {
      return;
    }
    const userIndex = this.userMap.findIndex((user) => user?.uid === uid);

    if (userIndex !== -1) {
      this.userMap[userIndex] = user;
      this.userMap = [...this.userMap];
    }
    this.saveAgoraUserInfo(user);
    if (mediaType === 'audio' && !this.liveStreamStatusService.isCoHost(uid)) {
      this.saveTableChanges('MUTE', uid);
    }
    const userInfo = this.userInfoMap.get(uid);

    if (userInfo) {
      userInfo.audioEnabled = user.hasAudio;
      userInfo.videoEnabled = user.hasVideo;
    }
    this.cdr.detectChanges();
    this.logger.info('#handleUserUnpublished updated userMap ', this.userMap);
  };

  private handleChefUnPublishedSecondCamera = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    this.logger.info(`#handleChefUnPublishedSecondCamera start un-publishing ${mediaType} for user ${user}`);
    const uid = user.uid.toString();

    // remove second video for chef
    if (this.streamInfo.isStreamOwner && this.isSecondChannelUser(uid) && mediaType === this.VIDEO) {
      this.logger.info('#handleChefUnPublishedSecondCamera stop second camera video track');
      try {
        await this.secondCameraVideoTrack.setEnabled(false);
        await this.secondCameraVideoTrack.stop();
        await this.secondCameraVideoTrack.close();
        this.secondCameraVideoTrack = null;
      } catch (e) {
        this.logger.warn('#handleChefUnPublishedSecondCamera error: ', e);
      }
      this.cdr.detectChanges();
    }
  };

  private handleConnectionStateChange = (curState: ConnectionState) => {
    this.logger.info('#handleConnectionStateChange new connection status: ', curState);
    this.currentConnectionState = curState;
    this.cdr.detectChanges();
  };

  private handleUserJoined = (user: IAgoraRTCRemoteUser) => {
    this.logger.info('#handleUserJoined new user joined ', user);
    const uid = user.uid.toString();

    if (this.isSecondChannelUser(uid)) {
      return;
    }

    if (this.isUserExists(uid) || uid === this.stream.channel.chefProfile.id) {
      this.logger.info('#handleUserJoined exit form method for existing user or a chef');

      return;
    }
    if (!this.liveStreamStatusService.isCoHost(uid)) {
      this.logger.info('#handleUserJoined add user to chef table');
      this.addUserVideoToTable(user);
      this.saveTableChanges('JOIN', uid);
    }
    this.saveAgoraUserInfo(user);
    this.setUserInfo(user);
    this.logger.info('#handleUserJoined updated userMap ', this.userMap);
    this.logger.debug('#handleUserJoined loading avatar');
    this.loadAvatar(uid);
    this.cdr.detectChanges();
  };
  // <<<=== AGORA EVENTS HANDLERS END

  async leaveTable(): Promise<void> {
    this.logger.info('#leaveTable start leaving table');
    this.logger.debug('#leaveTable turn off camera');
    await this.turnOffCamera();
    this.logger.debug('#leaveTable turn off microphone');
    await this.turnOffMicrophone();
    this.logger.debug('#leaveTable closing video/audio tracks');
    this.localTracks.videoTrack?.close();
    this.localTracks.audioTrack?.close();

    this.logger.debug('#leaveTable remove self from the table');
    this.removeUserVideoFromTable(this.streamInfo.profileId);
    const userInfo = this.userInfoMap.get(this.streamInfo.profileId);

    userInfo.videoEnabled = false;
    userInfo.audioEnabled = false;
    userInfo.raisedHand = false;
    this.liveStreamStatusService.isUserJoined = false;
    this.isCoHostInviteForUser = false;
    try {
      await this.mainCameraClient.setClientRole('audience').then(() => this.currentUserRole = 'audience');
    } catch (e) {
      this.logger.warn('#leaveTable can\'t set client role to audience ', e);
    }
    this.cdr.detectChanges();
  }

  private isUserExists(uid: string): boolean {
    return !!this.allJoinedUsersMap.find((user) => user?.uid === uid);
  }

  private initRTM() {
    this.rtmChannel.on('ChannelMessage', async ({ text }: RtmTextMessage) => {
      this.logger.info('#initRTM RTM message ', text);
      try {
        await this.handleRtmMassage(text);
      } catch (e) {
        this.logger.warn('#initRTM handleRtmMassage error ', e);
      }
    });
  }

  // RTM MASSAGES HANDLERS START ===>>>
  private async handleRtmMassage(text: string): Promise<void> {
    const message: RtmMessage = this.agoraRTMTool.parseMassage(text);

    switch (message.type) {
      case RtmMassageType.USER_RAISED_HAND:
        this.handleUserRaiseHand(message);
        break;
      case RtmMassageType.SEND_EMOJI:
        this.handleEmoji(message);
        break;
      case RtmMassageType.SEND_TIP:
        this.handleTip(message);
        break;
      case RtmMassageType.USER_JOINED_STREAM:
        this.handleUserJoinStream(message);
        break;
      case RtmMassageType.USER_LEFT_STREAM:
        this.handleUserLeftStream(message);
        break;
      case RtmMassageType.USER_JOINED_AS_CO_HOST:
        await this.handleUserJoinedAsCoHost(message);
        break;
      case RtmMassageType.USER_LEFT_AS_CO_HOST:
        await this.handleUserLeftAsCoHost(message);
        break;
    }
    this.cdr.detectChanges();
  }

  private handleUserRaiseHand(massage: RtmMessage): void {
    const user = this.userInfoMap.get(massage.uid);

    user.raisedHand = massage.payload.status;
    if (user.raisedHand) {
      this.handSound.volume = 0.3;
      this.handSound.play();
    }
  }

  private handleEmoji(message: RtmMessage): void {
    if (message.uid !== this.streamInfo.profileId) {
      this.animateEmoji(message.payload.emojiSrc, message.payload.emojiAmount);
      this.soundEmoji(message.payload.emojiSrc);
    }
  }

  private handleTip(message: RtmMessage): void {
    if (message.uid === this.streamInfo.profileId || message.payload.uid === this.streamInfo.profileId) {
      this.updateBalance.emit();
      if (message.payload.tipsAmount >= 5) {
        this.tipsInfo = {
          viewerName: message.payload.userInfo.viewerName,
          tipsAmount: message.payload.tipsAmount,
        };
        this.tipsSuccessMessageToggle.emit(true);
        setTimeout(() => {
          this.tipsSuccessMessageToggle.emit(false);
        }, 4000);
      }
    }
    this.streamInfo.tipsBalance = this.streamInfo.tipsBalance + message.payload.tipsAmount;
    this.tipsSound.volume = 0.25;
    if (!this.isMuteEmogi) {
      this.tipsSound.play();
    }
  }

  private handleUserJoinStream(message: RtmMessage): void {
    const userPayload = message.payload.userInfo;
    const checkExistedUser = this.audienceUsers.filter((item) => item.profileId === userPayload.profileId);

    this.logger.info('#handleUserJoinStream handle event for uid: ', userPayload.profileId);

    if (checkExistedUser.length === 0) {
      this.audienceUsers.push({
        createdAt: Date.now().toString(),
        profileId: userPayload.profileId,
        viewerName: userPayload.viewerName,
        viewerPhoto: userPayload.avatar,
      });
    }
  }

  private handleUserLeftStream(message: RtmMessage): void {
    const userPayload = message.payload.userInfo;

    this.logger.info('#handleUserLeftStream handle event for uid: ', userPayload.profileId);

    this.audienceUsers.splice(this.audienceUsers.findIndex(item => item.profileId === userPayload.profileId), 1);
  }

  private async handleUserJoinedAsCoHost(message: RtmMessage): Promise<void> {
    this.logger.info('#handleUserJoinedAsCoHost handle event for uid: ', message.uid);
    if (message.uid === this.streamInfo.profileId) {
      await this.addSelfToCoHostsTable();
    } else {
      await this.addUserToCoHostsTable(message.uid);
    }
  }

  private async handleUserLeftAsCoHost(message: RtmMessage): Promise<void> {
    this.logger.info('#handleUserLeftAsCoHost handle event for uid: ', message.uid);
    if (message.uid === this.streamInfo.profileId) {
      await this.removeSelfFromCoHostsTable(message.uid);
    } else {
      await this.removeUserFromCoHostsTable(message.uid);
    }
  }
  // <<<=== RTM MASSAGES HANDLERS END

  // API METHODS START ===>>>
  protected getTips(): void {
    this.clamsTranslate = true;
    const params: TipSearchParams = {
      page: 1,
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      profileId: this.stream.channel.chefProfile.id,
      videoId: this.stream.id,
    };

    this.tipsService.getAll(params).subscribe(tips => {
      this.streamInfo.tipsBalance = tips.results
        .map((tips) => tips.amount)
        .reduce((previous, current) => {
          if (previous - current === 1) {
            this.clamsTranslate = false;
          }

          return previous + current;
        }, 0);
    });
  }

  private loadAvatar(uid: string): void {
    if (this.isSecondChannelUser(uid)) {
      return;
    }
    this.profilesService
      .getUserProfile(uid)
      .pipe(
        map((profiles) => profiles.results[0]),
        filter((profile) => !!profile),
      )
      .subscribe((profile) => {
        this.updateUserInfo(uid, profile.photo, profile.displayName);
      });
  }

  private getAllAudience(): Observable<AppPagesItem<AudienceViewerUser>> {
    const params: TipSearchParams = {
      profileId: this.streamInfo.profileId,
      videoId: this.stream.id,
    };

    return this.audienceService.getAll(params)
      .pipe(
        tap((response) => this.audienceUsers = response.results),
      );
  }

  protected saveTableChanges(action: VisitorAction, profileId: string): void {
    if (!this.streamInfo.isStreamOwner || !this.isStreamStarted) {
      return;
    }
    this.logger.info(`#saveTableChanges save ${action} for user profileId ${profileId}`);

    // when live stream is started 'recordStartedAt' is null for users who were in prep mode
    const startRecordDate = this.stream.recordStartedAt ? new Date(this.stream.recordStartedAt) : new Date();
    const startDate = DateTime.fromJSDate(startRecordDate);
    const streamSecond = DateTime.local().diff(startDate, 'second').seconds;
    const visitor: ChefTableVisitorInfo = {
      action,
      profileId,
      streamSecond,
      videoId: this.stream.id,
    };

    this.chefTableService.updateVisitor(visitor).subscribe();
  }
  // <<<=== API METHODS END

  // CO-HOST METHODS START ===>>>
  removeFromTable(user: IAgoraRTCRemoteUser): void {
    this.logger.info('#removeFromTable remove from co-hosts user: ', user);
    this.agoraRTMTool.removeUserFromTable(user.uid.toString());
  }

  inviteCoHost(user: IAgoraRTCRemoteUser): void {
    const uid = user.uid.toString();

    this.logger.info('#inviteCoHost invite to co-hosts uid: ', uid);

    this.invitedCoHosts.push(uid);
    this.agoraRTMTool.inviteAsCoHost(uid);
    this.setInviteWasSendModalStatus(true);
  }

  setInviteWasSendModalStatus(status: boolean): void {
    this.isInviteWasSendModalOpened = status;
  }

  setStopStreamModalStatus(status: boolean): void {
    this.isStopStreamModalOpened = status;
  }

  protected async addUserToCoHostsTable(uid: string, cameraId = 0): Promise<void> {
    this.logger.info('#addUserToCoHostsTable uid: ', uid);
    this.liveStreamStatusService.addToCoHosts({
      profileId: uid,
      cameraId,
    });
    const coHost = this.chefUser?.uid === uid ?
      this.chefUser :
      this.userMap.find(user => user?.uid === uid);

    if (this.streamInfo.isStreamOwner && !this.isCoHostsMode) {
      this.agoraRTMTool.userJoinedAsCoHost(this.streamInfo.profileId);
      await this.addSelfToCoHostsTable();
    }
    this.removeUserVideoFromTable(uid);
    this.saveTableChanges('DISCONNECT', uid);
    setTimeout(() => {
      this.logger.info('#addUserToCoHostsTable play co-host video for id ', coHost?.uid);
      coHost?.videoTrack?.play(`co-host-${coHost.uid}`, { mirror: false, fit: 'cover' });
      if (uid === this.stream.channel.chefProfile.id && this.chefSecondCamera) {
        this.logger.info('#addUserToCoHostsTable play co-host second camera video');
        this.chefSecondCamera.videoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
      }
    }, 100);
    this.logger.debug('#addUserToCoHostsTable changed main video to co-host mode');
    this.isCoHostsMode = true;
    this.cdr.detectChanges();
  }

  protected async removeSelfFromCoHostsTable(uid: string): Promise<void> {
    this.logger.info('#removeSelfFromCoHostsTable uid: ', uid);
    this.liveStreamStatusService.removeCoHost(uid);
    const playerId = this.streamInfo.isStreamOwner ? 'chef-player' : uid;

    try {
      await this.agoraRTCTool.stopAutoAdjustResolution();
    } catch (e) {
      this.logger.warn('#removeSelfFromCoHostsTable can\'t stopped auto adjust video resolution', e);
    }

    if (this.streamInfo.isStreamOwner) {
      this.logger.debug('#removeSelfFromCoHostsTable reInitVideoTrack for stream owner');
      await this.reInitVideoTrack();
      this.logger.debug('#removeSelfFromCoHostsTable turn on camera for stream owner');
      await this.turnOnCamera();
      setTimeout(() => {
        this.logger.info('#removeSelfFromCoHostsTable play main video for id ', playerId);
        this.localTracks.videoTrack?.play(playerId, { mirror: this.isVideoMirrored(uid), fit: 'cover' });
        this.logger.info('#removeSelfFromCoHostsTable play second camera video');
        this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
      });
    } else {
      if (this.hasTableAvailableSeats()) {
        this.logger.debug('#removeSelfFromCoHostsTable reInitVideoTrack for user');
        await this.reInitVideoTrack();
        this.logger.debug('#removeSelfFromCoHostsTable add user to a chef table');
        this.addUserVideoToTable(this.allJoinedUsersMap.find(u => u.uid === uid));
        this.logger.debug('#removeSelfFromCoHostsTable turn on camera for user');
        await this.turnOnCamera();
        setTimeout(() => {
          this.logger.info('#removeSelfFromCoHostsTable play main video for id ', playerId);
          this.localTracks.videoTrack?.play(playerId, { mirror: this.isVideoMirrored(uid), fit: 'cover' });
        });
      } else {
        this.logger.debug('#removeSelfFromCoHostsTable can\'t rejoin chef table');
        await this.leaveTable();
      }
    }
    this.cdr.detectChanges();
  }

  protected async removeUserFromCoHostsTable(uid: string): Promise<void> {
    this.logger.info('#removeUserFromCoHostsTable uid ', uid);
    this.liveStreamStatusService.removeCoHost(uid);
    this.isCoHostsMode = this.liveStreamStatusService.coHosts.length > 1;
    this.logger.info('#removeUserFromCoHostsTable is main screen in co-host mode ', this.isCoHostsMode);

    const isChef = uid === this.stream.channel.chefProfile.id;
    const playerId = isChef ? 'chef-player' : uid;
    const coHost = isChef ?
      this.chefUser :
      this.allJoinedUsersMap.find(user => user?.uid === uid);

    if (isChef) {
      setTimeout(() => {
        this.logger.info('#removeUserFromCoHostsTable play chef video track for uid ', playerId);
        coHost?.videoTrack?.play(playerId, { mirror: this.isVideoMirrored(uid), fit: 'cover' });
        this.logger.info('#removeUserFromCoHostsTable play second camera video');
        this.chefSecondCamera?.videoTrack.play('second-camera-player', { mirror: false, fit: 'cover' });
      });
    } else {
      if (this.hasTableAvailableSeats()) {
        this.logger.debug('#removeUserFromCoHostsTable table have available seats, add user to the table', playerId);
        this.addUserVideoToTable(coHost);
        this.saveTableChanges('JOIN', uid);
        if (coHost.hasAudio) {
          this.saveTableChanges('UNMUTE', uid);
        }
        setTimeout(() => {
          this.logger.info('#removeUserFromCoHostsTable play user video track for uid ', playerId);
          coHost?.videoTrack?.play(playerId, { mirror: this.isVideoMirrored(uid), fit: 'cover' });
        });
      }
    }

    if (this.streamInfo.isStreamOwner && !this.isCoHostsMode) {
      this.logger.info('#removeUserFromCoHostsTable chef removes his video from co-hosts');
      this.agoraRTMTool.userLeftAsCoHost(this.streamInfo.profileId);
      await this.removeSelfFromCoHostsTable(this.streamInfo.profileId);
    }
    this.cdr.detectChanges();
  }

  private async addSelfToCoHostsTable(): Promise<void> {
    this.logger.info('#addSelfToCoHostsTable started');
    this.liveStreamStatusService.addToCoHosts({
      profileId: this.streamInfo.profileId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cameraId: this.mainCameraClient._joinInfo?.uid,
    });

    this.logger.debug('#addSelfToCoHostsTable re init video track for co-host video settings');
    await this.reInitVideoTrack();
    this.logger.debug('#addSelfToCoHostsTable turn on camera');
    await this.turnOnCamera();
    this.logger.debug('#addSelfToCoHostsTable remove self from chefs table');
    this.removeUserVideoFromTable(this.streamInfo.profileId);
    setTimeout(() => {
      this.logger.info('#addSelfToCoHostsTable play user video to co-hosts table for uid ', this.streamInfo.profileId);
      this.localTracks.videoTrack?.play(`co-host-${this.streamInfo.profileId}`, { mirror: false, fit: 'cover' });
      this.logger.info('#addSelfToCoHostsTable play second camera video if its a chef');
      this.secondCameraVideoTrack?.play('second-camera-player', { mirror: false, fit: 'cover' });
    }, 100);
    this.cdr.detectChanges();
  }
  // <<<=== CO-HOST METHODS END

  protected animateEmoji(emojiSrc: string, emojiAmount: number): void {
    const random = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;
    const move = () => {
      const container = document.getElementsByClassName('player-wrap')[0];
      const containerProperties: DOMRect = container.getBoundingClientRect();
      const containerHeight = containerProperties.height;
      const containerWidth = containerProperties.width;
      const emojiSize = 45;
      const duration = random(4, 2);
      const emojiPosition = containerHeight - emojiSize;

      const emoji = document.createElement('img');

      emoji.className = 'emoji';
      emoji.src = emojiSrc;
      emoji.style.left = `${random(containerWidth - emojiSize, emojiSize)}px`;
      emoji.style.top = `${emojiPosition}px`;
      emoji.style.animationName = 'emoji';
      emoji.style.animationDuration = `${duration}s`;

      this.currentEmojiCount++;
      setTimeout(() => {
        emoji.remove();
        this.currentEmojiCount--;
      }, duration * 1000);

      container.appendChild(emoji);
    };

    if (this.currentEmojiCount < this.MAX_EMOJI_AMOUNT) {
      const emojiMoves = Math.min(this.MAX_EMOJI_AMOUNT - this.currentEmojiCount, emojiAmount);

      for (let i = 0; i < emojiMoves; i++) {
        move();
      }
    }
  }

  async switchChefMicrophone(): Promise<void> {
    // if (this.localTracks.audioTrack && !this.localTracks.audioTrack['_enabled']) {
    //   await this.localTracks.audioTrack.setEnabled(true);
    // }
    const isEnabled = this.localTracks.audioTrack['_enabled'];

    await this.localTracks.audioTrack.setEnabled(!isEnabled);
    this.cdr.detectChanges();
  }

  protected soundEmoji(src: string): void {
    const emojiName = src.substring(src.lastIndexOf('/') + 1);

    switch (emojiName) {
      case 'clap.png':
        this.clapSound.volume = 0.3;
        if (!this.isMuteEmogi) {
          this.clapSound.play();
        }
        break;
    }
  }

  // init video on chef table when switch to this tab
  protected onTabSwitched(): void {
    if (this.liveReplayTabsService.isTabActive(TabId.CHEFS_TABLE)) {
      this.subscribeToChefTableVideo();
    } else {
      this.userMap.forEach(user => user.videoTrack?.stop());
    }

    if (this.liveReplayTabsService.isTabActive(TabId.CHAT)) {
      this.isAskChefLoaded = true;
    }
  }

  protected subscribeToChefTableVideo(): void {
    this.userMap
      .filter(user => user.hasVideo)
      .forEach(user => {
        if (user.uid === this.streamInfo.profileId) {
          this.logger.info('#subscribeToChefTableVideo play user video track for uid ', user.uid);
          user.videoTrack?.play(
            this.getPlayerId(user),
            { mirror: this.isVideoMirrored(this.streamInfo.profileId), fit: 'cover' },
          );
        } else {
          this.subscribeToRemoteUser(user, 'video');
        }
      });
  }

  protected subscribeToRemoteUser(user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio'): void {
    const playerId = this.getPlayerId(user);

    let timeout;

    if (mediaType === this.VIDEO) {
      timeout = document.getElementById(playerId) ? 1 : 2000;
    } else {
      timeout = 500;
    }

    setTimeout(() => {
      this.mainCameraClient
        .subscribe(user, mediaType)
        .then(async () => {
          const uid = user.uid.toString();
          const isChef = uid === this.stream.channel.chefProfile.id;

          if (mediaType === this.VIDEO) {
            // allow stream to fall back to audio only when congested
            const streamVideoType = (isChef || this.liveStreamStatusService.isCoHost(uid)) ?
              AgoraRTC.RemoteStreamType.HIGH_STREAM : AgoraRTC.RemoteStreamType.LOW_STREAM;

            await this.mainCameraClient.setStreamFallbackOption(user.uid, AgoraRTC.RemoteStreamFallbackType.LOW_STREAM);
            await this.mainCameraClient.setRemoteVideoStreamType(user.uid, streamVideoType);
            this.logger.info('#subscribeToRemoteUser play video track for uid ', playerId);
            if (document.getElementById(playerId)) {
              user.videoTrack.play(playerId, { mirror: this.isVideoMirrored(uid), fit: 'cover' });
            }
          }

          if (mediaType === this.AUDIO) {
            this.logger.info('#subscribeToRemoteUser play audio track for uid ', playerId);
            user.audioTrack.play();
            if (!this.liveStreamStatusService.isCoHost(uid)) {
              this.isMuteUsersSound = false;
            }
          }

          this.cdr.detectChanges();
        })
        .catch((e) => {
          this.logger.warn('#subscribeToRemoteUser main camera client subscribe error: ', e);
        });
    }, timeout);
  }

  private updateUserInfo(uid: string, avatar: string, displayName: string): void {
    if (this.userInfoMap.has(uid)) {
      const userInfo = this.userInfoMap.get(uid);

      userInfo.avatar = avatar;
      userInfo.displayName = displayName;
      this.cdr.detectChanges();
    }
  }

  private isSecondChannelUser(uid: string): boolean {
    return uid.includes(this.streamInfo.streamToken.secondCameraRtcUidPrefix);
  }

  private async updateViewersCount(): Promise<void> {
    if (!this.streamInfo.isLoggedInRtm) {
      this.logger.debug('#updateViewersCount exit from method for a not logged in user');

      return;
    }
    try {
      const membersCount = (await this.rtmChannel.getMembers()).length - 1; // minus chef

      if (this.isStreamStarted && membersCount !== this.viewersCount) {
        if (this.streamInfo.isStreamOwner) {
          this.logger.debug('#updateViewersCount emit new members count value to backend ', membersCount);
          this.streamViewCountChange.emit(membersCount);
        }
        this.logger.debug('#updateViewersCount new viewersCount: ', membersCount);
        this.viewersCount = membersCount;
        this.cdr.detectChanges();
      }
    } catch (e) {
      this.logger.warn('#updateViewersCount error: ', e);
      this.rtmChannel.removeAllListeners();
      try {
        await this.rtmChannel.join();
        this.initRTM();
      } catch (e) {
        this.logger.warn('#updateViewersCount error during rejoining RTM channel: ', e);
      }
    }
  }

  protected isDualCamerasStream(): boolean {
    return this.secondCameraVideoTrack !== null;
  }

  protected getPlayerId(user: IAgoraRTCRemoteUser): string {
    const uid = user.uid.toString();

    if (uid === this.stream.channel.chefProfile.id) {
      return this.getChefPlayerId(uid);
    } else {
      return this.liveStreamStatusService.isAudienceUser(user) ? uid : `co-host-${user.uid}`;
    }
  }

  protected getChefPlayerId(uid: string): string {
    return this.liveStreamStatusService.isCoHost(uid) ? `co-host-${uid}` : 'chef-player';
  }

  private setUserInfo(user: IAgoraRTCRemoteUser): void {
    const uid = user.uid.toString();
    let userInfo: UserInfo = {
      avatar: '',
      displayName: '',
      raisedHand: false,
      audioEnabled: user.hasAudio,
      videoEnabled: user.hasVideo,
    };

    if (this.userInfoMap.has(uid)) {
      userInfo = this.userInfoMap.get(uid);
      userInfo.audioEnabled = user.hasAudio;
      userInfo.videoEnabled = user.hasVideo;
    } else {
      this.userInfoMap.set(uid, userInfo);
    }
    this.logger.info('setUserInfo ', userInfo);
  }

  protected hasTableAvailableSeats(): boolean {
    return this.userMap.filter(user => !!user).length < this.MAX_TABLE_SEATS;
  }

  protected isVideoMirrored(uid: string): boolean {
    // mirror video for users in the side video bar
    return !(uid === this.stream.channel.chefProfile.id || this.liveStreamStatusService.isCoHost(uid));
  }

  protected saveAgoraUserInfo(user: IAgoraRTCRemoteUser): void {
    const uid = user.uid.toString();
    const existingUser = this.allJoinedUsersMap.find((user) => user?.uid === uid);

    if (existingUser) {
      this.allJoinedUsersMap[this.allJoinedUsersMap.indexOf(existingUser)] = user;
    } else {
      this.allJoinedUsersMap.push(user);
    }
  }

  private removeAgoraUserInfo(uid: string): void {
    this.allJoinedUsersMap = this.allJoinedUsersMap.filter(u => u.uid !== uid);
  }

  private addUserVideoToTable(user: IAgoraRTCRemoteUser | null): void {
    if (!user) {
      return;
    }
    const uid = user.uid.toString();

    this.logger.info('#addUserVideoToTable uid: ', uid);
    const existingUser = this.userMap.find((user) => user?.uid === uid);

    if (existingUser) {
      this.logger.info('#addUserVideoToTable update existing user: ', user);
      // update current user in array
      this.userMap[this.userMap.indexOf(existingUser)] = user;
    } else {
      this.logger.info('#addUserVideoToTable add new user: ', user);
      this.userMap.push(user);
    }
    this.userMap = [...this.userMap];
    this.cdr.detectChanges();
    this.logUserMap('addUserVideoToTable');
  }

  private removeUserVideoFromTable(uid: string): void {
    this.logger.info('#removeUserVideoFromTable removing uid: ', uid);
    this.userMap = this.userMap.filter(user => user.uid !== uid);
    this.cdr.detectChanges();
    this.logUserMap('removeUserVideoFromTable');
  }

  private logUserMap(methodName: string): void {
    this.logger.info(`#${methodName} this.userMap: ${this.userMap.map(u => u?.uid)}`);
  }

  toggleUserAudio({ uid, status }: ToggleUserAudioEvent): void {
    this.logger.info('#toggleUserAudio toggled audio for user ', uid, 'to status ', status);
    const user = this.userInfoMap.get(uid);

    user.audioEnabled = status;
    this.agoraRTMTool.toggleUserAudio(this.streamInfo.profileId, uid, status);
  }

  async turnOffCamera(): Promise<void> {
    const user = this.userMap.find((u) => u?.uid === this.streamInfo.profileId);
    const agoraUser = this.allJoinedUsersMap.find((u) => u?.uid === this.streamInfo.profileId);
    const userInfo = this.userInfoMap.get(this.streamInfo.profileId);

    this.logger.info('#turnOffCamera started: ',
      'user: ', user, 'agoraUser: ', agoraUser, 'userInfo: ', userInfo);

    if (this.localTracks.videoTrack && this.localTracks.videoTrack.enabled) {
      this.logger.info('#turnOffCamera unpublish and stop main camera video');
      try {
        await this.mainCameraClient.unpublish(this.localTracks.videoTrack);
      } catch (e) {
        this.logger.warn('#turnOffCamera error on unpublishing main camera client ', e);
      }
      try {
        await this.localTracks.videoTrack.setEnabled(false);
      } catch (e) {
        this.logger.warn('#turnOffCamera can\'t disable main camera video track', e);
      }
      try {
        await this.localTracks.videoTrack.stop();
      } catch (e) {
        this.logger.warn('#turnOffCamera can\'t stop main camera video track', e);
      }
      if (user) {
        user.hasVideo = false;
      }
      if (agoraUser) {
        agoraUser.hasVideo = false;
      }
      if (userInfo) {
        userInfo.videoEnabled = false;
      }
    }
    this.logger.info('#turnOffCamera ended: ',
      'user: ', user, 'agoraUser: ', agoraUser, 'userInfo: ', userInfo);
    this.cdr.detectChanges();
  }

  async turnOnCamera(): Promise<void> {
    const user = this.userMap.find((u) => u?.uid === this.streamInfo.profileId);
    const agoraUser = this.allJoinedUsersMap.find((u) => u?.uid === this.streamInfo.profileId);
    const userInfo = this.userInfoMap.get(this.streamInfo.profileId);

    this.logger.info('#turnOnCamera started: ',
      'user: ', user, 'agoraUser: ', agoraUser, 'userInfo: ', userInfo);

    if (this.localTracks.videoTrack && this.localTracks.videoTrack.enabled) {
      this.logger.debug('#turnOnCamera main video track already enabled, updating user info video status');
      if (user) {
        user.hasVideo = true;
      }
      if (agoraUser) {
        agoraUser.hasVideo = true;
      }
      if (userInfo) {
        userInfo.videoEnabled = true;
      }

      this.logger.info('#turnOnCamera ended: ',
        'user: ', user, 'agoraUser: ', agoraUser, 'userInfo: ', userInfo);

      return;
    }

    if (this.localTracks.videoTrack && !this.localTracks.videoTrack.enabled) {
      this.logger.debug('#turnOnCamera: enable main video track');

      try {
        await this.localTracks.videoTrack.setEnabled(true);
      } catch (e) {
        this.logger.warn('#turnOnCamera can\'t enable main camera video track', e);
      }

      if (user) {
        user.hasVideo = true;
      }
      if (agoraUser) {
        agoraUser.hasVideo = true;
      }
      if (userInfo) {
        userInfo.videoEnabled = true;
      }

      this.logger.info('#turnOnCamera ended: ',
        'user: ', user, 'agoraUser: ', agoraUser, 'userInfo: ', userInfo);

      this.addLocalUserVideo();

      try {
        await this.mainCameraClient.publish(this.localTracks.videoTrack);
      } catch (e) {
        this.logger.warn('#turnOnCamera can\'t publish main camera video track', e);
      }
    } else {
      this.logger.debug('#turnOnCamera open devices modal to create video track');
      this.setDeviceModalStatus(true);
    }
    this.cdr.detectChanges();
  }

  async turnOffMicrophone(): Promise<void> {
    this.logger.debug('#turnOffMicrophone start');
    const user = this.userInfoMap.get(this.streamInfo.profileId);

    if (this.localTracks.audioTrack && this.localTracks.audioTrack.enabled) {
      this.logger.info('#turnOffMicrophone unpublish audio track');
      try {
        await this.mainCameraClient.unpublish(this.localTracks.audioTrack);
      } catch (e) {
        this.logger.warn('#turnOffMicrophone error on unpublishing audio track ', e);
      }
      try {
        await this.localTracks.audioTrack.setEnabled(false);
      } catch (e) {
        this.logger.warn(`#turnOffMicrophone can't disable audio track for user ${this.streamInfo.profileId}`, e);
      }
      if (user) {
        user.audioEnabled = false;
      }
      this.logger.info('#turnOffMicrophone set audioEnabled to false for user ', this.streamInfo.profileId);
      this.cdr.detectChanges();
    }
  }

  async turnOnMicrophone(): Promise<void> {
    this.logger.debug('#turnOnMicrophone start');
    if (this.localTracks.audioTrack && this.localTracks.audioTrack.enabled) {
      this.logger.info('#turnOnMicrophone audio already enabled, exit from method');

      return;
    }
    const user = this.userInfoMap.get(this.streamInfo.profileId);

    if (this.localTracks.audioTrack && !this.localTracks.audioTrack.enabled) {
      try {
        await this.localTracks.audioTrack.setEnabled(true);
      } catch (e) {
        this.logger.warn(`#turnOnMicrophone can't enable audio track for user ${this.streamInfo.profileId}`, e);
      }
      try {
        await this.mainCameraClient.publish(this.localTracks.audioTrack);
      } catch (e) {
        this.logger.warn('#turnOnMicrophone error on publishing audio track ', e);
      }
      user.audioEnabled = true;
      this.logger.info('#turnOnMicrophone set audioEnabled to true for user ', this.streamInfo.profileId);
      this.cdr.detectChanges();
    } else {
      this.logger.info('#turnOnMicrophone user doesn\'t have audio device, open devices modal');
      this.setDeviceModalStatus(true);
    }
  }

  private async publishAudioVideoToChannel() {
    // disabled dual stream mode to test stream behavior
    // if (this.streamInfo.isStreamOwner) {
    //   this.mainCameraClient
    //     .enableDualStream()
    //     .then(() => {
    //       this.logger.info('Enable Dual stream success!');
    //     })
    //     .catch((err) => {
    //       this.logger.error(err);
    //     });
    //   const { lowVideoConfig } = this.agoraRTCTool;
    //   this.mainCameraClient.setLowStreamParameter({
    //     width: lowVideoConfig.width,
    //     height: lowVideoConfig.height,
    //     framerate: lowVideoConfig.frameRate,
    //     bitrate: lowVideoConfig.bitrateMax,
    //   });
    // }

    const tracks: ILocalTrack[] = [];

    if (this.localTracks.audioTrack) {
      tracks.push(this.localTracks.audioTrack);
    }
    if (this.localTracks.videoTrack) {
      tracks.push(this.localTracks.videoTrack);
    }
    if (tracks.length) {
      try {
        await this.mainCameraClient.publish(tracks);
      } catch (e) {
        this.logger.warn('#publishAudioVideoToChannel can\'t publish main camera video', e);
      }
    }
    if (this.secondCameraVideoTrack) {
      try {
        await this.secondCameraClient.publish(this.secondCameraVideoTrack);
      } catch (e) {
        this.logger.warn('#publishAudioVideoToChannel can\'t publish second camera video', e);
      }
    }

    if (this.liveStreamStatusService.isCoHost(this.streamInfo.profileId) || this.streamInfo.isStreamOwner) {
      // disabled adjusting resolution in KB-955
      // await this.agoraRTCTool.startAutoAdjustResolution(this.mainCameraClient);
    }
  }

  protected addLocalUserVideo(): void {
    const user: IAgoraRTCRemoteUser = {
      uid: this.streamInfo.profileId,
      hasAudio: !!this.devicesSet?.microphone,
      hasVideo: !!this.devicesSet?.camera,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoTrack: this.localTracks.videoTrack,
    };

    this.logger.info('#addLocalUserVideo add user to a chef table: ', user);

    if (this.hasTableAvailableSeats() && !this.liveStreamStatusService.isCoHost(this.streamInfo.profileId)) {
      this.addUserVideoToTable(user);
    }
    this.saveAgoraUserInfo(user);
    // play video in the next change detection run
    setTimeout(() => {
      this.logger.info('#addLocalUserVideo play user video track for uid ', this.getPlayerId(user));
      user.videoTrack?.play(
        this.getPlayerId(user),
        { mirror: this.isVideoMirrored(this.streamInfo.profileId), fit: 'cover' },
      );
    }, 1);
    const userInfo = this.userInfoMap.get(user.uid.toString());

    if (userInfo) {
      userInfo.videoEnabled = user.hasVideo;
    }
    this.logger.info('#addLocalUserVideo ended with updated userMap: ', this.userMap);
    this.logger.info('#addLocalUserVideo ended with updated allJoinedUsersMap ', this.allJoinedUsersMap);
    this.cdr.detectChanges();
  }

  protected async createCameraMicrophoneTrack(): Promise<void> {
    if (this.devicesSet?.camera) {
      try {
        await this.createCameraTrack();
      } catch (e) {
        this.logger.warn('#createCameraMicrophoneTrack createCameraTrack error ', e);
      }
    }

    if (this.devicesSet?.microphone) {
      try {
        await this.createMicrophoneTrack();
      } catch (e) {
        this.logger.warn('#createCameraMicrophoneTrack createMicrophoneTrack error ', e);
      }
    }
  }

  private async createMicrophoneTrack() {
    const config: MicrophoneAudioTrackInitConfig = {};

    if (this.devicesSet?.microphone) {
      config.microphoneId = this.devicesSet.microphone;
    }
    this.logger.debug('#createMicrophoneTrack create audio track with config', config);
    this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack(config);
  }

  protected async createCameraTrack(): Promise<void> {
    const encoderConfig =
      this.liveStreamStatusService.isCoHost(this.streamInfo.profileId) || this.streamInfo.isStreamOwner
        ? this.agoraRTCTool.activeProfileConfig
        : this.agoraRTCTool.userVideoConfig;

    const config: CameraVideoTrackInitConfig = {
      encoderConfig: encoderConfig,
      // optimizationMode: 'motion',
    };

    if (this.devicesSet?.camera) {
      config.cameraId = this.devicesSet.camera;
    }
    try {
      this.logger.info('#createCameraTrack create main camera video track with config ', config);
      this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack(config);
    } catch (e) {
      this.localTracks.videoTrack = null;
      this.logger.warn('#createCameraTrack create main camera video track error: ', e);
    }
    this.logger.info('#createCameraTrack main camera video track was created: ', this.localTracks.videoTrack);
  }

  private async reInitVideoTrack(): Promise<void> {
    this.logger.debug('#reInitVideoTrack turn off camera');
    await this.turnOffCamera();
    this.logger.debug('#reInitVideoTrack close main camera video track');
    this.localTracks.videoTrack?.close();
    this.logger.debug('#reInitVideoTrack create new main camera video track');
    await this.createCameraTrack();
    try {
      await this.mainCameraClient.setClientRole('host').then(() => this.currentUserRole = 'host');
    } catch (e) {
      this.logger.warn('#reInitVideoTrack: can\'t set mainCameraClient to a host role', e);
    }
    try {
      await this.mainCameraClient.publish(this.localTracks.videoTrack);
    } catch (e) {
      this.logger.warn('#reInitVideoTrack: can\'t publish main camera video track', e);
    }
  }

  private async leaveCall(): Promise<void> {
    this.logger.info('#leaveCall destroy the local audio and video tracks');
    this.localTracks.videoTrack?.close();
    this.localTracks.audioTrack?.close();
    this.secondCameraVideoTrack?.close();

    this.logger.info('#leaveCall leave RTC channels');
    try {
      await Promise.all([
        this.mainCameraClient?.leave(),
        this.secondCameraClient?.leave(),
        this.compositeRecordingClient?.leave(),
        this.compositeMobileRecordingClient?.leave(),
        this.mainCameraRecordingClient?.leave(),
        this.secondCameraRecordingClient?.leave(),
      ]);
    } catch (e) {
      this.logger.warn('#leaveCall can\'t leave RTC channels: ', e);
    }
  }

  private updateWalmartRecipe(): void {
    this.recipes.forEach((recipe: Recipe) => {
      if (recipe.walmartRecipeId === this.walmartRecipeId) {
        this.walmartRecipe = recipe;
      }
    });
  }

  protected getAgoraStreamInfo(): Observable<AgoraStreamToken> {
    const streamUserRole: StreamUserRole =
      this.streamInfo.isStreamOwner ? StreamUserRole.PUBLISHER : StreamUserRole.SUBSCRIBER;

    return this.agoraStreamService.getStreamToken(this.stream.id, streamUserRole).pipe(
      tap(async (streamToken) => {
        this.streamInfo.streamToken = streamToken;
      }),
    );
  }
}
