import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { ChefTableVisitor, ChefTableVisitorProfile } from '@kitch/data-access/models';
import { StreamReplayService } from '@kitch/user/core/stream-replay.service';

interface User extends ChefTableVisitorProfile {
  isMuted: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-replay-chef-table',
  templateUrl: './replay-chef-table.component.html',
  styleUrls: ['./replay-chef-table.component.scss'],
})
export class ReplayChefTableComponent implements OnInit, OnDestroy {
  @Input() chefTableVisitors: ChefTableVisitor[];

  users: User[] = [];
  seconds: number[] = [];
  tableSnapshots: Map<number, User[]> = new Map();

  constructor(
    private streamReplayService: StreamReplayService,
  ) {}

  ngOnInit(): void {
    this.fillTableSnapshots();
    this.seconds = [...this.tableSnapshots.keys()].reverse();
    this.streamReplayService.currentVideoTime$
      .pipe(
        untilDestroyed(this),
        tap((second) => this.users = this.getTableSnapshot(second)),
      ).subscribe();
  }

  ngOnDestroy(): void {}

  onBrokenPhotoUrl(user: User): void {
    user.photo = null;
  }

  trackById(index: number, user: User): string {
    return user.id;
  }

  private getTableSnapshot(second: number): User[] {
    const foundSecond = this.seconds.find(sec => sec <= second);

    return this.tableSnapshots.has(foundSecond) ? this.tableSnapshots.get(foundSecond) : [];
  }

  private fillTableSnapshots(): void {
    let users: User[] = [];

    this.chefTableVisitors.forEach(visitor => {
      let user;

      this.setDeletedUserName(visitor);

      switch (visitor.action) {
        case 'JOIN':
          user = users.find(user => user.id === visitor.profile.id);

          if (!user) {
            users.push({
              ...visitor.profile,
              isMuted: true,
            });
          }
          break;
        case 'DISCONNECT':
          users = users.filter(user => user.id !== visitor.profile.id);
          break;
        case 'MUTE':
          user = users.find(user => user.id === visitor.profile.id);

          if (user) {
            user.isMuted = true;
          }
          break;
        case 'UNMUTE':
          user = users.find(user => user.id === visitor.profile.id);

          if (user) {
            user.isMuted = false;
          }
          break;
      }

      this.tableSnapshots.set(visitor.streamSecond, JSON.parse(JSON.stringify(users)));
    });
  }

  private setDeletedUserName(visitor: ChefTableVisitor): void {
    const nameChunks = visitor.profile.displayName.split('_');

    if (nameChunks.length === 3 && nameChunks[1] === 'deleted') {
      visitor.profile.displayName = 'Jane D.';
    }
  }
}
