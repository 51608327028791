<app-modal #modal class="invite-users-modal" [showCloseIcon]="false">
  <ng-container header>
    <h3 class="invite-users-modal__title">ADD GUESTS</h3>
    <p class="invite-users-modal__subtitle">Invite guests to your stream</p>
  </ng-container>

  <ng-container body>
    <div *ngIf="allUsers" class="invite-users-modal__content">
      <app-select-dropdown-with-avatar
        #selectDropdownWithAvatar
        [items]="allUsers"
        placeholder="Enter username"
        [maxSelectedItems]="8"
        [selectedItems]="inviteUserIds"
        [isInviteUserToPrepModeModal]="true"
        (selectedOptions)="setSelectedPrivateUsers($event)"
        (searchItems)="setSearchTerm($event)"
        (changePage)="setPagePrivateUsers($event)"
      ></app-select-dropdown-with-avatar>

      <div class="invite-users-modal__buttons-wrapper">
        <app-button
          class="invite-users-modal__button"
          [text]="'INVITE'"
          [type]="'button'"
          (clicked)="onInviteUsers(true)"
        ></app-button>

        <app-button
          class="invite-users-modal__button"
          [text]="'cancel'"
          [type]="'button'"
          [role]="'secondary'"
          (clicked)="onInviteUsers(false)"
        ></app-button>
      </div>
    </div>
  </ng-container>
</app-modal>
