<div class="stream-container">
  <div class="stream-wrap" [ngClass]="{'stream-wrap--with-left-chat' : isLeftChatPresent}">
    <!-- LEFT SIDE CHAT >> -->
    <ng-container *ngIf="isLeftChatPresent">
      <div
        class="left-chat-container"
      >
        <div class="left-chat-container-inner">
          <app-openweb-chat
            *ngIf="isAskChefLoaded"
            [chatId]="chatId"
            [isLeftChat]="true"
          ></app-openweb-chat>
        </div>

      </div>
    </ng-container>
    <!-- << LEFT SIDE CHAT -->

    <!-- CHEF VIDEO SECTION >> -->
    <div class="stream-container__video-wrap">
      <div class="player-wrap">
        <ng-container *ngIf="!coHosts.length">
          <div id="chef-player" class="player"></div>
          <app-pip-camera-area
            [index]="pipCameraPosition"
            [isDndDisabled]="!secondCameraVideoTrack"
            [isSwitchDisabled]="!secondCameraVideoTrack"
            [showHelperMessage]="isStreamPrepMode && !isDevicesFormModalOpened"
            (cameraDragStarted)="pipCameraDragStarted($event)"
            (cameraDropped)="pipCameraDropped($event)"
            (cameraSwitched)="switchPipCamera()"
          ></app-pip-camera-area>
        </ng-container>
        <ng-container *ngIf="coHosts.length">
          <div class="co-hosts-wrap" [ngClass]="coHosts.length === 2 ? 'co-hosts-wrap--dual' : ''">
            <div *ngFor="let coHost of coHosts" id="co-host-{{ coHost.profileId }}" class="player--co-host">
              <span *ngIf="coHost.profileId !== stream.channel.chefProfile.id" class="remove-co-host-btn">
                <img (click)="removeUserAsCoHost(coHost.profileId)" src="assets/ui/images/svg/close.svg" alt="close" />
              </span>
              <div
                class="users_video__raised-hand users_video__raised-hand--co-host"
                *ngIf="userInfoMap.get(coHost.profileId)?.raisedHand"
              >
                <img [src]="'assets/ui/images/svg/hand.svg'" alt="Hand"/>
              </div>
              <div
                *ngIf="secondCameraVideoTrack && coHost.profileId === stream.channel.chefProfile.id"
                id="second-camera-player"
                class="player--pip player--pip-{{ pipCameraPosition }}"
              ></div>

              <div class="co-host-name">{{ userInfoMap.get(coHost.profileId)?.displayName }}</div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="isStreamStarted && !isStreamPrepMode && !isStreamFirstPrepMode" class="viewers-count">
          <app-views-counter></app-views-counter>
        </div>
        <app-stream-popups
          [isPrepModePopupOpened]="isStreamPrepMode"
          [isConnectionPopupOpened]="isStreamPrepMode && isLowVideoProfile"
          (streamRescheduled)="rescheduleStream()"
        ></app-stream-popups>
      </div>

      <div class="users_video-wrap">
        <ng-container *ngTemplateOutlet="tabs"></ng-container>
      </div>
    </div>
    <!-- << CHEF VIDEO SECTION -->

    <!-- CHEF BOTTOM PANEL >> -->
    <div class="bottom-panel bottom-panel--chef">
      <div class="bottom-panel__button--wrap">
        <div class="tooltip-container">
          <button class="bottom-panel__control" (click)="toggleMuteEmoji()">
            <img *ngIf="!isMuteEmogi" [src]="'assets/ui/images/svg/mute-emogi-icon.svg'" alt="Mute"/>
            <img *ngIf="isMuteEmogi" [src]="'assets/ui/images/svg/unmute-emogi-icon.svg'" alt="Unmute"/>
          </button>
          <ng-container *ngTemplateOutlet="tooltip; context: { control: 'mute-emogi' }"></ng-container>
        </div>

        <div class="tooltip-container">
          <button class="bottom-panel__control" (click)="muteUserSounds()" [disabled]="isMuteUsersSound">
            <img *ngIf="!isMuteUsersSound" [src]="'assets/ui/images/svg/mute-sounds-icon.svg'" alt="Mute"/>
            <img *ngIf="isMuteUsersSound" [src]="'assets/ui/images/svg/unmute-sounds-icon.svg'" alt="Unmute"/>
          </button>
          <ng-container *ngTemplateOutlet="tooltip; context: { control: 'mute-sounds' }"></ng-container>
        </div>
      </div>
      <div class="stream-statistic-wrap">
        <div class="stream-statistic">
          <div class="stream-statistic__label stream-statistic__label--desktop">Tipped Clams</div>
          <div class="stream-statistic__label stream-statistic__label--tablet">Clams</div>
          <div class="stream-statistic__value">{{ streamInfo.tipsBalance }}</div>
        </div>
        <div class="stream-statistic stream-statistic--time">
          <div class="stream-statistic__label">Time Elapsed</div>
          <div class="stream-statistic__value">{{ streamDuration | duration }}</div>
        </div>
      </div>

      <div
        class="bottom-panel__button--wrap"
        @opacityAnimation
        *ngIf="!isTipsSuccessMessageShown; else tipsSuccessMessage"
      >
        <div class="bottom-panel__control-button-wrap">
          <button class="bottom-panel__control" (click)="toggleChefAudio()">
            <img
              *ngIf="localTracks.audioTrack && localTracks.audioTrack.enabled"
              [src]="'assets/ui/images/svg/microphone-on.svg'"
              alt="Turn on micro"
            />
            <img
              *ngIf="!localTracks.audioTrack || !localTracks.audioTrack.enabled"
              [src]="'assets/ui/images/svg/microphone-off.svg'"
              alt="Turn off micro"
            />
          </button>

          <button class="bottom-panel__control" (click)="toggleChefVideo()">
            <img
              *ngIf="localTracks.videoTrack && localTracks.videoTrack.enabled"
              [src]="'assets/ui/images/svg/camera-on.svg'"
              alt="Turn on camera"
            />
            <img
              *ngIf="!localTracks.videoTrack || !localTracks.videoTrack.enabled"
              [src]="'assets/ui/images/svg/camera-off.svg'"
              alt="Turn off camera"
            />
          </button>

          <app-invite-users-to-prep-mode-button
            *ngIf="isStreamPrepMode"
            (inviteUsersToPrepMode)="inviteUsersToPrepMode($event)"
          ></app-invite-users-to-prep-mode-button>
        </div>

        <div class="bottom-panel__button bottom-panel__button--start-stop">
          <app-button
            *ngIf="!isStreamStarted"
            data-testid="start-live-stream-btn"
            [text]="'Go live'"
            [type]="'button'"
            [iconUrl]="'assets/ui/images/svg/camera.svg'"
            [isDisabled]="isStreamStarting"
            (clicked)="startStream()"
          >
          </app-button>
          <app-button
            *ngIf="isStreamStarted"
            data-testid="end-live-stream-btn"
            [text]="'End Stream'"
            [type]="'button'"
            [role]="'warn'"
            [iconUrl]="'assets/ui/images/svg/square.svg'"
            [isDisabled]="isStreamStopping"
            (clicked)="setStopStreamModalStatus(true)"
          >
          </app-button>
        </div>
      </div>
      <ng-template #tipsSuccessMessage>
        <app-tips-success-message
          @opacityAnimation
          [tipsInfo]="tipsInfo"
          [isChef]="true"
          (tipsSuccessMessageClose)="tipsSuccessMessageToggle.emit($event)"
        ></app-tips-success-message>
      </ng-template>
    </div>
    <!-- << CHEF BOTTOM PANEL -->

    <div class="bottom-tabs-wrap">
      <ng-container *ngTemplateOutlet="tabs"></ng-container>
    </div>
  </div>
</div>

<app-select-devices-modal
  [isOpen]="isDevicesFormModalOpened"
  [isSubmitDisabled]="isDevicesSubmitFormDisabled"
  [isStreamOwner]="streamInfo.isStreamOwner"
  [microphones]="microphones"
  [cameras]="cameras"
  (formSubmit)="onDevicesFormSubmit($event)"
  (modalOpenChanged)="setDeviceModalStatus($event)"
></app-select-devices-modal>

<app-reload-page-modal
  [isOpen]="isReloadPageModalOpened"
  [message]="'Sorry, we ran into technical difficulties and your livestream isn’t recording. Try reloading the page.'"
  (needReloadPage)="onReloadPageModalEvent($event)"
></app-reload-page-modal>

<app-stream-recording-error-modal
  [isOpen]="isRecordingErrorModalOpened"
  (isContinueStream)="onRecordingErrorModalEvent($event)"
></app-stream-recording-error-modal>

<app-co-host-invite-was-send-modal
  [isOpen]="isInviteWasSendModalOpened"
  (modalOpenChanged)="setInviteWasSendModalStatus($event)"
></app-co-host-invite-was-send-modal>

<app-message-invited-users-modal
  #messageInvitedUsersModal
></app-message-invited-users-modal>

<app-stop-stream-alert-modal
  [isOpen]="isStopStreamModalOpened"
  (stopStream)="stopStream()"
  (modalOpenChanged)="setStopStreamModalStatus($event)"
></app-stop-stream-alert-modal>

<ng-template #tooltip let-control="control">
  <div *ngIf="control === 'mute-sounds' && !isMuteUsersSound" class="bottom-panel__tooltip">Mute guests</div>
  <div *ngIf="control === 'mute-emogi' && !isMuteEmogi" class="bottom-panel__tooltip">
    Mute sounds from claps & tips
  </div>
</ng-template>

<ng-template #tabs>
  <app-tabs
    (switchedTab)="onTabSwitched()"
  >
    <app-stream-info
      tab-0
      *ngIf="!isDesktopView"
      [isDesktopView]="isDesktopView"
      [stream]="stream"
      [isChefOrAdmin]="isChefOrAdmin"
      [isAskChefLoaded]="isAskChefLoaded"
      [isChefStreamOwner]="isChefStreamOwner"
      [walmartRecipeId]="walmartRecipeId"
      [walmartRecipePortions]="walmartRecipePortions"
      [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
      [tipsChefCount] ="tipsChefCount"
      (openSubscribeModal)="onClubBtnClick($event)"
      (sendTranslation)="onSubmitTranslate($event)"
    ></app-stream-info>

    <app-openweb-chat
      tab-1
      *ngIf="!isDesktopView && isAskChefLoaded"
      [chatId]="chatId"
    ></app-openweb-chat>

    <app-chef-table
      tab-2
      [userMap]="userMap"
      [userInfoMap]="userInfoMap"
      [isDesktopView]="isDesktopView"
      (componentViewInit)="subscribeToChefTableVideo()"
      (removedFromTable)="removeFromTable($event)"
      (invitedCoHost)="inviteCoHost($event)"
      (toggledUserAudio)="toggleUserAudio($event)"
    ></app-chef-table>

    <app-recipe-live-tab
      tab-3
      *ngIf="recipes[0]"
      [recipeId]="recipes[0].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_FIRST"
    ></app-recipe-live-tab>

    <app-recipe-live-tab
      tab-4
      *ngIf="recipes[1]"
      [recipeId]="recipes[1].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_SECOND"
    ></app-recipe-live-tab>

    <app-recipe-live-tab
      tab-5
      *ngIf="recipes[2]"
      [recipeId]="recipes[2].id"
      [isDesktopView]="isDesktopView"
      [tabId]="tabId.RECIPE_THIRD"
    ></app-recipe-live-tab>

    <app-shop-live-tab
      tab-6
      *ngIf="stream.products.length || walmartRecipe"
      [products]="stream.products"
      [walmartRecipe]="walmartRecipe"
    ></app-shop-live-tab>
  </app-tabs>
</ng-template>
