import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { STRIPE_URL } from '@kitch/data-access/constants';
import { SimpleResponse } from '@kitch/data-access/models';
import { CheckoutInfoPayload, CheckoutInfo } from '@kitch/data-access/models/checkout-info';
import { BuyClamsPayload, BuySubscriptionWithCardPayload } from '@kitch/data-access/models/subscription';
import { ApiService } from '@kitch/data-access/services';

import { makeUri } from '@kitch/util';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  addCreditCard(stripeToken: string): Observable<{id: string}> {
    return this.apiService.post(makeUri(STRIPE_URL, 'cards'), { source: stripeToken });
  }

  buyClams(buyClamsPayload: BuyClamsPayload): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STRIPE_URL, 'clams'), buyClamsPayload);
  }

  markCardPrimary(cardId: string): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STRIPE_URL, 'primary'), { cardId });
  }

  deleteCreditCard(cardId: string): Observable<SimpleResponse> {
    return this.apiService.delete(makeUri(STRIPE_URL, 'cards', cardId));
  }

  subscribeToVideoWithCard(buySubscriptionPayload: BuySubscriptionWithCardPayload): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STRIPE_URL, 'video-subscription'), buySubscriptionPayload);
  }

  subscribeToClubWithCard(buySubscriptionPayload: BuySubscriptionWithCardPayload): Observable<SimpleResponse> {
    return this.apiService.post(makeUri(STRIPE_URL, 'club-subscription'), buySubscriptionPayload);
  }

  getCheckoutInfo(checkoutInfoPayload: CheckoutInfoPayload): Observable<CheckoutInfo> {
    return this.apiService.post(makeUri(STRIPE_URL, 'checkout-info'), checkoutInfoPayload)
      .pipe(
        map(({ amountSubtotal, amountTotal, amountDiscount, amountTax, chargable, sessionId }): CheckoutInfo => {
          return {
            // convert cents to dollars
            amountSubtotal: amountSubtotal / 100,
            amountTotal: amountTotal / 100,
            amountDiscount: amountDiscount / 100,
            amountTax: amountTax / 100,
            chargable,
            sessionId,
          };
        }),
      );
  }
}
