import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-stream-invite-modal',
  templateUrl: './stream-invite-modal.component.html',
  styleUrls: ['./stream-invite-modal.component.scss'],
})
export class StreamInviteModalComponent implements OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalComponent;

  @Input() chefName: string;
  @Input() isInviteToPrepMode = false;
  @Input() isOpen = false;

  @Output() inviteAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(ch): void {
    if (ch.isOpen.currentValue) {
      this.modal.open();
    } else {
      this.modal?.close();
    }
  }

  emitInviteAccepted(status: boolean): void {
    this.inviteAccepted.emit(status);
    this.modal.close();
  }
}
