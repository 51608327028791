import { Component, Input } from '@angular/core';
import { Recipe } from '@kitch/data-access/models';
import { Product } from '@kitch/ui/models';

@Component({
  selector: 'app-stream-shop-card',
  templateUrl: './stream-shop-card.component.html',
  styleUrls: ['./stream-shop-card.component.scss'],
})
export class StreamShopCardComponent {
  @Input() products: Product[];
  @Input() walmartRecipe: Recipe;
  @Input() isDesktopView: boolean;

  isDisplayProductsShop = false;

  trackByFn(index: number, product: Product): string {
    return product.id;
  }

  displayProductsShop(): void {
    this.isDisplayProductsShop = true;
  }

  closeProductsShop(): void {
    this.isDisplayProductsShop = false;
  }
}
