export interface CheckoutInfoPayload {
  cardId: string,
  discount?: string,
  productId: string,
  productType: ProductType,
  profileId: string,
  quantity: number,
}

export enum ProductType {
  clamsPackage = 'clamsPackage',
  videoContent = 'videoContent',
}

export interface CheckoutInfo {
  amountDiscount: number
  amountSubtotal: number,
  amountTax: number
  amountTotal: number,
  chargable: boolean,
  sessionId: string,
}
