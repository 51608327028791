<app-modal
  (closed)="setClosedModal()"
  [class.series-modal]="isSeries"
  #purchaseModal
>
  <ng-container header>
    <ng-container *ngIf="stream">
      <h2 class="modal-title">Purchase {{ isSeries ? 'OVERVIEW' : 'Details' }}</h2>
      <div class="modal-subtitle">
        <ng-container *ngIf="isSeries; else withoutSeries">
          This stream is part of a Series! You can buy access to the
          <span class="series-title">{{ purchaseData?.title }}</span>
          series  with your credit card or clams.
          1 clam = $1 & here's a tip: you get free clams whenever you buy clams.
        </ng-container>
        <ng-template #withoutSeries>
          You can buy access to <span>{{ purchaseData?.chefProfile.chefName }}’s
          </span> stream with your <span>credit card</span> or with <span>clams</span>.
          1 clam = $1 & here's a tip: you get free clams whenever you buy clams.
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="isSeries; else contentWihoutSeries">
      <div *ngIf="purchaseData" class="content">
        <app-stream-top-card
          *ngFor="let stream of seriesStreams"
          [isNewTab]="true"
          [stream]="stream"
        ></app-stream-top-card>
      </div>
    </ng-container>

    <ng-template #contentWihoutSeries>
      <div *ngIf="purchaseData" class="modal-content-wrap">
        <div>
          <div class="stream-status">
            <ng-container *ngIf="purchaseData.status !== streamStatus.PAST">
              <div class="date-container">
                <div class="date-container__date">
                  {{ purchaseData?.scheduledVideo?.date | timezone }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="purchaseData?.status === streamStatus.PAST">
              <div class="stream-status__past">
                <img
                  [src]="'assets/ui/images/svg/show-indicator.svg'"
                  width="42"
                  height="28"
                  alt="show-indicator"
                />
              </div>
            </ng-container>
            <img
              class="paid-indicator"
              [src]="'assets/ui/images/svg/paid-indicator.svg'"
              alt="paid-indicator"
              width="24"
              height="24"
            />
          </div>
          <div class="stream-title">{{ purchaseData?.title }}</div>
          <div
            #description
            class="stream-description"
            [innerHTML]="purchaseData?.description"
          ></div>
          <a
            *ngIf="isShowLinkToDetailStream"
            class="stream-link"
            [routerLink]="'/' + purchaseData.chefProfile.chefSlug + '/streams/' + purchaseData.slug"
            (click)="closeModal()"
          >Learn more</a>
          <app-avatar
            *ngIf="!purchaseData.collaborators?.length; else multipleAvatars"
            [url]="purchaseData.chefProfile.photo"
            [size]="'scheduled'"
            [name]="purchaseData.chefProfile.chefName"
            [title]="purchaseData.channelName"
            [isLive]="purchaseData.chefProfile.isLive"
          ></app-avatar>
          <ng-template #multipleAvatars>
            <app-multiple-avatars [chefs]="purchaseData.collaborators!"></app-multiple-avatars>
          </ng-template>
        </div>
      </div>
    </ng-template>

    <div class="form__item form__item--center">
      <div class="form-button form-button--close">
        <app-button
          appNeedAuthorize
          [source]="'buy with clams'"
          [streamId]="purchaseData?.id"
          [afterLoginAction]="showPurchaseModal"
          class="modal-button buy-clams-button"
          [text]="balance && !isGuest ? 'Clams' : 'Buy with Clams'"
          (clicked)="buyWithClams()"
        >
          <ng-container right-side>
            <div class="clams">
              <span class="clams__text price-text">{{ purchaseData?.requiredSubscriptionPlan?.price }}</span>
              <img
                src="assets/ui/images/svg/clam.svg"
                width="48"
                height="48"
                alt="clam"
              />
            </div>
          </ng-container>
        </app-button>
      </div>
      <span>or</span>
      <div class="form-button form-button--close">
        <app-button
          appNeedAuthorize
          data-testid="buy-with-credit-card-btn"
          [source]="'buy with credit card'"
          [streamId]="purchaseData?.id"
          [afterLoginAction]="showPurchaseModal"
          role="'secondary'"
          class="modal-button"
          [text]="'Credit card'"
          (clicked)="buyCreditCard()"
        >
          <ng-container right-side>
            <div class="clams">
              <span class="clams__text">$ {{ purchaseData?.requiredSubscriptionPlan?.price }}</span>
            </div>
          </ng-container>
        </app-button>
      </div>
    </div>
  </ng-container>
</app-modal>

<app-buy-clams #buyClamsComponent></app-buy-clams>

<app-confirm-purchase-modal
  *ngIf="isConfirmPurchaseModalOpen"
  [card]="primaryCreditCard"
  [purchaseData]="purchaseData"
  [purchaseCurrency]="buySeriesModalType"
  [isHardcodedSeries]="isHardcodedSeries"
  [isBuyGift]="isSeries && buySeriesModalType === 'USD'"
  [isClub]="isClub"
  (purchaseModalOpen)="setPurchaseModalOpen()"
  (confirmPurchaseModalOpen)="setConfirmPurchaseModalStatus($event)"
></app-confirm-purchase-modal>

<app-add-credit-card-modal
  [isOpen]="isAddCreditCardModalOpen"
  [isClub]="isClub"
  (confirmPurchaseModalOpen)="openCreditCardModalAfterAddingCard()"
  (addCreditCardModalOpen)="setAddCreditCardModalOpen($event)"
></app-add-credit-card-modal>

<app-buy-series-modal
  *ngIf="bundleStreams?.length && purchaseData && bundle"
  [type]="buySeriesModalType"
  [bundleStreams]="bundleStreams"
  [seriesInfo]="bundle"
  [purchaseData]="purchaseData"
  [isOpen]="isBuySeriesModalOpen"
  (isBuySeriesModalOpen)="setBuySeriesModalOpen($event)"
  (openConfirmModal)="openConfirmModal($event)"
></app-buy-series-modal>

<app-join-club-modal
  *ngIf="isBuyClubModalOpen"
  [purchaseClub]="purchaseClub"
  (joinClub)="joinClub()"
  (setBuyClubModalStatus)="setBuyClubModalStatus($event)"
></app-join-club-modal>
