import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GA_EVENT_VIEW_PRODUCT_CLICK } from '@kitch/data-access/constants';
import { TokenService } from '@kitch/data-access/services';

@Injectable({
  providedIn: 'root',
})
export class GoogleTrackerService {
  constructor(
    private tokenService: TokenService,
    private $gaService: GoogleAnalyticsService,
  ) {
  }

  trackViewProductLink(label: string, url: string, source: string): void {
    this.$gaService.gtag('event', GA_EVENT_VIEW_PRODUCT_CLICK, {
      profile_id: this.tokenService.getProfileId(),
      product_label: label,
      product_source: source,
      product_url: url,
    });
  }
}
