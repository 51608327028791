<div class="prompt-container">
  <div class="prompt-label">Want to participate?</div>

  <div class="join-container">
    <div class="join-wrap">
      <div class="join-text-wrap">
        <div class="join-title">JOIN {{stream.channel.name}}’S CLUB</div>
        <div class="join-text">
          For {{ this.stream.subscriptionPlans?.channel.price | currency: this.stream.subscriptionPlans.channel.currency }}/mo, join {{stream.channel.name}}’s club to participate in this stream,
          plus unlimited access to the Chef’s Table, exclusive videos, content, and recipes.
        </div>
      </div>
      <div class="join-button">
        <app-button
          [text]="'Join'"
          [role]="'secondary'"
          [isDisabled]="true"
          (clicked)="onJoinClub()"
        ></app-button>
      </div>
    </div>
  </div>

  <div class="join-container join-container--by-clams">
    <div class="join-wrap">
      <div class="join-text-wrap">
        <div class="join-title join-title--by-clams">Buy access to this stream</div>
        <div class="join-text join-text--by-clams">
          Make a single payment to watch this stream if you don’t want to join the club.
        </div>
      </div>
      <div class="join-button join-button--by-clams">
        <app-button
          [text]="'buy now'"
          [role]="'secondary-white'"
          (clicked)="onBuyAccess()"
        >
          <ng-container right-side>
<!--            <span class="clams-count">{{stream.subscriptionPlans.video.price}}</span>-->
            <img
              class="clams-icon"
              src="{{ 'assets/ui/images/svg/clam.svg' }}"
              width="48"
              height="48"
              alt="clams"
            />
          </ng-container>
        </app-button>
      </div>
    </div>
  </div>

  <div class="close">Want to just watch the stream? <span (click)="onHidePaywall()">Keep watching</span></div>
</div>
<div class="prompt-background"></div>
