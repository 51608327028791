<div class="stream-page" *ngIf="stream">
  <div class="stream-page__banner">
    <!-- THUMBNAIL_PICTURE >> -->
    <div class="stream-page__thumbnail">
      <picture>
        <source srcset="{{ stream.thumbnailUrl }}"/>
        <img
          height="500"
          width="500"
          src="assets/ui/images/png/no-image.png"
          alt="{{ stream.channel.name }}"
        />
      </picture>

      <app-stream-shop-card
        *ngIf="stream.products.length || walmartRecipe"
        [walmartRecipe]="walmartRecipe"
        [products]="stream.products"
        [isDesktopView]="isDesktopView"
      ></app-stream-shop-card>
    </div>
    <!-- << THUMBNAIL_PICTURE -->

    <!--  CARD SIDE CONTENT >> -->
    <div class="stream-page__content">

      <!-- DATE, PAID, ACCESS INDICATORS  >> -->
      <div class="date-indicators-row">
        <div class="stream-page__container-date">
          <div class="stream-page__date">{{ stream.scheduledVideo.date | timezone }}</div>
        </div>
        <div
          *ngIf="isPaidIndicatorShown"
          class="paid-indicator-container"
        >
          <img
            class="paid-indicator"
            [src]="'assets/ui/images/svg/paid-indicator-light.svg'"
            alt="paid-indicator"
          />
        </div>
        <app-access-indicator
          [isVideoPrivate]="stream.videoPrivate || stream.hidden"
          [isPurchased]="stream.subscribed || stream.subscribedToClub"
        ></app-access-indicator>
      </div>
      <!-- << DATE, PAID, ACCESS INDICATORS -->

      <h1 class="stream-page__channel-name">
        {{ stream.title }}
      </h1>

      <!-- AVATAR(S)  >> -->
      <div class="stream-page__avatar">
        <a
          *ngIf="!stream.collaborators?.length"
          [routerLink]="'/' + stream.channel.chefProfile.chefSlug"
        >
          <app-avatar
            [url]="stream.channel.chefProfile.photo "
            [size]="'scheduled'"
            [name]="stream.channel.chefProfile.chefName"
            [title]="stream.channel.name"
            [isLive]="stream.channel.chefProfile.isLive"
            [isClub]="!!stream.requiredClubSubscriptionPlan"
            [avatarInputTextColor]="'green'"
          ></app-avatar>
        </a>
        <app-multiple-avatars
          *ngIf="stream.collaborators?.length"
          [chefs]="stream.collaborators"
          [backgroundColor]="'green'"
        ></app-multiple-avatars>
      </div>
      <!-- << AVATAR(S) -->

      <!-- ACTION BUTTONS: CLUB, BY STREAM, ADD TO CALENDAR, SAVE MY SPOT >> -->
      <div class="stream-page__action-button">
        <app-club-button
          *ngIf="isUnpaidClub; else buyStreamButton"
          [stream]="stream"
          [isDisabled]="stream.subscribedToClub"
          [isJoinShown]="false"
        ></app-club-button>

        <ng-template #buyStreamButton>
          <app-buy-stream-button
            *ngIf="isPaymentRequire && !stream.requiredClubSubscriptionPlan; else addToCalendarButton"
            [stream]="stream"
          ></app-buy-stream-button>
        </ng-template>

        <ng-template #addToCalendarButton>
          <app-add-to-calendar-button
            *ngIf="stream.subscribed || stream.subscribedToClub; else saveMySpotButton"
            [stream]="stream"
          ></app-add-to-calendar-button>
        </ng-template>

        <ng-template #saveMySpotButton>
          <app-save-my-spot-button [stream]="stream"></app-save-my-spot-button>
        </ng-template>
      </div>
      <!-- << ACTION BUTTONS: CLUB, BY STREAM, ADD TO CALENDAR, SAVE MY SPOT -->

      <div *ngIf="recipes.length" class="recipes-link-wrap">
        <!-- VIEW RECIPE LINK(1 RECIPE) >> -->
        <ng-container *ngIf="recipes.length === 1">
          <ng-container
            *ngTemplateOutlet="recipeLink; context: {
            recipeChefSlug: recipes[0].profile.chefSlug,
            recipeSlug: recipes[0].slug
          }"
          ></ng-container>
        </ng-container>
        <!-- << VIEW RECIPE LINK(1 RECIPE) -->

        <!-- VIEW RECIPE LINK(SOME RECIPES) >> -->
        <ul *ngIf="recipes.length > 1">
          <li *ngFor="let recipe of recipes">
            <ng-container
              *ngTemplateOutlet="recipeLink; context: {
              recipeChefSlug: recipe.profile.chefSlug,
              recipeSlug: recipe.slug,
              recipeName: recipe.recipeName
            }"
            ></ng-container>
          </li>
        </ul>
        <!-- << VIEW RECIPE LINK(SOME RECIPES) -->
      </div>
    </div>
    <!-- << CARD SIDE CONTENT -->
  </div>

  <!-- CHEF TOOLS >> -->
  <div *ngIf="isShowChefTools" class="chef-tools">
    <h3 class="chef-tools__title">Chef Tools</h3>
    <app-share-buttons
      class="chef-tools__button chef-tools__button--share"
      [buttonTitle]="'Share'"
      [title]="stream.title"
      [description]="stream.description"
      [image]="stream.thumbnailUrl"
      [role]="'secondary-big'"
    ></app-share-buttons>

    <app-button
      class="chef-tools__button chef-tools__button--edit"
      [text]="'Edit'"
      [iconUrl]="'assets/ui/images/svg/edit.svg'"
      [iconHeight]="20"
      [iconWidth]="21"
      [role]="'secondary-big'"
      (clicked)="onEditStream()"
    ></app-button>

    <app-button
      *ngIf="isChefStreamOwner"
      class="chef-tools__button chef-tools__button--pred-mode"
      [text]="'Enter Prep Mode'"
      [iconUrl]="'assets/ui/images/svg/video-camera.svg'"
      [iconHeight]="24"
      [iconWidth]="24"
      [role]="'secondary-big'"
      (clicked)="onEnterPrepMode()"
    ></app-button>
  </div>
  <!-- << CHEF TOOLS -->

  <div class="separator separator-add-to-calendar" *ngIf="isMobileScreen"></div>

  <div
    class="upcoming-tabs"
    *ngIf="!isDesktopView"
  >
    <app-tabs
      (switchedTab)="onTabSwitched()"
    >
      <app-stream-info
        tab-0
        [isDesktopView]="isDesktopView"
        [stream]="stream"
        [isChefOrAdmin]="isChefOrAdmin"
        [isAskChefLoaded]="true"
        [isChefStreamOwner]="isChefStreamOwner"
        [isScheduled]="true"
        [walmartRecipeId]="walmartRecipeId"
        [walmartRecipePortions]="walmartRecipePortions"
        [isTipsSuccessMessageShown]="isTipsSuccessMessageShown"
        [tipsChefCount] ="tipsChefCount"
        [chatId]="chatId"
        (tipToChef)="tipToChef.emit($event)"
        (openSubscribeModal)="openSubscribeModal.emit($event)"
      ></app-stream-info>

      <app-openweb-chat
        tab-1
        *ngIf="isAskChefLoaded"
        [chatId]="chatId"
      ></app-openweb-chat>

      <app-recipe-live-tab
        tab-3
        *ngIf="recipes[0]"
        [recipeId]="recipes[0].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_FIRST"
      ></app-recipe-live-tab>

      <app-recipe-live-tab
        tab-4
        *ngIf="recipes[1]"
        [recipeId]="recipes[1].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_SECOND"
      ></app-recipe-live-tab>

      <app-recipe-live-tab
        tab-5
        *ngIf="recipes[2]"
        [recipeId]="recipes[2].id"
        [isDesktopView]="isDesktopView"
        [tabId]="tabId.RECIPE_THIRD"
      ></app-recipe-live-tab>

      <app-shop-live-tab
        tab-6
        *ngIf="stream.products.length || walmartRecipe"
        [products]="stream.products"
        [walmartRecipe]="walmartRecipe"
      ></app-shop-live-tab>
    </app-tabs>
  </div>
</div>


<!-- VIEW RECIPE LINK TEMPLATE >> -->
<ng-template
  #recipeLink
  let-recipeChefSlug="recipeChefSlug"
  let-recipeSlug="recipeSlug"
  let-recipeName="recipeName"
>
  <a
    [routerLink]="'/' + recipeChefSlug + '/recipes/' + recipeSlug"
    target="_blank"
    class="stream-page__recipe-link"
  >
    <img
      src="assets/ui/images/svg/recipe-icon-black.svg"
      alt="recipes icon"
      class="stream-page__recipe-link-icon"
      width="23"
      height="20"
    />
    View
    <!-- SOME RECIPES TEXT: CAPPED RECIPE NAME >> -->
    <ng-container *ngIf="recipeName; else nameStub">
      {{ recipeName | limitWords }}
    </ng-container>
    <!-- << SOME RECIPES TEXT: CAPPED RECIPE NAME -->
    <!-- 1 RECIPE TEXT >> -->
    <ng-template #nameStub>
      recipe
    </ng-template>
    <!-- << 1 RECIPE TEXT -->
  </a>
</ng-template>
<!-- << VIEW RECIPE LINK TEMPLATE -->
