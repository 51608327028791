<div class="open-web-header-wrap" [attr.data-open-web-id]="chatId">
  <h2 class="open-web-header">{{ title }}</h2>

  <div class="open-web-comments-counter">
    (<div #commentCounterEl></div> <span>Comments</span>)
  </div>
</div>

<div class="open-web-wrapper" #openWebWrapper>
  <div data-spotim-module="pitc"></div>
</div>

<app-login-register-modal-wrapper
  [userRole]="userRole"
  [refCode]="refCode"
  [isLoginModal]="isLoginModalOpened"
  [isRegisterModal]="isRegisterModalOpened"
  [source]="'ask a chef'"
  (loginModalClosed)="onLoginModalClosed()"
  (registerModalClosed)="onRegisterModalClosed()"
></app-login-register-modal-wrapper>
