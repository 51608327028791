<div
  *ngIf="!isTipsSuccessMessageShown"
  @opacityAnimation
  class="tips-wrapper"
>
  <app-button
    class="tips-wrapper__button-left"
    appNeedAuthorize
    [source]="'send tips'"
    [text]="tipBtnTitle"
    [type]="'button'"
    [role]="role"
    [iconHeight]="48"
    [iconWidth]="48"
    [iconUrl]="'assets/ui/images/svg/clam.svg'"
    [isSmallOnTablet]="isSmallOnTablet"
    [buttonClass]="'tips'"
    (clicked)="onTipToChef(1)"
  ></app-button>

  <app-button
    class="tips-wrapper__button-right"
    [text]="''"
    [type]="'button'"
    [role]="role"
    [iconWidth]="18"
    [iconHeight]="10"
    [iconUrl]="'assets/ui/images/svg/dropdown.svg'"
    [isSmallOnTablet]="isSmallOnTablet"
    [buttonClass]="'tips tips-chevron'"
    (clicked)="onToggleDropdown()"
  ></app-button>

  <div
    *ngIf="isShownDropdown"
    class="tips-dropdown"
  >
    <button
      class="tips-dropdown__button"
      type="button"
      appNeedAuthorize
      [source]="'send tips'"
      (click)="onTipToChef(5)"
    >
      <img
        class="tips-dropdown__icon"
        height="45"
        width="31"
        [src]="'assets/ui/images/svg/buy-drink.svg'"
        alt="clams"
      />
      <div class="tips-dropdown__text-wrapper">
        <h3 class="tips-dropdown__title">tip a drink</h3>
        <p class="text tips-dropdown__text">5 clams</p>
      </div>
      <span class="tips-dropdown__price">
        <p class="text tips-dropdown__price-text">$5</p>
      </span>
    </button>

    <button
      class="tips-dropdown__button"
      type="button"
      appNeedAuthorize
      [source]="'send tips'"
      (click)="onTipToChef(25)"
    >
      <img
        class="tips-dropdown__icon"
        height="42"
        width="39"
        [src]="'assets/ui/images/svg/buy-dinner.svg'"
        alt="clams"
      />
      <div class="tips-dropdown__text-wrapper">
        <h3 class="tips-dropdown__title">tip a dinner</h3>
        <p class="text tips-dropdown__text">25 clams</p>
      </div>
      <span class="tips-dropdown__price">
        <p class="text tips-dropdown__price-text">$25</p>
      </span>
    </button>

    <button
      class="tips-dropdown__button"
      type="button"
      appNeedAuthorize
      [source]="'send tips'"
      (click)="onTipToChef(100)"
    >
      <img
        class="tips-dropdown__icon"
        height="36"
        width="37"
        [src]="'assets/ui/images/svg/buy-day-off.svg'"
        alt="clams"
      />
      <div class="tips-dropdown__text-wrapper">
        <h3 class="tips-dropdown__title">tip a day off</h3>
        <p class="text tips-dropdown__text">100 clams</p>
      </div>
      <span class="tips-dropdown__price">
        <p class="text tips-dropdown__price-text">$100</p>
      </span>
    </button>
  </div>
</div>
