import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { concatMap, finalize, first, mergeMap, tap } from 'rxjs/operators';
import { CheckoutInfo, ProductType } from '@kitch/data-access/models/checkout-info';
import { ClamsPlan } from '@kitch/data-access/models/clam';
import { CreditCard } from '@kitch/data-access/models/credit-card';
import { ProductsService } from '@kitch/data-access/services/products.service';
import { ProfilesService } from '@kitch/data-access/services/profiles.service';
import { TokenService } from '@kitch/data-access/services/token.service';
import { SelectedClamsPlan } from '@kitch/ui/components/clams-plan/clams-plan.component';
import { ModalComponent } from '@kitch/ui/components/modal/modal.component';
import { UserProfileService } from '@kitch/user/core/user-profile.service';
import { StripeService } from '@kitch/user/shared/services/stripe.service';

@UntilDestroy()
@Component({
  selector: 'app-buy-clams',
  templateUrl: './buy-clams.component.html',
  styleUrls: ['./buy-clams.component.scss'],
})
export class BuyClamsComponent implements OnInit {
  @Input() selectedClamsPlan: ClamsPlan;

  @ViewChild('clamsSelectModal', { static: false }) clamsSelectModal: ModalComponent;
  @ViewChild('clamsPurchaseModal', { static: false }) clamsPurchaseModal: ModalComponent;
  @ViewChild('successfulClamsPurchaseModal', { static: false }) successfulClamsPurchaseModal: ModalComponent;

  userClamsBalance = 0;

  checkoutInfo: CheckoutInfo;
  clamPlansFetching = false;
  clamsPlans: ClamsPlan[];
  clamsPlansLimit = 4;

  primaryCreditCard: CreditCard;
  profileId: string;

  isModalSubmitting = false;

  constructor(
    private stripeService: StripeService,
    private userProfileService: UserProfileService,
    private tokenService: TokenService,
    private profilesService: ProfilesService,
    private productsService: ProductsService,
  ) { }

  get selectedClamsPlanIndex(): number {
    return this.clamsPlansLimit ?
      this.clamsPlans?.findIndex(({ id }) => id === this.selectedClamsPlan.id) + 2 : 1;
  }

  ngOnInit(): void {
    this.userProfileService.userProfile$
      .subscribe(profile => {
        this.userClamsBalance = profile.balance;
        this.profileId = profile.id;
      });
    this.getUserCards().subscribe();
  }

  open(plan?: ClamsPlan): void {
    if (!this.clamsPlans) {
      this.getClamsPlans();
    }
    if (plan) {
      this.selectedClamsPlan = plan;

      this.proceedToPurchaseModal();
    } else {
      this.openClamsSelectModal();
    }
  }

  clamsPlanSelectHandler({ clamsPlan }: SelectedClamsPlan): void {
    this.selectedClamsPlan = clamsPlan;

    this.proceedToPurchaseModal();
  }

  clamsPlanChangeHandler(): void {
    this.clamsPurchaseModal.close();

    this.openClamsSelectModal();
  }

  buttonBuyClamsClickHandler(): void {
    const buyClamsPayload = {
      packageId: this.selectedClamsPlan.id,
      cardId: this.primaryCreditCard.id,
      sessionId: this.checkoutInfo.sessionId,
    };

    this.isModalSubmitting = true;

    this.stripeService
      .buyClams(buyClamsPayload)
      .pipe(
        finalize(() => {
          this.isModalSubmitting = false;
        }),
      )
      .subscribe(() => {
        this.userProfileService.updateUserBalance();

        this.clamsPurchaseModal.close();
        this.successfulClamsPurchaseModal.open();
      });
  }

  closeSuccessfulClamsPurchaseModal(): void {
    this.successfulClamsPurchaseModal.close();
  }

  creditCardCreatedHandler(token: string): void {
    this.isModalSubmitting = true;

    this.stripeService
      .addCreditCard(token)
      .pipe(
        concatMap(() => this.getUserCards()),
        mergeMap(() => this.getCheckoutInfo()),
        finalize(() => {
          this.isModalSubmitting = false;
        }),
      )
      .subscribe(checkoutInfo => {
        if (checkoutInfo) {
          this.checkoutInfo = checkoutInfo;
        }
      });
  }

  private openClamsSelectModal(): void {
    this.clamsSelectModal.open();
  }

  private proceedToPurchaseModal(): void {
    this.clamsSelectModal.close();
    this.clamsPurchaseModal.open();

    this.getCheckoutInfo().subscribe((checkoutInfo) => {
      this.checkoutInfo = checkoutInfo;
    });
  }

  private getClamsPlans(): void {
    this.clamPlansFetching = true;

    this.productsService.getClamPlans()
      .pipe(
        untilDestroyed(this),
        first(),
      )
      .subscribe(plans => {
        this.clamsPlans = plans.sort((a, b) => a.clams - b.clams).slice(0, this.clamsPlansLimit);
        this.clamPlansFetching = false;
      });
  }

  private getUserCards(): Observable<CreditCard[]> {
    return this.profilesService.getUserCards(this.tokenService.getProfileId()).pipe(
      tap(cards => {
        this.primaryCreditCard = cards.find(({ primary }) => primary);
      }),
    );
  }

  private getCheckoutInfo() {
    const checkoutInfoPayload = {
      cardId: this.primaryCreditCard.id,
      productId: this.selectedClamsPlan.id,
      productType: ProductType.clamsPackage,
      profileId: this.profileId,
      quantity: 1,
    };

    return this.stripeService.getCheckoutInfo(checkoutInfoPayload);
  }
}
