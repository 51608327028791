import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from '@kitch/ui/components';

@Component({
  selector: 'app-message-invited-users-modal',
  templateUrl: './message-invited-users-modal.component.html',
  styleUrls: ['./message-invited-users-modal.component.scss'],
})
export class MessageInvitedUsersModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  title: string;

  openAcceptedInviteModal(): void {
    this.modal.open();
    this.title = 'A guest has joined the stream.';
  }

  openDeclineInviteModal(inviteUserName: string): void {
    this.modal.open();
    this.title = `${inviteUserName} declined your invitation.`;
  }
}
